<template>
  <div>
    <div class="index3_clt_one">
      <div class="tab-wrapper">
        <ul class="tab-menu tab-menu-form">
          <li
            :class="tabIndex == index ? 'active' : ''"
            v-for="(i, index) in dataList"
            @click="getTab(i, index)"
          >
            {{ "材料" + (index + 1) }}
          </li>
        </ul>
        <div class="tab-content">
          <div>
            <div class="cai1">
              <p
                v-html="detail.desc"
                :style="
                  fontType == 14
                    ? 'font-size:14px'
                    : fontType == 18
                    ? 'font-size:18px'
                    : 'font-size:16px'
                "
              ></p>
              <img :src="detail.image.url" v-if="detail.image" />
              <BjDianbo
                v-show="detail.video_id"
                ref="BjDianbo"
                @play="playing = 1"
                @pause="playing = 0"
                @ended="endView"
              ></BjDianbo>
            </div>
            <div class="index3_fen">
              <img src="@/assets/img/index3_fen.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BjDianbo from "@/components/BjDianbo";
export default {
  name: "index",
  components: {
    BjDianbo,
  },
  props: {
    dataList: Array,
  },
  data() {
    return {
      tabIndex: 0,
      detail: {},
      fontType: 16,
    };
  },
  mounted() {
    var _this = this
     if (this.detail.video_id) {
      console.log(_this.$refs,'创建')
      _this.$refs.BjDianbo.init(
        _this.detail.video_token,
        _this.detail.video_id
      );
    }
  },
  methods: {
    getTab(i, index) {
      console.log(i);
      this.detail = i;
      this.tabIndex = index;
    },
    fontInit(type) {
      this.fontType = type;
    },
  },
  created() {
    let _this = this;
    this.detail = this.dataList[0];
   
  },
};
</script>
<style scoped>
.tab-menu {
  border: none;
  margin-top: 20px;
}
.tab-menu li.active::after {
  content: "";
  position: absolute;
  left: 1px;
  bottom: -8px;
  width: 78px;
  height: 8px;
  border-radius: 4px;
  background: #d7dff4;
  z-index: -1;
}
.tab-menu li {
  margin-right: 30px;
}
.cai1 {
  margin-bottom: 30px;
}
</style>