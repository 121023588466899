<template>
  <div>
    <div class="per3_right per3_right1 per_right">
      <div class="p_remind_title p_remind_title1">
        <img src="@/assets/img/remind_icon.png" />
        <h6>设置</h6>
      </div>
    </div>
    <div class="per3_right per3_right2 per_right">
      <div class="per7_integral">
        <!-- tab -->
        <div class="per7_in2">
          <div class="tab-wrapper">
            <ul class="tab-menu tab-menu-form tab_cxfs">
              <li
                :class="tabIndex == index ? 'active' : ''"
                @click="getTab(index)"
                v-for="(i, index) in tabList"
              >
                {{ i }}
              </li>
            </ul>
            <div class="tab-content">
              <!-- 1 -->
              <div v-if="tabIndex == 0">
                <editInformation></editInformation>
              </div>
              <!-- 2 -->
              <div v-if="tabIndex == 1">
                <!-- 旧手机号页面 -->
                <EditPhone></EditPhone>
              </div>
              <!-- 3 -->
              <div v-if="tabIndex == 2">
                <!-- 已设置密码 -->
                <EditPassword></EditPassword>
              </div>
              <!-- 4 -->
              <!-- <div v-if="tabIndex == 3">
                <div class="set set4">
                  <div class="set4_l"  @click="replaceQQ">
                    <a class="disanf">
                      <div class="set4_l_wen">
                        <img src="@/assets/img/set4.png" />
                        <p>QQ账号</p>
                      </div>
                      <div class="set4_l_wen set4_r_wen">
                        <p>{{$store.state.user.has_openid == 1?$store.state.user.nickname:'未绑定'}}</p>
                        <img src="@/assets/img/set4_1.png" />
                      </div>
                    </a>
                  </div>
                  <div class="set4_l set4_r" @click="replaceWechat">
                    <div
                      class="set4_r1"
                      style="display: inline-block; width: 50%"
                    >
                      <a class="disanf">
                        <img src="@/assets/img/set4_wei.png" />
                        <p>微信登录</p>
                      </a>
                    </div>
                    <div class="s_rs">
                      <p>{{$store.state.user.wechat_name ? $store.state.user.wechat_name:'未绑定'}}</p>
                      <img src="@/assets/img/set4_3.png" />
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- 5 -->
              <div v-if="tabIndex == 3">
                  <Address></Address>
              </div>
              <!-- 6 -->
              <div v-if="tabIndex == 4">
                <!-- 已绑定微信 -->
                <div class="set set7" v-if="$store.state.user.has_openid == 1">
                  <div class="set7_p">
                    <p>您当前绑定的微信号</p>
                  </div>
                  <div class="set6">
                    <a href="">
                      <img :src="$store.state.user.head_portrait.url" />
                      <span>{{ $store.state.user.nickname }}</span>
                    </a>
                  </div>
                  <div class="set7_a1">
                    <a class="genhuan" @click="replaceWechat">更换微信号</a>
                  </div>
                </div>
                <!-- 未绑定微信 -->
                <div class="set set7" v-else>
                  <div class="set7_p">
                    <p>还未绑定微信</p>
                  </div>
                  <div class="set7_a1" style="margin-top: 30px">
                    <a class="shouquan" @click="replaceWechat">点击绑定</a>
                  </div>
                </div>
              </div>
              <!-- 7 -->
              <div v-if="tabIndex == 5">
                <EditPayPassword></EditPayPassword>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 页码 -->
      <!-- <div class="page mt20">
					<a href="">&lt;&lt;</a>
					<a class="active" href="">1</a>
					<a href="">2</a>
					<a href="">&gt;&gt;</a>
				</div> -->
    </div>
   
  </div>
</template>

<script>
import SrButton from "@/components/SrButton";
import SrMessage from "@/components/SrMessage";
import storage from "@/utils/storage";
import editInformation from './modules/editInformation'
import EditPhone from './modules/EditPhone'
import EditPassword from './modules/EditPassword'
import EditPayPassword from './modules/EditPayPassword'
import Address from './modules/Address'

import { uploadImage, sendMessage, location } from "@/api/common";
export default {
  name: "UserSetting",
  components: {
    SrButton,
    SrMessage,
    editInformation,
    EditPhone,
    EditPassword,
    EditPayPassword,
    Address
  },
  data() {
    return {
      tabList: [
        "修改资料",
        "修改手机号",
        "修改密码",
        "收货地址",
        "绑定微信",
        "支付密码",
      ],
      tabIndex: 0,
  
   
    };
  },
  methods: {
    getTab(index) {
      this.tabIndex = index;
    },
  
 
    replaceWechat() {
      storage.setItem("socialiteType",'bind')
      window.location.href =
        "https://api.srgongkaow.com/web/socialite/weixinweb";
    },
    replaceQQ(){
       window.location.href =
        "https://api.srgongkaow.com/web/socialite/qq";
    }
  },
  created() {
    console.log(this.$store.state.user);
    this.nickImg = this.$store.state.user.head_portrait.url;
  
  },
};
</script>

<style scoped>
.set6 img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
}
.s_rs {
  float: right;
}
.s_rs p {
  display: inline-block;
}
.s_rs > img {
  margin: 19px 0 0 10px;
}
.tab-menu li {
    float: left;
    width: 80px;
    margin-right: 53px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    font-size: 16px;
}
.set4_r{
  text-align: left;
}
</style>