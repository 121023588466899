<template>
    <div>
        <a-spin tip="Loading..."
                :spinning="loading">
            <a-icon slot="indicator"
                    type="loading"
                    style="font-size: 24px"
                    spin/>
            <ul class="kc-down">
                <li v-for="item in list"
                    :key="item.houdout_id">
                    <p style="width: 150px;"
                       v-if="item.type===1"><img src="@/assets/img/public-version.jpg"></p>
                    <p style="width: 150px;"
                       v-else><img src="@/assets/img/student-edition.jpg"></p>
                    <p>
                        <span class="f18">{{item.name}}</span>
                        <span class="color-999">{{$moment(item.created_at).format('YYYY.MM.DD')}}</span>
                    </p>
                    <p style="width: 35px;"><a @click="download(item)"><img src="@/assets/img/down.png"></a></p>
                </li>
            </ul>
        </a-spin>
    </div>
</template>

<script>
    import {download} from "@/api/school/handout"

    export default {
        name: "HandoutList",
        props: {
            list: {}
        },
        data() {
            return {
                loading: false
            }
        },
        methods: {
            download(item) {
                var _self = this
                _self.loading = true
                download(item).then((res) => {
                    _self.loading = false
                    if (res.status === 200) {
                        window.open(res.result)
                    } else {
                        _self.$srmsg.info({
                            title: '提示',
                            content: res.msg
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
