<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的训练营</h6>
      </div>
      <div class="per3_nr">
        <ul v-if="list.length > 0">
          <li v-for="i in list" @click="goDetail(i.relation_id)">
            <a>
              <div class="per3_camp">
                <img v-if="i.relation.image" :src="i.relation.image.url" />
              </div>
              <div class="per3_camp_nr">
                <h6>{{i.relation.name}}</h6>
                <p>已有{{i.relation.user_clock_count}}人完成打卡<span>{{i.relation.end}}/{{i.relation.all}}课</span></p>
              </div>
              <div class="per3_sign" v-if="i.qiandao == 1">
                <img src="@/assets/img/sign_1.png" />
                <p>未签到</p>
              </div>
              <div class="per3_sign" v-else>
                 <img src="@/assets/img/qiandao.png" />
                <p>已签到</p>
              </div>
            </a>
          </li>
          <!-- <li>
            <a>
              <div class="per3_camp">
                <img src="@/assets/img/camp1.jpg" />
              </div>
              <div class="per3_camp_nr">
                <h6>训练营文字版标题</h6>
                <p>已有1000人完成打卡<span>1/7课</span></p>
              </div>
              <div class="per3_sign1">
                <img src="@/assets/img/sign_2.png" />
              </div>
            </a>
          </li> -->
    
        </ul>
        <div class="zanwu" v-if="list.length == 0">
          <img src="@/assets/img/zanwu.png" alt="" />
          暂无数据
        </div>
        <!-- 页码 -->
      </div>
      <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
      ></SrPaginate>
    </div>
  </div>
</template>
<script>
import { myCamp } from "@/api/user";
import SrPaginate from "@/components/SrPaginate";
export default {
  name: "MyCamp",
  components: {
    SrPaginate,
  },
  data() {
    return {
      list: [],
      pagination: {
        page: 1,
        page_size: 9,
      },
    };
  },
  methods: {
    loadList() {
      let _this = this;
      myCamp({..._this.pagination}).then((res) => {
        console.log(res, "训练营------");
        var data = res.result.items;
         _this.list= [];
           data.map(function(s){
          if(s.relation.name != '无'){
            _this.list.push(s)
          }
        })
        _this.pagination = res.result.pagination;
        _this.list.map(function(n){
          // 1未签到 2已签到
          if(n.relation.begin + n.relation.end > n.relation.user_theme_clock){
            n.qiandao = 1
          }else{
            n.qiandao = 2
          }
        })

      });
    },
    goDetail(id){
       this.$router.push({ path: "/camp/info/"+ id });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style scoped>
.zanwu {
  margin: 0 auto;
  text-align: center;
  margin-top: 130px;
}
.zanwu img {
  width: 200px;
  margin: 0 auto;
}
</style>
