<template>
    <div>
        <dl v-if="$store.state.user.is_login"
            class="kc-evaluate clearfix">
            <dt class="fn_l"><img :src="$store.state.user.head_portrait.url"></dt>
            <dd class="fn_r">
                <div class="clearfix">
                    <input class="fn_l"
                           type="text"
                           v-model="form.content"/>
                    <SrButton class="fn_r"
                              @click="comment"
                              :loading="comment_loading"
                              style="width: 90px"
                              height="40px"
                              padding="0"
                              border-radius="5px"
                              font-size="14px">回复
                    </SrButton>
                </div>
                <div class="clearfix mt20">
                    <a-spin :spinning="upload_loading"
                            style="text-align: center;width: 100%;margin-top: 20px;color: #3561C7">
                        <a-icon slot="indicator"
                                type="loading"
                                style="font-size: 30px"
                                spin/>
                        <p v-if="form.video.url"
                           class="kc-upload fn_l">
                            <video
                                    width="100%"
                                    height="94px"
                                    :src="form.video.url"></video>
                        </p>
                        <p v-for="(item,index) in form.image"
                           class="kc-upload fn_l upload-image"
                           :key="index">
                            <img width="100%"
                                 height="100%"
                                 :src="item.url">
                            <span @click="deleteImage(index)"></span>
                        </p>
                        <p
                                v-if="form.image.length<9"
                                class="kc-upload fn_l choose-image-btn"
                                @click="chooseImage">
                            <img src="@/assets/img/upload-image.jpg">
                            <input type="file"
                                   style="display: none"
                                   ref="file"
                                   @change="fileChange">
                        </p>
                    </a-spin>
                </div>
            </dd>
        </dl>
        <div>
            <SrSwitch @change="orderChange"
                      style="float: right"
                      :list="['推荐','时间']"></SrSwitch>
        </div>
        <div v-if="!loading">
            <a-empty v-if="list.length===0"
                     description="暂无评论"/>
            <div v-else>
                <transition-group
                        tag="div">
                    <dl v-for="item in list"
                        :key="item.comment_id"
                        class="kc-evaluate-list clearfix">
                        <dt class="fn_l"><img :src="item.user.head_portrait.url"></dt>
                        <dd class="fn_r">
                            <div class="">
                                <h3>
                                    <span>{{item.user.nickname}}</span>
                                    <!--<img src="@/assets/img/hyjb.png">-->
                                    <span @click="like(item)"
                                          :class="[item.is_like?'kc-like-hover':'','kc-like']">
                                        {{item.likes}}
                                    </span>
                                </h3>
                                <p class="color-666">{{item.content}}</p>

                                <div class="clearfix">
                                    <div v-if="item.video_id"
                                         class="fn_l kc-evaluate-listImg video-play">
                                        <img @click="showVideo(item.video.url)"
                                             :src="item.video.cover">
                                    </div>
                                    <div :key="image_index"
                                         v-for="(image_item,image_index) in item.image"
                                         class="fn_l kc-evaluate-listImg square-inner-item">
                                        <el-image
                                                style="width: 100%; height: 100%"
                                                :preview-src-list="imageFilter(item.image)"
                                                :src="image_item.url"
                                                fit="cover"></el-image>
                                        <!--<img width="100%"-->
                                        <!--:src="image_item.url">-->
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </transition-group>
            </div>
        </div>
        <div v-else>
            <a-spin style="text-align: center;width: 100%;margin-top: 90px;color: #3561C7">
                <a-icon slot="indicator"
                        type="loading"
                        style="font-size: 30px"
                        spin/>
            </a-spin>
        </div>
        <!-- 页码 -->
        <SrPaginate style="margin: 0 auto;text-align: center;margin-top: 50px"
                    :current="pagination.current_page"
                    :total="pagination.total"
                    :pageSize="pagination.page_size"
                    @change="changePage"></SrPaginate>
        <VideoModal ref="VideoModal"></VideoModal>
    </div>
</template>

<script>
    import SrPaginate from '@/components/SrPaginate'
    import SrButton from '@/components/SrButton'
    import {commentList, comment} from "@/api/radio/radio"
    import {like} from "@/api/comment"
    import {uploadVideo, uploadImage} from "@/api/common"
    import VideoModal from '@/components/VideoModal'
    import SrSwitch from '@/components/SrSwitch'

    export default {
        name: "CommentList",
        props: {
            radio_id: {}
        },
        components: {
            SrPaginate,
            SrButton,
            VideoModal,
            SrSwitch
        },
        filter: {
            ImageFilter(item) {
                console.log(item)
            }
        },
        data() {
            return {
                loading: false,
                list: [],
                pagination: {
                    page: 1,
                    page_size: 6
                },
                order_by: 'default',
                comment_loading: false,
                upload_loading: false,
                form: {
                    video: {},
                    comment_enclosure_id: [],
                    image: []
                }
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                var _self = this
                _self.loading = true
                commentList({
                    radio_id: this.radio_id,
                    ...this.pagination,
                    order_by: this.order_by
                }).then((res) => {
                    _self.list = res.result.items
                    _self.loading = false
                    _self.pagination = res.result.pagination
                })
            },
            changePage(page, page_size) {
                this.pagination.page = page
                this.getData()
            },
            chooseImage() {
                this.$refs.file.click()
            },
            comment() { // 提交评论
                var _self = this
                _self.comment_loading = true
                comment({radio_id: _self.radio_id, ..._self.form}).then((res) => {
                    _self.comment_loading = false
                    if (res.status === 200) {
                        _self.$message.success('提交成功')
                        _self.form = {
                            video: {},
                            comment_enclosure_id: [],
                            image: []
                        }
                        _self.list.unshift(res.result)
                    } else {
                        _self.$message.warning(res.msg)
                    }
                })
            },
            fileChange() {
                var file = this.$refs.file.files[0]
                if (file) {
                    var allow_img = ['image/jpeg', 'image/png', 'image/bmp'];
                    var allow_video = ['video/mp4', 'video/msvideo', 'video/ms-wmv', 'video/flv'];
                    if (allow_img.indexOf(file.type) !== -1) {
                        this.addImage(file)
                        return true
                    }
                    if (allow_video.indexOf(file.type) !== -1) {
                        this.addVideo(file)
                        return true
                    }
                    this.$message.warning('所选文件格式不支持')
                }
            },
            addImage(file) {
                var _self = this
                var formData = new FormData()
                formData.append('image', file)
                _self.upload_loading = true
                uploadImage(formData).then((res) => {
                    _self.upload_loading = false
                    if (res.status === 200) {
                        _self.form.comment_enclosure_id.push(res.result.image_id)
                        _self.form.image.push(res.result)
                    } else {
                        _self.$message.warning(res.msg)
                    }
                })
            },
            deleteImage(index) {
                this.form.comment_enclosure_id.splice(index, 1)
                this.form.image.splice(index, 1)
            },
            addVideo(file) {
                var _self = this
                var formData = new FormData()
                formData.append('video', file)
                _self.upload_loading = true
                uploadVideo(formData).then((res) => {
                    _self.upload_loading = false
                    if (res.status === 200) {
                        _self.form.video_id = res.result.video_id
                        _self.form.video = res.result
                    } else {
                        _self.$message.warning(res.msg)
                    }
                })
            },
            showVideo(url) { // 视频窗口
                this.$refs.VideoModal.show(url)
            },
            like(item) { // 点赞
                like({comment_id: item.comment_id, status: !item.is_like})
                if (item.is_like) {
                    item.likes--
                } else {
                    item.likes++
                }
                item.is_like = !item.is_like
            },
            imageFilter(item) {
                var arr = item.map((item) => {
                    return item.url
                })
                return arr
            },
            orderChange(index) {
                if (index === 0) {
                    this.order_by = 'digest'
                } else {
                    this.order_by = 'created_at'
                }
                this.getData()
            }
        }
    }
</script>

<style scoped>
    .choose-image-btn {
        cursor: pointer;
    }


    .square-inner-item {
        width: 30%;
        height: 160px;
        overflow: hidden;
        margin: 1%;
        cursor: pointer;
        border-radius: 3px;
    }

    .list-complete-item {
        transition: all 1s;
        display: inline-block;
        margin-right: 10px;
    }

    .list-complete-enter, .list-complete-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }

    .video-play {
        cursor: pointer;
    }

    .upload-image {
        margin-top: 1%;
        width: 30%;
        height: 150px;
    }
</style>
