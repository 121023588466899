<template>
    <div>
        <p>
            <input
                    type="text"
                    v-model="form.phone"
                    placeholder="请输入手机号"
            />
        </p>
        <p>
            <input
                    type="text"
                    v-model="form.code"
                    placeholder="请输入验证码"
            />
            <SrButton
                    :disabled="sms_time !== 0"
                    @click="sendMessage"
                    class="login-hqyzm"
                    height="33px"
                    padding="0 20px"
                    font-size="14px"
                    :loading="sms_loading"
            >
                <span v-if="sms_time === 0">获取验证码</span>
                <span v-else>重新发送({{ sms_time }})</span>
            </SrButton>
            <!-- <span class="login-hqyzm bg-999">60秒/重新发送</span> -->
        </p>
        <p><input type="password"
                  v-model="form.password"
                  placeholder="请输入密码"/></p>

        <p>
            <SrButton
                    style="width: 100%"
                    @click="register"
                    :loading="register_loading"
            >
                立即注册
            </SrButton>
            <!-- <button type="button">立即注册</button> -->
        </p>
        <p class="te_c f14">
            <input type="radio"
                   :value="true"
                   v-model="form.is_read"/> 我已阅读并同意<span
                class="color-main"
        >
            <router-link style="display: inline" to="/agreement?id=10"
                         target="_blank">《用户协议》</router-link>
        </span>
            和
            <span class="color-main">
            <router-link style="display: inline" to="/agreement?id=10"
                         target="_blank">《隐私政策》</router-link>
        </span>
        </p>
    </div>
</template>

<script>
    import storage from '@/utils/storage'
    import SrButton from "@/components/SrButton"
    import {mobileLogin, register} from "@/api/user"
    import {sendMessage} from "@/api/common"

    export default {
        name: "RegisterBox",
        components: {
            SrButton,
        },
        data() {
            return {
                form: {
                    is_read: false,
                    phone: "",
                    password: "",
                    code: ""
                },
                sms_time: 0,
                register_loading: false,
                sms_loading: false,
            };
        },
        created() {
            var _this = this
            var sms_time = storage.getItem('sms_time');
            if (sms_time > 0) {
                _this.sms_time = sms_time
                var timer = setInterval(function () {
                    _this.sms_time--;
                    storage.setItem('sms_time', _this.sms_time);
                    if (_this.sms_time <= 0) {
                        clearInterval(timer)
                    }
                }, 1000)
            }
        },
        methods: {
            //   发送验证码
            sendMessage() {
                let _this = this;
                if (_this.register_loading) {
                    return false;
                }
                if (_this.form.phone == "") {
                    _this.$message.warning("请输入手机号");
                    return;
                }
                _this.sms_loading = true;
                 var char = new Date().getTime();
                var key = 'xCf188EYN4arKCXqo3nUYDumTccz7'
                var sign = this.$md5('char='+ char +'&phone=' + _this.form.phone + '&scenario=RegisterCode'+ key)
                sendMessage({phone:_this.form.phone, scenario: 'RegisterCode',char:char,sign:sign.toUpperCase()}).then((res) => {
                    if (res.status === 200) {
                        _this.register_loading = false;
                        _this.sms_time = 60;
                        _this.sms_loading = false;
                        var timer = setInterval(function () {
                            _this.sms_time--;
                            storage.setItem("sms_time", _this.sms_time);
                            if (_this.sms_time <= 0) {
                                clearInterval(timer);
                            }
                        }, 1000);
                    } else {
                        _this.$message.warning(res.msg);
                        _this.sms_loading = false;
                    }
                });
            },
            // 注册
            register() {
                let _this = this;
                if (_this.register_loading) {
                    return false;
                }
                if (!_this.form.is_read) {
                    _this.$message.warning("请先阅读协议");
                    return false;
                }
                if (_this.form.phone == '') {
                    _this.$message.warning("请输入手机号");
                    return;
                }
                if (_this.form.code == '') {
                    _this.$message.warning("请输入验证码");
                    return;
                }
                if (_this.form.password == '') {
                    _this.$message.warning("请输入密码");
                    return;
                }
                register(this.form).then((res) => {
                    if (res.status === 200) {
                        _this.register_loading = false;
                        _this.$message.success("注册成功");
                        storage.setItem('ACCESS_TOKEN', res.result)
                        //获取跳转页
                        var url = storage.getItem('LOGIN_SKIP')
                        if (!url) {
                            url = '/'
                        }
                        _this.$router.push(url)
                    } else {
                        _this.$message.warning(res.msg);
                        _this.register_loading = false;
                    }
                });
            },
        },
    };
</script>

<style scoped>
</style>
