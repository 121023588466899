<template>
    <div id="box">
        <div id="messagelist">
            <div class="placeholder" style="padding-bottom: 100px"></div>
        </div>
        <!--<div id="messagelist"-->
        <!--style="height: 371px;overflow-y: scroll;padding-bottom: 50px">-->
        <!--<dl v-for="(item,index) in list"-->
        <!--class="kc-evaluate-list clearfix">-->
        <!--<dt class="fn_l"><img :src="item.from.avatar"></dt>-->
        <!--<dd class="fn_r">-->
        <!--<div class="">-->
        <!--<h3>{{item.from.name}}</h3>-->
        <!--<p class="color-666">{{item.content}}</p>-->
        <!--</div>-->
        <!--</dd>-->
        <!--</dl>-->
        <!--</div>-->
        <div class="kc-videoList-pl">
            <input type="text"
                   v-model="message"
                   placeholder="请输入内容......"/>
            <button @click="send"
                    type="button">发送
            </button>
        </div>
        <div id="message-sender"
             class="kc-videoList-pl">
            <div class="placeholder1"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DianboChatRoom",
        data() {
            return {
                message: '',
                list: []
            }
        },
        mounted() {
            var _self = this
            _self.init()
        },
        methods: {
            send() {
                var eventEmitter = BJY.eventEmitter
                eventEmitter.one(
                    eventEmitter.MESSAGE_SEND, function (event, data) {
                        console.log(event)
                    })
            },
            init() {
                var _self = this
                var eventEmitter = BJY.eventEmitter
                eventEmitter
                    .one(
                        eventEmitter.VIEW_RENDER_TRIGGER,
                        function (event, data) {

                            _self.initList()
                            // 创建一个使用百家云表情配置的消息发送组件
                            BJY.MessageSender.create({
                                // 消息发送组件的占位元素
                                element: $('#message-sender .placeholder1'),
                                // 能否发送表情
                                canSendEmoji: false,
                                // 能否发送图片
                                canSendImage: false,
                                // 能否使用私聊功能，可选，默认为false
                                canWhisper: false,
                                // 是否多行输入，在移动端浏览器下此项传false
                                multiline: true,
                                // 提示信息
                                placeholder: '请输入...',
                                // 设置最大字符数
                                messageMaxLength: 140
                            });
                        }
                    );
            },
            initList() {
                BJY.MessageList.create({
                    // 组件占位元素
                    element: $('#messagelist .placeholder'),
                    // 加载表情 返回一个<img>标签
                    renderEmoji: function (name, url) {
                        if (BJY.isAlicloudImage(url)) {
                            return '<img ondragstart="return false" src="'
                                + (url + '@100w_1e_1l.png')
                                + '" srcset="'
                                + (url + '@200w_1e_1l.png')
                                + ' 2x">';
                        }
                        return '<img ondragstart="return false" src="' + url + '">';
                    },
                    // 加载图片，返回一个<img>标签
                    renderImage: function (url) {
                        if (BJY.isAlicloudImage(url)) {
                            return '<img ondragstart="return false" src="'
                                + (url + '@100w_1e_1l.png')
                                + '" srcset="'
                                + (url + '@200w_1e_1l.png')
                                + ' 2x">';
                        }
                        return '<img ondragstart="return false" src="' + url + '">';
                    },
                    // 配置用户渲染属性 必须
                    renderUser: function (user, width, height) {
                        return {
                            id: user.id,
                            name: user.name,
                            number: user.number,
                            // 低密度屏幕压缩，详情查看基础第二条
                            avatar: BJY.compressImage({
                                url: user.avatar,
                                // 对于低密度屏幕将图片压缩到头像的大小
                                width: width,
                                height: height
                            }),
                            // 高密度屏幕压缩，详情查看基础第二条
                            avatar2x: BJY.compressImage({
                                url: user.avatar,
                                // 对于高密度屏幕（如 MAC）将图片压缩到头像的2倍大小
                                width: width * 2,
                                height: height * 2
                            }),
                            // 根据用户类型，配置角色的英文语言
                            role: 'student',
                            // 根据用户类型，配置角色的本地语言
                            localRole: '学生'
                        }
                    },
                    messageFilter: function (message) {
                        // SDK 版本号 >= 0.1.24 可用
                        // return true 显示该消息；
                        // return false 不显示该条消息;

                        /* 你可以随时通过
                        messageList.set('messageFilter', function () {});设置过滤函数;
                        当你设置之后调用 messageList.forceUpdate()刷新组件。
                        */
                    }
                });
            }
        }
    }
</script>

<style scoped>
    #box {
        height: auto;
        position: relative;
    }

    #box >>> .bjy-input {
        /*float: left;*/
        width: 280px;
        height: 36px;
        line-height: 24px;
        background: #f3f3f3;
        border: none;
        border-radius: 18px;
        /*padding: 0 20px;*/
        /*box-sizing: border-box;*/
    }

    #box >>> .bjy-no-forbidden {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: space-between;
    }

    #box >>> .bjy-length-hint {
        display: none;
    }

    #box >>> .bjy-button {
        background: #3561C7;
        float: right;
        width: 70px;
        height: 36px;
        line-height: 36px;
        background: #3561C7;
        color: #FFFFFF;
        border: none;
        border-radius: 10px;
    }

    #box >>> .bjy-action-bar {
        margin-top: 0;
    }


    #messagelist {
        height: 371px
    }
    #box >>> .bjy-message-list{
        padding-bottom: 70px;
    }
</style>
