import { render, staticRenderFns } from "./ZhiboWhiteboard.vue?vue&type=template&id=69e37e92&scoped=true&"
import script from "./ZhiboWhiteboard.vue?vue&type=script&lang=js&"
export * from "./ZhiboWhiteboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69e37e92",
  null
  
)

export default component.exports