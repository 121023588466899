<template>
    <div id="box">
        <a-pagination v-model="current_data" :total="total_data" :pageSize="page_size_data" show-less-items @change="changePage"/>
    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            current: {},
            total: {},
            pageSize: {}
        },
        watch: {
            current(value) {
                this.current_data = value
            },
            total(value) {
                this.total_data = value
            },
            pageSize(value) {
                this.page_size_data = value
            },
        },
        data() {
            return {
                current_data: this.current,
                total_data: this.total,
                page_size_data: this.pageSize,
            }
        },
        methods: {
            changePage(page, pageSize) {
                this.$emit('change', page, pageSize)
            }
        }
    }
</script>

<style scoped>
    a {
        color: #000;
    }

    #box >>> .ant-pagination-item {
        border-radius: 50% 50%;
        border: none;
    }

    #box >>> .ant-pagination-item-link {
        border: none;
    }

    #box >>> .ant-pagination-item-active {
        background: #3561C7;
    }

    #box >>> .ant-pagination-item-active a {
        color: #fff;

    }
</style>
