import request from '@/utils/request'

/**
 * 获取课程详情
 * @param parameter
 */
export function info(parameter) {
    return request({
        url: '/api/school/course/info',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取课程评论列表
 * @param parameter
 */
export function commentList(parameter) {
    return request({
        url: '/api/school/course/comment_list',
        method: 'post',
        data: parameter
    })
}

/**
 * 评论课程
 * @param parameter
 */
export function comment(parameter) {
    return request({
        url: '/api/school/course/comment',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取视频token
 * @param parameter
 */
export function video(parameter) {
    return request({
        url: '/api/school/course/video',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取直播签名
 * @param parameter
 */
export function zhibo(parameter) {
    return request({
        url: '/api/school/course/zhibo',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取子章节详情
 * @param parameter
 */
export function subchapterInfo(parameter) {
    return request({
        url: '/api/school/courseSubchapter/info',
        method: 'post',
        data: parameter
    })
}

// 获取指定课程分类下的推荐课程
export function recommend(parameter) {
    return request({
        url: '/api/school/course_category/recommend',
        method: 'post',
        data: parameter
    })
}