import request from '@/utils/request'

/**
 * 点赞/取消赞
 * @param parameter
 */
export function like(parameter) {
    return request({
        url: '/api/comment/like',
        method: 'post',
        data: parameter
    })
}

/**
 * 更新视频观看进度
 * @param parameter
 */
export function updateVideoProgress(parameter) {
    return request({
        url: '/api/common/updateVideoProgress',
        method: 'post',
        data: parameter
    })
}
