<template>
  <div>
    <SrNav></SrNav>
    <div class="community2_zong">
      <div class="warp clearfix">
        <div class="community2_nav">
          <ul>
            <li>
              <span>一级菜单：</span>
              <div class="community2_nav_li">
                <a
                  :class="typeIndex1 == index ? 'community2_on' : ''"
                  v-for="(i, index) in typeList"
                  @click="getTab(i, index)"
                  >{{ i.name }}</a
                >
              </div>
            </li>
            <li v-if="typeList2.length > 0">
              <span>二级菜单：</span>
              <div class="community2_nav_li">
                <a
                  :class="typeIndex2 == index ? 'community2_on' : ''"
                  v-for="(i, index) in typeList2"
                  @click="getTab(i, index)"
                  >{{ i.name }}</a
                >
              </div>
            </li>
            <li v-if="typeList3.length > 0">
              <span>三级菜单：</span>
              <div class="community2_nav_li">
                <a
                  :class="typeIndex3 == index ? 'community2_on' : ''"
                  v-for="(i, index) in typeList3"
                  @click="getTab(i, index)"
                  v-if=""
                  >{{ i.name }}</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="warp clearfix">
      <div class="community2_list" v-for="(i, index) in list">
        <div class="p_remind_title p_remind_title1">
          <img src="@/assets/img/remind_icon.png" />
          <h6>
            {{ i.title
            }}<a @click="goMore(i.lists[0].community_category_id, i.title)"
              >查看更多 ></a
            >
          </h6>
        </div>
        <div class="collection-eq">
          <ul v-for="item in i.lists">
            <router-link
              :to="{
                path: '/community/detail',
                query: {
                  id: item.community_id,
                  community_category_id: item.community_category_id,
                },
              }"
            >
              <li>
                <a>
                  <div class="coll_l fn_l">
                    <img :src="item.image.url" />
                    <span class="collection-hui" v-if="item.type == 1"
                      ><img src="@/assets/img/huiyuanzhuanxiang.png"
                    /></span>
                  </div>
                  <div class="coll_r fn_r coll_r1 coll_r2">
                    <h6>
                      {{ item.name }}
                      <em class="zhiding" v-if="item.place_top == 1">置顶</em
                      ><em class="jiajing" v-if="item.is_digest == 1">加精</em>
                      <span>{{ item.created_at }}</span>
                    </h6>
                    <div class="contens" v-html="item.content"></div>
                    <div class="coll_r_nei">
                      <!-- <div class="coll_n_one">
                      <img src="@/assets/img/coll_tea.jpg" />
                      <span>江洋</span>
                    </div> -->
                      <div class="coll_n_one coll_n_one1">
                        <img src="@/assets/img/coll_liu.png" />
                        <span
                          >浏览量：<i>{{ item.browse }}</i></span
                        >
                      </div>
                      <div class="coll_n_one coll_n_one1">
                        <img src="@/assets/img/coll_ping.png" />
                        <span
                          >评论量：<i>{{ item.comment_count }}</i></span
                        >
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import { category, list } from "@/api/community";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
  },
  data() {
    return {
      typeList: [],
      typeList2: [],
      typeList3: [],
      typeIndex1: 0,
      typeIndex2: 0,
      typeIndex3: 0,
      typeShowIndex1: null,
      typeShowIndex2: null,
      community_category_id: "",
      title: "",
      list: [],
    };
  },
  mounted() {},

  methods: {
    loadCategory() {
      let _this = this;
      category({}).then((res) => {
        console.log(res, "------分类");
        _this.typeList = res.result;
        _this.title = res.result[_this.typeIndex1].name;
        console.log(res.result[_this.typeIndex1].name,'titles')
        _this.typeList2 = res.result[_this.typeIndex1].children;
        _this.typeList3 = _this.typeList2[_this.typeIndex2].children;
        console.log(_this.typeList3, "-------分类3");
        console.log(_this.typeList2, "-------分类2");
        if(_this.typeList3.length == 0){
          _this.community_category_id = _this.typeList2[0].community_category_id
        }else{
           _this.community_category_id = _this.typeList3[0].community_category_id
        }
          _this.loadList();
      });
    },
    loadList() {
      let _this = this;
      list({ community_category_id: _this.community_category_id }).then(
        (res) => {
          console.log(res, "筛选出的列表");
          var data = res.result.items;
          var list = [];
          var lock = false;
          var obj = {};
          for (var i = 0; i < data.length; i++) {
            for (var k = 0; k < list.length; k++) {
              if (
                data[i].community_category_id != list[k].community_category_id
              ) {
                lock = true;
              }
            }
            // 获取相同的分类
            if (!lock) {
              console.log(data[i],'相同---ss')
              list.push(data[i]);
            }
            // 获取不同的分类
            if (lock) {
              var arrs = [];
              console.log(data[i],'不同')
              arrs.push(data[i]);
              _this.list.push({
                ["lists"]: arrs,
                ["title"]: arrs[0].category.name,
              });
            }
          }
          console.log(list, "list------");
          if (list.length > 0) {
            _this.list = [];
            _this.list.push({
              ["lists"]: list,
              ["title"]: list[0].category.name,
            });
          } else {
            _this.list = [];
          }
        }
      );
    },
    getTab(i, index) {
      console.log(i);
      console.log(index);
      let _this = this;
      if (i.level == 1) {
        _this.typeIndex1 = index;
        _this.typeList2 = i.children;
        _this.typeIndex2 = 0;
        _this.community_category_id = i.children[0].community_category_id;
      }
      if (i.level == 2) {
        _this.typeList3 = i.children;
        _this.typeIndex2 = index;
         _this.community_category_id = i.community_category_id;
      }
      if (i.level == 3) {
        _this.typeIndex3 = index;
         _this.community_category_id = i.community_category_id;
      }
     
      this.loadList();
    },
    goMore(id, title) {
      this.$router.push({
        path: "/community/list",
        query: { id: id, title: title },
      });
    },
  },
  created() {
    this.loadCategory();
  
  },
};
</script>
<style>
.contens {
  font-size: 14px;
  color: #999;
  line-height: 22px;
  margin-top: 5px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  height: 110px !important;
}
</style>