<template>
    <div>
        	<div class="per3_right  per_right">
				<div class="p_remind_title">
					<img src="@/assets/img/remind_icon.png" />
					<h6>我的VIP会员-购买记录<router-link
            :to="{ path: '/user/center/myAbout', query: { id: 6 } }"
            ><a href="">会员权益与分销说明</a></router-link></h6>
				</div>

				<div class="per7_integral">
					<!-- tab -->
					<div class="per1_record">
						<div>
							<table border="0" cellspacing="0" cellpadding="0" class="form">
								<tr>
									<th>订单号</th>
									<th>商品名称</th>
									<th>订单金额</th>
									<th>获取方式</th>
									<th>下单时间</th>
									<th>支付方式</th>
								</tr>
								<tr v-if="list.length>0" v-for="i in list">
									<td>{{i.num}}</td>
									<td>{{i.order_item[0].goods.name}}</td>
									<td class="red">￥{{i.order_item[0].price}}</td>
									<td class="pink">购买</td>
									<td>{{i.created_at}}</td>
									<td>{{i.pay_type == null?'未付款':i.pay_type == 1?'支付宝':'微信'}}</td>
								</tr>
								<tr v-if="list.length == 0">
									<td colspan="6">暂无数据</td>
								</tr>
							</table>
						</div>


					</div>
				</div>

				<!-- 页码 -->
				<!-- <div class="page mt20">
					<a href="">&lt;&lt;</a>
					<a class="active" href="">1</a>
					<a href="">2</a>
					<a href="">&gt;&gt;</a>
				</div> -->
			</div>
    </div>
</template>
<script>
import { memberOrder } from "@/api/user";
export default {
    name: "PayViperRecord",
    data() {
        return {
			list:[]
		};
    },
    methods: {
		
	},
    created() {
		let _this = this
		memberOrder().then((res) => {
			console.log(res, "积分");
			_this.list = res.result.items
      });
	},
};
</script>

<style scoped>
table {
  text-align: center;
}
</style>