<template>
  <div>
    <!-- 错题集内容 -->
    <div class="index3_wdctj_nr">
      <ul v-if="list.length > 0">
        <li v-for="i in list">
          <div class="index3_wdctj_list_l">
            <i></i>
            <h6>{{i.paper.title}}</h6>
          </div>
          <div class="index3_wdctj_list_center">
            <p><span>{{i.subject_count}}</span>道题</p>
          </div>
          <div class="index3_wdctj_list_r">
            <a @click="openCollege(i)">点击查看</a>
          </div>
        </li>
      </ul>
      <div class="index3_mktk warp" v-if="list.length == 0">
        <div class="in3_mktk in3_mktk_zw">
          <img src="@/assets/img/index3_mktk_tu1.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <!-- 页码 -->
    <SrPaginate
      style="margin: 0 auto; text-align: center; margin-top: 50px"
      :current="pagination.current_page"
      :total="pagination.total"
      :pageSize="pagination.page_size"
      @change="changePage"
      v-if="list.length > 0"
    ></SrPaginate>
  </div>
</template>
<script>
import SrPaginate from "@/components/SrPaginate";
import { collList } from "@/api/exam";
export default {
  name: "index",
  components: {
    SrPaginate,
  },
  props: {
    id: String || Number,
    type: String || Number,
  },
  data() {
    return {
      pagination: {
        page: 0,
        page_size: 9,
        page: 1,
      },
      list: [],
    };
  },
  methods: {
    loadCollList() {
      let _this = this;
      collList({
        relation_id: _this.id,
        type: _this.type,
      }).then((res) => {
        console.log(res, "收藏");
        _this.list = res.result.items;
        _this.pagination = res.result.pagination;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadCollList();
    },
    openMySubject(item) {
      this.$router.push({
        path: "/answerDetail",
        query: {
          answer_order_id: item.answer_order_id,
        },
      });
    },
    openCollege(item) {
      console.log(item);
      this.$router.push({
        path: "/answerDetail",
        query: {
          answer_order_id: item.answer_order_id,
          type: "coll",
        },
      });
    },
  },
  created() {
    this.loadCollList();
  },
};
</script>