<template>
    <div>
        <div class="per3_right per_right">
            <div class="p_remind_title">
                <img src="@/assets/img/remind_icon.png"/>
                <h6 style="display: inline-block">我的钱包</h6>
                <router-link to="/user/center/myWalletDetail">
                    <p
                            style="
              float: right;
              cursor: pointer;
              color: #3561c7;
              font-size: 16px;
            "
                    >
                        账单明细
                    </p>
                </router-link>
            </div>

            <div class="per7_integral">
                <div class="per7_in1">
                    <ul>
                        <li>
                            <img src="@/assets/img/integral_img1.png"/>
                            <div class="integral_wen">
                                <p>累计分销赚钱</p>
                                <h6><span>￥</span>{{ wallets.share_money }}</h6>
                            </div>
                        </li>
                        <li>
                            <img src="@/assets/img/integral_img2.png"/>
                            <div class="integral_wen">
                                <p>累计打卡退费</p>
                                <h6><span>￥</span>{{ wallets.return_money }}</h6>
                            </div>
                        </li>
                        <li>
                            <img src="@/assets/img/integral_img4.png"/>
                            <div class="integral_wen">
                                <p>累计可提现余额</p>
                                <h6><span>￥</span>{{ wallets.user_commission }}</h6>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="per6_withdrawal">
                    <div class="per6_with_top">
                        <p>提现金额</p>
                        <span>￥</span>
                        <input
                                :value="price"
                                type="number"
                                @change="getPrice"
                                class="ss_with"
                        />
                    </div>
                    <div class="per6_with_middle">
                        <div class="middle_l">
                            <p>
                                当前零钱余额{{ wallets.user_commission }}元,<a @click="getAll"
                            >全部提现</a
                            >
                            </p>
                        </div>
                        <div class="middle_r">
                            <select>
                                <option value="volvo">微信</option>
                            </select>
                        </div>
                    </div>
                    <div class="per6_withdrawal_sm">
                        <h6>提现说明:</h6>
                        <p>
                            提现申请将在1-3小时内到帐，提现金额将自动到微信余额，如遇高峰期可能延迟到帐，请耐心等待。
                        </p>
                    </div>

                    <SrButton
                            style="width: 360px;margin-top: 30px"
                            @click="walletCash"
                            font-size="14px"
                            height="40px"
                            padding="0 20px"
                            :loading="pay_loading"
                    >
                        立即提现
                    </SrButton>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import SrButton from "@/components/SrButton";
    import {wallet, walletCash} from "@/api/user";

    export default {
        name: "MyWallet",
        components: {
            SrButton,
        },
        data() {
            return {
                wallets: {},
                price: 0,
                walletsPrices: null,
                pay_loading: false,
            };
        },
        methods: {
            loadWallet() {
                let _this = this;
                wallet().then((res) => {
                    _this.wallets = res.result;
                });
            },
            //   全部提现
            getAll() {
                this.price = parseFloat(this.wallets.user_commission);
            },
            getPrice(e) {
                this.price = parseFloat(e.target.value);
            },
            walletCash() {
                let _this = this;
                console.log(_this.price);
                if (_this.price == 0) {
                    _this.$message.warning("请输入要提现的金额");
                    return;
                }
                if (_this.price > _this.wallets.user_commission) {
                    _this.$message.warning("提现金额不得大于余额");
                    return;
                }
                _this.pay_loading = true;
                walletCash({money: _this.price}).then((res) => {
                    if (res.status === 200) {
                        _this.$message.success("提现成功");
                        _this.pay_loading = false;
                        _this.loadWallet()
                    } else {
                        _this.$message.warning(res.msg);
                        _this.pay_loading = false;
                    }
                    console.log(res, "钱包");
                }).catch((res) => {
                    _this.$message.error('提现失败');
                    _this.pay_loading = false;
                });
            },
        },
        created() {
            this.loadWallet();
        },
    };
</script>

<style scoped>
</style>
