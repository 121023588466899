import request from '@/utils/request'

/**
 * 获取课程包详情
 * @param parameter
 */
export function info(parameter) {
    return request({
        url: '/api/school/coursePackage/info',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取课程评论列表
 * @param parameter
 */
export function commentList(parameter) {
    return request({
        url: '/api/school/coursePackage/commentList',
        method: 'post',
        data: parameter
    })
}
