<template>
    <div style="width: 100%;height: 100%">
        <iframe :src="url"
                style="width: 100%;height: 100%;border: none"
                id="huifang"></iframe>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                url: ''
            }
        },
        methods: {
            init(room_id, token) {
                this.url = 'http://b37112080.at.baijiayun.com/web/playback/index?classid=' + room_id + '&token=' + token;
            }
        }
    }
</script>

<style scoped>

</style>
