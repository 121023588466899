<template>
    <div>
        <div class="kcxq-list fn_r">
            <div v-for="item in list"
                    class="kcxq-list fn_r">
                <div class="kc-list dtxq-list ml0">
                    <router-link :to="{path:'/radio/info/'+item.relation_id}" target='_blank'>
                        <div class=""><img :src="item.relation.image.url"></div>
                        <div class="kc-listTex">
                            <p>{{item.relation.name}}</p>
                            <p class="f14 color-666">播放量次</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {recommend} from "@/api/common"

    export default {
        name: "ReRadio",
        data() {
            return {
                list: []
            }
        },
        created() {
            var _self = this
            recommend({type: 3, page_size: 4}).then((res) => {
                _self.list = res.result.items
            })
        }
    }
</script>

<style scoped>

</style>
