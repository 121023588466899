<template>
    <div>
        <!-- 视频播放 -->
        <div class="warp clearfix mt50 kcxq-box">
            <h3 class="f26">攻破重难点之生活常识 —— 公基教材第六章</h3>
            <div class="clearfix mt20">
                <div class="kc-video fn_l">
                    <BjDianbo ref="BjDianbo"
                              :token="video.token"></BjDianbo>
                    <!--<img src="img/CK-img.png">-->
                    <!--<p class="kc-yinpin"><img src="img/yinpin.png"></p>-->
                </div>
                <div class="kc-videoList fn_r">
                    <div class="tab-wrapper">
                        <ul class="tab-menu">
                            <li @click="tab_active=1"
                                :class="[tab_active===1?'active':'']">聊天
                            </li>
                            <li @click="tab_active=2"
                                :class="[tab_active===2?'active':'']">课程大纲
                            </li>
                        </ul>
                        <div class="tab-content">
                            <ChatRoom v-if="tab_active===1"
                                      ref="ChatRoom"></ChatRoom>
                            <CourseChapter v-if="tab_active===2"
                                           :list="course.course_chapter"
                                           type="small"></CourseChapter>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <ReCourseBig></ReCourseBig>
    </div>
</template>

<script>
    import {video, info} from "@/api/school/course"
    import BjDianbo from '@/components/BjDianbo'
    import ReCourseBig from '@/layout/recommend/ReCourseBig'
    import ChatRoom from './modules/ChatRoom'
    import CourseChapter from '../detail/modules/CourseChapter'

    export default {
        name: "index",
        components: {
            BjDianbo,
            ReCourseBig,
            ChatRoom,
            CourseChapter
        },
        data() {
            return {
                loading: false,
                tab_active: 1,
                video: {},
                course_id: 0,
                course_subchapter_id: 0,
                course: {
                    course_chapter: []
                }
            }
        },
        created() {
            var _self = this
            _self.loading = true
            var course_subchapter_id = this.$route.query.course_subchapter_id
            var course_id = this.$route.query.course_id
            if (!course_subchapter_id) {
                _self.noVideo()
            }

            info({course_id: course_id}).then((res) => {
                if (res.status === 200) {
                    _self.course = res.result
                    video({course_subchapter_id: course_subchapter_id}).then((res) => {
                        if (res.status === 200) {
                            _self.video = res.result
                            _self.loading = false
                            _self.$refs.BjDianbo.init(_self.video.token, _self.video.video_id)
                        } else {
                            _self.noVideo()
                        }
                    })
                } else {
                    _self.noVideo()
                }
            })

        },
        methods: {
            noVideo() {
                this.$srmsg.info({
                    title: '提示',
                    content: '视频不存在'
                }).then(() => {
                    // this.$router.go(-1)
                })
            }
        }
    }
</script>

<style scoped>

</style>
