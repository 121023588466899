import request from '@/utils/request'

/**
 * 支付时获取可用优惠券列表
 * @param parameter
 */
export function able(parameter) {
    return request({
        url: '/api/coupon/able',
        method: 'post',
        data: parameter
    })
}
