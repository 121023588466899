const storage = {
    setItem(key, value, expire = null) {
        if (value) {
            //计算过期时间
            if (expire) {
                expire = Date.parse(new Date()) + expire
            } else {
                expire = null
            }

            var data = {
                data: JSON.stringify(value),
                expire: expire
            }
            return localStorage.setItem('sr_' + key, JSON.stringify(data))
        } else {
            this.removeItem(key)
        }
    },
    getItem(key) {
        var value = localStorage.getItem('sr_' + key)
        value = JSON.parse(value)
        if (!value) {
            return null
        }
        //判断是否过期
        if (!value.expire) { //expire为空 永久有效
            return JSON.parse(value.data)
        }
        if (value.expire > Date.parse(new Date())) {//未过期
            return JSON.parse(value.data)
        } else { //已过期
            return null
        }

    },
    removeItem(key) {
        return localStorage.removeItem('sr_' + key)
    }
}
export default storage
