var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"box"}},[(_vm.list.length === 0)?_c('a-empty',{attrs:{"description":"暂无数据"}}):_vm._l((_vm.list),function(item){return _c('a-collapse',{key:item.course_chapter_id,attrs:{"expand-icon-position":"right"}},[_c('a-collapse-panel',{key:item.course_chapter_id + '',attrs:{"header":item.name}},_vm._l((item.course_subchapter),function(subitem){return _c('dl',{staticClass:"kc-dg clearfix mt20"},[_c('dd',[_c('h3',[_vm._v(_vm._s(subitem.name))]),(subitem.type != 5)?_c('p',{staticClass:"f14 color-999"},[_vm._v(" "+_vm._s(subitem.teacher ? subitem.teacher.name : "")+" 丨 "),(subitem.type === 1 || subitem.type === 4)?_c('span',[_vm._v(" "+_vm._s(_vm.$moment(subitem.start_at).format("YYYY.MM.DD"))+" "+_vm._s(_vm.$moment(subitem.start_at).format("HH:mm"))+" - "+_vm._s(_vm.$moment(subitem.end_at).format("HH:mm"))+" ")]):_vm._e()]):_vm._e(),(subitem.type !== 1 && subitem.type !== 5)?_c('a-progress',{attrs:{"percent":subitem.baijia_video.user_progress,"format":function (percent) { return '已观看' + percent + '%'; },"strokeColor":"#eb98c2","strokeWidth":3}}):_vm._e(),(
              subitem.type === 1 &&
              subitem.class_status < 2 &&
              subitem.auth_level == 1
            )?_c('a',{on:{"click":function($event){return _vm.toZhibo(item, subitem)}}},[_vm._v(" "+_vm._s(_vm._f("ZhiBo")(subitem))+" ")]):_vm._e(),(
              subitem.type === 1 &&
              subitem.class_status === 2 &&
              subitem.auth_level == 1
            )?_c('a',{on:{"click":function($event){return _vm.openvideo(item.course_id, subitem.course_subchapter_id)}}},[_vm._v(" 播放视频 ")]):_vm._e(),(
              subitem.type === 1 &&
              subitem.class_status === 2 &&
              subitem.auth_level == -1
            )?_c('a',{on:{"click":function($event){return _vm.openvideos(item.course_id, subitem.course_subchapter_id)}}},[_vm._v(" 播放视频 ")]):_vm._e(),(subitem.type === 2 && subitem.auth_level == 1)?_c('a',{on:{"click":function($event){return _vm.openvideo(item.course_id, subitem.course_subchapter_id)}}},[_vm._v(" 播放视频 ")]):_vm._e(),(subitem.type === 2 && subitem.auth_level == -1)?_c('a',{on:{"click":function($event){return _vm.openvideos(item.course_id, subitem.course_subchapter_id)}}},[_vm._v(" 试听 ")]):_vm._e(),(
              subitem.type === 4 &&
              subitem.class_status < 2 &&
              subitem.auth_level == 1
            )?_c('a',{on:{"click":function($event){return _vm.toZhibo(item, subitem)}}},[_vm._v(" "+_vm._s(_vm._f("ZhiBo")(subitem))+" ")]):_vm._e(),(
              subitem.type === 4 &&
              subitem.class_status === 2 &&
              subitem.auth_level == 1
            )?_c('a',{on:{"click":function($event){return _vm.openvideo(item.course_id, subitem.course_subchapter_id)}}},[_vm._v(" 播放视频 ")]):_vm._e(),(
              subitem.type === 4 &&
              subitem.class_status === 2 &&
              subitem.auth_level == -1
            )?_c('a',{on:{"click":function($event){return _vm.openvideo2(item.course_id, subitem.course_subchapter_id)}}},[_vm._v(" 试听 ")]):_vm._e(),(subitem.type === 5 && subitem.auth_level == 1)?_c('a',{on:{"click":function($event){return _vm.openSubject(subitem)}}},[_vm._v(" 去做题 ")]):_vm._e(),(subitem.type === 3 && subitem.auth_level == 1)?_c('router-link',{on:{"click":function($event){return _vm.openvideo(item.course_id)}}},[_vm._v(" 音频 ")]):_vm._e(),(subitem.type === 3 && subitem.auth_level == -1)?_c('router-link',{on:{"click":function($event){return _vm.openvideos(item.course_id)}}},[_vm._v(" 试听 ")]):_vm._e()],1)])}),0)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }