<template>
    <a-modal :visible="visible"
             :footer="false"
             :closable="false"
             :bodyStyle="{padding:0}"
             width="800px"
             wrapClassName="sr-dialog">
        <div style="padding: 30px 50px;box-sizing: border-box;overflow: hidden">
            <div class="yao_share1">
                <div class="yao_share">
                    <span>分享</span>
                    <div class="yao_share_nr">
                        <ul>
                            <li>
                                <a @click="shareToSinaWB">
                                    <img src="@/assets/img/yao_share2.png" />
                                </a>
                            </li>
                            <li>
                                <a @click="shareToqq">
                                    <img src="@/assets/img/yao_share3.png" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="pintuan-m mt10">
                <img width="100%"
                     :src="info.share_image.image.url"
                     alt="">
            </div>
            <div class="yao_wen_l">
                <h6>{{info.share_image.text}}</h6>
                <p>我已坚持{{info.user_clock}}天</p>
            </div>
            <div class="yao_wen_r">
                <div ref="qrcode"></div>
            </div>

        </div>
        <a class="modal-close">
            <img @click="close"
                 src="@/assets/img/modal-close.png">
        </a>
    </a-modal>
</template>

<script>
    import QRCode from 'qrcodejs2'
    import {shareUser} from "@/api/camp";

    export default {
        name: "Share",
        data() {
            return {
                visible: false,
                info: {
                    share_image: {
                        image: {}
                    }
                }
            }
        },
        methods: {
            show(camp) {
                var _self = this
                this.visible = true
                this.camp = camp
                shareUser(camp).then((res) => {
                    _self.info = res.result
                    new QRCode(_self.$refs.qrcode, {
                        text: 'http://srgongkaow.com/appDownload/index',
                        width: 200,
                        height: 200,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    _self.loading = false
                }, 1000)
            },
            close() {
                this.visible = false
                this.$refs.qrcode.innerHTML = ''
            },
            shareToSinaWB(event){
                event.preventDefault();
                var _shareUrl = 'http://v.t.sina.com.cn/share/share.php?';     //真实的appkey，必选参数
                _shareUrl += '&url='+ encodeURIComponent(window.location.href);     //参数url设置分享的内容链接|默认当前页location，可选参数
                _shareUrl += '&title=' + encodeURIComponent(this.info.share_image.text);    //参数title设置分享的标题|默认当前页标题，可选参数
                _shareUrl += '&source=' + encodeURIComponent('');
                _shareUrl += '&sourceUrl=' + encodeURIComponent('');
                _shareUrl += '&content=' + 'utf-8';   //参数content设置页面编码gb2312|utf-8，可选参数
                _shareUrl += '&pic=' + encodeURIComponent(this.info.share_image.image.url);  //参数pic设置图片链接|默认为空，可选参数
                window.open(_shareUrl,'_blank');
            },
            shareToqq(event){
                event.preventDefault();
                var _shareUrl = 'https://connect.qq.com/widget/shareqq/iframe_index.html?';
                _shareUrl += 'url=' + encodeURIComponent(window.location.href);   //分享的链接
                _shareUrl += '&title=' + encodeURIComponent(this.info.share_image.text);     //分享的标题
                window.open(_shareUrl,'_blank');
            }
        }
    }
</script>

<style scoped>

</style>
