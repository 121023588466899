<template>
    <a-modal :visible="visible"
             :footer="false"
             :closable="false"
             :bodyStyle="{padding:0}"
             width="800px"
             wrapClassName="sr-dialog">
        <div v-if="visible"  style="padding: 30px 50px;box-sizing: border-box">
            <h3 class="modal-boxT te_c">参与拼单</h3>
            <slot></slot>
            <div class="mt20 te_c">
                <div class="f22">
                    参与【{{pintuan_group.chief.nickname}}】的拼单
                </div>
                <div class="color-999">
                    仅剩{{pintuan.number-pintuan_group.pintuan_user_count}}个名额，
                    <a-statistic-countdown :value="pintuan_group.expire_at|toTime"
                                           format="D 天 H 时 m 分 s 秒"/>
                    后结束
                </div>
            </div>
            <div class="mt20 te_c">
                <div class="pinduan-ry"
                     v-for="item in list">
                    <img v-if="item.user"
                         :src="item.user.head_portrait.url">
                    <img v-else
                         src="@/assets/img/PT-avatar2.jpg">
                </div>
            </div>
            <div class="te_c mt30">
                <SrButton style="width: 106px;margin: 0 auto"
                          height="42px"
                          padding="0"
                          @click="toPindan"
                          font-size="14px">参与拼单
                </SrButton>
                <!-- 状态2 -->
                <!-- <a href="">邀请好友来听课</a> -->
            </div>
            <div class="pintuan-xz color-666 mt20">
                <h4><img src="@/assets/img/KC-tbg2.png"> 拼课须知：</h4>
                1. 拼团活动，开团成为团长，并邀请好友参团，在拼团有效时间内凑齐成团人数，即可拼团成功，商城将会安排发货；<br/>
                2. 拼团有效时间内未凑齐成团人数，即拼团失败。系统自动取消订单并全额退款，支付金额将会原路退回付款账户；<br/>
                3. 拼团有效时间为24小时，即拼团允许邀请好友参团的时间，可在拼团详情页查看倒计时；<br/>
                4. 用户购买拼团商品并无退货情况产生，可以获得与订单完成时的金额相等的积分数量；<br/>
                5. 拼团商品可以使用积分，但需要积分数量≥100时才可进行抵扣；<br/>
                6. 针对拼团商品，如果价格低于原商品价格的50%，用户购买时不可使用积分抵扣，也不能获得积分。<br/>
                7. 拼团商品不可以使用优惠券；<br/>
                8. 用户可在【我的】-【我的拼团】中，查看自己的拼团订单。
            </div>
            <a class="modal-close"><img @click="visible = false"
                                        src="@/assets/img/modal-close.png"></a>
        </div>
    </a-modal>
</template>

<script>
    import {userList} from "@/api/pintuan"
    import SrButton from '@/components/SrButton'

    export default {
        name: "JoinPintuan",
        components: {
            SrButton
        },
        data() {
            return {
                visible: false,
                pintuan: {},
                pintuan_group: {},
                list: []
            }
        },
        filters: {
            toTime(value) {
                return new Date(value).getTime()
            },
        },
        methods: {
            show(pintuan_group,pintuan) {
                var _self = this
                _self.pintuan_group = pintuan_group
                _self.pintuan = pintuan
                _self.visible = true
                userList({pintuan_group_id: pintuan_group.pintuan_group_id}).then((res) => {
                    _self.list = res.result
                    //不足补齐
                    if (_self.list.length < _self.pintuan.number) {
                        for (let i = 0; i <= (_self.pintuan.number - _self.list.length); i++) {
                            _self.list.push({})
                        }
                    }
                })
            },
            toPindan() {
                let routeData = this.$router.resolve({
                    path: '/buy/pintuan', query: {
                        goods_id: this.pintuan.goods.goods_id,
                        pintuan_id: this.pintuan.pintuan_id,
                        pintuan_group_id: this.pintuan_group.pintuan_group_id
                    }
                });
                window.open(routeData.href, '_blank');
            }
        }
    }
</script>

<style scoped>
    .price {
        font-weight: bold;
        font-size: 16px;
    }

    .color-999 {
        font-size: 16px;
    }

    .color-999 >>> .ant-statistic {
        display: inline-block;
    }

    .color-999 >>> .ant-statistic-content {
        display: inline-block;
    }

    .color-999 >>> .ant-statistic-content-value {
        font-size: 16px;
        color: #999;
    }

    .pinduan-ry img {
        width: 93px;
        height: 93px;
        border-radius: 50% 50%;
    }
</style>
