import request from '@/utils/request'

/**
 * 获取训练营分类列表
 * @param parameter
 */
export function typeList(parameter) {
    return request({
        url: '/api/camp/type',
        method: 'post',
        data: parameter
    })
}

/**
 *
 * 训练营列表
 * @param parameter
 */
export function list(parameter) {
    return request({
        url: '/api/camp',
        method: 'post',
        data: parameter
    })
}

/**
 * 训练营详情
 * @param parameter
 */
export function detail(parameter) {
    return request({
        url: '/api/camp/detail',
        method: 'post',
        data: parameter
    })
}

/**
 * 学员笔记列表
 * @param parameter
 */
export function commentList(parameter) {
    return request({
        url: '/api/camp/campList',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取某训练营主题列表
 * @param parameter
 */
export function themeList(parameter) {
    return request({
        url: '/api/camp/theme',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取某主题任务列表
 * @param parameter
 */
export function taskList(parameter) {
    return request({
        url: '/api/camp/themeTask',
        method: 'post',
        data: parameter
    })
}

/**
 * 打卡
 * @param parameter
 */
export function clock(parameter) {
    return request({
        url: '/api/camp/clock',
        method: 'post',
        data: parameter
    })
}

/**
 * 笔记点赞/取消赞
 * @param parameter
 */
export function like(parameter) {
    return request({
        url: '/api/camp/like',
        method: 'post',
        data: parameter
    })
}

/**
 * 任务详情
 * @param parameter
 */
export function themeTaskDetail(parameter) {
    return request({
        url: '/api/camp/themeTaskDetail',
        method: 'post',
        data: parameter
    })
}

// 发表主题日记
export function diary(parameter) {
    return request({
        url: '/api/camp/diary',
        method: 'post',
        data: parameter
    })
}

// 获取分享图
export function shareUser(parameter) {
    return request({
        url: '/api/user/share_user',
        method: 'post',
        data: parameter
    })
}

export function video(parameter) {
    return request({
        url: '/api/camp/video',
        method: 'post',
        data: parameter
    })
}

/**
 * 完成任务
 * @param parameter
 */
export function doTask(parameter) {
    return request({
        url: '/api/camp/doTask',
        method: 'post',
        data: parameter
    })
}

/**
 * 学习进度
 * @param parameter
 */
export function reward(parameter) {
    return request({
        url: '/api/camp/reward',
        method: 'post',
        data: parameter
    })
}
