<template>
  <div>
    <BjZhibo2
      v-show="
        course_subchapter.type == 1 && course_subchapter.class_status == 1
      "
      style="height: 800px"
      ref="BjZhibo"
    ></BjZhibo2>
    <BjZhibo2
      v-show="
        course_subchapter.type == 4 && course_subchapter.class_status == 1
      "
      style="height: 800px"
      ref="BjZhibo"
    ></BjZhibo2>
    <!-- 视频播放 -->
    <div class="clearfix mt50 kcxq-box" style="overflow: hidden">
      <h3 class="f26">{{ course_subchapter.name }}</h3>
      <div class="clearfix mt20">
        <BjHuifang
          ref="BjHuifang"
          style="height: 800px"
          v-show="isHuifang"
        ></BjHuifang>
        <!-- <BjHuifang
          ref="BjHuifang"
          style="height: 800px"
          v-show="
            course_subchapter.type == 1 && course_subchapter.class_status == 2
          "
        ></BjHuifang> -->
        <div v-show="course_subchapter.type == 2" class="kc-video fn_l">
          <BjDianbo
            v-show="course_subchapter.type == 2"
            ref="BjDianbo"
            @play="playing = 1"
            @pause="playing = 0"
            @ended="endView"
          ></BjDianbo>
        </div>
        <div v-if="course_subchapter.type == 2" class="kc-videoList fn_r">
          <div class="tab-wrapper">
            <ul class="tab-menu">
              <li class="active">课程大纲</li>
            </ul>
            <div class="tab-content">
              <CourseChapter
                :list="course.course_chapter"
                type="small"
              ></CourseChapter>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReCourseBig></ReCourseBig>
  </div>
</template>

<script>
import { subchapterInfo, info } from "@/api/school/course";
import ReCourseBig from "@/layout/recommend/ReCourseBig2";
import BjDianbo from "@/components/BjDianbo";
import BjZhibo2 from "@/components/BjZhibo2";
import BjHuifang from "@/components/BjHuifang";
import DianboChatRoom from "./modules/DianboChatRoom";
import ZhiboChatRoom from "./modules/ZhiboChatRoom";
import CourseChapter from "./modules/CourseChapter";
import { updateVideoProgress } from "@/api/comment";
import ZhiboWhiteboard from "@/views/course/subchapter/modules/ZhiboWhiteboard";
import ZhiboNotice from "@/views/course/subchapter/modules/ZhiboNotice";

export default {
  name: "index",
  components: {
    ZhiboNotice,
    ZhiboWhiteboard,
    ReCourseBig,
    BjDianbo,
    DianboChatRoom,
    CourseChapter,
    ZhiboChatRoom,
    BjZhibo2,
    BjHuifang,
  },

  computed: {
    IsZhibo() {
      var course_subchapter = this.course_subchapter;
      if (course_subchapter.type === 1) {
        if (course_subchapter.class_status < 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    IsDianbo() {
      var course_subchapter = this.course_subchapter;
      if (course_subchapter.type === 1) {
        return false;
      } else {
        return true;
      }
    },
    IsHuifang() {
      var course_subchapter = this.course_subchapter;
      if (course_subchapter.type === 4) {
        if (course_subchapter.class_status < 2) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      course_id: 0,
      course_subchapter_id: 0,
      course_subchapter: {},
      tab_active: 1,
      course: {},
      playing: 0,
      token: "",
      video_id: 0,
      isHuifang: false,
    };
  },
  created() {
    var _self = this;
    this.course_id = this.$route.params.course_id;
    this.course_subchapter_id = this.$route.params.course_subchapter_id;
    info({ course_id: this.course_id }).then((res) => {
      _self.course = res.result;
      subchapterInfo({ course_subchapter_id: this.course_subchapter_id }).then(
        (res) => {
          console.log(res, "信息");
          if (res.status === 200) {
            _self.course_subchapter = res.result;
            if (res.result.type === 1) {
              //直播课
              if (res.result.class_status === 2) {
                console.log("大课回放");
                _self.isHuifang = true;
                _self.$refs.BjHuifang.init(
                  res.result.huifang.room_id,
                  res.result.huifang.token
                );
                _self.timeupdate();
              } else {
                var config = res.result.sign;
                config.customCoverImg = _self.course.first_cover.url;
                _self.$refs.BjZhibo.init(config);
              }
            } else {
              if (res.result.type == 2) {
                _self.$refs.BjDianbo.init(
                  res.result.sign.token,
                  res.result.sign.video_id
                );
              }
              // 小班课回放
              if (res.result.type === 4 && res.result.class_status === 2) {
                _self.isHuifang = true;
                console.log("小班课回放");
                _self.$refs.BjHuifang.init(
                  res.result.huifang.room_id,
                  res.result.huifang.token
                );
                _self.timeupdate();
              }
              if (res.result.type === 4 && res.result.class_status === 1) {
                var config = res.result.sign;
                config.customCoverImg = _self.course.first_cover.url;
                _self.$refs.BjZhibo.init(config);
              }
              if (res.result.type === 4 && res.result.class_status === 0) {
                var config = res.result.sign;
                config.customCoverImg = _self.course.first_cover.url;
                _self.$refs.BjZhibo.init(config);
              }
            }
          } else {
            if (res.status === 400) {
              _self.$srmsg.info({
                title: "提示",
                content: res.msg,
              });
            }
          }
        }
      );
    });
  },
  methods: {
    play() {
      this.playing = 1;
    },
    pause() {
      this.playing = 0;
    },
    timeupdate() {},
    endView() {
      //结束视频观看
      var _self = this;
      var baijia_video_id = _self.course_subchapter.baijia_video_id;
      var seconds = _self.$refs.BjDianbo.getCurrentTime();
      updateVideoProgress({
        baijia_video_id: baijia_video_id,
        seconds: seconds,
      });
    },
  },
};
</script>

<style scoped>
.whiteboard {
  width: 100%;
  height: 500px;
  position: relative;
}
</style>
