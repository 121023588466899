<template>
  <div>
    <div class="per3_right per3_right1 per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>分销赚钱<router-link
            :to="{ path: '/user/center/myAbout', query: { id: 7} }"
            ><a href="">赚钱规则</a></router-link
          ></h6>
      </div>

      <div class="per7_integral">
        <div class="per7_in1 per7_in2 per7_in3">
          <ul>
            <li>
              <img src="@/assets/img/integral_img1.png" />
              <div class="integral_wen integral_wen1">
                <p>已邀请人数</p>
                <h6>
                  {{ detail.invitation }}
                  <router-link to="/user/center/invitedUser">
                    <a href="">明细</a>
                  </router-link>
                </h6>
              </div>
            </li>
            <li>
              <img src="@/assets/img/integral_img2.png" />
              <div class="integral_wen integral_wen1">
                <p>可提现奖金</p>
                <h6>
                  {{ detail.user_commission }}
                  <router-link to="/user/center/myWallet">
                    <a href="">提现</a>
                  </router-link>
                </h6>
              </div>
            </li>
            <li>
              <img src="@/assets/img/integral_img4.png" />
              <div class="fx_zong">
                <div class="fx_z_img">
                  <img src="@/assets/img/fx_er.jpg" />
                  <div class="fx_z_r">
                    <h6>分享二维码,邀请好友 <br />加入”成公社“</h6>
                    <p>
                      邀&nbsp;&nbsp;好&nbsp;&nbsp;友&nbsp;&nbsp;可&nbsp;&nbsp;获&nbsp;&nbsp;得
                      <br />永&nbsp;&nbsp;久&nbsp;&nbsp;分&nbsp;&nbsp;销&nbsp;&nbsp;积&nbsp;&nbsp;分
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 页码 -->
      <!-- <div class="page mt20">
					<a href="">&lt;&lt;</a>
					<a class="active" href="">1</a>
					<a href="">2</a>
					<a href="">&gt;&gt;</a>
				</div> -->
    </div>

    <div class="per2_fx">
      <div class="per2_fx_course">
        <div class="p_remind_title">
          <img src="@/assets/img/remind_icon.png" />
          <h6>分销赚钱课程</h6>
        </div>
        <div class="per2_fx_nr">
          <ul v-if="shareList.length > 0">
            <li v-for="i in shareList">
              <a>
                <div class="per2_fxkc_l">
                  <img :src="i.image.url" />
                  <div class="fxkc_wen">
                    <div class="fxkc_wen1">
                      <h6>{{ i.relation.name }}</h6>
                      <p>{{ i.startTime }}-{{ i.endTime }}</p>
                    </div>
                    <!-- <div class="fxkc_wen2">
												<p>奖学金最高<span>99</span>元</p>
											</div> -->
                  </div>
                </div>
                <div class="per2_fxkc_r">
                  <a class="yaoqing" @click="copyLink(i.type, i.relation_id)"
                    >邀请</a
                  >
                </div>
              </a>
            </li>
          </ul>
          <div class="zanwu" v-if="shareList.length == 0">暂无数据</div>
          <!-- 页码 -->
           <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
      ></SrPaginate>
        </div>
      </div>

      <div class="per2_talent">
        <div class="p_remind_title">
          <img src="@/assets/img/remind_icon.png" />
          <h6>赚钱达人榜</h6>
        </div>
        <div class="per2_talent_nr">
          <ul>
            <li v-if="rankList.length > 0" v-for="i in rankList">
              <div>
                <div class="talent_l">
                  <img :src="i.share.head_portrait.url" />
                  <p>{{ i.share.nickname }}</p>
                </div>
                <div class="talent_r">
                  <p>
                    收益：<span>￥{{ i.money }}</span>
                  </p>
                </div>
              </div>
            </li>
            <div
              v-if="rankList.length == 0"
              style="text-align: center; color: #999999; margin-top: 60px"
            >
              暂无数据
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { statistical, ranking, share } from "@/api/user";
import SrPaginate from "@/components/SrPaginate";
export default {
  name: "Distribution",
  components: {
    SrPaginate,
  },
  data() {
    return {
      detail: {},
      rankList: [],
      shareList: [],
      pagination: {
        page: 1,
        page_size: 4,
      },
    };
  },
  methods: {
    loadStatistical() {
      let _this = this;
      statistical().then((res) => {
        _this.detail = res.result;
      });
    },
    loadRanking() {
      let _this = this;
      ranking().then((res) => {
        _this.rankList = res.result.items;
      });
    },
    loadShare() {
      let _this = this;
      share({..._this.pagination}).then((res) => {
        _this.shareList = res.result.items;
         _this.pagination = res.result.pagination;
        _this.shareList.map(function (n) {
          n.startTime = n.relation.start_at.substring(0, 10);
          n.endTime = n.relation.end_at.substring(0, 10);
        });
      });
    },
    copyLink(type, id) {
      //   1网校  3电台 4训练营
      if (type == 1) {
        //   document.execCommand();
		var url = 'http://srgongkaow.com/course/info/'+ id + '?user_id=' + this.$store.state.user.user_id
		this.$copyText(url).then(() => {
      	this.$message.success("复制链接成功！");
		})
      }else if(type ==3){
		  	var url = 'http://srgongkaow.com/radio/info/'+ id + '?user_id=' + this.$store.state.user.user_id
		this.$copyText(url).then(() => {
      	this.$message.success("复制链接成功！");
		})
	  }else{
		  	var url = 'http://srgongkaow.com/camp/info/'+ id + '?user_id=' + this.$store.state.user.user_id
		this.$copyText(url).then(() => {
      	this.$message.success("复制链接成功！");
		})
	  }
    },
     changePage(page, page_size) {
      this.pagination.page = page;
      this.loadShare();
    },
  },
  created() {
    this.loadStatistical();
    this.loadRanking();
    this.loadShare();
  },
};
</script>
<style scoped>
.talent_l img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.per2_fxkc_l img {
  width: 200px;
}
</style>