<template>

</template>

<script>
    export default {
        name: "DianboChatRoom",
        data() {
            return {

            }
        }
    }
</script>

<style scoped>

</style>
