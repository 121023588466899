<template>
    <div>
        <a-spin :spinning="list_loading">
            <a-icon slot="indicator"
                    type="loading"
                    style="font-size: 24px"
                    spin/>

            <div class="warp mt30">
                <h3 class="kc-tuijian f26"><img src="@/assets/img/KC-tbg.png">购物车</h3>

                <a-empty v-if="list.length===0"
                         description="购物车空空如也"/>
                <div v-else>
                    <div
                            v-for="(item,index) in list"
                            :key="item.cart_id"
                            class="cart-list dis-table mt20">
                        <div class="dis-tablecell">
                            <SrRadio v-model="cart_id[index]"
                                     :zhi="item.cart_id"></SrRadio>
                        </div>
                        <div class="dis-tablecell"
                             style="padding-right: 20px">
                            <img height="100px"
                                 :src="item.goods.image.url">
                        </div>
                        <div class="dis-tablecell">
                            <p class="f22">{{item.goods.name}}</p>
                            <p class="color-666" v-if="item.goods.relation.start_at || item.goods.relation.end_at">
                                <span>{{$moment(item.goods.relation.start_at).format('YYYY.MM.DD')}}</span>
                                <span> - </span>
                                <span>{{$moment(item.goods.relation.end_at).format('YYYY.MM.DD')}}</span>
                                <span v-if="item.goods.relation.period"> {{item.goods.relation.period}}课时</span>
                            </p>
                            <span class="f14 color-999 cart-btex">
                                距结束：
                            <a-statistic-countdown :value="item.goods.relation.end_at|toTime"
                                                   format="D 天 H 时 m 分 s 秒"/>
                            </span>
                            <p class="f14 color-999">限售{{item.goods.sales_restriction}}人 |
                                已报名{{item.goods.sale_amount}}人</p>
                        </div>
                        <div class="dis-tablecell te_c color-red f26">
                            ￥{{item.goods.real_price}}
                        </div>
                        <div style="cursor: pointer"
                             class="dis-tablecell"
                             @click="remove(item,index,1)">
                            <img src="@/assets/img/delete.png">
                        </div>
                    </div>
                </div>
                <div v-if="expire_list.length!==0">
                    <h3 class="cart-gqkc mt20">过期的课程</h3>
                    <div v-for="(item,index) in expire_list"
                         :key="item.cart_id"
                         class="cart-list dis-table mt20">
                        <div class="dis-tablecell">
                            <div class="checkbox checkbox-flase">
                                <!--<SrRadio v-model="cart_id[index]"-->
                                <!--:zhi="item.cart_id"></SrRadio>-->
                            </div>
                        </div>
                        <div class="dis-tablecell">
                            <!--<img :src="item.image.url">-->
                        </div>
                        <div class="dis-tablecell">
                            <p class="f22">{{item.goods.name}}</p>
                            <p class="color-666">
                                <span>{{$moment(item.goods.relation.start_at).format('YYYY.MM.DD')}}</span>
                                <span> - </span>
                                <span>{{$moment(item.goods.relation.end_at).format('YYYY.MM.DD')}}</span>
                                <span> {{item.goods.relation.period}}课时</span>
                            </p>
                            <span class="f14 color-999 cart-btex">已结束</span>
                            <p class="f14 color-999">限售{{item.goods.sales_restriction}}人 |
                                已报名{{item.goods.sale_amount}}人</p>
                        </div>
                        <div class="dis-tablecell te_c color-red f26">
                            ￥{{item.goods.real_price}}
                        </div>
                        <div style="cursor: pointer"
                             class="dis-tablecell"
                             @click="remove(item,index,2)">
                            <img src="@/assets/img/delete.png">
                        </div>
                    </div>
                </div>
                <div class="cart-list dis-table mt20">
                    <div class="dis-tablecell">
                        <SrRadio v-model="all"
                                 @change="selectAll"
                                 :zhi="true"></SrRadio>
                    </div>
                    <div class="dis-tablecell">
                        全选
                    </div>
                    <div class="dis-tablecell te_r color-red f26 price-btn">
                        <span style="margin-right: 20px">
                        ￥{{price}}
                        </span>
                        <SrButton @click="toBuy"
                                  style="width: 120px;float: right"
                                  padding="0"
                                  font-size="18px">结算
                        </SrButton>
                    </div>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import SrRadio from '@/components/SrRadio'
    import {list, remove} from "@/api/cart"
    import SrButton from '@/components/SrButton'

    export default {
        name: "index",
        components: {
            SrRadio,
            SrButton
        },
        data() {
            return {
                all: false,
                list: [],
                expire_list: [],
                cart_id: [],
                list_loading: false
            }
        },
        filters: {
            toTime(value) {
                return new Date(value).getTime()
            },
        },
        computed: {
            price() {
                var _self = this
                var price = 0
                _self.list.forEach((item) => {
                    if (_self.cart_id.indexOf(item.cart_id) !== -1) {

                        price = price + parseFloat(item.goods.real_price)
                    }

                })
                return price
            },
            goodsId() {
                var _self = this
                var goods_id = []
                _self.list.forEach((item) => {
                    if (_self.cart_id.indexOf(item.cart_id) !== -1) {
                        var obj = {}
                        if(item.sku){
                            obj.sku_id = item.sku.sku_id
                        }else{
                            obj.sku_id = ''
                        }
                        obj.num = item.num
                        obj.goods_id = item.goods_id
                        goods_id.push(obj)
                    }
                })
                return goods_id
            }
        },
        created() {
            var _self = this
            _self.list_loading = true
            list().then((res) => {
                console.log(res,'列表')
                _self.list_loading = false
                _self.list = res.result.list
                _self.expire_list = res.result.expire_list
            })
        },
        methods: {
            remove(item, index, type) {
                var _self = this
                _self.list_loading = true
                remove({cart_id: [item.cart_id]}).then((res) => {
                    _self.list_loading = false
                    if (res.status === 200) {
                        _self.$message.success('操作成功')
                        if (type === 1) {
                            _self.list.splice(index, 1)
                        } else {
                            _self.expire_list.splice(index, 1)
                        }
                        _self.$forceUpdate()
                    } else {
                        _self.$srmsg.info({
                            title: '提示',
                            content: res.msg
                        })
                    }
                })
            },
            selectAll(value) {// 全选
                if (value) {
                    var _self = this
                    this.cart_id = []
                    _self.list.forEach((item) => {
                        _self.cart_id.push(item.cart_id)
                    })
                } else {
                    this.cart_id = []
                }
            },
            toBuy() { //前往结算页
                if (this.goodsId.length === 0) {
                    this.$srmsg.info({
                        title: '提示',
                        content: "请至少选择一件商品"
                    })
                    return false
                }
                console.log(this.goodsId)
                this.$router.push({
                    path: '/buy/settlement',
                    query: {goods_id: this.goodsId}
                })
            }
        }
    }
</script>

<style scoped>
    .price-btn {
        display: flex;
        align-items: center;
        float: right;
    }

    .cart-btex >>> .ant-statistic {
        display: inline-block;
    }

    .cart-btex >>> .ant-statistic-content {
        display: inline-block;
        font-size: 14px;
    }

    .cart-btex >>> .ant-statistic-content-value {
        font-size: 14px;
        color: #999;
    }
</style>
