<template>
    <div id="box">
        <div v-if="!loading" v-html="imgWH(camp.introduce)">

        </div>
    </div>
</template>

<script>

    export default {
        name: "CampIntroduce",
        props: {
            loading: {},
            camp: {}
        },
        data() {
            return {
                // loading: true
            }
        },
        created() {

        },
        methods: {
            imgWH(string){ //正则替换图片宽高
                if(string){
                    string = string.replace(/ height="\d+"/g, " height=\"auto\"");
                    string = string.replace(/ width="\d+"/g, " width=\"100%\"");
                    return string
                }else{
                    return ''
                }
            }
        }
    }
</script>

<style scoped>
</style>
