<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的优惠券<a href=""></a></h6>
      </div>

      <div class="per7_integral">
        <!-- tab -->
        <div class="per7_in2">
          <div class="tab-wrapper">
            <ul class="tab-menu tab-menu-form">
              <li :class="typeIndex == 0?'active':''" @click="getTap(0)">未使用({{available.length}})</li>
              <li :class="typeIndex == 1?'active':''" @click="getTap(1)">已使用({{used.length}})</li>
              <li :class="typeIndex == 2?'active':''" @click="getTap(2)">已过期({{overdue.length}})</li>
            </ul>
            <div class="tab-content">
              <div v-if="typeIndex == 0">
                <div class="you_coupons">
                  <div class="coupon-items">
                    <div class="coupon-item coupon-item-d" v-for="i in available" v-if="available.length > 0">
                      <div class="c-type">
                        <div class="c-price">
                          <em>¥</em>
                          <strong>{{i.coupon.value}}</strong>
                          <span class="type">优惠券</span>
                        </div>
                        <div class="c-limit">满{{i.coupon.threshold}}可用</div>
                        <div class="c-type-top"></div>
                        <div class="c-type-bottom"></div>
                      </div>
                      <div class="c-msg">
                        <div class="c-range">
                          <div class="range-item">
                            <span class="label" style="display:block;color:#ff4545;float:none;width:100%;text-align:left;font-size:16px">{{i.coupon.name}}</span>
                            <span class="txt" style="display:block;float:none;width:100%;text-align:left;padding-left:15px">过期时间：{{i.expire_at}}</span>
                          </div>
                        </div>
                        <div class="op-btns">
                          <a class="btn" target="_blank" clstag=""
                            ><span class="txt">未使用</span><b></b
                          ></a>
                        </div>
                        <div class="ftx-03 ac mt5">&nbsp;&nbsp;</div>
                      </div>
                      <div class="c-del" onclick="" clstag=""></div>
                    </div>
                    <div class="zanwu" v-if="available.length == 0">暂无优惠券</div>
                  </div>
                </div>
              </div>
              <div v-if="typeIndex == 1">
                <div class="you_coupons">
                  <div class="coupon-items">
                    <div class="coupon-item coupon-item-d" v-for="i in used" v-if="used.length > 0">
                      <div class="c-type">
                        <div class="c-price">
                          <em>¥</em>
                          <strong>{{i.coupon.value}}</strong>
                          <span class="type">优惠券</span>
                        </div>
                        <div class="c-limit">满{{i.coupon.threshold}}可用</div>
                        <div class="c-type-top"></div>
                        <div class="c-type-bottom"></div>
                      </div>
                      <div class="c-msg">
                        <div class="c-range">
                          <div class="range-item">
                           <span class="label" style="display:block;color:#ff4545;float:none;width:100%;text-align:left;font-size:16px">{{i.coupon.name}}</span>
                            <span class="txt" style="display:block;float:none;width:100%;text-align:left;padding-left:15px">过期时间：{{i.expire_at}}</span>
                          </div>
                        </div>
                        <div class="op-btns coupon_overdue">
                          <a class="btn" target="_blank" clstag=""
                            ><span class="txt">已使用</span><b></b
                          ></a>
                        </div>
                        <div class="ftx-03 ac mt5">&nbsp;&nbsp;</div>
                      </div>
                      <div class="c-del" onclick="" clstag=""></div>
                    </div>
                    <div class="zanwu" v-if="used.length == 0">暂无优惠券</div>
                  </div>
                </div>
              </div>
              <div v-if="typeIndex == 2">
                <div class="you_coupons">
                  <div class="coupon-items">
                    <div class="coupon-item coupon-item-d coupon_overdue" v-for="i in overdue" v-if="overdue.length > 0">
                      <div class="c-type">
                        <div class="c-price">
                          <em>¥</em>
                          <strong>{{i.coupon.value}}</strong>
                          <span class="type">优惠券</span>
                        </div>
                        <div class="c-limit">满{{i.coupon.threshold}}可用</div>
                        <div class="c-type-top"></div>
                        <div class="c-type-bottom"></div>
                      </div>
                      <div class="c-msg">
                        <div class="c-range">
                          <div class="range-item">
                           <span class="label" style="display:block;color:#ff4545;float:none;width:100%;text-align:left;font-size:16px">{{i.coupon.name}}</span>
                            <span class="txt" style="display:block;float:none;width:100%;text-align:left;padding-left:15px">过期时间：{{i.expire_at}}</span>
                          </div>
                        </div>
                        <div class="op-btns">
                          <a class="btn" target="_blank" clstag=""
                            ><span class="txt">已过期</span><b></b
                          ></a>
                        </div>
                        <div class="ftx-03 ac mt5">&nbsp;&nbsp;</div>
                      </div>
                      <div class="c-del" onclick="" clstag=""></div>
                    </div>
                       <div class="zanwu" v-if="overdue.length == 0">暂无优惠券</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 页码 -->
      <!-- <div class="page mt20">
					<a href="">&lt;&lt;</a>
					<a class="active" href="">1</a>
					<a href="">2</a>
					<a href="">&gt;&gt;</a>
				</div> -->
    </div>
  </div>
</template>
<script>
import { myCoupon } from "@/api/user";
export default {
  name: "MyCoupon",

  data() {
    return {
        available:[],
        overdue:[],
        used:[],
        typeIndex:0
    };
  },
  methods: {
    loadMyCoupon() {
      let _this = this;
      myCoupon().then((res) => {
        console.log(res, "优惠券");
        var data = res.result.items;
        data.map(function(n){
          	// 1未使用 2已使用 3已过期
          if(n.status == 1){
            _this.available.push(n)
          }else if(n.status == 2){
            _this.used.push(n)
          }else{
            _this.overdue.push(n)
          }
        })
      });
    },
    getTap(index){
        this.typeIndex = index
    }
  },
  created() {
    this.loadMyCoupon();
  },
};
</script>
<style scoped>
@import "../../../assets/css/coupon_style.css";
.coupon-item .range-item .label {
    float: left;
    width: 80px;
    display: inline;
    text-align: right;
    font-size: 14px;
    padding-left: 15px;
}
.c-time{
    padding-bottom: 20px;
}
.c-type{
    padding-bottom: 20px;
}
</style>