<template>
  <div>
    <SrNav></SrNav>
    <SrBanner2></SrBanner2>
    <!-- 内容 -->
    <!-- 分类 -->
    <div class="warp">
      <div class="course-fl1 mt50 xun_fl1 mall2">
        <a
          :class="typeIndex == 'all' ? 'active' : ''"
          @click="clickType('', 'all')"
          >全部</a
        >
        <a
          v-for="(i, index) in typeList"
          @click="clickType(i.wares_category_id, index)"
          >{{ i.name }}</a
        >
      </div>
      <div class="warp clearfix mt30">
        <div class="kc-list fn_l mall2_list" v-for="i in list">
          <router-link
            :to="{ path: '/store/detail', query: { wares_id: i.wares_id } }"
          >
            <a>
              <div class=""><img :src="i.image.url" /></div>
              <div class="kc-listTex">
                <div class="dw" v-if="i.goods.notend_pintuan">
                  <img src="@/assets/img/pin_logo1.png" />
                </div>
                <div class="dw" v-if="i.goods.notend_seckill">
                  <img src="@/assets/img/miao_logo1.png" />
                </div>
                <h3>{{ i.goods.name }}</h3>
                <p class="clearfix color-666">
                  <span class="fn_l"
                    >已购买<em class="color-main"
                      >{{ i.goods.sale_amount }}人</em
                    >
                    <!-- | 剩余库存<em class="color-pink">{{i.goods.sales_restriction}}人</em> -->
                  </span>
                  <span class="fn_r">
					<s class="hx">￥{{i.goods.real_price}}</s>
                    <em class="f22 color-main"
                      >￥{{ i.order_price }}</em
                    >
					</span>
                </p>
              </div>
            </a>
          </router-link>
        </div>
      </div>
    </div>
    <!-- 页码 -->
    <SrPaginate
      style="margin: 0 auto; text-align: center; margin-top: 50px"
      :current="pagination.current_page"
      :total="pagination.total"
      :pageSize="pagination.page_size"
      @change="changePage"
    ></SrPaginate>
    <!-- foot -->
    <div class="foot1 mt80">
      <div class="warp clearfix">
        <dl>
          <dt></dt>
          <dd>
            <h3>视频合集</h3>
            <p>课程多，机构多，人才多</p>
          </dd>
        </dl>
        <dl>
          <dt></dt>
          <dd>
            <h3>考试练习</h3>
            <p>课程多，机构多，人才多</p>
          </dd>
        </dl>
        <dl>
          <dt></dt>
          <dd>
            <h3>天天低价</h3>
            <p>课程多，机构多，人才多</p>
          </dd>
        </dl>
        <dl>
          <dt></dt>
          <dd>
            <h3>畅选无忧</h3>
            <p>课程多，机构多，人才多</p>
          </dd>
        </dl>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import { category, list } from "@/api/store";
import SrPaginate from "@/components/SrPaginate";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import SrBanner2 from "@/layout/SrBanner2";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
    SrPaginate,
    SrBanner2,
  },
  data() {
    return {
      title: "",
      detail: {},
      typeList: [],
      typeIndex: "all",
      wares_category_id: "",
      list: [],
      pagination: {
        page: 1,
        page_size: 6,
      },
      keywords: "",
    };
  },
  mounted() {},

  methods: {
    //   获取分类
    loadType() {
      let _this = this;
      category({}).then((res) => {
        console.log(res, "分类");
        _this.typeList = res.result;
      });
    },
    clickType(id, index) {
      this.wares_category_id = id;
      this.typeIndex = index;
      this.loadList();
    },
    keywordss(e) {
      this.keywords = e;
      this.loadList();
    },
    loadList() {
      let _this = this;
      list({
        wares_category_id: _this.wares_category_id,
        keywords: _this.keywords,
        ..._this.pagination,
      }).then((res) => {
        console.log(res, "列表");
        //   商品 划线价就是原价  实际价格是当前会员价格 如果不是会员就显示原价
        var data = res.result.items;
        var userInfo = _this.$store.state.user
        data.map(function (n) {
          if (n.goods.notend_pintuan || n.goods.notend_seckill) {
            if (n.goods.notend_seckill) {
              n.order_price = n.goods.notend_seckill.goods.order_price;
            }
            if (n.goods.notend_pintuan) {
              n.order_price = n.goods.notend_pintuan.goods.order_price;
            }
          } else {
            if (n.sku.length > 0) {
              // 商品的sdk对应用户会员价  如果没有会员则显示vip1的价格
              if (userInfo.now_user_member) {
                n.sku[0].goods_sku_price.map(function (s) {
                  if (
                    s.user_member_config_id ==
                    userInfo.now_user_member.user_member_config_id
                  ) {
                    n.order_price = s.real_price;
                  }
                });
              } else {
                n.order_price = n.sku[0].goods_sku_price[5].real_price;
              }
            } else {
              n.order_price = n.goods.order_price;
            }
          }
        });
        _this.list = data;
		console.log(_this.list,'商品信息')
        _this.pagination = res.result.pagination;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
  },
  created() {
    this.loadType();
    this.loadList();
  },
};
</script>
<style scoped>
.kc-list {
  position: relative;
  width: 375px;
  margin-bottom: 30px;
  border-radius: 0px 0px 30px 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.kc-list img {
  width: 375px;
}
.kc-listTex h3 {
  margin-top: 0;
}
.mall2_zong a {
  font-size: 16px;
  color: #3561c7;
  width: 90px;
  margin-right: 15px;
}
.dw {
  position: absolute;
  top: 0;
  right: 0;
}
.dw img {
  width: 76px;
  height: 70px;
}
.hx{
  display: inline-block;
  color: #999999;
}
.mall2_list{
  height: 100%;
}
</style>