<template>
    <div class="warp mt30">
        <div class="xly-conT clearfix">
            <a-icon type="left" @click="goPrev" style="float:left;font-size:26px;margin-right:10px;cursor: pointer;padding-top:10px" />
            <p class="fn_l f22"><img class="dis-inb"
                                     src="@/assets/img/KC-tbg.png">{{task.name}}</p>
            <!--<a-button :href="task.file.url" @click="download">下载</a-button>-->
            <a :href="task.file.url"
               @click="download"
               target="_blank">下载</a>
            <p class="fn_r te_r">
                <img class="dis-inb"
                     @click="share"
                     src="@/assets/img/xun_share.png">
            </p>
        </div>

        <!-- PDF -->
        <div class="xun1_video">
            <div class="btn">
                <div class="btn-fanye"
                     @click="prePage">
                    <a-button>
                        上一页
                    </a-button>
                </div>
                <div>
                    {{page}}/{{pageCount}}
                </div>
                <div class="btn-fanye"
                     @click="nextPage">
                    <a-button>
                        下一页
                    </a-button>
                </div>
            </div>
            <div v-if="loading"
                 style="padding: 50px 100px;box-sizing: border-box;background: #fff">
                <a-progress :percent="progress"/>
            </div>
            <pdf @progress="pageProgress"
                 @page-loaded="loading=false"
                 @num-pages="pageCount=$event"
                 :src="task.file.url"
                 :page="page"></pdf>
        </div>
        <Share ref="Share"></Share>
        <ReCourseBig></ReCourseBig>
    </div>
</template>

<script>
    import {themeTaskDetail, doTask} from "@/api/camp"
    import ReCourseBig from '@/layout/recommend/ReCourseBig'
    import pdf from 'vue-pdf'
    import Share from '../theme/module/Share'

    export default {
        name: "index",
        components: {
            ReCourseBig,
            pdf,
            Share
        },
        data() {
            return {
                camp_id: 0,
                camp_theme_id: 0,
                camp_theme_task_id: 0,
                camp: {},
                task: {
                    file: {}
                },
                loading: true,
                progress: 0,
                page: 1,
                pageCount: 1, // pdf文件总页数
                time: 0
            }
        },
        created() {
            var _self = this
            this.camp_id = this.$route.params.camp_id
            this.camp_theme_id = this.$route.params.camp_theme_id
            this.camp_theme_task_id = this.$route.params.camp_theme_task_id
            themeTaskDetail({
                camp_id: this.camp_id,
                camp_theme_id: this.camp_theme_id,
                camp_theme_task_id: this.camp_theme_task_id
            }).then((res) => {
                _self.task = res.result
            })
            doTask({
                camp_id: _self.camp_id,
                camp_theme_id: _self.camp_theme_id,
                camp_theme_task_id: _self.camp_theme_task_id
            })
        },
        methods: {
            prePage() {
                if (this.page !== 1) {
                    this.page--
                }
            },
            nextPage() {
                if (this.page !== this.pageCount) {
                    this.page++
                }
            },
            pageProgress(e) {
                this.progress = e * 100
            },
            share() {
                this.$refs.Share.show()
            },
            download() {
                var _self = this
                _self.$srmsg.info({
                    title:'提示',
                    content:'如未开始下载请右键按钮选择链接另存为'
                })
            },
            goPrev(){
                history.go(-1)
            }
        }
    }
</script>

<style scoped>
    .btn {
        display: flex;
        justify-content: space-between;
        background: #fff;
        padding: 15px 100px;
        box-sizing: border-box;
    }

    .btn-fanye {
        cursor: pointer;
    }
</style>
