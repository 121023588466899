<template>
    <div id="box">
        <div v-if="!loading"
             v-html="course.introduce">

        </div>
    </div>
</template>

<script>

    export default {
        name: "CourseIntroduce",
        props: {
            loading: {},
            course: {}
        }
    }
</script>

<style scoped>
</style>
