import request from '@/utils/request'

export function uploadImage(parameter) {
    return request({
        url: '/api/common/upload_image',
        method: 'post',
        data: parameter
    })
}

export function uploadVideo(parameter) {
    return request({
        url: '/api/common/upload_video',
        method: 'post',
        data: parameter
    })
}

export function uploadAudio(parameter) {
    return request({
        url: '/api/common/uploadAudio',
        method: 'post',
        data: parameter
    })
}

// 公共发送验证码方法
export function sendMessage(parameter) {
    return request({
        url: '/api/common/sendMessage',
        method: 'post',
        data: parameter
    })
}

// 三级联动
export function location(parameter) {
    return request({
        url: '/js/location.json',
        method: 'GET',
        data: parameter,
        type:'Localhost'
    })
}

/**
 * 推荐课程
 * @param parameter
 */
export function recommend(parameter) {
    return request({
        url: '/api/cart/recommend',
        method: 'post',
        data: parameter
    })
}

// 标签列表
export function label(parameter) {
    return request({
        url: '/api/index/label',
        method: 'post',
        data: parameter
    })
}

// 用户提交标签
export function addLabel(parameter) {
    return request({
        url: '/api/user/addLabel',
        method: 'post',
        data: parameter
    })
}

export function banners(parameter) {
    return request({
        url: '/api/index/banner',
        method: 'post',
        data: parameter
    })
}