<template>
  <div>
    <!-- top -->
    <div class="index3_dx_top">
      <div class="index3_dx_top_nr warp">
        <div class="index3_dx_top_tui">
          <a>
            <img src="@/assets/img/in3_dx_tu.png" />
            <p @click="exit">退出</p>
          </a>
        </div>

        <div class="in3_dx_top_wen">
          <p>{{ title }}</p>
        </div>
      </div>
    </div>

    <!-- 3题目-单选题 -->
    <div class="index3_dx_nr mt50 warp">
      <div class="index3_dx_nr_l index3_mktk_con">
        <div class="index3_dx_nr_l_tit">
          <h6>
            {{
              tiDetail.type == 1
                ? "单选题"
                : tiDetail.type == 2
                ? "多选题"
                : "问答题"
            }}
            <span>{{ tiIndex + 1 }}/{{ subjectList.length }}</span>
          </h6>
        </div>

        <div class="index3_dx_one">
          <p v-html="tiDetail.title"></p>
        </div>

        <div class="index3_dx_four index3_dx_four1">
          <ul v-if="tiDetail.subject_option.length > 0">
            <li v-for="i in tiDetail.subject_option">
              <span :class="i.correct_flage == 1 ? 'active_true' : ''">{{
                i.option_key
              }}</span>
              <p>{{ i.title }}</p>
            </li>
          </ul>
        </div>
        <div class="index3_dx_five">
          <a class="index3_dx_five_f2" @click="next">下一题</a>
          <a class="index3_dx_five_f1" @click="last">上一题</a>
        </div>
        <div class="in3_jx_zong">
          <div class="in3_jx_duicuo" v-if="tiDetail.answer_situation">
            <div
              class="in3_jx_true"
              v-if="tiDetail.answer_situation.correct_flage == 1"
            >
              <img src="@/assets/img/in3_true.png" />
              <p>
                本题正确答案为<em>{{ tiDetail.correctOption }}</em
                >,<em>回答正确</em>
              </p>
            </div>
            <div
              class="in3_jx_wrong"
              v-if="tiDetail.answer_situation.correct_flage == 0"
            >
              <img src="@/assets/img/in3_wrong.png" />
              <p>
                本题正确答案为<em>{{ tiDetail.correctOption }}</em
                >,您的答案为<span>{{ tiDetail.myOption }}</span
                >，<span>回答错误</span>
              </p>
            </div>
          </div>

          <div class="in3_jx_nr">
            <div class="in3_jx_nr_tit">
              <span>解析</span>
              <a
                v-if="
                  tiDetail.answer_situation &&
                  tiDetail.answer_situation.wrong_book == 1
                "
                @click="removeWrong"
                >移除错题集</a
              >
            </div>
            <div class="in3_jx_nr_wen">
              <p v-html="tiDetail.subject_answer.title"></p>
            </div>

            <!-- <div class="in3_jx_nr_tu1">
              <img src="@/assets/img/in3_jx_nr_tu1.jpg" />
              <div class="in3_jx_nr_zi">
                <a href="">视频解析</a>
                <a href="" class="act">会员升级</a>
              </div>
            </div> -->
          </div>

          <div class="index3_laiyuan">
            <div class="index3_laiyuan_list" style="margin-top: 10px">
              <h6>来源</h6>
              <p>
                {{ tiDetail.from_paper.title }}
              </p>
            </div>
            <div class="index3_laiyuan_list1">
              <div class="index3_laiyuan_list1_tit">
                <span>笔记</span>
                <a @click="openNote">编辑笔记</a>
              </div>
              <div v-if="tiDetail.note.length > 0" v-for="i in tiDetail.note">
                <p>{{ i.content }}</p>
                <span v-for="item in i.image">
                  <img :src="item.images.url" alt="" />
                </span>
              </div>
              <p v-else>暂无笔记</p>
            </div>
          </div>
        </div>
      </div>
      <div class="index3_dx_nr_r">
        <div class="index3_dx_nr_r_tit">
          <i></i>
          <h6>答题卡</h6>
        </div>
        <div class="index3_dx_nr_r_nei index3_mktk_con index3_mktk_con1">
          <div class="index3_dx_nei_one">
            <ul>
              <li>
                <i class="in3_zq"></i>
                <p>正确</p>
              </li>
              <li>
                <i class="in3_cw"></i>
                <p>错误</p>
              </li>
              <li>
                <i class="in3_wz"></i>
                <p>未做</p>
              </li>
            </ul>
          </div>
          <div class="datika">
            <ul>
              <li v-for="(i, index) in subjectList" @click="openTi(index)">
                <span
                  v-if="i.answer_situation"
                  :class="i.answer_situation.correct_flage == 1 ? 'dui' : 'cuo'"
                  >{{ index + 1 }}</span
                >
                <span v-else>{{ index + 1 }}</span>
              </li>
              <!-- <li>
                <span class="index3_dx_nei_li">8<i></i></span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 评论回复弹窗开始 -->
    <div class="xly-modalBg" v-if="motals">
      <div class="xly-modalBox">
        <div class="">
          <span class="color-999">添加笔记</span>
          <!-- <span class="f22">主题名称</span>  -->
        </div>
        <div class="mt20">
          <textarea rows="10" cols="6" v-model="content"></textarea>
        </div>
        <div class="clearfix mt20">
          <p
            class="kc-upload fn_l in3_kc-upload"
            v-for="(i, index) in img_url"
            v-if="img_url.length > 0"
          >
            <img :src="i" />
            <span style="cursor: pointer" @click="delImg(i, index)"></span>
          </p>
          <p class="kc-upload fn_l choose-image-btn" @click="chooseImage2">
            <img src="@/assets/img/upload-image.jpg" style="cursor: pointer" />
            <input
              type="file"
              style="display: none"
              ref="file"
              @change="fileChange2"
            />
          </p>
        </div>
        <div class="in3_tjbj_a">
          <SrButton
            style="width: 100%; margin: 0 auto; margin-top: 50px"
            @click="setNote"
            height="45px"
            width="100%"
            color="#ffffff"
            padding="0 20px"
            font-size="14px"
            :loading="nickName_loading"
          >
            提交笔记
          </SrButton>
        </div>
        <a class="modal-close mt80"
          ><img @click="closeNote" src="@/assets/img/modal-close.png"
        /></a>
      </div>
    </div>
    <div class="right_nav">
      <ul>
        <li @click="setColloge">
          <img
            :src="
              tiDetail.collection_count == 1
                ? '/img/in3_shoucang_active.png'
                : '/img/in3_shoucang.png'
            "
            alt=""
          />
          <p>{{ tiDetail.collection_count == 1 ? "已收藏" : "收藏" }}</p>
        </li>
        <li>
          <img src="@/assets/img/in-zihao.png" alt="" />
          <p>字号</p>
          <div>
            <span>小</span>
            <span>中</span>
            <span>大</span>
          </div>
        </li>
        <li @click="getSun">
          <img :src="isSun ? '/img/sun.png' : '/img/night.png'" alt="" />
          <p>护眼</p>
        </li>
      </ul>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import {
  situation,
  wrongSetInfo,
  removeWrongSet,
  addNote,
  operate,
  collDetail,
} from "@/api/exam";
import { uploadImage } from "@/api/common";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import SrPaginate from "@/components/SrPaginate";
import SrButton from "@/components/SrButton";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
    SrPaginate,
    SrButton,
  },
  data() {
    return {
      answer_order_id: null,
      subjectList: [],
      title: "",
      tiIndex: 0,
      tiDetail: {},
      motals: false,
      content: "",
      img_ids: [],
      img_url: [],
      nickName_loading: false,
      isSun: true,
    };
  },
  mounted() {},
  methods: {
    loadSituation() {
      let _this = this;
      situation({ answer_order_id: _this.answer_order_id }).then((res) => {
        _this.subjectList = res.result.paper_subject;
        _this.subjectList.map(function (n) {
          var correct = [];
          if (n.subject_option) {
            n.subject_option.map(function (s) {
              if (s.correct_flage == 1) {
                correct.push(s.option_key);
              }
            });
          }
          if (n.answer_situation) {
            var myOption = [];
            n.answer_situation.choice_array.map(function (z) {
              n.subject_option.map(function (t) {
                if (z == t.subject_option_id) {
                  myOption.push(t.option_key);
                }
              });
            });
            if (myOption.length > 0) {
              n.myOption = myOption.toString();
            } else {
              n.myOption = "";
            }
          }
          if (correct.length > 0) {
            n.correctOption = correct.toString();
          } else {
            n.correctOption = "";
          }
        });
        console.log(_this.subjectList, "答案");
        _this.tiDetail = _this.subjectList[_this.tiIndex];
        console.log(_this.tiDetail, "单项题目");
        _this.title = res.result.relation.name;
      });
    },
    loadCollDetail() {
      let _this = this;
      collDetail({ answer_order_id: _this.answer_order_id }).then((res) => {
        console.log(res, "收藏详情");
        _this.subjectList = res.result.wrong_set;

        _this.subjectList.map(function (n) {
          var correct = [];
          if (n.subject_option) {
            n.subject_option.map(function (s) {
              if (s.correct_flage == 1) {
                correct.push(s.option_key);
              }
            });
          }
          if (n.answer_situation) {
            var myOption = [];
            n.answer_situation.choice_array.map(function (z) {
              n.subject_option.map(function (t) {
                if (z == t.subject_option_id) {
                  myOption.push(t.option_key);
                }
              });
            });
            if (myOption.length > 0) {
              n.myOption = myOption.toString();
            } else {
              n.myOption = "";
            }
          }
          if (correct.length > 0) {
            n.correctOption = correct.toString();
          } else {
            n.correctOption = "";
          }
        });
        console.log(_this.subjectList, "答案");
        _this.tiDetail = _this.subjectList[_this.tiIndex];
        console.log(_this.tiDetail, "单项题目");
        _this.title = res.result.relation.name;
      });
    },
    loadWrongSetInfo() {
      let _this = this;
      wrongSetInfo({ answer_order_id: _this.answer_order_id }).then((res) => {
        console.log(res, "错题集详情");
        _this.subjectList = res.result.wrong_set;

        _this.subjectList.map(function (n) {
          var correct = [];
          if (n.subject_option) {
            n.subject_option.map(function (s) {
              if (s.correct_flage == 1) {
                correct.push(s.option_key);
              }
            });
          }
          if (n.answer_situation) {
            var myOption = [];
            n.answer_situation.choice_array.map(function (z) {
              n.subject_option.map(function (t) {
                if (z == t.subject_option_id) {
                  myOption.push(t.option_key);
                }
              });
            });
            if (myOption.length > 0) {
              n.myOption = myOption.toString();
            } else {
              n.myOption = "";
            }
          }
          if (correct.length > 0) {
            n.correctOption = correct.toString();
          } else {
            n.correctOption = "";
          }
        });
        console.log(_this.subjectList, "答案");
        _this.tiDetail = _this.subjectList[_this.tiIndex];
        console.log(_this.tiDetail, "单项题目");
        _this.title = res.result.relation.name;
      });
    },
    last() {
      if (this.tiIndex == 0) {
      } else {
        this.tiIndex = this.tiIndex - 1;
        this.tiDetail = this.subjectList[this.tiIndex];
      }
    },
    next() {
      if (this.tiIndex + 1 >= this.subjectList.length) {
      } else {
        this.tiIndex = this.tiIndex + 1;
        this.tiDetail = this.subjectList[this.tiIndex];
        console.log(this.tiDetail, "tiDetail");
      }
    },
    openTi(index) {
      this.tiIndex = index;
      this.tiDetail = this.subjectList[this.tiIndex];
      console.log(this.tiDetail);
    },
    exit() {
      history.go(-1);
    },
    removeWrong() {
      let _this = this;
      this.$srmsg
        .confirm({
          title: "提醒",
          content: "是否将该题移除错题集",
        })
        .then(() => {
          removeWrongSet({
            answer_order_id: _this.answer_order_id,
            subject_problem_id: _this.tiDetail.subject_problem_id,
          }).then((res) => {
            if (res.status == 200) {
              _this.$message.success("操作成功！");
              if (_this.$route.query.type == "wrong") {
                // 判断是否为错题集进入
                _this.loadWrongSetInfo();
              } else if (_this.$route.query.type == "coll") {
                _this.loadCollDetail();
              } else {
                _this.loadSituation();
              }
            } else {
              _this.$message.warning(res.msg);
            }
          });
        });
    },
    openNote() {
      this.motals = true;
    },
    closeNote() {
      this.motals = false;
    },
    chooseImage2() {
      this.$refs.file.click();
    },
    fileChange2() {
      var file = this.$refs.file.files[0];
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage2(file);
          return true;
        }
        this.$message.warning("所选文件格式不支持");
      }
    },
    addImage2(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("image", file);
      uploadImage(formData).then((res) => {
        console.log(res, "上传图片");
        if (res.status === 200) {
          _self.img_ids.push(res.result.image_id);
          _self.img_url.push(res.result.url);
        } else {
          _self.$message.warning(res.msg);
        }
      });
    },
    delImg(i, index) {
      this.img_url.splice(index, 1);
      this.img_ids.splice(index, 1);
    },
    setNote() {
      let _this = this;
      var img_ids = null;
      if (this.img_ids.length > 0) {
        img_ids = this.img_ids.toString();
      } else {
        img_ids = "";
      }
      console.log(img_ids);
      this.nickName_loading = true;
      addNote({
        content: _this.content,
        subject_id: _this.tiDetail.subject_id,
        paper_id: _this.tiDetail.examination_paper_id,
        img_ids: img_ids,
        subject_problem_id: _this.tiDetail.subject_problem_id,
      }).then((res) => {
        if (res.status === 200) {
          _this.$message.success("操作成功！");
          _this.motals = false;
          _this.nickName_loading = false;
          _this.img_url = [];
          _this.img_ids = [];
          if (_this.$route.query.type == "wrong") {
            // 判断是否为错题集进入
            _this.loadWrongSetInfo();
          } else if (_this.$route.query.type == "coll") {
            _this.loadCollDetail();
          } else {
            _this.loadSituation();
          }
        } else {
          _this.$message.warning(res.msg);
          _this.nickName_loading = false;
        }
      });
    },
    getSun() {
      if (this.isSun) {
        this.isSun = false;
        document.getElementsByTagName("body")[0].className = "bac";
        return;
      } else {
        this.isSun = true;
        document.body.removeAttribute("class");
      }
    },
    setColloge() {
      let _this = this;
      operate({
        subject_id: _this.tiDetail.subject_id,
        paper_id: _this.tiDetail.examination_paper_id,
        answer_order_id: _this.answer_order_id,
        subject_problem_id: _this.tiDetail.subject_problem_id,
      }).then((res) => {
        console.log(res, "收藏");
        if (res.status == 200) {
          _this.$message.success(res.msg);
          if (_this.$route.query.type == "wrong") {
            // 判断是否为错题集进入
            _this.loadWrongSetInfo();
          } else if (_this.$route.query.type == "coll") {
            _this.loadCollDetail();
          } else {
            _this.loadSituation();
          }
        } else if (res.status == 201) {
          _this.$message.success(res.msg);
          if (_this.$route.query.type == "wrong") {
            // 判断是否为错题集进入
            _this.loadWrongSetInfo();
          } else if (_this.$route.query.type == "coll") {
            _this.loadCollDetail();
          } else {
            _this.loadSituation();
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
  },
  created() {
    this.answer_order_id = this.$route.query.answer_order_id;
    if (this.$route.query.tiIndex) {
      this.tiIndex = parseInt(this.$route.query.tiIndex);
    }
    if (this.$route.query.type == "wrong") {
      // 判断是否为错题集进入
      this.loadWrongSetInfo();
    } else if (this.$route.query.type == "coll") {
      this.loadCollDetail();
    }
    {
      this.loadSituation();
    }
  },
};
</script>
<style scoped>
.in3_jx_nr_tit {
  overflow: hidden;
  margin: 20px 0;
}
.in3_jx_nr_tit span {
  font-size: 22px;
  color: #333;
  float: left;
}
.in3_jx_nr_tit a {
  font-size: 14px;
  color: #3561c7;
  background: #d6dff4;
  float: right;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  border-radius: 35px;
}
.in3_jx_duicuo {
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
}
.in3_jx_true {
  width: 100%;
  overflow: hidden;
  margin-bottom: 18px;
}
.in3_jx_true img {
  float: left;
}
.in3_jx_true p {
  float: left;
  font-size: 16px;
  color: #666;
  margin-left: 15px;
}
.in3_jx_true p em {
  color: #17a20d;
}
.in3_jx_true p em {
  color: #17a20d;
}
.in3_jx_wrong {
  width: 100%;
  overflow: hidden;
}
.in3_jx_wrong img {
  float: left;
}
.in3_jx_wrong p {
  float: left;
  font-size: 16px;
  color: #666;
  margin-left: 20px;
}
.in3_jx_wrong p em {
  color: #17a20d;
}
.in3_jx_wrong p span {
  color: #ff0000;
}
.in3_jx_wrong p span {
  color: #ff0000;
}
.in3_jx_nr_tu1 {
  position: relative;
  margin: 20px 0;
}
.in3_jx_nr_zi {
  position: absolute;
  top: 32%;
  left: 17%;
}
.in3_jx_nr_zi a {
  font-size: 16px;
  color: #fff;
  padding: 0 15px;
}
.in3_jx_nr_zi .act {
  background: #ffbb00;
  height: 36px;
  line-height: 36px;
  text-align: center;
  padding: 0 15px;
  border-radius: 35px;
  margin-top: 12px;
}
.index3_laiyuan_list h6 {
  font-size: 16px;
  color: #333;
  font-weight: 400;
  margin-bottom: 8px;
}
.index3_laiyuan_list p {
  font-size: 16px;
  color: #666;
}
.index3_laiyuan_list {
  margin-bottom: 12px;
}
.index3_laiyuan_list1 .index3_laiyuan_list1_tit {
  overflow: hidden;
  width: 100%;
  margin-bottom: 8px;
}
.index3_laiyuan_list1 .index3_laiyuan_list1_tit span {
  font-size: 16px;
  color: #333;
  float: left;
}
.index3_laiyuan_list1 .index3_laiyuan_list1_tit a {
  font-size: 14px;
  color: #3561c7;
  float: left;
  margin: 2px 0 0 10px;
}
.datika {
  overflow: hidden;
  height: 385px;
  overflow-y: scroll;
}
.datika ul li span i {
  width: 8px;
  height: 8px;
  background: #eb98c2;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -3px;
  right: -2px;
}
.datika ul li {
  width: 20%;
  float: left;
  margin-top: 20px;
}
.datika ul li span {
  font-size: 16px;
  color: #333;
  background: #fff;
  border: #cdcdcd 1px solid;
  border-radius: 8px;
  width: 40px;
  height: 38px;
  display: block;
  line-height: 40px;
  text-align: center;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
}
.in3_zq {
  width: 12px;
  height: 12px;
  border: 2px #17a20d solid;
  display: block;
  float: left;
  margin: 5px 8px 0 0;
}
.in3_cw {
  width: 12px;
  height: 12px;
  border: 2px #ff0000 solid;
  display: block;
  float: left;
  margin: 5px 8px 0 0;
}
.dui {
  border: 2px #17a20d solid !important;
}
.cuo {
  border: 2px #ff0000 solid !important;
}
.in3_wz {
  border: #dedede 2px solid;
}
.index3_dx_five a {
  width: auto;
}
.index3_dx_five {
  float: right;
  width: 43%;
  margin-top: 20px;
}
.index3_dx_five a {
  float: right;
  margin-left: 3%;
  margin-right: 0;
}
.index3_dx_four1 ul li span.active_true {
  background: #17a20d;
  color: #fff;
  border: #cbeac8 2px solid;
  display: block;
  box-sizing: initial;
}
.xly-modalBg {
  display: block;
}
.in3_tjbj_a a {
  font-size: 18px;
  color: #fff;
  background: #3561c7;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-radius: 35px;
  text-align: center;
  margin-top: 30px;
}
>>> .el-upload-dragger {
  width: 188px !important;
  height: 140px !important;
}
.bac .datika ul li span {
  background: #1a1b1e;
}
.right_nav {
  position: fixed;
  right: 0;
  top: 30%;
  background: #fff;
  width: 40px;
  text-align: center;
  color: #636e92;
  border-radius: 4px;
}
.right_nav ul li > img {
  margin: 0 auto;
}
.right_nav ul li {
  padding: 5px 0;
  font-size: 12px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.right_nav ul {
  margin-bottom: 0;
}
.right_nav ul li {
  position: relative;
}
.right_nav ul li > div {
  position: absolute;
  left: -66px;
  background: #fff;
  top: 0;
  height: 100%;
  display: none;
}
.right_nav ul li:hover > div {
  display: block;
}
.right_nav ul li > div span {
  margin: 0 4px;
  line-height: 50px;
  font-size: 14px;
}
.right_nav ul li > div span:hover {
  color: #3561c7;
}
.right_nav ul li:last-child {
  border-bottom: none;
}
.bac .right_nav {
  background: #1a1b1e;
  border-bottom: 1px solid #2e2e2e;
}
</style>