import { render, staticRenderFns } from "./RegisterBox.vue?vue&type=template&id=69b634a5&scoped=true&"
import script from "./RegisterBox.vue?vue&type=script&lang=js&"
export * from "./RegisterBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69b634a5",
  null
  
)

export default component.exports