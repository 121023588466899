<template>
    <a-modal :visible="visible"
             :footer="false"
             :closable="false"
             :bodyStyle="{padding:0}"
             width="800px"
             wrapClassName="sr-dialog">
        <div style="padding: 30px 50px;box-sizing: border-box">
            <h3 class="modal-boxT te_c">可参与的拼单</h3>
            <div class="pintuan-m mt10">
                <ul>
                    <li v-for="item in list"
                        :key="item.pintuan_group_id">
                        <p><img class="pintuan-mImg"
                                :src="item.chief.head_portrait.url"></p>
                        <p style="color: #000;font-size: 16px">{{item.chief.nickname}}</p>
                        <p class="te_r">还差{{item.pintuan.number-item.pintuan_user_count}}人拼成<br/>剩余
                            <span>
                                    <a-statistic-countdown style="color:red"
                                                           :value="item.expire_at|toTime"
                                                           format="D 天 H 时 m 分 s 秒"/>
                                </span>
                        </p>
                        <p class="te_r">
                            <a class="modal-pd"
                               @click="$refs.JoinPintuan.show(item,pintuan)">去拼单</a>
                        </p>
                    </li>
                </ul>
            </div>
            <a class="modal-close"><img @click="visible=false"
                                        src="@/assets/img/modal-close.png"></a>
        </div>
        <JoinPintuan ref="JoinPintuan">
            <slot></slot>
        </JoinPintuan>
    </a-modal>
</template>

<script>
    import JoinPintuan from './JoinPintuan'

    export default {
        name: "PintuanUserList",
        components: {
            JoinPintuan
        },
        props: {
            pintuan: {}
        },
        data() {
            return {
                visible: false,
                list: []
            }
        },
        filters: {
            toTime(value) {
                return new Date(value).getTime()
            },
        },
        methods: {
            show(list) {
                this.visible = true
                this.list = list
            }
        }
    }
</script>

<style scoped>

    #box {
        padding: 30px 50px;
    }

    .te_r {
        color: #000;
        font-size: 16px;
    }

    .te_r >>> .ant-statistic {
        display: inline-block;
    }

    .te_r >>> .ant-statistic-content {
        display: inline-block;
    }

    .te_r >>> .ant-statistic-content-value {
        font-size: 16px;
    }

    .modal-pd {
        border-radius: 20px 20px;
        line-height: 40px;
    }
</style>
