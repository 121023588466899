<template>
    <div id="box">
        <div v-html="imgWH(course_package.introduce)">

        </div>
    </div>
</template>

<script>

    export default {
        name: "CourseIntroduce",
        props: {
            loading: {},
            course_package: {}
        },
        data() {
            return {
            }
        },
        created() {

        },
        methods: {
            imgWH(string){ //正则替换图片宽高
                if (string){
                    string = string.replace(/ height="\d+"/g, " height=\"auto\"");
                    string = string.replace(/ width="\d+"/g, " width=\"100%\"");
                    return string
                } else{
                    return ''
                }
            }
        }
    }
</script>

<style scoped>
    /*#box >>> img{*/
        /*min-width: 100%;*/
        /*max-width: 100%;*/
    /*}*/
</style>
