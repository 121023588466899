<template>
  <div>
    <div class="login-bg"></div>
    <div class="login-box">
      <div class="clearfix">
        <p class="fn_l"><img src="@/assets/img/logo.jpg" /></p>
        <router-link to="/" class="fn_r color-main mt20"
          >返回首页 >
        </router-link>
      </div>
      <div class="login mt30">
        <div class="tab-wrapper">
          <ul class="tab-menu">
            <li
              :class="[action === 'login' ? 'active' : '']"
              @click="action = 'login'"
            >
              {{ login_title }}
            </li>
            <li
              :class="[action === 'register' ? 'active' : '']"
              @click="action = 'register'"
            >
              立即注册
            </li>
          </ul>
          <div class="tab-content">
            <div v-show="action === 'login'">
              <LoginBox
                @change="
                  (e) => {
                    login_title = e;
                  }
                "
              ></LoginBox>
            </div>

            <div v-show="action === 'register'">
              <RegisterBox></RegisterBox>
            </div>
          </div>
        </div>
      </div>
     
    </div>
    <div class="beian">
      <p>Copyright © 2013-2021 <a href="https://beian.miit.gov.cn/" target="_blank">{{$store.state.common.config[22].text}}</a> 圣儒教育版权所有 技术支持：开创云 </p>
    </div>
  </div>
</template>

<script>
import LoginBox from "./modules/LoginBox";
import RegisterBox from "./modules/RegisterBox";

export default {
  name: "Login",
  components: {
    LoginBox,
    RegisterBox,
  },
  data() {
    return {
      login_title: "密码登录",
      action: "login",
    };
  },
  created() {
    // 判断入口
    this.action = this.$route.query.action;
    console.log(this.action);
  },
  methods: {
 
  },
};
</script>

<style scoped>
  .beian{
    position: absolute;
    width:100%;
    bottom: 30px;
    left: 0;
    text-align: center;
    color: #666;
  }
  .beian a{
    display: inline;
    color: #666;
  }
  .beian a:hover{
    color: #386be2;
  }
</style>
