import { render, staticRenderFns } from "./myBook.vue?vue&type=template&id=19b22e21&scoped=true&"
import script from "./myBook.vue?vue&type=script&lang=js&"
export * from "./myBook.vue?vue&type=script&lang=js&"
import style0 from "./myBook.vue?vue&type=style&index=0&id=19b22e21&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19b22e21",
  null
  
)

export default component.exports