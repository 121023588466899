import Vue from 'vue';
import confirm from './SrMessage';

let confirmConstructor = Vue.extend(confirm);

function msg(type, text) {
    return new Promise((res, rej) => {
        let confirmDom = new confirmConstructor({
            el: document.createElement('div')
        })
        document.body.appendChild(confirmDom.$el);

        confirmDom.type = type;
        confirmDom.text = text;
        confirmDom.okHandle = function () {
            res()
            confirmDom.isShow = false
        }
        confirmDom.cancelHandle = function () {
            rej()
            confirmDom.isShow = false
        }

    })
}


const theConfirm = {
    info: (text) => {
        return msg('info', text)
    },
    confirm: (text) => {
        return msg('confirm', text)
    }
}

export default theConfirm;
