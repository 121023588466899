<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的钱包-账单</h6>
      </div>

      <div class="per7_integral">
        <!-- tab -->
        <div class="per7_in2">
          <div class="tab-wrapper">
            <ul class="tab-menu tab-menu-form tab_cxfs">
              <li @click="getTab(0)" :class="tabIndex == 0 ? 'active' : ''">
                全部
              </li>
              <li @click="getTab(1)" :class="tabIndex == 1 ? 'active' : ''">
                分销赚钱
              </li>
              <li @click="getTab(2)" :class="tabIndex == 2 ? 'active' : ''">
                打卡退费
              </li>
              <li @click="getTab(3)" :class="tabIndex == 3 ? 'active' : ''">
                购买
              </li>
              <li @click="getTab(4)" :class="tabIndex == 4 ? 'active' : ''">
                提现
              </li>
            </ul>
            <div class="tab-content">
              <div>
                <table border="0" cellspacing="0" cellpadding="0" class="form">
                  <tr>
                    <th>账单名称</th>
                    <th>交易金额</th>
                    <th>余</th>
                    <th>获取时间</th>
                  </tr>
				    <tr v-if="list.length > 0" v-for="i in list">
                    <td>{{i.account}}</td>
                    <td :class="i.status== 0?'red':'green'">{{i.status == 0?'+' + i.money : '-' + i.money}}</td>
                    <td>{{i.user_commission}}</td>
                    <td>{{i.created_at}}</td>
                  </tr>
				  <tr v-if="list.length == 0">
					  <td colspan="4" style="height:100px">暂无数据</td>
				  </tr>
   
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 页码 -->
      <SrPaginate v-if="list.length > 0"
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
      ></SrPaginate>
    </div>
  </div>
</template>
<script>
import { bill } from "@/api/user";
import SrPaginate from "@/components/SrPaginate";
export default {
  name: "MyWalletDetail",
   components: {
    SrPaginate
  },
  data() {
    return {
      tabIndex: 0,
	  type: "",
	   pagination: {
        page: 1,
        page_size: 10,
	  },
	  list:[]
    };
  },
  methods: {
    getTab(index) {
      this.tabIndex = index;
      if (index == 0) {
        this.type = "";
      } else {
        this.type = index;
	  }
	  this.loadList()
    },
    loadList() {
      let _this = this;
      bill({type:_this.type,..._this.pagination}).then((res) => {
		  console.log(res,'明细')
		  _this.list = res.result.items
		  _this.pagination = res.result.pagination;
	  });
	},
	changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
  },
  created() {
	  this.loadList()
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
</style>