<template>
  <div>
    <SrNav></SrNav>
    <div class="warp">
      <!-- 面包屑 -->
      <div class="xun1_mbx">
        <div class="xun1_mbx_nr">
          <i></i>
          <div class="xun1_mbx_a">
            <a @click="goIndex">首页 -</a>
            <a @click="goCommunity">社区 -</a>
            <a class="xun1_mbx_on">{{ title }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="warp clearfix mt30">
      <div class="xun_plan">
        <div class="xun_plan_l">
          <div class="collection-eq">
            <ul>
              <li v-for="i in list">
                <router-link
                  :to="{
                    path: '/community/detail',
                    query: { id: i.community_id, community_category_id: id },
                  }"
                >
                  <a>
                    <div class="coll_l fn_l coll_l1">
                      <img :src="i.image.url" />
                      <span class="collection-hui" v-if="i.type == 1"
                        ><img src="@/assets/img/huiyuanzhuanxiang.png"
                      /></span>
                    </div>
                    <div class="coll_r fn_r coll_r1">
                      <h6>
                        {{ i.name }}
                        <em class="zhiding" v-if="i.place_top == 1">置顶</em>
                        <em class="jiajing" v-if="i.is_digest == 1">加精</em>
                        <span>{{ i.created_at }}</span>
                      </h6>
                      <div class="contens" v-html="i.content"></div>
                      <div class="coll_r_nei">
                        <!-- <div class="coll_n_one">
                        <img src="@/assets/img/coll_tea.jpg" />
                        <span>江洋</span>
                      </div> -->
                        <div class="coll_n_one coll_n_one1">
                          <img src="@/assets/img/coll_liu.png" />
                          <span
                            >浏览量：<i>{{ i.browse }}</i></span
                          >
                        </div>
                        <div class="coll_n_one coll_n_one1">
                          <img src="@/assets/img/coll_ping.png" />
                          <span
                            >评论量：<i>{{ i.comment_count }}</i></span
                          >
                        </div>
                      </div>
                    </div>
                  </a>
                </router-link>
              </li>
            </ul>
          </div>
          <!-- 页码 -->
          <SrPaginate
            style="margin: 0 auto; text-align: center; margin-top: 50px"
            :current="pagination.current_page"
            :total="pagination.total"
            :pageSize="pagination.page_size"
            @change="changePage"
          ></SrPaginate>
        </div>
        <div class="xun_plan_r community2">
          <a v-for="i in relation_goods" @click="jump(i.goods)">
            <img :src="i.goods.image.url" />
            <p>{{ i.goods.name }}</p>
          </a>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import { category, list, specifiedCategory } from "@/api/community";
import SrPaginate from "@/components/SrPaginate";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
    SrPaginate,
  },
  data() {
    return {
      title: "",
      list: [],
      pagination: {
        page: 1,
        page_size: 10,
      },
      relation_goods: [],
    };
  },
  mounted() {},

  methods: {
    loadList() {
      let _this = this;
      list({
        community_category_id: _this.$route.query.id,
        ..._this.pagination,
      }).then((res) => {
        console.log(res.result.items, "list");
        _this.list = res.result.items;
        _this.pagination = res.result.pagination;
      });
    },
    // 获取指定分类下的推荐课程和相关课程
    loadSpecifiedCategory() {
      let _this = this;
      specifiedCategory({
        community_category_id: _this.$route.query.id,
      }).then((res) => {
        console.log(res, "相关");
        _this.relation_goods = res.result.relation_goods;
        _this.relation_about_goods = res.result.relation_about_goods;
      });
    },
    loadCategory() {
      let _this = this;
      category({ community_category_id: _this.$route.query.id }).then((res) => {
        console.log(res, "ddddd");
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
    goCommunity() {
      this.$router.push({ path: "/community" });
    },
    goIndex() {
      this.$router.push({ path: "/" });
    },
    jump(item) {
      if (item.type === 1) {
        this.$router.push({ path: "/course/info/" + item.relation_id });
      } else if (item.type === 2) {
        this.$router.push({ path: "/course-package/info/" + item.relation_id });
      } else if (item.type === 3) {
        this.$router.push({ path: "/radio/info/" + item.relation_id });
      } else if (item.type === 4) {
        this.$router.push({ path: "/camp/info/" + item.relation_id });
      } else {
        this.$router.push({
          path: "/store/detail?wares_id=" + item.relation_id,
        });
      }
    },
  },
  created() {
    this.title = this.$route.query.title;
    this.id = this.$route.query.id;
    this.loadList();
    this.loadCategory();
    this.loadSpecifiedCategory();
  },
};
</script>
<style>
.contens {
  font-size: 14px;
  color: #999;
  line-height: 22px;
  margin-top: 5px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
</style>
