<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的课程</h6>
      </div>
      <div class="per4_course">
        <ul v-if="list.length > 0">
          <li v-for="i in list" @click="goDetail(i.relation_id)" style="height:300px">
            <a>
              <div class="course_t">
                <h6>{{ i.relation.name}}</h6>
                <p v-if="i.relation.start_at != undefined">{{ i.relation.start_at }}--{{ i.relation.end_at }}</p>
              </div>
              <div class="course_list" v-if="i.relation.teacher.length > 0 && i.relation.teacher != undefined">
                <ul>
                  <li v-for="item in i.relation.teacher">
                    <img :src="item.avatar.url" />
                    <span>{{ item.name }}</span>
                  </li>
                </ul>
              </div>
              <div class="course_progress">
                <p>
                  授课进度<span>{{ i.jindu }}%</span>
                </p>
                <p class="pro_on">
                  <span
                    class="pro_on1"
                    :style="'width:' + i.jindu + '%'"
                  ></span>
                </p>
              </div>
            </a>
          </li>
        </ul>
        <div class="zanwu" v-if="list.length == 0">
          <img src="@/assets/img/zanwu.png" alt="" />
          暂无数据
        </div>
      </div>
      <!-- 页码 -->
      <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
      ></SrPaginate>
    </div>
  </div>
</template>
<script>
import SrPaginate from "@/components/SrPaginate";
import { myCourse } from "@/api/user";
export default {
  name: "MyCourse",
  components: {
    SrPaginate,
  },
  data() {
    return {
      list: [],
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
  },
  methods: {
    loadList() {
      let _this = this;
      myCourse({..._this.pagination}).then((res) => {
        console.log(res, "--- 课程");
        var data = res.result.items;
        _this.list= [];
        data.map(function(s){
          if(s.relation.name != '无'){
            _this.list.push(s)
          }
        })
        _this.pagination = res.result.pagination;
        console.log(_this.list,'list')
        _this.list.map(function (n) {
          if(n.relation.teacher == undefined){
            n.relation.teacher = [];
          }
          // 计算授课进度 end + begin / all
          if (n.relation.all == 0) {
            n.jindu = 0;
          } else {
            n.jindu =
              ((parseFloat(n.relation.end) + parseFloat(n.relation.begin)) /
                parseFloat(n.relation.all)) *
              100;
          }
        });
        //  _this.pagination = res.result.pagination;
      });
    },
    goDetail(id) {
      this.$router.push({ path: "/course/info/"+ id });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style scoped>
.zanwu {
  margin: 0 auto;
  text-align: center;
  margin-top: 130px;
}
.zanwu img {
  width: 200px;
  margin: 0 auto;
}
.per4_course ul li .course_list ul li img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
