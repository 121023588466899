<template>
    <div>
        <div class="clearfix mt20">
            <p class="fn_l">已有<span class="color-pink">{{pagination.total}}</span> 次留言</p>
            <!--<p class="fn_r xly-px">-->
            <!--<span>推荐</span>-->
            <!--<span class="active">时间</span>-->
            <!--</p>-->
            <SrSwitch @change="orderChange"
                      style="float: right"
                      :list="['推荐','时间']"></SrSwitch>
        </div>
        <a-spin :spinning="loading">
            <a-icon slot="indicator"
                    type="loading"
                    style="font-size: 24px"
                    spin/>
            <div v-if="loading&&list.length===0"
                 style="width: 100%;height: 200px">

            </div>
            <dl v-else
                v-for="item in list"
                :key="item.camp_diary_id"
                class="kc-evaluate-list clearfix">
                <dt class="fn_l"><img :src="item.user.head_portrait.url"></dt>
                <dd class="fn_r">
                    <div class="">
                        <h3>{{item.user.nickname}} <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 1" src="/img/hyjb.png">
                        <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 2" src="/img/hyjb2.png">
                        <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 3" src="/img/hyjb3.png">
                        <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 4" src="/img/hyjb4.png">
                        <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 5" src="/img/hyjb5.png">
                        <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 6" src="/img/hyjb6.png">
                            <span v-if="item.is_digest == 1"
                                  class="xly-jy">加精</span>
                            <!-- <span class="xly-hfBut fn_r"><img src="@/assets/img/xly-pl.png"></span> -->
                            <span class="kc-like"
                                  @click="like(item)"
                                  :class="[item.is_like?'kc-like-hover':'','kc-like']">{{item.likes}}</span></h3>
                        <div style="font-size:13px; color:#999999">已打卡{{item.user.clock_theme_count}}天</div>
                        <p class="color-666">
                            {{item.content}}
                        </p>
                        <div class="square">

                            <div class="square-inner">
                                <div v-if="item.video"
                                     style="position: relative"
                                     class="square-inner-item">
                                    <div class="play-btn"
                                         @click="showVideo(item.video)">
                                        <img src="@/assets/img/video-play.png">
                                    </div>
                                    <img height="100%" width="100%"  :src="item.video.cover">
                                </div>
                                <div v-for="image_item in item.image"
                                     :key="image_item.image_id"
                                     class="square-inner-item">
                                    <el-image
                                            :preview-src-list="imageFilter(item.image)"
                                            :src="image_item.url"
                                            fit="contain"></el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="xly-hfBox mt10"
                         v-if="item.reply">
                        <div v-for="reply_item in item.reply"
                             :key="reply_item.reply_id"
                             class="">
                            <p class="f14 color-666"><span>{{reply_item.user.teacher.name}}回复：</span>
                                {{reply_item.content}}
                            </p>
                            <div class="clearfix">
                                <div v-for="reply_image_item in reply_item.image"
                                     :key="reply_image_item.image_id"
                                     class="fn_l kc-evaluate-listImg">
                                    <img :src="reply_image_item.url">
                                </div>
                            </div>
                        </div>
                    </div>
                </dd>
            </dl>
        </a-spin>
        <!-- 页码 -->
        <SrPaginate style="margin: 0 auto;text-align: center;margin-top: 50px"
                    :current="pagination.current_page"
                    :total="pagination.total"
                    :pageSize="pagination.page_size"
                    @change="changePage"></SrPaginate>
        <VideoModal ref="VideoModal"></VideoModal>
    </div>
</template>

<script>
    import {commentList, like} from "@/api/camp"
    import SrPaginate from '@/components/SrPaginate'
    import SrSwitch from '@/components/SrSwitch'
    import VideoModal from '@/components/VideoModal'

    export default {
        name: "CommentList",
        components: {
            SrPaginate,
            SrSwitch,
            VideoModal
        },
        props: {
            camp_id: {}
        },
        data() {
            return {
                loading: false,
                list: [],
                query: {},
                pagination: {
                    page: 1,
                    page_size: 6
                },
                comment_loading: false,
                upload_loading: false,
                form: {
                    video: {},
                    comment_enclosure_id: [],
                    image: []
                }
            }
        },
        created() {
            this.getData()
        },
        watch: {
            camp_id(value) {
                if (value) {
                    this.getData()
                }
            }
        },
        methods: {
            getData() {
                var _self = this
                _self.loading = true
                commentList({camp_id: this.camp_id, ...this.pagination, ...this.query}).then((res) => {
                    _self.list = res.result.items
                    console.log(_self.list)
                    _self.loading = false
                    _self.pagination = res.result.pagination
                })
            },
            changePage(page, page_size) {
                this.pagination.page = page
                this.getData()
            },
            orderChange(index) {
                if (index === 0) {
                    this.query.order_by = 'default'
                } else {
                    this.query.order_by = 'created_at'
                }
                this.getData()
            },
            showVideo(video) {
                this.$refs.VideoModal.show(video.url)
            },
            imageFilter(item) {
                var arr = item.map((item) => {
                    return item.url
                })
                return arr
            },
            like(item) {
                item.is_like = !item.is_like
                if (item.is_like) {
                    item.likes++
                } else {
                    item.likes--
                }
                like({camp_diary_id: item.camp_diary_id, status: item.is_like})
            }
        }
    }
</script>

<style scoped>

    .square-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .square-inner-item {
        width: 30%;
        height: 140px;
        overflow: hidden;
        margin: 1%;
        cursor: pointer;
        border-radius: 3px;
    }

    .play-btn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        background: rgba(0, 0, 0, .5);
        cursor: pointer;
    }

    .play-btn img {
        margin: 40px auto;
        width: 40px;
        height: 50px;
        transform: rotate(90deg);
    }
</style>
