<template>
    <div id="box">
        <div v-if="value===zhi" class="select" @click="cancel">
            <a-icon type="check" />
        </div>
        <div v-else class="no-select" @click="select">

        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            value: {},
            zhi: {}
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        methods: {
            select() {
                this.$emit('change', this.zhi)
            },
            cancel() {
                this.$emit('change', this.null)
            }
        }
    }
</script>

<style scoped>
    #box {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
    }

    .no-select {
        transition: all .1s;
        width: 100%;
        height: 100%;
        border: 1px solid #ddd;
        border-radius: 50% 50%;
        background: #fff;
    }

    .select {
        transition: all .1s;
        width: 100%;
        height: 100%;
        border: 1px solid #3561C7;
        border-radius: 50% 50%;
        background: #3561C7;
        color: #fff;
    }
</style>
