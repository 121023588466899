<template>
  <div>
    <!-- 答案解析 -->
    <div class="motal sanqi-fxJX" v-show="motalShow">
      <div class="as">
        <div class="txtx">
          <img src="@/assets/img/in3_guan.png" @click="close" alt="" />
          <div class="txtx_wen">
            <h3 class="te_c">提示</h3>
            <p>本次消耗次数：1次</p>
            <p v-if="type == 1">剩余点评次数：{{ detail.comment_number }}次</p>
            <p v-if="type == 2">剩余批改次数：{{ detail.correct_number }}次</p>
          </div>
          <div class="txtx_zhuang">
            <a href="" @click="payNum">购买次数</a>
            <a class="guanbi guanbi_a" @click="setNeedEvaluationService"
              >消耗次数</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { detail, needEvaluationService } from "@/api/corrects";
export default {
  name: "index",
  components: {},
  props: {
    type: Number,
    answer_order_id: String,
  },
  data() {
    return {
      motalShow: false,
      detail: {},
    };
  },
  mounted() {},
  methods: {
    show() {
      this.motalShow = true;
    },
    close() {
      this.motalShow = false;
    },
    loadData() {
      let _this = this;
      detail({}).then((res) => {
        console.log(res, "数据");
        _this.detail = res.result.user_surplus_number;
      });
    },
    payNum() {
      this.$router.push({
        path: "/user/center/myCorrects",
      });
    },
    setNeedEvaluationService() {
      let _this = this;
      needEvaluationService({
        answer_order_id: _this.answer_order_id,
        type: _this.type,
      }).then((res) => {
        console.log(res, "点评批改");
        if (res.status == 200) {
          _this.$message.success("操作成功！");
            _this.motalShow = false;
            _this.loadData();
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
  },
  created() {
    this.loadData();
  },
};
</script>
<style scoped>
.motal {
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.txtx > img {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.sanqi-fxJX .txtx p {
  font-size: 14px;
}
.txtx_wen {
  padding: 40px 60px;
}
</style>