<template>
  <div>
    <SrNav></SrNav>
    <div class="warp">
      <!-- 面包屑 -->
      <div class="xun1_mbx">
        <div class="xun1_mbx_nr">
          <i></i>
          <div class="xun1_mbx_a">
            <router-link to="/">
              <a href="">首页 -</a>
            </router-link>
            <a href="" class="xun1_mbx_on">关于我们</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 考试计划列表 -->
    <div class="warp clearfix mt30">
      <div class="xun_plan">
        <div class="xun_plan_l">
          <div class="xun_plan_list xun1_bei1">
            <h6>关于我们</h6>
          </div>
          <div class="xun1_bei1_nr">
            <p>
              圣儒教育依托大数据和人工智能打造个性化的学习工具包，既有效利用当前科技发展的红利，又让教育回归到“因材施教”、“因人而异”的本源。这就是“圣儒”二字的来源：慕圣人之道，有儒者之德。
            </p>
            <p>
              目前公司已经从公职类培训入手，将“互联网思维”与“私塾理念”有机结合，机构于2020年荣获“最具影响力的十大职业教育机构”。
            </p>
            <p>
              公司始终以行动践行“为员工幸福而奋斗，为学员上岸而努力”的企业文化，在未来，公司将继续深耕公职培训领域，不断完善升级课程价值和服务，打造学员上岸一站式服务平台！
            </p>
            <img src="@/assets/img/zizhi.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
export default {
  name: "detail",
  components: {
    SrNav,
    SrFooter,
  },
  data() {
    return {
      imgList: [],
      detail: {},
    };
  },
  watch: {
    
  },
  methods: {
      
  },
  created() {

  },

};
</script>
<style scoped>
.xun1_bei1_nr {
  margin-top: 10px;
}
.xun1_bei1_nr img {
  display: inline-block;
}
.xun_plan_l{
    width: 100%;
}
.xun1_bei1_nr p{
    line-height: 28px;
    margin-bottom: 10px;
}
</style>
