<template>
    <div>
        <!--<div class="">-->
        <!--<dl class="kc-evaluate-list clearfix">-->
        <!--<dt class="fn_l"><img src="@/assets/img/KC-avatar.jpg"></dt>-->
        <!--<dd class="fn_r">-->
        <!--<div class="">-->
        <!--<h3>圣儒用户 <img src="@/assets/img/hyjb.png"> <span class="kc-like">988</span>-->
        <!--</h3>-->
        <!--<p class="color-666">刚刚收到资料啦，内容很全面，旁听了很多老师的课就决定购买了讲的真的很好，支持笔芯老师！支持笔芯老师!</p>-->
        <!--</div>-->
        <!--</dd>-->
        <!--</dl>-->
        <!--<dl class="kc-evaluate-list clearfix">-->
        <!--<dt class="fn_l"><img src="img/KC-avatar.jpg"></dt>-->
        <!--<dd class="fn_r">-->
        <!--<div class="">-->
        <!--<h3>圣儒用户 <img src="img/hyjb.png"> <span class="kc-like">988</span></h3>-->
        <!--<p class="color-666">刚刚收到资料啦，内容很全面，旁听了很多老师的课就决定购买了讲的真的很好，支持笔芯老师！支持笔芯老师!</p>-->
        <!--</div>-->
        <!--</dd>-->
        <!--</dl>-->
        <!--<dl class="kc-evaluate-list clearfix">-->
        <!--<dt class="fn_l"><img src="img/KC-avatar.jpg"></dt>-->
        <!--<dd class="fn_r">-->
        <!--<div class="">-->
        <!--<h3>圣儒用户 <img src="img/hyjb.png"> <span class="kc-like">988</span></h3>-->
        <!--<p class="color-666">刚刚收到资料啦，内容很全面，旁听了很多老师的课就决定购买了讲的真的很好，支持笔芯老师！支持笔芯老师!</p>-->
        <!--</div>-->
        <!--</dd>-->
        <!--</dl>-->
        <!--<div class="kc-videoList-pl">-->
        <!--<input type="text"-->
        <!--placeholder="请输入内容......"-->
        <!--id=""-->
        <!--value=""/>-->
        <!--<button type="button">发送</button>-->
        <!--</div>-->
        <!--</div>-->
    </div>
</template>

<script>
    export default {
        name: "ChatRoom",
        methods: {
            init() {
                let eventEmitter = BJY.eventEmitter
                // 收到新消息时tab栏对应tab给出提示
                eventEmitter.on(
                    eventEmitter.MESSAGE_RECEIVE,
                    function (e, date) {
                        console.log(e)
                        console.log(date)
                    }
                )
            }
        }
    }
</script>

<style scoped
       type="less">
    #message-panel {
        width: 100%;
        height: 100%;

        .notice {
            position: absolute;
            top: 0;
            z-index: 1;
            background: #fff;
            width: 100%;
            border-top: 1px solid #ddd;
        }

        .bjy-message-list {
            bottom: 110px;
            height: unset;
            top: 32px;
        }

        .bjy-message-sender {
            position: absolute;
            bottom: 10px;
            width: 100%;
        }
    }
</style>
