import request from '@/utils/request'

export function banner(parameter) {
    return request({
        url: '/api/index/banner',
        method: 'post',
        data: parameter
    })
}

// 文章列表
export function article(parameter) {
    return request({
        url: '/api/article',
        method: 'post',
        data: parameter
    })
}
export function article2(parameter) {
    return request({
        url: '/api/article',
        method: 'post',
        data: parameter
    })
}
// pc文章分类
export function newType(parameter) {
    return request({
        url: '/api/article/type',
        method: 'post',
        data: parameter
    })
}
// pc文章详情
export function detail(parameter) {
    return request({
        url: '/api/article/detail',
        method: 'post',
        data: parameter
    })
}
// Pc 获取广告位
export function advertising(parameter) {
    return request({
        url: '/api/article/advertising',
        method: 'post',
        data: parameter
    })
}
// Pc 老师列表
export function teacher(parameter) {
    return request({
        url: '/api/index/teacher',
        method: 'post',
        data: parameter
    })
}

//
export function link(parameter) {
    return request({
        url: '/api/index/link',
        method: 'post',
        data: parameter
    })
}


/**
 * pc首页视频
 * @param parameter
 */
export function pcVideo(parameter) {
    return request({
        url: '/api/index/pc_video',
        method: 'post',
        data: parameter
    })
}


// 热门课程

export function recommendModular(parameter) {
    return request({
        url: '/api/index/recommendModular',
        method: 'post',
        data: parameter
    })
}