<template>
  <div>
    <!-- top -->
    <div class="index3_dx_top">
      <div class="index3_dx_top_nr warp">
        <div class="index3_dx_top_tui">
          <a @click="exits">
            <img src="@/assets/img/in3_dx_tu.png" />
            <p>退出</p>
          </a>
        </div>
        <div class="in3_dx_top_wen">
          <p>{{ paperDetail.examination_paper.title }}</p>
        </div>
      </div>
    </div>
    <!-- 3题目-单选题 -->
    <div class="index3_dx_nr mt50 warp">
      <div class="index3_dx_nr_l index3_mktk_con">
        <div class="index3_dx_nr_l_tit">
          <h6>
            <!-- 1单选2多选3问答 -->
            {{ tiType == 1 ? "单选题" : tiType == 2 ? "多选题" : "问答题" }}
            <span>{{ tiIndex + 1 }}/{{ subjectList.length }}</span>
            <p
              class="timers"
              v-if="paperDetail.examination_paper.length_of_examination"
            >
              <img src="@/assets/img/exam_time.png" alt="" />
              <a-statistic-countdown :value="deadline" />
            </p>
          </h6>
        </div>
        <Datas
          :dataList="detail.subject_datas"
          v-if="detail.subject_datas.length > 0"
          ref="datat"
        ></Datas>

        <div class="index3_clt_one">
          <div class="tab-wrapper">
            <!-- <ul
              class="tab-menu tab-menu-form"
              :style="
                tiDetail.subject.subject_problem.length > 1
                  ? 'display:block'
                  : 'display:none'
              "
            >
              <li
                :class="tabIndex == index ? 'active' : ''"
                v-for="(i, index) in tiDetail.subject.subject_problem"
                @click="getTab(i, index)"
              >
                {{ "问题" + (index + 1) }}
              </li>
            </ul> -->
            <div class="tab-content">
              <!-- 1单选2多选3问答 -->
              <Radio
                v-if="detail.type == 1"
                :detail="detail"
                @getRadio="getRadios"
                ref="radios"
              ></Radio>
              <Check
                v-if="detail.type == 2"
                @getCheck="getChecks"
                :detail="detail"
                ref="checks"
              ></Check>
              <Answers
                v-if="detail.type == 3"
                @getAnswer="getAnswers"
                @getAnswerImg="getAnswerImg"
                :detail="detail"
                ref="answers"
              ></Answers>
            </div>
            <div class="index3_dx_five">
              <a
                class="index3_dx_five_f2"
                v-if="tiIndex + 1 < subjectList.length"
                @click="next"
                >下一题</a
              >
              <a class="index3_dx_five_f1" v-if="tiIndex > 0" @click="last"
                >上一题</a
              >
              <a
                class="index3_dx_five_f1"
                @click="submitAnswer"
                v-if="detail.type == 3"
                >提交答案</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="index3_dx_nr_r">
        <div class="index3_dx_nr_r_tit">
          <i></i>
          <h6>答题卡</h6>
          <em>已做0/{{ subjectList.length }}</em>
        </div>
        <div class="index3_dx_nr_r_nei index3_mktk_con index3_mktk_con1">
          <div class="index3_dx_nei_one">
            <ul>
              <li>
                <i class="in3_yz"></i>
                <p>已做</p>
              </li>
              <li>
                <i class="in3_wz"></i>
                <p>未做</p>
              </li>
            </ul>
          </div>
          <div class="datika">
            <ul>
              <li v-for="(i, index) in subjectList" @click="openTi(index)">
                <span :class="i.answer_situation ? 'zuo' : ''">{{
                  index + 1
                }}</span>
              </li>
              <!-- <li>
                <span class="index3_dx_nei_li">8<i></i></span>
              </li> -->
            </ul>
          </div>
          <a-button
            class="jiaojuan"
            type="primary"
            shape="circle"
            @click="openHand"
            icon="file-text"
          >
            交卷
          </a-button>
        </div>
      </div>
      <div class="right_nav">
        <ul>
          <li @click="setColloge">
            <img
              :src="
                detail.collection_count == 1
                  ? '/img/in3_shoucang_active.png'
                  : '/img/in3_shoucang.png'
              "
              alt=""
            />
            <p>{{ detail.collection_count == 1 ? "已收藏" : "收藏" }}</p>
          </li>
          <li>
            <img src="@/assets/img/in-zihao.png" alt="" />
            <p>字号</p>
            <div>
              <span @click="getFont(14)">小</span>
              <span @click="getFont(16)">中</span>
              <span @click="getFont(18)">大</span>
            </div>
          </li>
          <li @click="getSun">
            <img :src="isSun ? '/img/sun.png' : '/img/night.png'" alt="" />
            <p>护眼</p>
          </li>
          <!-- <li @click="openHand">
            <img src="@/assets/img/submit-light-normal.png" alt="" />
            <p>交卷</p>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- 测评须知弹窗 -->
    <div class="kapian" v-show="introduceShow">
      <div class="as">
        <div class="txtx txtx_cpxz">
          <div class="txtx_wen txtx_wen_cpxz txtx_wen_cpxz1">
            <h6>
              {{ paperDetail.introduce_type == 1 ? "答题须知" : "评测须知" }}
            </h6>
            <em>{{ paperDetail.examination_paper.title }}</em>
            <p v-html="paperDetail.introduce"></p>
          </div>
          <div class="txtx_zhuang txtx_zhuang1">
            <a @click="close">我知道了</a>
            <!-- <a class="guanbi guanbi_a">取消</a> -->
          </div>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import SrFooter from "@/layout/SrFooter";
import Radios from "./modules/radio";
import Datas from "./modules/datas";
import Radio from "./modules/radio";
import Check from "./modules/check";
import Answers from "./modules/answers";
import {
  paper,
  subjectProblem,
  createOrder,
  mockPaper,
  submit,
  hand,
  operate,
} from "@/api/exam";
export default {
  name: "index",
  inject: ["reload"],
  components: {
    SrFooter,
    Radios,
    Datas,
    Check,
    Answers,
    Radio,
  },
  data() {
    return {
      paperDetail: {},
      tiIndex: 0,
      subjectList: {},
      tiDetail: {},
      yizuoIndex: 0,
      tiType: null,
      answer_order_id: null,
      type: null,
      typeList: [],
      isSun: true,
      timer: "",
      introduceShow: false,
      deadline: null,
      image: [],
    };
  },
  mounted() {},
  methods: {
    loadPaper() {
      let _this = this;
      paper({
        relation_id: _this.mock_id,
        type: _this.type,
      }).then((res) => {
        console.log(res, "试卷");
        _this.paperDetail = res.result;
        _this.examination_paper_id = res.result.examination_paper_id;
        _this.deadline =
          new Date().getTime() +
          res.result.examination_paper.length_of_examination * 60 * 1000;
        if (
          _this.paperDetail.introduce != "" ||
          _this.paperDetail.introduce != null
        ) {
          _this.introduceShow = true;
        }
        _this.createSubject(res.result.examination_paper_id);
      });
    },
    loadSubject() {
      let _this = this;
      subjectProblem({
        examination_paper_id: _this.examination_paper_id,
        answer_order_id: _this.answer_order_id,
      }).then((res) => {
        console.log(res, "题目接口");
        _this.subjectList = res.result.list;
        _this.detail = res.result.list[_this.tiIndex];
        console.log(_this.subjectList, "----subjectList");
        console.log(_this.detail, "-----题目");
        if (_this.detail.type == 1) {
          _this.$nextTick(function () {
            _this.$refs.radios.init();
          });
        } else if (_this.detail.type == 2) {
          _this.$nextTick(function () {
            _this.$refs.checks.init();
          });
        } else {
          _this.$nextTick(function () {
            _this.$refs.answers.init();
          });
        }
        _this.tiType = _this.detail.type;
      });
    },
    exits() {
      history.go(-1);
    },
    // 提交答案
    submitAnswer() {
      let _this = this;
      submit({
        answer_order_id: _this.answer_order_id,
        subject_id: _this.detail.subject_id,
        subject_problem_id: _this.detail.subject_problem_id,
        choice: _this.choice,
        answer: _this.answer,
        image: _this.image,
      }).then((res) => {
        console.log(res, "提交答案");
        if (res.status == 200) {
          _this.loadSubject();
          _this.$nextTick(function () {
            if (_this.detail.type == 1) {
              _this.$refs.radios.init();
            } else if (_this.detail.type == 2) {
              _this.$refs.checks.init();
            } else {
              _this.$refs.answers.init();
            }
          });
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    getFont(type) {
      if (this.tiType == 1) {
        this.$refs.radios.fontInit(type);
      } else if (this.tiType == 2) {
        this.$refs.checks.fontInit(type);
      } else {
        this.$refs.answers.fontInit(type);
      }
      if (this.detail.subject_datas.length) {
        this.$refs.datat.fontInit(type);
      }
    },
    getTab(i, index) {
      console.log(i);
      this.detail = i;
      this.tabIndex = index;
    },
    // 多选题提交答案
    getChecks(data) {
      let _this = this;
      console.log(data, "多选");
      submit({
        answer_order_id: _this.answer_order_id,
        subject_id: _this.detail.subject_id,
        subject_problem_id: _this.detail.subject_problem_id,
        choice: data,
      }).then((res) => {
        console.log(res, "提交答案");
        if (res.status == 200) {
          console.log(_this.detail);
          if (_this.subjectList[_this.tiIndex].answer_situation) {
            _this.subjectList[_this.tiIndex].answer_situation.choice_array =
              data;
            _this.subjectList[_this.tiIndex].answer_situation.choice =
              JSON.stringify(data);
          } else {
            var obj = {
              choice_array: data,
              choice: JSON.stringify(data),
            };
            _this.subjectList[_this.tiIndex].answer_situation = obj;
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    getAnswers(data) {
      this.answer = data;
    },
    // 单选题提交答案
    getRadios(data) {
      let _this = this;
      console.log(data, "单选答案");
      submit({
        answer_order_id: _this.answer_order_id,
        subject_id: _this.detail.subject_id,
        subject_problem_id: _this.detail.subject_problem_id,
        choice: data,
      }).then((res) => {
        console.log(res, "提交答案");
        if (res.status == 200) {
          console.log(_this.detail);
          if (_this.subjectList[_this.tiIndex].answer_situation) {
            _this.subjectList[_this.tiIndex].answer_situation.choice_array =
              data;
            _this.subjectList[_this.tiIndex].answer_situation.choice =
              JSON.stringify(data);
          } else {
            var obj = {
              choice_array: data,
              choice: JSON.stringify(data),
            };
            _this.subjectList[_this.tiIndex].answer_situation = obj;
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    getAnswerImg(data) {
      this.image.push(data);
    },
    last() {
      let _this = this;
      if (this.tiIndex == 0) {
      } else {
        this.tiIndex = this.tiIndex - 1;
        this.detail = this.subjectList[this.tiIndex];
        this.tiType = this.subjectList[this.tiIndex].type;
        _this.$nextTick(function () {
          if (_this.detail.type == 1) {
            _this.$refs.radios.init();
          } else if (_this.detail.type == 2) {
            _this.$refs.checks.init();
          } else {
            _this.$refs.answers.init();
          }
        });
      }
    },
    next() {
      let _this = this;
      if (this.tiIndex + 1 >= this.subjectList.length) {
      } else {
        this.tiIndex = this.tiIndex + 1;
        this.detail = this.subjectList[this.tiIndex];
        this.tiType = this.subjectList[this.tiIndex].type;
        _this.$nextTick(function () {
          if (_this.detail.type == 1) {
            _this.$refs.radios.init();
          } else if (_this.detail.type == 2) {
            _this.$refs.checks.init();
          } else {
            _this.$refs.answers.init();
          }
        });
      }
    },
    openTi(index) {
      let _this = this;
      this.detail = this.subjectList[index];
      this.tiType = this.subjectList[index].type;
      this.tiIndex = index;
      _this.$nextTick(function () {
        if (_this.detail.type == 1) {
          _this.$refs.radios.init();
        } else if (_this.detail.type == 2) {
          _this.$refs.checks.init();
        } else {
          _this.$refs.answers.init();
        }
      });
    },
    createSubject(examination_paper_id) {
      let _this = this;
      createOrder({
        examination_paper_id: examination_paper_id,
        relation_id: _this.mock_id,
        type: _this.type,
      }).then((res) => {
        console.log(res, "创建订单");
        if (res.status == 200) {
          _this.answer_order_id = res.result.answer_order_id;
          _this.loadSubject();
        } else {
          _this.$srmsg
            .info({
              title: "提示",
              content: res.msg,
            })
            .then(() => {
              history.go(-1);
            });
        }
      });
    },
    getSun() {
      if (this.isSun) {
        this.isSun = false;
        document.getElementsByTagName("body")[0].className = "bac";
        return;
      } else {
        this.isSun = true;
        document.body.removeAttribute("class");
      }
    },
    openHand() {
      let _this = this;
      this.$srmsg
        .confirm({
          title: "提醒",
          content: "是否确定交卷，交卷后不可修改",
        })
        .then(() => {
          hand({
            answer_order_id: _this.answer_order_id,
          }).then((res) => {
            console.log(res, "交卷");
            var data = res.result;
            if (res.status == 200) {
              if (_this.type == 13) {
                _this.$router.push({
                  path: "/exam/report",
                  query: { answer_order_id: _this.answer_order_id },
                });
              } else {
                _this.$router.push({
                  path: "/exam/reportObjective",
                  query: {
                    answer_order_id: _this.answer_order_id,
                    title: _this.paperDetail.examination_paper.title,
                  },
                });
              }
            } else {
              _this.$message.warning(res.msg);
            }
          });
        });
    },
    setColloge() {
      let _this = this;
      operate({
        subject_id: _this.detail.subject_id,
        paper_id: _this.detail.examination_paper_id,
        answer_order_id: _this.answer_order_id,
        subject_problem_id: _this.detail.subject_problem_id,
      }).then((res) => {
        console.log(res, "收藏");
        if (res.status == 200) {
          _this.$message.success(res.msg);
          _this.loadSubject();
        } else if (res.status == 201) {
          _this.$message.success(res.msg);
          _this.loadSubject();
        } else {
          _this.loadSubject();
          _this.$message.warning(res.msg);
        }
      });
    },
    close() {
      this.introduceShow = false;
    },
  },
  created() {
    this.mock_id = this.$route.query.mock_id;
    this.type = this.$route.query.type;
    console.log(this.tiIndex);
    this.loadPaper();
  },
};
</script>
<style scoped>
.tab-menu li {
  margin-bottom: 20px;
}
.tab-menu {
  height: auto;
  overflow: hidden;
}
.index3_dx_five a {
  width: auto;
}
.index3_dx_four ul li span.active {
  border: none;
}
.datika {
  overflow: hidden;
  height: 385px;
  overflow-y: scroll;
}
.datika ul li span i {
  width: 8px;
  height: 8px;
  background: #eb98c2;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -3px;
  right: -2px;
}
.datika ul li {
  width: 20%;
  float: left;
  margin-top: 20px;
}
.datika ul li span {
  font-size: 16px;
  color: #333;
  background: #fff;
  border: #cdcdcd 1px solid;
  border-radius: 8px;
  width: 40px;
  height: 38px;
  display: block;
  line-height: 40px;
  text-align: center;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
}
.index3_dx_nei_li {
  background: #3561c7;
}
.datika ul {
  overflow: hidden;
}
.index3_dx_five a {
  width: auto;
}
.index3_dx_five {
  float: right;
  width: 43%;
  margin-top: 20px;
}
.index3_dx_five a {
  float: right;
  margin-left: 3%;
  margin-right: 0;
}
.index3_clt_one {
  margin-top: 20px;
}
.right_nav {
  position: fixed;
  right: 0;
  top: 30%;
  background: #fff;
  width: 40px;
  text-align: center;
  color: #636e92;
  border-radius: 4px;
}
.right_nav ul li > img {
  margin: 0 auto;
}
.right_nav ul li {
  padding: 5px 0;
  font-size: 12px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.right_nav ul {
  margin-bottom: 0;
}
.right_nav ul li {
  position: relative;
}
.right_nav ul li > div {
  position: absolute;
  left: -66px;
  background: #fff;
  top: 0;
  height: 100%;
  display: none;
}
.right_nav ul li:hover > div {
  display: block;
}
.right_nav ul li > div span {
  margin: 0 4px;
  line-height: 50px;
  font-size: 14px;
}
.right_nav ul li > div span:hover {
  color: #3561c7;
}
.right_nav ul li:last-child {
  border-bottom: none;
}
.bac .right_nav {
  background: #1a1b1e;
  border-bottom: 1px solid #2e2e2e;
}
.bac .datika ul li span {
  background: #1a1b1e;
}
.zuo {
  background: #3561c7 !important;
  color: #fff !important;
}
.datika ul li span {
  line-height: 38px;
}
.timers {
  float: right;
  margin-right: 20px;
  width: 80px;
  overflow: hidden;
}
.timers > img {
  display: inline-block;
  float: left;
  width: 26px;
  height: 26px;
  margin-top: 4px;
}
.jiaojuan {
  display: block;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  margin-top: 20px;
}
>>> .ant-btn-circle,
.ant-btn-circle-outline {
}
</style>