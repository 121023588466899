<template>
    <a-modal v-model="visible"
             title="视频"
             :width="800"
             :footer="false">
        <video controls
               width="100%"
               :src="url"></video>
    </a-modal>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                visible: false,
                url: ''
            }
        },
        methods: {
            show(url) {
                this.visible = true
                this.url = url
            }
        }
    }
</script>

<style scoped>

</style>
