<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title" style="overflow: hidden">
        <img src="@/assets/img/remind_icon.png" />
        <h6 style="width: 86%; float: left">
          我的VIP会员-购买记录<router-link
            :to="{ path: '/user/center/myAbout', query: { id: 6,type:'vip' } }"
            ><a href="">会员权益与分销须知</a></router-link
          >
        </h6>
        <router-link to="/user/center/payViperRecord">
          <p
            style="
              float: right;
              cursor: pointer;
              color: #3561c7;
              font-size: 16px;
            "
          >
            购买记录
          </p>
        </router-link>
      </div>
      <div class="per7_integral">
        <div class="per7_in1 per7_in2">
          <ul>
            <li>
              <img src="@/assets/img/integral_img0.png" />
              <div class="integral_wen1">
                <div class="per1_w1">
                  <div class="per1_w">
                    <img
                      :src="$store.state.user.now_user_member &&
                        $store.state.user.now_user_member
                          .user_member_config_id == 1
                          ? '/img/n1.png'
                          :$store.state.user.now_user_member && $store.state.user.now_user_member
                              .user_member_config_id == 2
                          ? '/img/n2.png'
                          :$store.state.user.now_user_member && $store.state.user.now_user_member
                              .user_member_config_id == 3
                          ? '/img/n3.png'
                          :$store.state.user.now_user_member && $store.state.user.now_user_member
                              .user_member_config_id == 4
                          ? '/img/n4.png'
                          : $store.state.user.now_user_member && $store.state.user.now_user_member
                              .user_member_config_id == 5
                          ? '/img/n5.png'
                          : '/img/n6.png'
                      "
                    />
                  </div>
                  <p>·成公社</p>
                  <!-- <a href="">终身</a> -->
                </div>
                <div class="per1_wen2" v-if="$store.state.user.now_user_member">
                  <p>VIP用户</p>
                  <span>{{ vipEndTime }}到期</span>
                </div>
                <div class="per1_wen2" v-else>
                  <p>您还不是会员</p>
                </div>
              </div>
            </li>
            <li>
              <img src="@/assets/img/integral_img1.png" />
              <div class="integral_wen">
                <p>累计积分</p>
                <h6>{{ count_credit }}</h6>
              </div>
            </li>
            <li>
              <img src="@/assets/img/integral_img2.png" />
              <div class="integral_wen">
                <p>剩余积分</p>
                <h6>{{ credit }}</h6>
              </div>
            </li>
          </ul>
        </div>

        <div class="vip_buy">
          <ul>
            <li
              v-for="(i, index) in viperList"
              :style="index == vipIndex ? 'filter: none;' : ''"
              @click="getViper(i, index)"
            >
              <div class="buy_top">
                <img
                  :src="
                    index == 0
                      ? '/img/buy1.png'
                      : index == 1
                      ? '/img/buy2.png'
                      : index == 2
                      ? '/img/buy3.png'
                      : index == 3
                      ? '/img/buy4.png'
                      : index == 4
                      ? '/img/buy5.png'
                      : '/img/buy6.png'
                  "
                />
                <p>{{ i.level }}</p>
              </div>
              <div class="buy_bottom">
                <p>{{ i.name }}</p>
              </div>
            </li>
            <!-- <li>
              <div class="buy_top">
                <img src="@/assets/img/buy2.png" />
                <p>普通会员</p>
              </div>
              <div class="buy_bottom">
                <p>VIP2</p>
              </div>
            </li>
            <li>
              <div class="buy_top">
                <img src="@/assets/img/buy3.png" />
                <p>普通会员</p>
              </div>
              <div class="buy_bottom">
                <p>VIP3</p>
              </div>
            </li>
            <li>
              <div class="buy_top">
                <img src="@/assets/img/buy4.png" />
                <p>普通会员</p>
              </div>
              <div class="buy_bottom">
                <p>VIP4</p>
              </div>
            </li>
            <li>
              <div class="buy_top">
                <img src="@/assets/img/buy5.png" />
                <p>普通会员</p>
              </div>
              <div class="buy_bottom">
                <p>VIP5</p>
              </div>
            </li>
            <li>
              <div class="buy_top">
                <img src="@/assets/img/buy6.png" />
                <p>普通会员</p>
              </div>
              <div class="buy_bottom">
                <p>VIP6</p>
              </div>
            </li> -->
          </ul>
        </div>

        <div class="pay_fs">
          <div class="pay_top">
            <h6>选择支付方式</h6>
          </div>
          <div class="pay_bottom">
            <div class="pay_bo_l">
              <div class="pay_wechat">
                <img src="@/assets/img/wechat1.png" />
                <p>微信支付</p>
              </div>
              <div class="pay_wechat">
                <img src="@/assets/img/wechat2.png" />
                <p>支付宝支付</p>
              </div>
            </div>
            <div class="pay_bo_r">
              <div class="pay_1">
                <div class="radio">
                  <input
                    type="radio"
                    id="radio1"
                    value="2"
                    v-model="payType"
                    name="pay"
                  />
                  <label for="radio1"></label>
                </div>
                <div class="radio radio1">
                  <input
                    type="radio"
                    id="radio2"
                    value="1"
                    v-model="payType"
                    name="pay"
                  />
                  <label for="radio2"></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 选框选中之后无法取消--------------------------- -->
        <div class="pay_qy1">
          <div class="pay_qy">
            <div class="pay_qy_l">
              <div class="checkbox checkbox1">
                <input
                  type="checkbox"
                  id="checkbox3"
                  v-model="textCheck"
                />
                <label for="checkbox3"></label>
              </div>
            </div>
            <div class="pay_qy_r">
              <p>
                已阅读并同意<router-link
                  :to="{ path: '/user/center/myAbout', query: { id: 6,type:'vip' } }"
                  ><a href="">VIP会员权益</a></router-link
                >
              </p>
            </div>
          </div>
        </div>
        <SrButton
          style="width: 250px; float: right; margin-top: 30px"
          @click="viperPay"
          font-size="14px"
          height="40px"
          padding="0 20px"
          :loading="pay_loading"
        >
          立即支付￥{{ price }}
        </SrButton>
      </div>
    </div>
    <WechatScan ref="WechatScan"></WechatScan>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import { getUserMembers, getUserCredit, buyMember } from "@/api/user";
import { web } from "@/api/pay";
import WechatScan from "../../buy/settlement/modules/WechatScan";
export default {
  name: "PayViper",
  components: {
    SrButton,
    WechatScan,
  },
  data() {
    return {
      viperList: [],
      price: 0,
      count_credit: null,
      credit: null,
      vipIndex: 0,
      payType: "",
      pay_loading: false,
      member_config_id: null,
      textCheck: false,
      vipEndTime: "",
    };
  },
  methods: {
    //   获取vip等级列表
    loadVipLavel() {
      let _this = this;
      getUserMembers().then((res) => {
        //console.log(res, "------");
        _this.viperList = res.result;
        if (_this.$store.state.user.now_user_member) {

        } else {
          _this.price = _this.viperList[0].price;
          _this.member_config_id = _this.viperList[0].user_member_config_id;
        }
      });
    },
    // 获取用户积分
    getUserCredit() {
      let _this = this;
      getUserCredit().then((res) => {
       // console.log(res, "积分");
        _this.count_credit = res.result.count_credit;
        _this.credit = res.result.credit;
      });
    },
    getViper(i, index) {
     // console.log(i)
      this.vipIndex = index;
      this.price = i.price;
      this.member_config_id = i.user_member_config_id
    },
    viperPay() {
      let _this = this;
      if (_this.payType == "") {
        _this.$message.warning("请选择支付方式");
      } else {
        if (!_this.textCheck) {
          _this.$message.warning("请阅读并同意VIP会员权益");
          return;
        }
        _this.pay_loading = true;
        buyMember({ member_config_id: _this.member_config_id }).then((res) => {
        //  console.log(res,'购买会员')
          if (res.status === 200) {
            _this.order_id = res.result;
            web({ order_id: _this.order_id, pay_type: _this.payType }).then(
              (res) => {
                if (_this.payType == 1) {
                  document.querySelector("body").innerHTML = res.result;
                  document.forms[0].submit();
                }else{
                   _this.$refs.WechatScan.show(res.result, _this.order_id)
                }
              }
            );
          } else {
            _this.$message.warning(res.msg);
            _this.pay_loading = false;
          }
        });
      }
    },
  },
  created() {
    this.loadVipLavel();
    this.getUserCredit();
    if (this.$store.state.user.now_user_member) {
      this.vipEndTime = this.$store.state.user.now_user_member.failure_time.substring(
        0,
        10
      );
    }
     if(this.$route.query.textCheck){
        this.textCheck = this.$route.query.textCheck
      }
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
.pay_qy_r{
  margin-top: 0;
}
.pay_qy_r p {
  padding-left: 0;
}
.checkbox {
    position: relative;
    height: 30px;
    width: 30px;
}
</style>
