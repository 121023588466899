<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的收藏</h6>
      </div>
      <div class="per1_message">
        <div class="collection-eq">
          <ul>
            <li v-for="i in list">
              <router-link
                :to="{
                  path: '/community/detail',
                  query: {
                    id: i.community.community_id,
                    community_category_id: i.community.community_category_id,
                  },
                }"
              >
                <a>
                  <div class="coll_l fn_l">
                    <img :src="i.community.image.url" />
                  </div>
                  <div class="coll_r fn_r">
                    <h6>
                      {{ i.community.name
                      }}<span>{{ i.community.created_at }}</span>
                    </h6>
                    <p v-html="i.community.name"></p>
                    <div class="coll_r_nei">
                      <!-- <div class="coll_n_one">
                      <img src="@/assets/img/coll_tea.jpg" />
                      <span>江洋</span>
                    </div> -->
                      <div class="coll_n_one coll_n_one1">
                        <img src="@/assets/img/coll_liu.png" />
                        <span
                          >浏览量:<i>{{ i.community.browse }}</i></span
                        >
                      </div>
                      <div class="coll_n_one coll_n_one1">
                        <img src="@/assets/img/coll_ping.png" />
                        <span
                          >评论量:<i>{{ i.community.comment_count }}</i></span
                        >
                      </div>
                    </div>
                  </div>
                </a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- 页码 -->
      <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
      ></SrPaginate>
    </div>
  </div>
</template>
<script>
import { community_collection } from "@/api/user";
import SrPaginate from "@/components/SrPaginate";
export default {
  name: "myCommunity",
  components: {
    SrPaginate,
  },
  data() {
    return {
      pagination: {
        page: 1,
        page_size: 8,
      },
      list: [],
    };
  },
  methods: {
    loadList() {
      let _this = this;
      community_collection({ ...this.pagination }).then((res) => {
        console.log(res, "收藏");
        _this.list = res.result.items;
        _this.pagination = res.result.pagination;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
  },
  created() {
    this.loadList();
  },
};
</script>