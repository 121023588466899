<template>
    <div>
        <a-skeleton style="margin-top: 50px"
                    v-if="loading"
                    :title="false"
                    :paragraph="{rows:3}"/>
        <div v-else
             class="course-fl1 mt50">
            <a v-for="(item,index) in list"
               :index="item.radio_category_id"
               @click="selectCategory(item,index)"
               :class="[active_index===index?'active':'']">
                {{item.name}}
            </a>
        </div>
    </div>
</template>

<script>
    import {getlist} from "@/api/radio/category"

    export default {
        name: "CategoryList",
        data() {
            return {
                loading: false,
                list: [],
                active_index: 0
            }
        },
        created() {
            var _self = this
            _self.loading = true
            getlist().then((res) => {
                console.log(res,'ress')
                _self.loading = false
                _self.list = res.result
                this.$emit('change', res.result[0])
            })
        },
        methods: {
            selectCategory(item, index) {
                this.active_index = index
                this.$emit('change', item)
            }
        }
    }
</script>

<style scoped>

</style>
