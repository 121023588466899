import request from '@/utils/request'
// 获取社区多级分类
export function category(parameter) {
    return request({
        url: '/api/community/category',
        method: 'post',
        data: parameter
    })
}
// 帖子列表
export function list(parameter) {
    return request({
        url: '/api/community',
        method: 'post',
        data: parameter
    })
}
// 帖子详情
export function info(parameter) {
    return request({
        url: '/api/community/info',
        method: 'post',
        data: parameter
    })
}
// 获取指定分类下的推荐课程和相关课程
export function specifiedCategory(parameter) {
    return request({
        url: '/api/community/specifiedCategory',
        method: 'post',
        data: parameter
    })
}
// 评论帖子
export function comment(parameter) {
    return request({
        url: '/api/community/comment',
        method: 'post',
        data: parameter
    })
}
// 回复帖子
export function reply(parameter) {
    return request({
        url: '/api/community/reply',
        method: 'post',
        data: parameter
    })
}
// 点赞取消赞
export function like(parameter) {
    return request({
        url: '/api/community/like',
        method: 'post',
        data: parameter
    })
}
// 收藏、取消收藏
export function collection(parameter) {
    return request({
        url: '/api/community/collection',
        method: 'post',
        data: parameter
    })
}