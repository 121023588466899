<template>
    <div>
        <SrNav></SrNav>

        <div class="warp">
            <!-- 面包屑 -->
            <div class="xun1_mbx xun1_ming">
                <div class="xun1_mbx_nr">
                    <i></i>
                    <div class="xun1_mbx_a">
                        <router-link to="/">首页 -</router-link>
                        <a href="" class="xun1_mbx_on">名师列表</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="warp clearfix mt30">
            <a-spin :spinning="loading">
                <a-icon slot="indicator"
                        type="loading"
                        style="font-size: 30px"
                        spin/>
                <div v-if="loading&&list.length===0"
                     style="width: 100%;height: 200px">
                </div>
                <div class="xun1_teacher">
                    <ul>
                        <li v-for="item in list">
                            <div class="xun1_tea">
                                <img :src="item.avatar.url"/>
                                <div class="xun1_tea1">
                                    <h6><i></i>{{item.name}}</h6>
                                    <span>主讲科目：{{item.label}}</span>
                                    <div class="xun1_tea1_p">
                                        <p v-html="item.introduce"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="xun1_hidden">
                                <div class="xun1_hi">
                                    <h6><i></i>{{item.name}}</h6>
                                    <p>主讲科目：{{item.label}}</p>
                                </div>
                                <div class="xun1_hi1">
                                    <p v-html="item.introduce"></p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </a-spin>
        </div>

        <SrPaginate style="margin: 0 auto;text-align: center;margin-top: 50px"
                    :current="pagination.current_page"
                    :total="pagination.total"
                    :pageSize="pagination.page_size"
                    @change="changePage"></SrPaginate>
        <SrFooter></SrFooter>
    </div>

</template>

<script>
    import {teacher} from "@/api/index"
    import SrNav from "@/layout/SrNav"
    import SrFooter from "@/layout/SrFooter"
    import SrPaginate from '@/components/SrPaginate'

    export default {
        name: "index",
        components: {
            SrNav,
            SrFooter,
            SrPaginate
        },
        data() {
            return {
                loading: true,
                list: [],
                pagination: {
                    page: 1,
                    page_size: 8
                }
            }
        },
        created() {
            this.getData()
        },
        methods: {
            changePage(page, page_size) {
                this.pagination.page = page
                this.getData()
            },
            getData() {
                var _self = this
                _self.loading = true
                teacher({...this.pagination}).then((res) => {
                    _self.list = res.result.items
                    _self.loading = false
                    _self.pagination = res.result.pagination
                })
            },
        }
    }
</script>

<style scoped>
    .xun1_tea1 span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .xun1_tea1_p p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: box;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .xun1_tea1 h6{
        font-size: 18px;
    }
</style>
