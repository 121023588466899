<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>
          点评/批改
           <router-link to="/user/center/myCorrectsList">
                 <a >账单</a>
                </router-link>
         
        </h6>
      </div>

      <div class="per7_integral">
        <div class="per7_in1 in3_per7_in1">
          <ul>
            <li>
              <img src="@/assets/img/integral_img1.png" />
              <div class="integral_wen">
                <p>点评剩余次数</p>
                <h6>{{ detail.user_surplus_number.comment_number }}</h6>
              </div>
            </li>
            <li>
              <img src="@/assets/img/integral_img2.png" />
              <div class="integral_wen">
                <p>批改剩余次数</p>
                <h6>{{ detail.user_surplus_number.correct_number }}</h6>
              </div>
            </li>
          </ul>
        </div>

        <!-- 购买次数 -->
        <div class="index3_gm">
          <div class="index3_gm_tit">
            <span>购买类型</span>
            <div class="index3_gm_tit_dian">
              <a-radio-group
                name="radioGroup"
                @change="radioChange"
                :default-value="1"
              >
                <a-radio :value="1"> 点评 </a-radio>
                <a-radio :value="2"> 批改 </a-radio>
              </a-radio-group>
            </div>
          </div>
          <!-- 购买次数 -->
          <div class="index3_gm_nr">
            <div class="index3_gm_nr_one">
              <i></i>
              <p>购买次数</p>
            </div>
            <div class="index3_gm_list">
              <ul>
                <li
                  v-for="(i, index) in detail.allow_buy_list"
                  :class="tabIndex == index ? 'active' : ''"
                  @click="getTab(i, index)"
                >
                  <h6>{{ i.number }}<span>次</span></h6>
                  <em>售价{{ i.price }}元</em>
                </li>
              </ul>
            </div>
          </div>

          <!-- 购买说明 -->
          <div class="index3_gmsm">
            <h6>购买说明:</h6>
            <p v-html="detail.buy_description.content"></p>
          </div>
        </div>

        <!-- 选择支付方式 -->
        <div class="pay_fs index3_pay_xiugai">
          <div class="pay_top">
            <h6>选择支付方式</h6>
          </div>
          <div class="pay_bottom">
            <div class="pay_bo_l">
              <div class="pay_wechat">
                <img src="@/assets/img/wechat1.png" />
                <p>微信支付</p>
              </div>
              <div class="pay_wechat">
                <img src="@/assets/img/wechat2.png" />
                <p>支付宝支付</p>
              </div>
            </div>
            <div class="pay_bo_r">
              <div class="pay_1">
                <div class="radio">
                  <input
                    type="radio"
                    id="radio1"
                    value="2"
                    v-model="payType"
                    name="pay"
                  />
                  <label for="radio1"></label>
                </div>
                <div class="radio radio1">
                  <input
                    type="radio"
                    id="radio2"
                    value="1"
                    v-model="payType"
                    name="pay"
                  />
                  <label for="radio2"></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pay_qy1">
          <div class="pay_qy">
            <div class="pay_qy_l">
              <div class="radio radio1">
                <input type="radio" id="checkbox3" name="checkboox1[]" />
                <label for="checkbox3"></label>
              </div>
            </div>
          </div>
        </div>
         <SrButton
          style="width: 250px; float: right; margin-top: 30px"
          @click="pay"
          font-size="14px"
          height="40px"
          padding="0 20px"
          :loading="pay_loading"
        >
          立即支付
        </SrButton>
        <!-- <div class="pay_a">
          <a class="in3_pay_xiu" @click="pay">立即支付</a>
        </div> -->
      </div>
      <WechatScan ref="WechatScan"></WechatScan>
      <!-- 页码 -->
      <!-- <div class="page mt20">
					<a href="">&lt;&lt;</a>
					<a class="active" href="">1</a>
					<a href="">2</a>
					<a href="">&gt;&gt;</a>
				</div> -->
    </div>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import { detail, buy } from "@/api/corrects";
import WechatScan from "../../buy/settlement/modules/WechatScan";
import { web } from "@/api/pay";
export default {
  name: "index",
  components: {
    WechatScan,
    SrButton
  },
  data() {
    return {
      type: 1,
      detail: {},
      listIndex: 0,
      payType: "",
      tabIndex: null,
      corrects_id: null,
      pay_loading:false
    };
  },
  mounted() {},
  methods: {
    loadDetail() {
      let _this = this;
      detail({
        type: _this.type,
      }).then((res) => {
        console.log(res, "详情");
        _this.detail = res.result;
      });
    },
    radioChange(e) {
      this.type = e.target.value;
      this.loadDetail();
      this.tabIndex = null;
      this.corrects_id = null;
    },
    pay() {
      let _this = this;
      if (_this.payType == "") {
        _this.$message.warning("请选择支付方式");
        return;
      }
      if (_this.corrects_id == null) {
        _this.$message.warning("请选择购买次数");
        return;
      }
      _this.pay_loading = true;
      buy({
        corrects_id: _this.corrects_id,
      }).then((res) => {
        console.log(res, "订单");
        if (res.status === 200) {
          _this.order_id = res.result.order_id;
          web({ order_id: _this.order_id, pay_type: _this.payType }).then(
            (res) => {
              if (_this.payType == 1) {
                document.querySelector("body").innerHTML = res.result;
                document.forms[0].submit();
              } else {
                _this.$refs.WechatScan.show(res.result, _this.order_id);
                _this.pay_loading = false;
              }
            }
          );
        } else {
          _this.$message.warning(res.msg);
          _this.pay_loading = false;
        }
      });
    },
    getTab(i, index) {
      this.tabIndex = index;
      this.corrects_id = i.corrects_id;
    }
  },
  created() {
    this.loadDetail();
  },
};
</script>
<style scoped>
/* 点评批评 */
.index3_gm {
  width: 94.5%;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 8px;
  overflow: hidden;
}
.in3_per7_in1 {
  border-bottom: none;
  padding-bottom: 25px;
}

.index3_gm_tit {
  border-bottom: #efefef 1px solid;
  overflow: hidden;
  padding-bottom: 15px;
}

.index3_gm_tit span {
  font-size: 16px;
  color: #eacea1;
  float: left;
}
.index3_gm_tit_dian {
  float: left;
  margin-left: 15px;
}
.index3_gm_tit_dian ul .in3_dian_active i {
  width: 7px;
  height: 7px;
  background: #eacea1;
  border-radius: 50%;
  border: #eacea1 1px solid;
  display: block;
  float: left;
  margin: 6px 8px 0 0;
}
.index3_gm_tit_dian ul .in3_dian_active p {
  font-size: 16px;
  color: #eacea1;
  float: left;
}
.index3_gm_tit_dian ul li i {
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 50%;
  border: #c1c1c1 1px solid;
  display: block;
  float: left;
  margin: 6px 8px 0 0;
}
.index3_gm_tit_dian ul li p {
  font-size: 16px;
  color: #bfbfbf;
  float: left;
  margin-right: 18px;
}
.index3_gm_tit_dian ul li {
  float: left;
}

.index3_gm_nr_one {
  overflow: hidden;
}
.index3_gm_nr_one i {
  width: 3px;
  height: 20px;
  background: #3561c7;
  display: block;
  float: left;
}
.index3_gm_nr_one p {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  float: left;
  margin: 0px 0 0 15px;
}
.index3_gm_nr {
  margin: 20px 0;
}
.index3_gm_list ul li {
  width: 18%;
  background: #f5f9ff;
  padding: 20px;
  border-radius: 8px;
  float: left;
  margin: 0 20px 20px 0;
  cursor: pointer;
}
.index3_gm_list ul li:nth-child(5n) {
  margin-right: 0;
}
.index3_gm_list ul li h6 {
  font-size: 28px;
  color: #3561c7;
  font-weight: 400;
  text-align: center;
}
.index3_gm_list ul li h6 span {
  font-size: 16px;
  color: #3561c7;
}
.index3_gm_list ul li em {
  font-size: 14px;
  display: block;
  color: #b6b6b6;
  text-align: center;
  margin-top: 5px;
}
.index3_gm_list {
  overflow: hidden;
  margin-top: 25px;
}

.index3_gmsm h6 {
  font-size: 14px;
  color: #ff5657;
  font-weight: 400;
  margin-bottom: 10px;
}
.index3_gmsm p {
  font-size: 14px;
  color: #999;
  line-height: 26px;
}
.index3_pay_xiugai {
  margin-top: 35px;
}

.index3_guanbi {
  position: absolute;
  top: 13px;
  right: 15px;
}
.index3_gm_list ul .active {
  border: 1px solid #3561c7;
}
</style>