<template>
  <div>
    <!-- 分类 -->
    <CampTag @change="typeChange"></CampTag>
    <div v-if="loading">
      <a-spin
        style="
          text-align: center;
          width: 100%;
          margin-top: 50px;
          color: #3561c7;
        "
      >
        <a-icon slot="indicator" type="loading" style="font-size: 30px" spin />
      </a-spin>
    </div>
    <div v-else>
      <a-empty v-if="list.length === 0" description="暂无数据" />
      <div v-else class="warp clearfix mt30">
        <div v-for="item in list" :key="item.camp_id" class="kc-list fn_l ml0">
          <router-link :to="{ path: '/camp/info/' + item.camp_id }">
            <div class=""><img :src="item.image.url" /></div>
            <div class="kc-listTex">
              <h3 style="color: #000">{{ item.name }}</h3>
              <!-- <p class="f12 color-999">
                报名倒计时：
                <span class="seckill-countdown">
                  <a-statistic-countdown
                    :value="item.start_at | toTime"
                    format="D 天 H 时 m 分 s 秒"
                  />
                </span>
              </p> -->
              <p class="clearfix f14 color-666">
                <span class="fn_l" style="font-size:12px;"
                  >限售<em class="color-main">
                    {{ item.goods.sales_restriction }}人</em
                  >
                  | 已报名
                  <em class="color-pink">{{ item.goods.sale_amount }}人</em>
                </span>
                <span class="fn_r"
                  ><em class="f22 color-main"
                    >￥{{ item.goods.order_price }}</em
                  ></span
                >
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 页码 -->
    <SrPaginate
      style="margin: 0 auto; text-align: center; margin-top: 50px"
      :current="pagination.current_page"
      :total="pagination.total"
      :pageSize="pagination.page_size"
      @change="changePage"
    ></SrPaginate>
  </div>
</template>

<script>
import CampTag from "./modules/CampTag";
import { list } from "@/api/camp";
import SrPaginate from "@/components/SrPaginate";

export default {
  name: "index",
  components: {
    CampTag,
    SrPaginate,
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  data() {
    return {
      query: {},
      pagination: {
        page: 1,
        page_size: 6,
      },
      list: [],
      loading: true,
    };
  },
  methods: {
    typeChange(camp) {
      this.query.camp_id = camp.camp_type_id;
      console.log(camp)
      this.getData();
    },
    getData() {
      var _self = this;
      _self.loading = true;
      list({ ...this.pagination,camp_type_id:this.query.camp_id }).then((res) => {
        _self.list = res.result.items;
        _self.loading = false;
        _self.pagination = res.result.pagination;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.getData();
    },
  },
};
</script>

<style scoped>
.kc-list {
  margin: 20px;
}

.seckill-countdown >>> .ant-statistic {
  display: inline-block;
}

.seckill-countdown >>> .ant-statistic-content {
  display: inline-block;
  font-size: 12px;
}

.seckill-countdown >>> .ant-statistic-content-value {
  font-size: 12px;
  color: #999;
}
.kc-list {
  width: 23%;
  height: 250px;
}
.ant-empty{
    margin-top: 50px;
}
</style>
