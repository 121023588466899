import storage from "@/utils/storage"
import {list} from "@/api/cart"

const cart = {
    state: {
        is_init: false,
        list: [],
        expire_list: []
    },

    mutations: {
        SET_LIST: (state, list) => {
            state.is_init = true
            state.list = list.list
            state.expire_list = list.expire_list
        },
        ADD_CART: (state, goods) => {
            state.list.push(goods)
        }
    },

    actions: {
        // 获取购物车信息
        getCart({commit}) {
            if (!this.state.cart.is_init) {
                return new Promise((resolve, reject) => {
                    list().then((res) => {
                        var list = res.result
                        commit('SET_LIST', list)
                        resolve(list)
                    })
                })
            }
        }
    }
}

export default cart
