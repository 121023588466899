<template>
  <div>
    <div class="tab-content">
      <div class="cai1">
        <p v-html="detail.title" :style="fontType == 14?'font-size:14px':fontType == 18?'font-size:18px':'font-size:16px'"></p>
        <img v-if="detail.image.url" :src="detail.image.url" alt="">
        <div class="wenti1" style="overflow: hidden">
          <textarea
            placeholder="请输入答案/请开始答题"
            v-model="detail.answer_situation.answer"
            v-if="detail.answer_situation"
          ></textarea>
          <textarea
            placeholder="请输入答案/请开始答题"
            @input="answer"
            v-else
          ></textarea>
          <div v-for="i in imgList" class="imgList" v-if="imgList.length > 0">
            <img :src="i" alt="">
          </div>
          <p class="kc-upload fn_l choose-image-btn" @click="chooseImage">
            <img src="@/assets/img/upload-image.jpg" style="cursor: pointer" />
            <input
              type="file"
              style="display: none"
              ref="file"
              @change="fileChange"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadVideo, uploadImage, uploadAudio } from "@/api/common";
export default {
  name: "index",
  props: {
    detail: Object,
  },
  data() {
    return {
      fontType:16,
      imgList:[]
    };
  },
  mounted() {},
  methods: {
    answer(e) {
      this.$emit("getAnswer", e.target.value);
    },
    init() {},
    fileChange() {
      var file = this.$refs.file.files[0];
      console.log(file);
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        var allow_audio = ["audio/mp3"];
        if (allow_video.indexOf(file.type) !== -1) {
          this.addVideo(file);
          return true;
        }
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage(file);
          return true;
        }
        if (allow_audio.indexOf(file.type) !== -1) {
          this.addAudio(file);
          return true;
        }
        this.$message.warning("所选文件格式不支持");
      }
    },
    addVideo(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("video", file);
      _self.upload_loading = true;
      uploadVideo(formData).then((res) => {
        console.log(res, "视频");
        _self.upload_loading = false;
        if (res.status === 200) {
          _self.video_id = res.result.video_id;
          _self.video_url = res.result.cover;
        } else {
          _self.$message.warning(res.msg);
        }
      });
    },
    addImage(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("image", file);
      _self.upload_loading = true;
      uploadImage(formData).then((res) => {
        console.log(res, "图片");
        _self.imgList.push(res.result.url);
        _self.$emit("getAnswerImg", res.result.url);
      });
    },
    addAudio(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("audio", file);
      _self.upload_loading = true;
      uploadAudio(formData).then((res) => {
        console.log(res, "音频");
        _self.upload_loading = false;
        if (res.status === 200) {
          _self.audio_id = res.result.video_id;
        } else {
          _self.$message.warning(res.msg);
        }
      });
    },
    chooseImage() {
      this.$refs.file.click();
    },
    fontInit(type){
      this.fontType = type
    }
  },
  created() {
    console.log(this.detail);
  },
};
</script>
<style>
.wenti1 textarea {
  width: 97%;
  height: 190px;
  border: none;
  background: #f8f8f8;
  padding: 15px 0 0 20px;
  border-radius: 10px;
  margin-top: 25px;
  font-size: 14px;
  color: #999;
}
.imgList{
  overflow: hidden;
  float: left;
}
.imgList img{
  float: left;
  margin-right: 10px;
}
</style>