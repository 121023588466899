<template>
  <div>
    <div class="warp clearfix mt30">
      <div v-for="(item, index) in list">
        <div class="kc-list kc-listBg fn_l">
          <!-- <span v-if="item.goods.notend_seckill" class="kc-listBq">
                    <img src="@/assets/img/miao_logo1.png" />
                  </span>
                  <span v-if="item.goods.notend_pintuan" class="kc-listBq">
                    <img src="@/assets/img/pin_logo1.png" />
                  </span> -->
          <a @click="toCourseDetail(item)">
            <div class="kc-listTex">
              <p
                class="f12 color-main"
                :style="{ color: item.relation.subtitle_color }"
              >
                {{ item.relation.subtitle }}
              </p>
              <h3 style="margin-top: 10px">
                {{ item.relation.name }}
              </h3>
              <ul class="kc-avatar clearfix">
                <li
                  v-for="(teacher_item, teacher_index) in item.relation.teacher"
                  v-if="teacher_index < 3"
                >
                  <img
                    width="66px"
                    height="66px"
                    :src="teacher_item.avatar.url"
                  />
                  <p>{{ teacher_item.name }}</p>
                </li>
              </ul>
              <p class="clearfix f14 color-666 dwss" style="margin-top: 50px">
                <span v-if="item.sales_restriction == item.sale_amount"
                  ><em class="color-pink">已售空</em></span
                >
                <span class="fn_l" v-else
                  >限售<em class="color-main"
                    >{{ item.sales_restriction }}人</em
                  >
                  | 已报名<em class="color-pink"
                    >{{ item.sale_amount }}人</em
                  ></span
                >
                <span class="fn_r">
                  <span v-if="item.order_price !== item.real_price">
                    <s class="color-999" v-if="item.real_price"
                      >￥{{ item.real_price }}</s
                    >
                    <em class="f22 color-main">￥{{ item.order_price }}</em>
                  </span>
                  <span v-else>
                    <s class="color-999" v-if="item.real_price"
                      >￥{{ item.real_price }}</s
                    >
                    <em class="f22 color-main">￥{{ item.order_price }}</em>
                  </span>
                </span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { info } from "@/api/school/course_package";
export default {
  name: "CourseTag",
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.course_package_id = this.$route.params.course_id;
    this.getData();
  },
  methods: {
    getData() {
      var _self = this;
      info({ course_package_id: _self.course_package_id }).then((res) => {
        console.log(res, "详情");
        _self.list = res.result.relation_goods;
        // _self.loading = false;
        // _self.course_package = res.result;
        // document.title = res.result.name + "-" + document.title;
      });
    },
    toCourseDetail(item) {
      console.log(item);
      if (item.type == 1) {
        this.$router.push({
          path: "/course/info/" + item.relation.course_id,
        });
      }
      if (item.type == 3) {
        this.$router.push({
          path: "/camp/info/" + item.relation.camp_id,
        });
      }
    },
  },
};
</script>
<style>
.sr-list-margin {
  margin: 0 20px;
}

.kc-listTex h3 {
  font-weight: 900;
  font-size: 18px;
}

.kc-listBg {
  background: url(/sr/img/KC-listBg.jpg) #ffffff no-repeat top right;
}

.kc-avatar {
  margin-top: 20px;
}
.kc-list {
  margin-right: 22px;
  position: relative;
  height: 330px;
}
/* .f14 {
  position: absolute;
  bottom: 10px;
  width: 95%;
} */
.dwss{
   position: absolute;
  bottom: 10px;
  width: 95%;
}
</style>