<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>点评/修改 - 账单</h6>
      </div>

      <div class="per7_integral">
        <!-- <div class="per7_in1">
						<ul>
							<li>
								<img src="img/integral_img1.png" />
								<div class="integral_wen">
									<p>累计积分</p>
									<h6>30.00</h6>
								</div>
							</li>
							<li>
								<img src="img/integral_img2.png" />
								<div class="integral_wen">
									<p>剩余积分</p>
									<h6>30.00</h6>
								</div>
							</li>
						</ul>
					</div> -->
        <!-- tab -->
        <div class="per7_in2">
          <div class="tab-wrapper">
            <ul class="tab-menu tab-menu-form">
              <li :class="type == 1 ? 'active' : ''" @click="getTab(1)">
                账单
              </li>
              <li :class="type == 2 ? 'active' : ''" @click="getTab(2)">
                消费
              </li>
            </ul>
            <div class="tab-content">
              <div>
                <table border="0" cellspacing="0" cellpadding="0" class="form">
                  <tr>
                    <th>类型</th>
                    <th>购买次数</th>
                    <th>交易金额</th>
                    <th>下单时间</th>
                  </tr>
                  <tr v-for="i in list">
                    <td>{{i.type_text}}</td>
                    <td>{{i.number}}次</td>
                    <td class="green">{{i.price}}</td>
                    <td>{{i.created_at}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
      ></SrPaginate>
    </div>
  </div>
</template>
<script>
import SrPaginate from "@/components/SrPaginate";
import { bill } from "@/api/corrects";
export default {
  name: "index",
  components: {
    SrPaginate,
  },
  data() {
    return {
      type: 1,
      pagination: {
        page: 0,
        page_size: 9,
        page: 1,
      },
    };
  },
  mounted() {},
  methods: {
    getTab(index) {
      this.type = index;
      this.loadList();
    },
    loadList() {
      let _this = this;
      bill({
        type: _this.type,
      }).then((res) => {
        console.log(res, "账单");
        _this.list = res.result.items;
        _this.pagination = res.result.pagination;
      });
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style scoped>
.form tr th {
  text-align: center;
}
</style>