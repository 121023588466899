<template>
  <div>
    <!-- 错题集内容 -->
    <div class="index3_wdctj_nr">
      <ul v-if="list.length > 0">
        <li v-for="i in list">
          <div class="index3_wdctj_list_l">
            <i></i>
            <h6>{{ i.relation.name }}</h6>
          </div>
          <div class="index3_wdctj_list_center">
            <p>
              <span>{{ i.answer_situation_count }}</span
              >道题
            </p>
          </div>
          <div class="index3_wdctj_list_r">
            <a @click="openAnswer(i)">点击查看</a>
          </div>
        </li>
      </ul>
      <div class="index3_mktk warp" v-if="list.length == 0">
        <div class="in3_mktk in3_mktk_zw">
          <img src="@/assets/img/index3_mktk_tu1.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SrPaginate from "@/components/SrPaginate";
import { wrongSet } from "@/api/exam";
export default {
  name: "index",
  components: {
    SrPaginate,
  },
  props: {
    id: String || Number,
    type: String || Number,
  },
  data() {
    return {
      pagination: {
        page: 0,
        page_size: 9,
        page: 1,
      },
      list: [],
    };
  },
  methods: {
    loadWrongSet() {
      let _this = this;
      wrongSet({
        relation_id: _this.id,
        type: _this.type,
      }).then((res) => {
        console.log(res, "错题");
        _this.list = res.result.data;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadWrongSet();
    },
    openMySubject(item) {
      this.$router.push({
        path: "/answerDetail",
        query: {
          answer_order_id: item.answer_order_id,
        },
      });
    },
    openAnswer(item) {
      console.log(item);
      this.$router.push({
        path: "/answerDetail",
        query: {
          answer_order_id: item.answer_order_id,
          type: "wrong",
        },
      });
    },
  },
  created() {
    this.loadWrongSet();
  },
};
</script>