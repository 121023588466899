<template>
    <div>
        <div class="login_loading">
         <a-spin size="large" />
         <div>登录跳转中...</div>
        </div>
    </div>
</template>
<script>
import storage from "@/utils/storage";
import {Spin} from 'ant-design-vue'
import { socialiteValidation } from "@/api/user";
export default {
  name: "LoginBox",
  components: {
  },
  data() {
    return {
        crypt:''
    };  
  },
  created() {
      console.log(this.$route.query,'中间页')
      this.getSocialiteValidation()
  },
  watch: {
 
  },
  methods: {
      getSocialiteValidation(){
          let _this = this
          socialiteValidation({
              type:_this.$route.query.type,
              crypt:_this.$route.query.crypt
          }).then((res) =>{
              console.log(res,'社会化验证')
              if(res.msg == '账号不存在'){
                  _this.$router.push({ path: "/bindPhone",query:{crypt:_this.$route.query.crypt,type:_this.$route.query.type} });
              }else{
                   _this.$message.success("登录成功");
                   storage.setItem("ACCESS_TOKEN", res.result);
                    _this.$router.push('/');
              }
          })
      }
  },
};
</script>
<style scoped>
    .login_loading{
        margin: 0 auto;
        text-align: center;
        margin-top: 300px;
    }
</style>