<template>
  <div>
    <SrNav></SrNav>
    <div class="warp">
      <!-- 面包屑 -->
      <div class="xun1_mbx">
        <div class="xun1_mbx_nr">
          <i></i>
          <div class="xun1_mbx_a">
            <router-link to="/">
              <a href="">首页 -</a>
            </router-link>
            <router-link to="/new/planList" v-if="$route.query.type == 1">
              <a href="">考试计划 -</a>
            </router-link>
            <router-link to="/" v-if="$route.query.type == 2">
              <a href="">备考资料 -</a>
            </router-link>
            <a href="" class="xun1_mbx_on">{{ detail.name }}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 考试计划列表 -->
    <div class="warp clearfix mt30">
      <div class="xun_plan">
        <div class="xun_plan_l">
          <div class="xun_plan_list xun1_bei1">
            <h6>{{ detail.name }}</h6>
            <p>发布时间：{{ detail.created_at }}</p>
          </div>
          <div class="xun1_bei1_nr">
            <div v-html="detail.content"></div>
          </div>
        </div>
         <div class="xun_plan_r">
          <a v-for="(i,index) in imgList" v-if="index <=3" @click="openCourse(i)"><img :src="i.goods.image.url" />
            <p>{{i.goods.name}}</p>
          </a>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
  import SrNav from "@/layout/SrNav";
  import SrFooter from "@/layout/SrFooter";
  import { advertising, detail,recommendModular } from "@/api/index";
  export default {
    name: "detail",
    components: {
      SrNav,
      SrFooter,
    },
    data() {
      return {
        imgList: [],
        detail: {},
      };
    },
    watch: {
      $router: {
        handler: "detail",
        //调用方法
        immediate: true,
        //进入立即执行一次
      },
    },
    methods: {
      loadImg() {
        let _this = this;
        advertising({location:1}).then((res) => {
          console.log(res, "广告位");
          _this.imgList = res.result;
        });
      },
          openCourse(item){
        if (item.goods.type == 1) {
        this.$router.push({
          path: "/course/info/" + item.goods.relation.course_id,
        });
      }
      if (item.goods.type == 2 && item.goods.relation.style_temp == 0) {
        this.$router.push({
          path: "/course-package/info/" + item.goods.relation.course_package_id,
        });
      }
      if (item.goods.type == 2 && item.goods.relation.style_temp == 1) {
        this.$router.push({
          path:
            "/course/courseGroupList/" + item.goods.relation.course_package_id,
        });
      }
      if (item.goods.type == 3) {
        this.$router.push({
          path: "/camp/info/" + item.goods.relation.camp_id,
        });
      }
    },
        recommendModular() {
      let _this = this;
      recommendModular().then((res) => {
        console.log(res, "广告位");
        if (res.status == 200) {
          var arr = [];
          var list = res.result.data;
          if(list.length > 0){
            list.map((n)=>{
              if(n.about.length > 0){
                n.about.map((s)=>{
                  arr.push(s)
                })
              }
            })
          }
          console.log(arr,'arrrrr')
          _this.imgList = arr;
        }
      });
    },
    },
    created() {
      this.recommendModular();
      let _this = this;
      detail({ article_id: _this.$route.query.article_id }).then((res) => {
        console.log(res, "详情");
        // _this.imgList = res.result;
        _this.detail = res.result;
      });
    },
    activated() {
      let _this = this;
      detail({ article_id: _this.$route.query.article_id }).then((res) => {
        console.log(res, "详情");
        // _this.imgList = res.result;
        _this.detail = res.result;
      });
    },
  };
</script>
<style>
  .xun1_bei1_nr {
    margin-top: 10px;
  }
  .xun1_bei1_nr img{
    display: inline-block;
  }
</style>
