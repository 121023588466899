<template>
  <div>
    <SrNav></SrNav>
    <!-- 1-4我的错题集 -->
    <div class="warp clearfix mt50 kcxq-box kcxq-box_bj">
      <div class="index3_wdctj_title">
        <div class="index3_wdctj_title_l">
          <a :class="tabIndex == 0 ? 'index3_wdctj_on' : ''" @click="getTab(0)"
            >我的错题</a
          >
          <a :class="tabIndex == 1 ? 'index3_wdctj_on' : ''" @click="getTab(1)"
            >我的收藏</a
          >
          <a :class="tabIndex == 2 ? 'index3_wdctj_on' : ''" @click="getTab(2)"
            >练习历史</a
          >
        </div>
        <div class="index3_wdctj_title_r">
          <img src="@/assets/img/index3_di3.png" />
          <div class="index3_wdctj_mbx">
            <a
              >{{
                courseType == 1
                  ? "课程"
                  : courseType == 2
                  ? "课程包"
                  : courseType == 3
                  ? "训练营"
                  : courseType == 13
                  ? '模考'
                  : "电台"
              }}
              -
            </a>
            <a href="">{{ title }}</a>
          </div>
        </div>
      </div>
      <WrongQuestion v-if="tabIndex == 0" :type="courseType" :id="id"></WrongQuestion>
      <Collection v-if="tabIndex == 1" :type="courseType" :id="id"></Collection>
      <History v-if="tabIndex == 2" :type="courseType" :id="id"></History>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import Swiper from "@/assets/js/swiper.min.js";
import SrNav from "@/layout/SrNav";
import SrBanner from "@/layout/SrBanner";
import SrFooter from "@/layout/SrFooter";
import WrongQuestion from "./modules/wrongQuestion";
import Collection from "./modules/collection";
import History from "./modules/history";

export default {
  name: "index",
  components: {
    SrNav,
    SrBanner,
    SrFooter,
    WrongQuestion,
    Collection,
    History,
  },
  data() {
    return {
      tabIndex: 0,
      name: "",
      courseType: null,
      historyList:[]
    };
  },
  methods: {
    getTab(index) {
      this.tabIndex = index;
    },
  },
  created() {
    this.tabIndex = this.$route.query.tabIndex;
    this.title = this.$route.query.title;
    this.courseType = this.$route.query.courseType;
    this.id = this.$route.query.id;
    this.types = this.$route.query.types;
    console.log(this.title);
  },
};
</script>
<style scoped>
.index3_wdctj_title_r {
  width: auto;
}
</style>