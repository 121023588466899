<template>
    <div>
        <!-- 分类 -->
        <div class="warp">
            <CategoryList @change="categoryChange"></CategoryList>
            <div class="course-fl2 mt30">
                <a v-for="(item,index) in order_by"
                   :key="index"
                   @click="orderByChange(item)"
                   :class="[query.order_by===item.value?'active':'']">{{item.label}}</a>
            </div>
        </div>
        <!-- 课程列表 -->
        <div class="warp clearfix mt30">
            <a-spin :spinning="loading">
                <a-icon slot="indicator"
                        type="loading"
                        style="font-size: 30px"
                        spin/>
                <div v-if="loading&&list.length===0"
                     style="width: 100%;height: 200px">

                </div>
                <div v-else>
                    <a-empty v-if="list.length===0"
                             description="暂无数据"/>
                    <div v-else
                         v-for="item in list"
                         :index="item.radio_id"
                         class="dt-list fn_l ml0 sr-list-margin">
                        <a @click="toRadioInfo(item)">
                            <p class="dt-listImg">
                                <el-image
                                        style="width: 275px;height: 275px"
                                        :src="item.image.url"
                                        fit="contain"></el-image>
                                <span v-if="item.audition"
                                      class="dt-shiting"><img src="@/assets/img/DT-shiting.png"></span>
                            </p>
                            <p>{{item.name}}</p>
                            <p class="color-666" style="font-size:14px">播放量{{item.playback_volume}}次</p>
                        </a>
                    </div>
                </div>
            </a-spin>
        </div>
        <!-- 页码 -->
        <SrPaginate style="margin: 0 auto;text-align: center;margin-top: 50px"
                    :current="pagination.current_page"
                    :total="pagination.total"
                    :pageSize="pagination.page_size"
                    @change="changePage"></SrPaginate>
    </div>
</template>

<script>
    import CategoryList from './modules/CategoryList'
    import {getlist} from "@/api/radio/radio"
    import SrPaginate from '@/components/SrPaginate'

    export default {
        name: "index",
        components: {
            CategoryList,
            SrPaginate
        },
        data() {
            return {
                query: {
                    order_by: 'default'
                },
                pagination: {
                    page: 1,
                    page_size: 6
                },
                list: [],
                order_by: [
                    {
                        label: '默认排序',
                        value: 'default'
                    },
                    {
                        label: '播放更多',
                        value: 'playback_volume'
                    },
                    {
                        label: '最新更新',
                        value: 'created_at'
                    }
                ],
                loading: true
            }
        },
        methods: {
            categoryChange(item) {
                console.log(item,'itm')
                this.query.radio_category_id = item.radio_category_id
                this.getData()
            },
            changePage(page, page_size) {
                this.pagination.page = page
                this.getData()
            },
            getData() {
                var _self = this
                _self.loading = true
                getlist({...this.query, ...this.pagination}).then((res) => {
                    console.log(res,'电台')
                    _self.list = res.result.items
                    _self.loading = false
                    _self.pagination = res.result.pagination
                })
            },
            orderByChange(item) {
                this.query.order_by = item.value
                this.getData()
            },
            toRadioInfo(item) {
                this.$router.push({path: '/radio/info/' + item.radio_id})
            }
        }
    }
</script>

<style scoped>
    .sr-list-margin {
        margin: 0 20px;
    }
</style>
