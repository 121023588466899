<template>
  <div>
    <Pintuan
      v-if="radio.goods.notend_pintuan && radio.has_unlock == 0"
      :radio="radio"
    ></Pintuan>
    <div v-else class="warp clearfix mt50 kcxq-box">
      <div class="dtxq-l fn_l">
        <a-skeleton v-if="loading" :title="false" :paragraph="{ rows: 9 }" />
        <img v-else width="100%" :src="radio.image.url" />
        <span v-if="radio.audition === 1" class="dt-shiting"
          ><img src="@/assets/img/DT-shiting.png"
        /></span>
      </div>
      <div class="dtxq-r fn_r">
        <a-skeleton v-if="loading" :title="false" :paragraph="{ rows: 9 }" />
        <div v-else>
          <h3 class="mt10">{{ radio.name }}</h3>
          <p class="f14 color-666 mt20">
            <!-- 开课时间：
            {{ $moment(radio.start_at).format("YYYY.MM.DD") }}
            --
            {{ $moment(radio.end_at).format("YYYY.MM.DD") }} -->
            购买后有效期{{radio.valid_days}}天
            {{ radio.period }}
            课时
          </p>
          <p class="f12 color-main" :style="{ color: radio.subtitle_color }">
            {{ radio.subtitle }}
          </p>
          <p style="font-size:14px">
            <span class="color-999">
              限售{{ radio.goods.sales_restriction }}人 | 已报名{{
                radio.goods.sale_amount
              }}人
            </span>
            <!-- <img src="@/assets/img/KC-time.png" />
            <span class="color-pink">
              距结束：<a-statistic-countdown
                :value="radio.end_at | toTime"
                format="D 天 H 时 m 分 s 秒"
              />
            </span> -->
          </p>

          <p class="clearfix dt-teachlist mt20">
            <a v-for="item in radio.teacher" :key="item.id">
              <img
                style="border-radius: 50% 50%"
                :src="item.avatar.url"
              /><br />{{ item.name }}
            </a>
          </p>
          <p class="clearfix mt30">
            <span v-if="buttonStatus === 4" class="fn_l f26 color-main"
              >￥{{ radio.order_price }}
              <s class="color-999" style="font-size:14px"
                >￥{{ radio.goods.real_price }}</s
              >
            </span>
            <span v-else class="fn_l f26 color-main"
              >￥{{ radio.order_price }}
              <s class="color-999" style="font-size:14px"
                >￥{{ radio.goods.real_price }}</s
              >
              </span
            >
            <!--判断按钮状态-->
            <router-link
              v-if="buttonStatus === 1"
              class="fn_r bg-main color-fff"
              :to="{ path: '/radio/play', query: { radio_id: radio.radio_id } }"
              >进入课程
            </router-link>
            <a
              v-if="buttonStatus === 2"
              class="fn_r bg-999 border-none color-fff"
              href=""
              >报名结束</a
            >
            <a
              v-if="buttonStatus === 3"
              class="fn_r bg-999 border-none color-fff"
              href=""
              >人员已满</a
            >
            <!--判断秒杀状态-->
            <span v-if="buttonStatus === 4">
              <a
                v-if="radio.goods.notend_seckill.status === 1"
                class="fn_r bg-main color-fff f14 yx-msBut"
                href=""
              >
                <span>抢购即将开始</span><br />
                <span class="seckill-countdown">
                  <a-statistic-countdown
                    :value="radio.notend_seckill.start_at | toTime"
                    format="D 天 H 时 m 分 s 秒"
                    @finish="seckillStart"
                  />
                </span>
              </a>
              <a
                v-if="radio.goods.notend_seckill.status === 2"
                class="fn_r bg-main color-fff"
                @click="seckill"
                >立即秒杀</a
              >
            </span>
            <a
              v-if="buttonStatus === 5"
              class="fn_r bg-main color-fff"
              @click="buy"
              >立即购买</a
            >
            <SrButton
              v-if="buttonStatus === 5"
              @click="addCart"
              style="width: 130px; display: inline-block; float: right"
              height="42px"
              padding="0"
              font-size="14px"
              :loading="cart_btn_loading"
              type="hollow"
              >加入购物车
            </SrButton>
            <!--<a class="fn_r">加入购物车</a>-->
          </p>
        </div>
      </div>
    </div>
    <div class="warp clearfix mt30">
      <div class="kcxq-con fn_l">
        <SrTabs
          :list="['介绍', '大纲', '评价']"
          @change="
            (e) => {
              tag_index = e;
            }
          "
        ></SrTabs>

        <div class="tab-content">
          {{ radio.radio_chapter }}
          <RadioIntroduce
            v-if="tag_index === 0"
            :loading="loading"
            :radio="radio"
          ></RadioIntroduce>
          <CourseChapter
            v-if="tag_index === 1"
            :loading="loading"
            :radio_id="radio_id"
            :list="chapter_list"
          ></CourseChapter>
          <CommentList
            v-if="tag_index === 2"
            :radio_id="radio.radio_id"
          ></CommentList>
        </div>
      </div>
      <ReRadio></ReRadio>
    </div>
  </div>
</template>

<script>
import { info, chapterList } from "@/api/radio/radio";
import RadioIntroduce from "./modules/RadioIntroduce";
import CommentList from "./modules/CommentList";
import SrButton from "@/components/SrButton";
import Pintuan from "./modules/Pintuan";
import { add } from "@/api/cart";
import SrTabs from "@/components/SrTabs";
import ReRadio from "@/layout/recommend/ReRadio";
import CourseChapter from "./modules/CourseChapter";

export default {
  name: "index",
  components: {
    RadioIntroduce,
    CommentList,
    SrButton,
    Pintuan,
    SrTabs,
    ReRadio,
    CourseChapter,
  },
  data() {
    return {
      cart_btn_loading: false,
      loading: false,
      radio_id: 0,
      radio: {
        goods: {},
      },
      tag_index: 0,
      goods: {},
      chapter_list: [],
      invitation_user_id: null,
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  computed: {
    buttonStatus() {
      // 判断按钮状态 1已购买 2已过期 3已售完 4有秒杀 5可购买
      var radio = this.radio;
      if (radio.has_unlock !== 0) {
        return 1;
      }
      if (new Date(radio.end_at) < new Date()) {
        return 2;
      }
      if (radio.goods.sale_amount >= radio.goods.sales_restriction) {
        return 3;
      }
      if (radio.goods.notend_seckill) {
        return 4;
      }
      return 5;
    },
  },
  created() {
    var _self = this;
      if (document.body.clientWidth <= 1199) {
      window.location.href = "http://wap.srgongkaow.com/radio/detail?id=" +  this.$route.params.radio_id;
    }
    this.radio_id = this.$route.params.radio_id;
    this.getData();
    _self.invitation_user_id = this.$route.query.user_id;
  },
  methods: {
    getData() {
      var _self = this;
      _self.loading = true;
      var radio_id = this.radio_id;
      info({ radio_id: radio_id }).then((res) => {
        console.log(res, "-----详情");
        var data = res.result;
        if (data.goods.vip_price.length > 0) {
          data.goods.vip_price.map(function (n) {
            console.log(n, "会员价");
            if (_self.$store.state.user.now_user_member) {
              if (
                n.user_member_config_id ==
                _self.$store.state.user.now_user_member.user_member_config
                  .user_member_config_id
              ) {
                data.order_price = n.price;
              }
            } else {
              data.order_price = data.goods.order_price;
            }
          });
        } else {
          data.order_price = data.goods.order_price;
        }
        _self.radio = data;
        document.title = res.result.name + "-" + document.title;
        _self.loading = false;
      });
      chapterList({ radio_id: radio_id }).then((res) => {
        _self.chapter_list = res.result;
      });
    },
    buy() {
      let routeData = this.$router.resolve({
        path: "/buy/settlement",
        query: {
          goods_id: this.radio.goods.goods_id,
          invitation_user_id: this.invitation_user_id,
          sku_id: "",
          num: 1,
        },
      });
      window.open(routeData.href, "_blank");
    },
    seckill() {
      var seckill = this.radio.goods.notend_seckill;
      let routeData = this.$router.resolve({
        path: "/buy/seckill",
        query: {
          goods_id: seckill.goods.goods_id,
          seckill_id: seckill.seckill_id,
          sku_id: "",
          num: 1,
        },
      });
      window.open(routeData.href, "_blank");
    },
    addCart() {
      // 加入购物车
      var _self = this;
      _self.cart_btn_loading = true;
      var goods = _self.radio.goods;

      if (!goods) {
        return false;
      }
      add(goods).then((res) => {
        _self.cart_btn_loading = false;
        if (res.status === 200) {
          _self.$store.commit("ADD_CART", goods);
          _self.$message.success("添加成功");
        } else {
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}

.seckill-countdown >>> .ant-statistic {
  display: inline-block;
}

.seckill-countdown >>> .ant-statistic-content {
  display: inline-block;
  font-size: 14px;
}

.seckill-countdown >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #fff;
}
</style>
