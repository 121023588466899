import request from '@/utils/request'

export function getUserInfo(parameter) {
    return request({
        url: '/api/user/getUserInfo',
        method: 'post',
        data: parameter
    })
}

/**
 * 验证码登录
 * @param parameter
 */
export function mobileLogin(parameter) {
    return request({
        url: '/api/user/login/mobileLogin',
        method: 'post',
        data: parameter
    })
}

/**
 * 发送验证码
 * @param parameter
 */
export function sendMessage(parameter) {
    return request({
        url: '/api/user/login/sendMessage',
        method: 'post',
        data: parameter
    })
}

// 账号密码登录
export function login(parameter) {
    return request({
        url: '/api/user/login',
        method: 'post',
        data: parameter
    })
}

// 用户注册
export function register(parameter) {
    return request({
        url: '/api/user/register',
        method: 'post',
        data: parameter
    })
}
// 用户修改昵称
export function setNickname(parameter) {
    return request({
        url: '/api/user/setNickname',
        method: 'post',
        data: parameter
    })
}
// 用户修改头像
export function setHeadPortrait(parameter) {
    return request({
        url: '/api/user/setHeadPortrait',
        method: 'post',
        data: parameter
    })
}
// 修改手机号旧手机号验证码
export function checkModifyMessage(parameter) {
    return request({
        url: '/api/user/checkModifyMessage',
        method: 'post',
        data: parameter
    })
}
// 执行修改手机号
export function changePhone(parameter) {
    return request({
        url: '/api/user/changePhone',
        method: 'post',
        data: parameter
    })
}
// 修改密码 未设置密码
export function setUserPassword(parameter) {
    return request({
        url: '/api/user/setUserPassword',
        method: 'post',
        data: parameter
    })
}
// 修改密码 已设置密码
export function editUserPassword(parameter) {
    return request({
        url: '/api/user/editUserPassword',
        method: 'post',
        data: parameter
    })
}
// 设置支付密码
export function setPay(parameter) {
    return request({
        url: '/api/user/setPay',
        method: 'post',
        data: parameter
    })
}
// 获取本人收货地址列表
export function addressGetList(parameter) {
    return request({
        url: '/api/shipping_address/getlist',
        method: 'post',
        data: parameter
    })
}
// 添加收货地址
export function addAddress(parameter) {
    return request({
        url: '/api/shipping_address/add',
        method: 'post',
        data: parameter
    })
}
// 删除收货地址
export function delAddress(parameter) {
    return request({
        url: '/api/shipping_address/remove',
        method: 'post',
        data: parameter
    })
}
// 编辑收货地址
export function editAddress(parameter) {
    return request({
        url: '/api/shipping_address/edit',
        method: 'post',
        data: parameter
    })
}
// 获取会员等级列表
export function getUserMembers(parameter) {
    return request({
        url: '/api/user/getUserMembers',
        method: 'post',
        data: parameter
    })
}
// 用户积分统计
export function getUserCredit(parameter) {
    return request({
        url: '/api/user/getUserCredit',
        method: 'post',
        data: parameter
    })
}
// 用户购买会员
export function buyMember(parameter) {
    return request({
        url: '/api/user/buyMember',
        method: 'post',
        data: parameter
    })
}
// 用户会员购买订单
export function memberOrder(parameter) {
    return request({
        url: '/api/user/memberOrder',
        method: 'post',
        data: parameter
    })
}
// 用户钱包
export function wallet(parameter) {
    return request({
        url: '/api/user/wallet',
        method: 'post',
        data: parameter
    })
}
// 申请提现
export function walletCash(parameter) {
    return request({
        url: '/api/user/wallet/cash',
        method: 'post',
        data: parameter
    })
}
// 积分列表
export function credit(parameter) {
    return request({
        url: '/api/user/credit',
        method: 'post',
        data: parameter
    })
}
// 协议通用
export function userAbout(parameter) {
    return request({
        url: '/api/user/about',
        method: 'post',
        data: parameter
    })
}
// 用户订单列表
export function order(parameter) {
    return request({
        url: '/api/user/order',
        method: 'post',
        data: parameter
    })
}
// 订单详情
export function orderDetail(parameter) {
    return request({
        url: '/api/user/order/detail',
        method: 'post',
        data: parameter
    })
}
// 取消订单
export function orderCancel(parameter) {
    return request({
        url: '/api/user/order/cancel',
        method: 'post',
        data: parameter
    })
}
// 查快递
export function courier(parameter) {
    return request({
        url: '/api/user/order/courier',
        method: 'post',
        data: parameter
    })
}
// 申请退款
export function refund(parameter) {
    return request({
        url: '/api/user/refund',
        method: 'post',
        data: parameter
    })
}
// 最近学习
export function recentList(parameter) {
    return request({
        url: '/api/user/recentList',
        method: 'post',
        data: parameter
    })
}
// 我的网校
export function myCourse(parameter) {
    return request({
        url: '/api/user/course',
        method: 'post',
        data: parameter
    })
}
// 我的训练营
export function myCamp(parameter) {
    return request({
        url: '/api/user/camp',
        method: 'post',
        data: parameter
    })
}
// 我的电台
export function myRadio(parameter) {
    return request({
        url: '/api/user/radio',
        method: 'post',
        data: parameter
    })
}
// 消息列表
export function messageList(parameter) {
    return request({
        url: '/api/user/message',
        method: 'post',
        data: parameter
    })
}
// 消息详情
export function messageDetail(parameter) {
    return request({
        url: '/api/user/messageDetail',
        method: 'post',
        data: parameter
    })
}
// 我的日历
export function calendar(parameter) {
    return request({
        url: '/api/user/calendar',
        method: 'post',
        data: parameter
    })
}
// 课程提醒
export function classesRemind(parameter) {
    return request({
        url: '/api/user/classes_remind',
        method: 'post',
        data: parameter
    })
}
// 课程推荐
export function homeRemind(parameter) {
    return request({
        url: '/api/user/home_remind',
        method: 'post',
        data: parameter
    })
}
// 社会化登陆验证
export function socialiteValidation(parameter) {
    return request({
        url: '/api/user/login/socialiteValidation',
        method: 'post',
        data: parameter
    })
}
// 社会化登陆
export function socialiteLogin(parameter) {
    return request({
        url: '/api/user/login/socialiteLogin',
        method: 'post',
        data: parameter
    })
}

// 账单列表
export function bill(parameter) {
    return request({
        url: '/api/user/bill',
        method: 'post',
        data: parameter
    })
}
// 统计邀请人数、当前余额、当前积分、累计会员优惠、累计分销赚钱、累计提现金额
export function statistical(parameter) {
    return request({
        url: '/api/user/share/statistical',
        method: 'post',
        data: parameter
    })
}
// 赚钱达人榜
export function ranking(parameter) {
    return request({
        url: '/api/user/share/ranking',
        method: 'post',
        data: parameter
    })
}
// 分享课程列表
export function share(parameter) {
    return request({
        url: '/api/user/share',
        method: 'post',
        data: parameter
    })
}
// 用户邀请列表
export function invitation(parameter) {
    return request({
        url: '/api/user/share/invitation',
        method: 'post',
        data: parameter
    })
}
// 用户分享佣金
export function commission(parameter) {
    return request({
        url: '/api/user/share/commission',
        method: 'post',
        data: parameter
    })
}
// 我的优惠券
export function myCoupon(parameter) {
    return request({
        url: '/api/user/coupon/list',
        method: 'post',
        data: parameter
    })
}
// 验证码修改密码
export function codePassword(parameter) {
    return request({
        url: '/api/user/login/codePassword',
        method: 'post',
        data: parameter
    })
}
// 我的收藏
export function community_collection(parameter) {
    return request({
        url: '/api/user/community_collection',
        method: 'post',
        data: parameter
    })
}
export function mylist(parameter) {
    return request({
        url: '/api/user/mylist',
        method: 'post',
        data: parameter
    })
}