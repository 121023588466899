<template>
  <div>
    <SrNav></SrNav>
    <SrBanner></SrBanner>
    <div class="warp clearfix mt80">
      <div class="index-videoBox fn_l">
        <div class="index-video">
          <video :src="video_url" style="width: 100%" controls></video>
          <!--<img src="@/assets/img/index-video.jpg"/>-->
        </div>
        <div class="index-videoTex" v-html="srintr"></div>
      </div>
      <div class="index-planBox fn_r">
        <div class="index-planT clearfix">
          <h3 class="fn_l">考试计划</h3>
          <router-link to="/new/planList">
            <a class="fn_r">更多 &gt;</a>
          </router-link>
        </div>
        <div class="index-planM">
          <ul>
            <li v-for="i in KaoShiList" @click="goDetail(i.article_id, 1)">
              <a>
                <h3>{{ i.name }}</h3>
                <p>开始报名时间：{{ i.startTime }}至{{ i.endTime }}</p>
                <em v-if="i.days >= 0"
                  ><img src="@/assets/img/index-countdown.png" />仅剩{{
                    i.days == 0?1:i.days
                  }}天</em
                >
                <em v-else
                  ><img src="@/assets/img/index-countdown.png" />已过期</em
                >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mk" v-if="mkBanner.length > 0">
      <img :src="mkBanner[0].image.url" @click="openMk" alt="" />
    </div>
    <div class="index-teacherBg">
      <div class="index-teacherT te_c">
        <h3>名师专栏</h3>
        <p>圣儒教育拥有丰富教辅经验，针对考试命题特点和考生需求</p>
        <em></em>
      </div>
      <div class="warp mt30">
        <div class="swiper-container index-teacher">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in teacher_list"
              class="swiper-slide index-teacherBox"
            >
              <div class="index-teacherImg">
                <img :src="item.avatar.url" />
              </div>
              <div class="index-teacherTex">
                <div class="index-teacherTex-t">
                  <h3>{{ item.name }}</h3>
                  <p>{{ item.label }}</p>
                </div>
                <div class="index-teacherTex-m">
                  <p v-html="item.introduce"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-nexts">
          <img src="@/assets/img/index-Rbut.png" />
        </div>
        <div class="swiper-button-prevs">
          <img src="@/assets/img/index-Lbut.png" />
        </div>
      </div>
      <div class="index-teacherMore te_c mt30">
        <router-link to="teacher">查看更多</router-link>
      </div>
    </div>
    <!-- <div class="warp clearfix">
      <div class="index-bkBox fn_l">
        <div class="index-bkT">
          <h3 class="fn_l">备考资料</h3>
          <router-link to="/new/test">
            <a class="fn_r">更多 &gt;</a>
          </router-link>
        </div>
        <div class="card-fouth">
          <div class="desc desc-list">
            <div
              :class="
                typeIndex == index ? 'industry first active' : 'industry first'
              "
              v-for="(i, index) in typeList"
              @click="getTab(i.article_type_id, index)"
            >
              <span>{{ i.name }}</span>
            </div>
          </div>
          <div class="case-container">
            <div class="case-panel tab-1 active">
              <dl
                class="clearfix"
                v-for="i in BeiKaoList"
                v-if="BeiKaoList.length > 0"
                @click="goDetail(i.article_id, 1)"
              >
                <a>
                  <dt class="fn_l">
                    <img :src="i.image.url" />
                  </dt>
                  <dd class="fn_r">
                    <h3>{{ i.name }}</h3>
                    <div
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        height: 82px;
                      "
                    >
                      <p v-html="i.content_remove_html"></p>
                    </div>
                  </dd>
                </a>
              </dl>
              <div class="zanwu" v-if="BeiKaoList.length == 0">
                <img src="@/assets/img/zanwu.png" alt="" />
                暂无数据
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-yqBox fn_r">
        <div class="index-bkT">
          <h3>友情链接</h3>
        </div>
        <div class="index-bkImg clearfix">
          <a v-for="item in link_list" target="_blank" :href="item.url"
            ><img :src="item.image.url"
          /></a>
        </div>
        <div class="index-bkEwm clearfix">
          <p>
            <img :src="$store.state.common.config[23].image.url" />圣儒APP下载
          </p>
          <p><img :src="$store.state.common.config[12].image.url" />联系客服</p>
          <p style="float: right; width: 36%">
            <span class="color-main">客服电话</span><br />
            <span>{{ $store.state.common.config[24].text | RemoveHtml }}</span
            ><br />
            <span class="color-main">客服时间</span><br />
            <span>{{ $store.state.common.config[25].text | RemoveHtml }}</span>
          </p>
        </div>
      </div>
    </div> -->
    <SrFooter></SrFooter>
  </div>
</template>

<script>
import Swiper from "@/assets/js/swiper.min.js";
import SrNav from "@/layout/SrNav";
import SrBanner from "@/layout/SrBanner";
import SrFooter from "@/layout/SrFooter";
import {
  article,
  newType,
  article2,
  teacher,
  link,
  pcVideo,
} from "@/api/index";
import { userAbout } from "@/api/user";
import { banners } from "@/api/common";
export default {
  name: "index",
  components: {
    SrNav,
    SrBanner,
    SrFooter,
  },
  data() {
    return {
      KaoShiList: [],
      article_type_id: "",
      BeiKaoList: [],
      typeList: [],
      typeIndex: 0,
      teacher_list: [],
      link_list: [],
      video_url: "",
      srintr: "",
      mkBanner:[]
    };
  },
  mounted() {
    var _self = this;
    pcVideo().then((res) => {
      _self.video_url = res.result.video.url;
    });
    userAbout({ id: 18 }).then((res) => {
      _self.srintr = res.result.text;
    });
    // document
    //   .querySelector("body")
    //   .setAttribute("style", "background-color:transparent!important");
  },
  filters: {
    RemoveHtml(s) {
      var str = s.replace(/<\/?.+?>/g, "");
      return str.replace(/ /g, ""); //dds为得到后的内容
    },
  },
  methods: {
    // 获取备考资料
    loadBeiKao() {
      let _this = this;
      article({
        page: 1,
        page_size: 2,
        type: 2,
        article_type_id: _this.article_type_id,
      }).then((res) => {
        console.log(res, "备考资料");
        _this.BeiKaoList = res.result.items;
      });
    },
    loadKaoShi() {
      let _this = this;
      article2({ page: 1, page_size: 5, type: 1, article_type_id: "" }).then(
        (res) => {
          // console.log(res, "考试");
          var data = res.result.items;
          var time = Date.parse(new Date());
          data.map(function (n) {
            n.startTime = n.start_at.substring(0, 10);
            n.endTime = n.end_at.substring(0, 10);
            var endTime = Date.parse(new Date(n.end_at));
            n.days = _this.intervalTime(time / 1000, endTime / 1000);
          });
          _this.KaoShiList = data;
          console.log(_this.KaoShiList, "------------");
        }
      );
    },
    loadTeacher() {
      let _this = this;
      teacher({ page: 1, page_size: 100 }).then((res) => {
        _this.teacher_list = res.result.items;
        setTimeout(() => {
          var swiper = new Swiper(".index-teacher", {
            slidesPerView: 5,
            spaceBetween: 30,
            slidesPerGroup: 5,
            loop: true,
            loopFillGroupWithBlank: true,
            navigation: {
              nextEl: ".swiper-button-nexts",
              prevEl: ".swiper-button-prevs",
            },
          });
        }, 500);
      });
    },
    loadLink() {
      let _this = this;
      link({ page: 1, page_size: 6 }).then((res) => {
        _this.link_list = res.result;
      });
    },
    // 获取当前时间
    intervalTime(startTime, endTime) {
      // var timestamp=new Date().getTime(); //计算当前时间戳
      var timestamp = Date.parse(new Date()) / 1000; //计算当前时间戳 (毫秒级)
      var date1 = ""; //开始时间
      if (timestamp < startTime) {
        date1 = startTime;
      } else {
        date1 = timestamp; //开始时间
      }
      var date2 = endTime; //结束时间
      // var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
      var date3 = (date2 - date1) * 1000; //时间差的毫秒数
      //计算出相差天数
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数

      var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));

      //计算相差秒数

      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      //console.log(days);
      // return   days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒"
      return days;
    },
    loadType() {
      let _this = this;
      newType().then((res) => {
        //console.log(res, "分类");
        var data = res.result;
        _this.typeList = data;
        //console.log(_this.typeList, "32323232");
      });
    },
    getTab(id, index) {
      this.article_type_id = id;
      this.typeIndex = index;
      this.loadBeiKao();
    },
    goDetail(id, type) {
      this.$router.push({
        path: "/new/detail",
        query: { article_id: id, type: type },
      });
    },
    openMk() {
      this.$router.push({
        path: "/exam",
      });
    },
    // 模考banner
    loadBanner() {
      let _this = this;
      console.log("模考");
      banners({type:5,site:2}).then((res) => {
        console.log(res,'res')
        _this.mkBanner = res.result
      });
    },
  },
  created() {
    if (document.body.clientWidth <= 800) {
      window.location.href = "http://wap.srgongkaow.com";
    }
    this.loadBeiKao();
    this.loadKaoShi();
    this.loadType();
    this.loadTeacher();
    this.loadLink();
    this.loadBanner();
  },
};
</script>

<style scoped>
@import "../assets/css/swiper.min.css";

.index-teacherT {
  color: #3561c7 !important;
  padding-bottom: 20px;
  position: relative;
}

.index-teacherBox {
  height: 330px !important;
  border-radius: 40px 0px 8px 8px;
  background: none;
  position: relative;
}

.index-teacherBox img {
  height: 230px;
  width: 100%;
}

.index-teacherBox:hover .index-teacherTex {
  height: 100% !important;
  background: #3561c7;
  color: #ffffff;
  box-shadow: 1px 1px 10px rgba(53, 97, 199, 0.3);
  transition: all 0.6s;
}
.index-teacherTex {
  position: absolute;
  height: 160px;
  bottom: 0;
  left: 0;
  border-radius: 40px 0px 8px 8px;
  background: #ffffff;
  padding: 20px 30px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.6s;
  overflow: hidden;
  width: 100%;
}

.index-planT {
  padding-bottom: 10px;
  height: auto !important;
  line-height: 25px;
  border-bottom: 1px #e4e2e2 solid;
  position: relative;
}

.card-fouth .industry {
  font-size: 16px;
  display: inline-block;
  width: 100px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 15px 0 15px 0;
  margin-top: 8px;
  cursor: pointer;
}
.card-fouth .case-panel dl {
  margin-top: 10px;
}
.card-fouth .case-panel dl dt img {
  width: 100%;
  height: 130px;
}

.zanwu {
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #666666;
}

.zanwu img {
  width: 200px;
  margin: 0 auto;
}

.index-bkImg a {
  display: flex;
  align-content: center;
}

.index-bkImg a img {
  width: 100%;
}

.swiper-button-prevs {
  width: 40px;
  height: 40px;
}

.swiper-button-nexts {
  width: 40px;
  height: 40px;
}

.index-teacherTex-m p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /*超出3行部分显示省略号，去掉该属性 显示全部*/
  -webkit-box-orient: vertical;
}

.warp {
  position: relative;
}

.swiper-button-nexts {
  position: absolute;
  right: -72px;
  cursor: pointer;
  top: 44%;
}

.swiper-button-prevs {
  position: absolute;
  left: -72px;
  cursor: pointer;
  top: 44%;
}
.mk {
  margin-top: 50px;
  cursor: pointer;
}
.mk img {
  margin: 0 auto;
}
</style>
