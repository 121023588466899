<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的订单-订单详情</h6>
      </div>
      <div class="per8_ddxq">
        <div class="per8_ddxq_tit">
          <p><span>订单号:</span>{{ detail.num }}</p>
          <p class="ddxq_on">
            <span>状态:</span
            >{{
              detail.status == 1
                ? "未支付"
                : detail.status == 2
                ? "已支付"
                : detail.status == 3
                ? "已过期"
                : detail.status == 4
                ? "已取消"
                : detail.status == 5
                ? "已退款"
                : detail.status == 6
                ? "退款审核中"
                : "拒绝退款"
            }}
          </p>
          <p><span> 下单日期:</span>{{ detail.created_at }}</p>
        </div>
        <div class="per8_ddzz" v-if="detail.courier_number">
          <div class="per8_ddzz_tit">
            <p>订单追踪</p>
          </div>
          <div class="per8_ddzz_nr">
            <div class="package-status">
              <div class="status-box" id="status_list">
                <ul id="J_listtext2" class="status-list">
                  <li v-for="i in courierList">
                    <span class="date">{{ i.time }}</span>
                    <span class="text">{{ i.status }}</span>
                  </li>
                </ul>
              </div>

              <div></div>
            </div>
          </div>
          <div class="per8_recipient">
            <div class="recipient_tit">
              <p>收件人信息</p>
            </div>
            <div class="recipient_nr">
              <p><span>收货人:</span>{{ detail.order_shipping.name }}</p>
              <p>
                <span>地&nbsp;&nbsp;&nbsp;址:</span
                >{{ detail.order_shipping.province.name
                }}{{ detail.order_shipping.city.name
                }}{{ detail.order_shipping.district.name
                }}{{ detail.order_shipping.address }}
              </p>
              <p><span>手机号:</span>{{ detail.order_shipping.mobile }}</p>
            </div>
          </div>
        </div>
        <div class="per8_listing">
          <div class="per8_listing_tit">
            <p>商品清单</p>
          </div>
          <div class="per8_listing_nr">
            <table
              border="0"
              cellspacing="0"
              cellpadding="0"
              class="form form1"
            >
              <tr>
                <th>类型</th>
                <th>商品信息</th>
                <th>价格</th>
                <th>操作</th>
              </tr>
              <tr v-for="i in detail.order_item">
                <td>课程</td>
                <td>
                  <div class="form1_list">
                    <!-- <img src="@/assets/img/form1.jpg" /> -->
                    <div class="form1_wen">
                      <p @click="goDetail(i.type,i.relation_id)">{{ i.goods.name }}</p>
                      <!-- <span>2020.10.10 - 2020.10.10</span> -->
                    </div>
                  </div>
                </td>
                <td class="red">￥{{ i.goods.order_price }}</td>
                <td>
                  <div
                    class="form1_list_a"
                    v-if="detail.status == 2 && i.agreement_class == 1"
                  >
                    <router-link
                      :to="{
                        path: '/user/center/orderRefund',
                        query: {
                          order_id: $route.query.id,
                          order_item_id: i.order_item_id,
                        },
                      }"
                      ><a>退款申请</a></router-link
                    >
                  </div>
                  <div v-else>--</div>
                </td>
              </tr>
              <!-- <tr>
                <td>协议班</td>
                <td>
                  <div class="form1_list">
                    <img src="@/assets/img/form1.jpg" />
                    <div class="form1_wen">
                      <p>2021公务员课程包</p>
                      <span>2020.10.10 - 2020.10.10</span>
                    </div>
                  </div>
                </td>
                <td class="red">￥99.00</td>
                <td>--</td>
              </tr> -->
            </table>
          </div>
        </div>

        <div class="per8_yhq1">
          <div class="per8_yhq" v-if="detail.status != 1">
            <p>
              优惠券:<span>￥{{ detail.coupon_deduction }}</span>
            </p>
            <p>
              积分：<span>￥{{ detail.credit }}</span>
            </p>
            <p>
              积分抵扣:<span>￥{{ detail.credit_deduction }}</span>
            </p>
          </div>
          <div class="yhq_list">
            <p>
              支付金额:<span>￥{{ detail.pay_price }}</span>
            </p>
          </div>
          <div class="yhq_list yhq_list1">
            <a v-if="detail.status == 1" @click="goPay()">去支付</a>
            <a href="" v-if="detail.status == 1">取消订单</a>
          </div>
        </div>
      </div>
    </div>
    <div class="motal" v-show="showMotal">
      <div class="motal_box">
        <div class="motal_title">请选择支付方式</div>
        <div class="motal_input_box">
          <div class="pay_bottom">
            <div class="pay_bo_l">
              <div class="pay_wechat">
                <img src="@/assets/img/wechat1.png" />
                <p>微信支付</p>
              </div>
              <div class="pay_wechat">
                <img src="@/assets/img/wechat2.png" />
                <p>支付宝支付</p>
              </div>
            </div>
            <div class="pay_bo_r">
              <div class="pay_1">
                <div class="radio">
                  <input
                    type="radio"
                    id="radio1"
                    value="2"
                    v-model="payType"
                    name="pay"
                  />
                  <label for="radio1"></label>
                </div>
                <div class="radio radio1">
                  <input
                    type="radio"
                    id="radio2"
                    value="1"
                    v-model="payType"
                    name="pay"
                  />
                  <label for="radio2"></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SrButton
          style="width: 250px; margin: 0 auto; margin-top: 20px"
          @click="orderPay"
          font-size="14px"
          height="40px"
          padding="0 20px"
          :loading="pay_loading"
        >
          立即支付
        </SrButton>
      </div>
      <img
        class="motal_close"
        @click="closeMotal"
        src="@/assets/img/modal-close.png"
        alt=""
      />
    </div>
    <WechatScan ref="WechatScan"></WechatScan>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import { orderDetail, courier } from "@/api/user";
import { web } from "@/api/pay";
import WechatScan from "../../buy/settlement/modules/WechatScan";
export default {
  name: "MyOrderDetail",
  components: {
    SrButton,
    WechatScan,
  },
  data() {
    return {
      detail: {},
      payType: "",
      pay_loading: false,
      showMotal: false,
      courierList: [],
    };
  },
  methods: {
    loadDetail() {
      let _this = this;
      orderDetail({ order_id: _this.$route.query.id }).then((res) => {
        console.log(res, "详情");
        _this.detail = res.result;
        if (_this.detail.courier_number) {
          courier({ courier_number: _this.detail.courier_number }).then(
            (res) => {
              console.log(res, "查快递");
              _this.courierList = res.result.result.list;
            }
          );
        }
      });
    },
    goPay(id) {
      this.showMotal = true;
    },
    closeMotal() {
      this.showMotal = false;
    },
    orderPay() {
      let _this = this;
      if (_this.payType == "") {
        _this.$message.warning("请选择支付方式");
      } else {
        _this.pay_loading = true;
        _this.showMotal = false;
        web({ order_id: _this.$route.query.id, pay_type: _this.payType }).then(
          (res) => {
            if (res.status == 200) {
              if (_this.payType == 1) {
                document.querySelector("body").innerHTML = res.result;
                document.forms[0].submit();
              } else {
                _this.$refs.WechatScan.show(res.result);
              }
            } else {
              _this.$message.warning(res.msg);
              _this.pay_loading = false;
            }
          }
        );
      }
    },
    goDetail(type,id){
         if (type === 1) {
                    this.$router.push({path: '/course/info/' + id})
                } else if (type === 2) {
                    this.$router.push({path: '/course-package/info/' + id})
                } else if (type === 3) {
                    this.$router.push({path: '/radio/info/' + id})
                } else if (type === 4) {
                    this.$router.push({path: '/camp/info/' + id})
                } else {

                }
    }
  },
  created() {
    this.loadDetail();
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
.yhq_list a {
  float: right;
  margin-left: 20px;
}
.motal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  top: 0;
  left: 0;
}
.motal_box {
  width: 800px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 120px;
  padding: 30px;
  overflow: hidden;
}
.motal_title {
  font-size: 20px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
}
.motal_input_box {
  margin: 0 auto;
  width: 700px;
  overflow: hidden;
}
.motal_input {
  width: 100%;
  margin-bottom: 20px;
}
.motal_input p {
  display: inline-block;
  font-size: 16px;
  width: 100px;
}
.motal_input span {
  color: red;
  padding-right: 10px;
}
.motal_input input {
  display: inline-block;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  width: calc(100% - 100px);
}
.motal_input select {
  width: calc(100% / 3 - 40px);
  display: inline-block;
  border: 1px solid #eaeaea;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-radius: 4px;
  margin-right: 10px;
}
.motal_input select:last-child {
  margin-right: 0;
}

input[type="checkbox"] {
  outline: none;
  -webkit-appearance: none;
  position: relative;
  vertical-align: middle;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  min-height: 20px;
  min-width: 20px;
}
input[type="checkbox"]:checked {
  border: 1px solid #eaeaea;
  outline: none;
  background: #3561c7;
}
input[type="checkbox"]:checked::after {
  content: "\00a0";
  display: inline-block;
  border: 2px solid #fff;
  border-top-width: 0;
  border-right-width: 0;
  width: 16px;
  height: 8px;
  -webkit-transform: rotate(-50deg);
  position: absolute;
  top: 2px;
  left: 1px;
}
.motal_check span {
  margin-left: 10px;
  margin-top: 10px;
}
.form1_wen p{
  cursor: pointer;
}
</style>