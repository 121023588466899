<template>
    <div id="box">
        <a-collapse v-for="item in list"
                    :key="item.radio_chapter_id"
                    v-model="activeKey"
                    expand-icon-position="right">
            <a-collapse-panel :key="item.radio_chapter_id+''"
                              :header="item.name">
                <dl v-for="subitem in item.radio_subchapter"
                    class="kc-dg clearfix mt20">
                    <dt class="color-999">{{subitem.number}}</dt>
                    <dd>
                        <h3 :style="subitem.isPlay && subitem.type !=5?'color:red;':''">{{subitem.name}}</h3>
                        <a-progress
                                v-if="subitem.type!==1 && subitem.baijia_video"
                                :percent="subitem.baijia_video.user_progress"
                                :format="(percent)=>'已观看'+percent+'%'"
                                strokeColor="#eb98c2"
                                :strokeWidth="3"/>
                        <router-link v-if="subitem.type == 5"
                                     :to="{path:'/exam/examination',query:{
                                         mock_id:subitem.radio_subchapter_id,
                                         type:103
                                     }}">
                            去做题
                        </router-link>
                        <router-link v-else target="_blank"
                                     :to="{path:'/radio/play?radio_id='+subitem.radio_id+'&radio_subchapter_id='+subitem.radio_subchapter_id}">
                            {{subitem.isPlay?'播放中':"音频"}}
                        </router-link>
                    </dd>
                </dl>
            </a-collapse-panel>
        </a-collapse>
    </div>
</template>

<script>
    import {Collapse} from 'ant-design-vue'

    export default {
        name: "CourseChapter",
        components: {
            'a-collapse': Collapse,
            'a-collapse-panel': Collapse.Panel
        },
        props: {
            list: {}
        },
        data() {
            return {
                activeKey: 0
            }
        }
    }
</script>

<style scoped>
    #box >>> .ant-collapse-header {
        background: #fff;
        padding-left: 65px;
        position: relative;
        background-image: url("~@/assets/img/KC-tbg.png");
        background-repeat: no-repeat;
        background-position: 20px 23px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #eaeaea;
        border-radius: 0;
        line-height: 40px;
    }

    #box >>> .ant-collapse-content {
        border-top: 0;
    }

    #box >>> .ant-collapse {
        border: none;
    }

    #box >>> .ant-collapse-item {
        border-bottom: none;
    }
</style>
