import request from '@/utils/request'

/**
 * 订单支付
 * @param parameter
 */
export function web(parameter) {
    return request({
        url: '/api/pay/web',
        method: 'post',
        data: parameter
    })
}
