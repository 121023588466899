<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>最近学习</h6>
      </div>
      <div class="per2_study_nr per2_study_nr1">
        <div class="study">
          <h6 class="study_h6">今日</h6>
          <div class="study_list study_list1">
            <ul v-if="today.length > 0">
              <li
                v-if="today.length > 0"
                v-for="i in today"
                @click="goDetail(i.relation_id)"
              >
                <a>
                  <img :src="i.relation.baijia_video.preface_url" />
                  <div class="study_wen">
                    <h6>{{ i.relation.name }}</h6>
                    <span>时长：{{ i.videoTime }}</span>
                    <p>观看进度{{ i.relation.baijia_video.user_progress }}%</p>
                  </div>
                </a>
              </li>
            </ul>
            <div class="zanwu" v-if="today.length == 0">
              <!-- <img src="@/assets/img/zanwu.png" alt="" /> -->
              暂无数据
            </div>
          </div>
        </div>
        <div class="study">
          <h6 class="study_h6">一周</h6>
          <div class="study_list study_list1">
            <ul>
              <li
                v-if="week.length > 0"
                v-for="i in week"
                @click="goDetail(i.relation_id)"
              >
                <a>
                  <img :src="i.relation.baijia_video.preface_url" />
                  <div class="study_wen">
                    <h6>{{ i.relation.name }}</h6>
                    <span>时长：{{ i.videoTime }}</span>
                    <p>观看进度{{ i.relation.baijia_video.user_progress }}%</p>
                  </div>
                </a>
              </li>
            </ul>
            <div class="zanwu" v-if="week.length == 0">
              <!-- <img src="@/assets/img/zanwu.png" alt="" /> -->
              暂无数据
            </div>
          </div>
        </div>
        <div class="study">
          <h6 class="study_h6">更早</h6>
          <div class="study_list study_list1">
            <ul>
              <li
                v-if="old.length > 0"
                v-for="i in week"
                @click="goDetail(i.relation_id)"
              >
                <a>
                  <img :src="i.relation.baijia_video.preface_url" />
                  <div class="study_wen">
                    <h6>{{ i.relation.name }}</h6>
                    <span>时长：{{ i.videoTime }}</span>
                    <p>观看进度{{ i.relation.baijia_video.user_progress }}%</p>
                  </div>
                </a>
              </li>
            </ul>
            <div class="zanwu" v-if="old.length == 0">
              <!-- <img src="@/assets/img/zanwu.png" alt="" /> -->
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <!-- 页码 -->
    </div>
  </div>
</template>
<script>
import { recentList } from "@/api/user";
export default {
  name: "RecentLearning",
  data() {
    return {
      old: [],
      today: [],
      week: [],
    };
  },
  methods: {
    loadList() {
      let _this = this;
      recentList().then((res) => {
        res.result.old.map(function(s){
          if(s.relation){
            _this.old.push(s)
          }
        })
        _this.old.map(function (n) {
          if (n.relation) {
            var seconds = parseInt(n.relation.baijia_video.length);
            n.videoTime = _this.formatSeconds(seconds);
          }
        });
        
        res.result.today.map(function(s){
           if(s.relation){
            _this.today.push(s)
          }
        })
        _this.today.map(function (n) {
          if (n.relation) {
            var seconds = parseInt(n.relation.baijia_video.length);
            n.videoTime = _this.formatSeconds(seconds);
          }
        });
        res.result.week.map(function(s){
          if(s.relation){
            _this.week.push(s)
          }
        })
        _this.week.map(function (n) {
          if (n.relation) {
            var seconds = parseInt(n.relation.baijia_video.length);
            n.videoTime = _this.formatSeconds(seconds);
          }
        });
      });
    },
    goDetail(id) {
      this.$router.push({
        path: "/course/video",
        query: { course_subchapter_id: id },
      });
    },
    // 秒换算分钟
    formatSeconds(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + parseInt(secondTime) + "秒";

      if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
      }
      if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
      }
      return result;
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
.zanwu {
  margin: 40px auto;
  text-align: center;
}
.zanwu img {
  width: 200px;
  margin: 0 auto;
}
</style>