import request from '@/utils/request'

/**
 * 获取团组列表
 * @param parameter
 */
export function groupList(parameter) {
    return request({
        url: '/api/pintuanGroup/list',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取团组成员列表
 * @param parameter
 */
export function userList(parameter) {
    return request({
        url: '/api/pintuanUser/list',
        method: 'post',
        data: parameter
    })
}
