import { render, staticRenderFns } from "./SrMessage.vue?vue&type=template&id=492fd262&scoped=true&"
import script from "./SrMessage.vue?vue&type=script&lang=js&"
export * from "./SrMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492fd262",
  null
  
)

export default component.exports