import request from '@/utils/request'

export function list(parameter) {
    return request({
        url: '/api/school/course_type_item/list',
        method: 'post',
        data: parameter
    })
}
export function recommend_special(parameter) {
    return request({
        url: '/api/school/course_category/recommend_special',
        method: 'post',
        data: parameter
    })
}