<template>
    <div id="box">
        <a-empty description="暂无数据"
                 v-if="list.length===0"/>
        <a-collapse v-else
                    v-for="item in list"
                    :activeKey="activeKey"
                    :key="item.course_chapter_id"
                    expand-icon-position="right">
            <a-collapse-panel :key="item.course_chapter_id+''"
                              :header="item.name">
                <dl v-for="subitem in item.course_subchapter"
                    class="kc-dg clearfix mt20">
                    <dt class="color-999">{{subitem.number}}</dt>
                    <dd>
                        <h3>{{subitem.name}}</h3>
                        <p class="f14 color-999">
                            <span> {{subitem.teacher?subitem.teacher.name:''}} </span>
                            <span v-if="subitem.type===1">{{subitem.start_at}}</span>
                        </p>
                        <div id="progress">
                            <a-progress
                                    v-if="subitem.type!==1"
                                    :percent="subitem.baijia_video.user_progress"
                                    :format="(percent)=>'已学习'+percent+'%'"
                                    strokeColor="#eb98c2"
                                    :strokeWidth="3"/>
                        </div>
                        <router-link v-if="subitem.type===1&&subitem.class_status<2"
                                     target="_blank"
                                     :to="{path:'/course/'+item.course_id+'/subchapter/'+subitem.course_subchapter_id}">
                            直播
                        </router-link>
                        <router-link v-if="subitem.type===1&&subitem.class_status===2"
                                     target="_blank"
                                     :to="{path:'/course/'+item.course_id+'/subchapter/'+subitem.course_subchapter_id}">
                            回放
                        </router-link>
                        <router-link v-if="subitem.type===2"
                                     target="_blank"
                                     :to="{path:'/course/'+item.course_id+'/subchapter/'+subitem.course_subchapter_id}">
                            录播
                        </router-link>
                        <router-link v-if="subitem.type===3"
                                     target="_blank"
                                     :to="{path:'/course/'+item.course_id+'/subchapter/'+subitem.course_subchapter_id}">
                            音频
                        </router-link>
                    </dd>
                </dl>
            </a-collapse-panel>
        </a-collapse>
    </div>
</template>

<script>
    import {Collapse} from 'ant-design-vue'

    export default {
        name: "CourseChapter",
        components: {
            'a-collapse': Collapse,
            'a-collapse-panel': Collapse.Panel
        },
        props: {
            list: {
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                activeKey: 0
            }
        },
        mounted() {
            if (this.list.length !== 0) {
                this.activeKey = this.list[0].course_chapter_id + ''
            }
        },
        watch: {
            list(value) {
                if (value.length !== 0) {
                    this.activeKey = this.list[0].course_chapter_id + ''
                }
            }
        }
    }
</script>

<style scoped>
    #box >>> .ant-collapse-header {
        background: #fff;
        padding-left: 40px;
        position: relative;
        background-image: url("~@/assets/img/KC-tbg2.png");
        background-repeat: no-repeat;
        background-position: 20px 23px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #eaeaea;
        border-radius: 0;
        line-height: 40px;
    }

    #box >>> .ant-collapse-content {
        border-top: 0;
    }

    #box >>> .ant-collapse {
        border: none;
    }

    #box >>> .ant-collapse-item {
        border-bottom: none;
    }

    #progress >>> .ant-progress-text {
        color: #eb98c2;
    }
</style>
