<template>
  <div>
    <SrNav></SrNav>
    <!-- 分类 -->
    <div class="warp">
      <div class="course-fl1 mt50">
        <a
          :class="typeIndex == index ? 'active' : ''"
          v-for="(i, index) in typeList"
          @click="getTab(i.article_type_id, index)"
          >{{ i.name }}</a
        >
      </div>
    </div>

    <div class="warp">
      <!-- 面包屑 -->
      <div class="xun1_mbx">
        <div class="xun1_mbx_nr">
          <i></i>
          <div class="xun1_mbx_a">
             <router-link to="/">
              <a href="">首页 -</a>
            </router-link>
            <a href="" class="xun1_mbx_on">备考资料</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 考试计划列表 -->
    <div class="warp clearfix mt30">
      <div class="xun_plan">
        <div class="xun_plan_l">
          <div class="xun_plan_list xun1_bei">
            <ul v-if="KaoShiList.length>0">
              <li v-for="i in KaoShiList" @click="goDetail(i.article_id)">
                <a>
                  <div class="xun1_bei_l">
                    <img :src="i.image?i.image.url:'/img/img.jpg'" />
                  </div>
                  <div class="xun1_bei_r">
                    <h6>{{i.name}}</h6>
                    <div style="height:108px;overflow:hidden;">
                    <p v-html="i.content_remove_html"></p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <div class="zanwu" v-else>
        	  <img src="@/assets/img/zanwu.png" alt="" />
          暂无数据
        </div>
          </div>
          <!-- 页码 -->
          <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
		v-if="KaoShiList.length > 0"
      ></SrPaginate>
        </div>
        <div class="xun_plan_r">
          <a v-for="i in imgList"><img :src="i.image.url" /></a>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import SrPaginate from "@/components/SrPaginate";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import { article, newType, advertising } from "@/api/index";
export default {
  name: "test",
  components: {
    SrNav,
    SrFooter,
    SrPaginate,
  },
  data() {
    return {
      KaoShiList: [],
      typeList: [],
      typeIndex: 0,
      pagination: {
        page: 1,
        page_size: 10,
      },
      imgList: [],
      article_type_id: "",
    };
  },
  methods: {
    loadImg() {
      let _this = this;
      advertising({location:2}).then((res) => {
        console.log(res, "广告位");
        _this.imgList = res.result;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
	},
    getTab(id, index) {
      console.log(id,'点击')
      this.article_type_id = id;
      this.typeIndex = index;
      this.loadList();
    },
    loadList() {
      let _this = this;
      article({
        type: 2,
        article_type_id: _this.article_type_id,
        ..._this.pagination,
      }).then((res) => {
        console.log(res, "13213");
        _this.KaoShiList = res.result.items;
        _this.pagination = res.result.pagination;
      });
    },
    goDetail(id){
		 this.$router.push({ path: "/new/detail", query: { article_id: id,type:2 } });
	},
  },
  created() {
    let _this = this;
    newType().then((res) => {
      console.log(res, "分类");
      var data = res.result;
      _this.typeList = data;
      _this.article_type_id = res.result[0].article_type_id
      console.log(_this.typeList, "32323232");
    _this.loadList()
    });
    _this.loadImg();
  },
};
</script>
<style>
.xun1_bei_r {
    float: left;
    width: 63%;
    margin-left: 30px;
}
.zanwu {
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #666666;
}
.zanwu img {
  width: 200px;
  margin: 0 auto;
}
.xun1_bei_l img{
  width: 240px;
}
.course-fl1 a{
  width: 100px!important;
}
</style>