<template>
    <div id="whiteboard"
         style="width: 100%;height: 100%">
        <div class="placeholder"></div>
    </div>
</template>

<script>
    export default {
        name: "ZhiboWhiteboard",
        mounted() {
            this.init()
            this.load()
        },
        methods: {
            init() {
                BJY.Whiteboard.create({
                    element: $('#whiteboard .placeholder'),
                    // 填充模式： 1 自适应  2 撑满容器
                    fit: 1,
                    // 给静态文档添加水印，如不需要可忽略。sdkVersion >= 0.3.1
                    watermark: {
                        text: '成公社', // 水印文字，必传，不宜过长，否则阿里云图片处理会出错
                        opacity: 8, // 可选，透明度
                        size: 36, // 可选，字号
                        rotate: 20, //可选，旋转角度
                        fill: 1 //可选，是否铺满 0/1，默认1
                    }
                });
            },
            load() {
                var eventEmitter = BJY.eventEmitter
                eventEmitter
                    .one(
                        eventEmitter.DOC_IMAGE_LOAD_START, function () {
                            console.log('文档图片加载开始')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.DOC_IMAGE_LOAD_END, function () {
                            console.log('文档图片加载结束')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.CURRENT_DOC_IMAGE_LOAD_END, function () {
                            console.log('当前文档图片加载结束')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.DOC_IMAGE_LOAD_SUCCESS, function () {
                            console.log('文档图片加载成功')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.CURRENT_DOC_IMAGE_LOAD_SUCCESS, function () {
                            console.log('当前文档图片加载成功')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.DOC_IMAGE_LOAD_ABORT, function () {
                            console.log('文档图片加载中止')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.CURRENT_DOC_IMAGE_LOAD_ABORT, function () {
                            console.log('当前文档图片加载中止')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.DOC_IMAGE_LOAD_FAIL, function () {
                            console.log('文档图片加载错误')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.CURRENT_DOC_IMAGE_LOAD_FAIL, function () {
                            console.log('当前文档图片加载错误')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.CURRENT_DOC_IMAGE_LOAD_TIMEOUT, function () {
                            console.log('当前文档图片加载超时')
                        }
                    )
                eventEmitter
                    .one(
                        eventEmitter.DOC_IMAGE_NOT_FOUND, function () {
                            console.log('文档图片没有找到')
                        }
                    )
            }
        }
    }
</script>

<style scoped>

</style>
