<template>
    <div id="box">
        <div v-if="!loading"
             v-html="imgWH(radio.introduce)">

        </div>
    </div>
</template>

<script>

    export default {
        name: "RadioIntroduce",
        props: {
            loading: {},
            radio: {}
        },
        data() {
            return {
                // loading: true
            }
        },
        created() {

        },
        methods: {
            imgWH(string) { //正则替换图片宽高
                string = string.replace(/ height="\d+"/g, " height=\"auto\"");
                string = string.replace(/ width="\d+"/g, " width=\"100%\"");
                return string

            }
        }
    }
</script>

<style scoped>
    /*#box >>> img{*/
    /*min-width: 100%;*/
    /*max-width: 100%;*/
    /*}*/
</style>
