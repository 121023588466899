<template>
    <!-- foot -->
    <div style="position: absolute;bottom: 0;width: 100%">

        <!--<div class="foot1 mt80">-->
        <!--<div class="warp clearfix">-->
        <!--<dl>-->
        <!--<dt></dt>-->
        <!--<dd>-->
        <!--<h3>视频合集</h3>-->
        <!--<p>课程多，机构多，人才多</p>-->
        <!--</dd>-->
        <!--</dl>-->
        <!--<dl>-->
        <!--<dt></dt>-->
        <!--<dd>-->
        <!--<h3>考试练习</h3>-->
        <!--<p>课程多，机构多，人才多</p>-->
        <!--</dd>-->
        <!--</dl>-->
        <!--<dl>-->
        <!--<dt></dt>-->
        <!--<dd>-->
        <!--<h3>天天低价</h3>-->
        <!--<p>课程多，机构多，人才多</p>-->
        <!--</dd>-->
        <!--</dl>-->
        <!--<dl>-->
        <!--<dt></dt>-->
        <!--<dd>-->
        <!--<h3>畅选无忧</h3>-->
        <!--<p>课程多，机构多，人才多</p>-->
        <!--</dd>-->
        <!--</dl>-->
        <!--</div>-->
        <!--</div>-->
        <div class="foot2">
            <p>
                <router-link to="/"><a>首页</a></router-link>
                |
                <router-link to="/camp/list"><a>训练营</a></router-link>
                |
                <router-link to="/course/list"><a>课程</a></router-link>
                |
                <router-link to="/radio/list"><a>电台</a></router-link>
                |
                <router-link to="/appDownload/index"><a
                        href=""> APP下载</a></router-link>
                丨
                 <router-link to="/about"><a
                        href=""> 关于我们</a></router-link>
            </p>
            <p class="mt30">Copyright © 2013-2021 <a href="https://beian.miit.gov.cn/"
                                                     target="_blank">{{$store.state.common.config[22].text}}</a> 圣儒教育版权所有 技术支持：开创云 </p>
        </div>
        <!-- 右侧悬浮 -->
        <div class="toolbar">
            <a href="###"
               class="toolbar-item toolbar-item-weixin">
                <span class="toolbar-layer"><img src="@/assets/img/index-img12.jpg"></span>
            </a>
            <a href="###"
               class="toolbar-item toolbar-item-app">
                <span class="toolbar-layer"><img src="@/assets/img/index-img12.jpg"></span>
            </a>
            <a href="javascript:scroll(0,0)"
               id="top"
               class="toolbar-item toolbar-item-top"></a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SrFooter",
        data() {
            return {
            }
        },
        created() {
        },
        methods: {}
    }
</script>

<style scoped>
</style>
