import storage from "@/utils/storage"
import {getUserInfo} from "@/api/user";
import router from "@/router"
const user = {
    state: {
        nickname: null,
        head_portrait: {},
        is_login: false
    },

    mutations: {
        SET_INFO: (state, info) => {
            state.nickname = info.nickname
            state.head_portrait = info.head_portrait
            state.mobile = info.mobile
            state.has_password = info.has_password
            state.now_user_member = info.now_user_member
            state.has_openid = info.has_openid
            state.user_id = info.user_id
            state.wechat_name = info.wechat_name
        },
        SET_IS_LOGIN: (state, status) => {
            state.is_login = status
        }
    },

    actions: {
        // 获取用户信息
        getUserInfo({commit}) {
            if (!this.state.user.is_login) {
                return new Promise((resolve, reject) => {
                    //判断是否有token
                    var token = storage.getItem('ACCESS_TOKEN')
                    if (token) {
                        getUserInfo().then(response => {
                            const result = response.result
                            // console.log(result)
                            commit('SET_INFO', result)
                            commit('SET_IS_LOGIN', true)
                            resolve(result)
                            if(result.user_label.length == 0){
                                router.push('/selection')
                            }
                        }).catch(error => {
                            commit('SET_IS_LOGIN', false)
                            reject(error)
                        })
                    } else {
                        commit('SET_IS_LOGIN', false)
                        resolve(false)
                    }
                })
            }

        }
    }
}

export default user
