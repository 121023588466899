<template>
    <div>

    </div>
</template>
<script>
import { info } from "@/api/school/course_package";
export default {
  name: "CourseTag",
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.course_package_id = this.$route.params.course_id;
    this.getData();
  },
  methods: {
    getData() {
      var _self = this;
      info({ course_package_id: _self.course_package_id }).then((res) => {
        console.log(res, "详情");
        if(res.result.style_temp == 0){
            this.$router.push({
          path: "/course-package/info/" + res.result.course_package_id,
        });
        }
        if(res.result.style_temp == 1){
             this.$router.push({
          path: "/course/courseGroupList/" + res.result.course_package_id,
        });
        }
      });
    },
    toCourseDetail(item) {
      console.log(item);
      if (item.type == 1) {
        this.$router.push({
          path: "/course/info/" + item.relation.course_id,
        });
      }
      if (item.type == 3) {
        this.$router.push({
          path: "/camp/info/" + item.relation.camp_id,
        });
      }
    },
  },
};
</script>