<template>
  <div>
    <div class="warp mt30">
      <h3 class="kc-tuijian f26">
        <img src="@/assets/img/KC-tbg.png" />购物车
      </h3>

      <a-spin :spinning="loading">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
        <div v-if="loading" style="height: 500px"></div>
        <div v-for="item in list">
          <div class="cart-list dis-table mt20">
            <div class="dis-tablecell"></div>
            <div
              class="dis-tablecell"
              style="padding-right: 20px; width: 150px"
            >
              <img height="100px" v-if="item.image" :src="item.image.url" />
            </div>
            <div class="dis-tablecell">
              <p class="f22">{{ item.name }}</p>
              <!-- <p class="color-666">
                                <span>{{$moment(item.relation.start_at).format("YYYY.MM.DD")}}</span>
                                <span>-</span>
                                <span>{{$moment(item.relation.end_at).format("YYYY.MM.DD")}}</span>
                                <span v-if="item.relation.period">
                               {{item.relation.period}} 课时
                            </span>
                            </p> -->
              <!-- <span class="f14 color-999 cart-btex">
                            <span>距结束：</span>
                        <a-statistic-countdown
                                :value="item.relation.end_at|toTime"
                                format="D 天 H 时 m 分 s 秒"/>
                        </span> -->
              <p class="f14 color-999">购买人数：{{ item.sale_amount }}人</p>
            </div>
            <div class="dis-tablecell te_c color-red f26">
              ￥{{ item.real_price }}
              <span style="color: #9c9c9c; font-size: 16px; margin-left: 10px"
                >X{{ item.num }}</span
              >
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import SrRadio from "@/components/SrRadio";
import { getGoodsInfo } from "@/api/buy";
export default {
  name: "Car",
  components: {
    SrRadio,
  },
  props: {
    value: {},
    type: {},
  },
  data() {
    return {
      list: [],
      loading: false,
      valueIds: [],
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  created() {
    console.log(this.value, "this");
    let _this = this;
    this.value.map(function (n) {
      _this.valueIds.push(n.goods_id);
    });
    console.log(this.valueIds, "value");
    this.getData();
  },
  methods: {
    getData() {
      var _self = this;
      _self.loading = true;
      getGoodsInfo({ goods_id: _self.valueIds }).then((res) => {
        console.log(res, "购物车信息");
        var data = res.result;
        data.map(function (n) {
           if(n.vip_price.length > 0){
               if(_self.$store.state.user.now_user_member){
                   n.vip_price.map(function(item){
                       if(item.user_member_config_id == _self.$store.state.user.now_user_member.user_member_config_id){
                           n.jia = item.price
                       }
                   })
               }else{
                   n.jia = n.order_price
               }
           }else{
               n.jia = n.order_price
           } 
          _self.value.map(function (s) {
            if (n.goods_id == s.goods_id) {
              n.num = s.num;
            }
          });
        });
        _self.list = data;
        _self.loading = false;
        if (_self.list.length === 0) {
          _self.$srmsg.info({
            title: "提示",
            content: "商品不存在",
          });
        } else {
          _self.$emit("change", _self.list);
        }
      });
    },
  },
};
</script>

<style scoped>
.cart-btex >>> .ant-statistic {
  display: inline-block;
}

.cart-btex >>> .ant-statistic-content {
  display: inline-block;
  width: auto;
}

.cart-btex >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #999999;
}
</style>
