<template>
    <div>
        <div class="per3_right per_right">
				<div class="p_remind_title">
					<img src="@/assets/img/remind_icon.png" />
					<h6>我的消息-消息详情</h6>
				</div>
				<div class="per1_message">
				   <div class="per1_message_tit">
					  <h6>{{detail.name}}</h6>
					  <p>{{detail.created_at}}</p>
				   </div>
				   <div class="per1_message_nr">
                       <p v-html="detail.system.content"></p>
					  
				   </div>
				</div>
				<!-- 页码 -->
				<!-- <div class="page mt20">
					<a href="">&lt;&lt;</a>
					<a class="active" href="">1</a>
					<a href="">2</a>
					<a href="">&gt;&gt;</a>
				</div> -->
			</div>
    </div>
</template>
<script>
import { messageDetail } from "@/api/user";
export default {
    name: "MyMessageDetail",
    data() {
        return {
			detail:{}
		};
    },
    methods: {

	},
    created() {
		let _this = this
		  messageDetail({message_id:_this.$route.query.id}).then((res) => {
			  console.log(res,'12321321')
			  _this.detail = res.result
      });
	},
};
</script>

<style scoped>
table {
  text-align: center;
}
</style>