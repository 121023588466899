<template>
  <div>
    <div class="login-bg"></div>
    <div class="login-box">
      <div class="clearfix">
        <p class="fn_l"><img src="@/assets/img/logo.jpg" /></p>
      </div>
      <div class="login mt30">
        <div class="tab-wrapper">
          <!-- 选择类别 -->
          <div class="xz_category">
            <div class="xz_category_nr">
              <h6>绑定手机号</h6>
              <div class="tab-content">
                <p>
                  <input
                    v-model="phone"
                    type="text"
                    placeholder="请输入手机号"
                  />
                </p>
                <p>
                  <input
                    v-model="code"
                    type="text"
                    placeholder="请输入验证码"
                    name=""
                    value=""
                  />
                  <SrButton
                    :disabled="sms_time !== 0"
                    @click="sendMessage"
                    class="login-hqyzm"
                    height="33px"
                    padding="0 20px"
                    font-size="14px"
                    :loading="sms_loading"
                  >
                    <span v-if="sms_time === 0">获取验证码</span>
                    <span v-else>重新发送({{ sms_time }})</span>
                  </SrButton>
                </p>
              </div>
              <SrButton @click="add()" :loading="add_loading" style="margin-top:30px">
                提 交
              </SrButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import SrButton from "@/components/SrButton";
import { sendMessage } from "@/api/common";
import { socialiteLogin } from "@/api/user";

export default {
  name: "Login",
  components: {
    SrButton,
  },
  data() {
    return {
      login_title: "验证码登录",
      action: "login",
      sms_loading: false,
      add_loading:false,
      phone:'',
      code:'',
      sms_time: 0
    };
  },
  created() {
    // 判断入口
    var _self = this;
    console.log(this.$route.query)
    var sms_time = storage.getItem("sms_time");
    if (sms_time > 0) {
      _self.sms_time = sms_time;
      var timer = setInterval(function () {
        _self.sms_time--;
        storage.setItem("sms_time", _self.sms_time);
        if (_self.sms_time <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  },
  methods:{
    sendMessage() {
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      _self.sms_loading = true;
      var char = new Date().getTime();
      var key = 'xCf188EYN4arKCXqo3nUYDumTccz7'
      var sign = this.$md5('char='+ char +'&phone=' + _this.phone + '&scenario=SocialiteCode'+ key)
      sendMessage({ phone:_self.phone,scenario: "SocialiteCode",char:char,sign:sign.toUpperCase() }).then((res) => {
        if (res.status === 200) {
          _self.sms_loading = false;
          _self.sms_time = 60;
          var timer = setInterval(function () {
            _self.sms_time--;
            storage.setItem("sms_time", _self.sms_time);
            if (_self.sms_time <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          _self.$message.warning(res.msg);
          _self.sms_loading = false;
        }
      });
    },
    add(){
      let _this = this
      if(_this.phone == ''){
        _this.$message.warning('请填写手机号');
        return
      }
      if(_this.code == ''){
        _this.$message.warning('请填写验证码');
        return
      }
      _this.add_loading = true
      socialiteLogin({
        phone:_this.phone,
        code:_this.code,
        type:_this.$route.query.type,
        crypt:_this.$route.query.crypt
        }).then((res)=>{
          console.log(res,'绑定')
          if(res.status == 200){
            _this.add_loading = false
            _this.$message.success("绑定成功");
             storage.setItem("ACCESS_TOKEN", res.result);
             _this.$router.push('/');
              window.location.reload();
          }else{
            _this.add_loading = false
           _this.$message.warning(res.msg);
          }
      })
    }
  }
};
</script>