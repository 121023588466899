<template>
  <div>
    <div class="index3_dx_one">
      <p
        v-html="detail.title"
        :style="
          fontType == 14
            ? 'font-size:14px'
            : fontType == 18
            ? 'font-size:18px'
            : 'font-size:16px'
        "
      ></p>
      <img v-if="detail.image.url" :src="detail.image.url" alt="">
    </div>
    <div class="index3_dx_four index3_duo_four">
      <ul>
        <li
          v-for="(i, index) in detail.subject_option"
          @click="openCheck(i, index)"
        >
          <span :class="i.checks == true ? 'active' : ''">{{
            i.option_key
          }}</span>
             <p v-if="i.title">{{ i.title }}</p>
          <img v-if="i.image.url" :src="i.image.url" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "index",
  components: {},
  props: {
    detail: Object,
  },
  data() {
    return {
      paperDetail: {},
      tiIndex: null,
      choice: [],
      fontType: 16,
    };
  },
  mounted() {},
  methods: {
    openCheck(i, index) {
      let _this = this;
      console.log(i)
      if (i.check) {
        i.check = false;
        i.checks = false;
        console.log('对')
        _this.$set(_this.detail.subject_option,index,i)
        _this.choice.map(function (n, indexs) {
          if (n == i.subject_option_id) {
            _this.choice.splice(indexs, 1);
          }
        });
      } else {
        i.check = true;
        i.checks = true;
        console.log('错')
        _this.$set(_this.detail.subject_option,index,i)
        _this.choice.push(i.subject_option_id);
      }
      // this.$set(this.detail.subject_option, index, i);
      this.$emit("getCheck", _this.choice);
    },
    init() {
      let _this = this;
      var ids = null;
      if (this.detail.answer_situation) {
        // var ids = this.detail.answer_situation.choice.substr(1,this.detail.answer_situation.choice.length-1)
        this.choice = this.detail.answer_situation.choice_array;
        var str1 = this.detail.answer_situation.choice.substr(1);

        var str2 = str1.substr(0, str1.length - 1);
        ids = str2.split(",");
        console.log(ids, "str2");
        this.detail.subject_option.map(function (n) {
          _this.$set(n, "checks", false);
          _this.$set(n, "check", false);
          if (ids) {
            ids.map(function (s) {
              if (n.subject_option_id == s) {
                _this.$set(n, "checks", true);
                _this.$set(n, "check", true);
              }
            });
          }
        });
      }
      console.log(this.detail, "多选题");
    },
    fontInit(type) {
      this.fontType = type;
    },
  },
  created() {},
};
</script>
<style scoped>
.index3_dx_four ul li span {
  cursor: pointer;
  border-radius: 0;
}
.index3_dx_four ul li span.active {
  border: none;
}
</style>