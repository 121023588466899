<template>
  <div>
    <div class="set5">
      <div class="set5_tit">
        <a class="xinzeng" @click="openMotal">新增收货地址</a>
      </div>
      <div class="set5_nr">
        <table border="0" cellspacing="0" cellpadding="0" class="form form2">
          <tr>
            <th>收货人</th>
            <th>收货地址</th>
            <th>手机号码</th>
            <th>操作</th>
          </tr>
          <tr v-if="addressList.length > 0" v-for="i in addressList">
            <td>{{ i.name }}</td>
            <td>
              {{ i.province.name }}{{ i.city.name }}{{ i.district.name
              }}{{ i.address }}
            </td>
            <td>{{ i.mobile }}</td>
            <td>
              <div class="bian">
                <div class="bian_l">
                  <a @click="addressEdit(i)">编辑</a>
                  <a @click="addressDel(i.shipping_address_id)">删除</a>
                </div>
                <div class="bian_r">
                  <a href="" v-if="i.is_default == 1" style="color: #3561c7"
                    >默认地址</a
                  >
                  <a @click="isDefault(i)" v-if="i.is_default == 0">设为默认</a>
                </div>
              </div>
            </td>
          </tr>
          <tr
            v-if="addressList.length == 0"
            style="height: 100px; line-height: 100px; text-align: center"
          >
            <td colspan="4">暂无收货地址 快去添加吧~</td>
          </tr>
        </table>
      </div>
    </div>
     <div class="motal" v-show="showMotal">
      <div class="motal_box">
        <div class="motal_title">添加收货地址</div>
        <div class="motal_input_box">
          <div class="motal_input">
            <p><span>*</span>收货人：</p>
            <input
              type="text"
              v-model="addressForm.name"
              placeholder="请输入收货人"
            />
          </div>
          <div class="motal_input">
            <p><span>*</span>收货地址：</p>
            <select
              class="form-control"
              v-model="provinceModel"
              @change="provinceChange($event)"
            >
              <option v-for="(i, index) in province_List" :value="index">
                {{ i.name }}
              </option>
            </select>
            <select
              class="form-control"
              v-model="cityModel"
              @change="cityChange($event)"
            >
              <option v-for="(i, index2) in city_List" :value="index2">
                {{ i.name }}
              </option>
            </select>
            <select
              class="form-control"
              v-model="districtModel"
              @change="districtChange($event)"
            >
              <option v-for="(i, index3) in district_List" :value="index3">
                {{ i.name }}
              </option>
            </select>
          </div>
          <div class="motal_input">
            <p><span>*</span>详细地址：</p>
            <input
              type="text"
              v-model="addressForm.address"
              placeholder="请输入详细地址"
            />
          </div>
          <div class="motal_input">
            <p><span>*</span>联系电话：</p>
            <input
              type="number"
              v-model="addressForm.mobile"
              placeholder="请输入联系电话"
            />
          </div>
          <div class="motal_check">
            <input type="checkbox" @change="checkBoxss" v-model="is_default" />
            <span>默认收货地址</span>
          </div>
          <SrButton
            style="width: 250px; margin: 0 auto; margin-top: 30px"
            @click="addAddress"
            font-size="14px"
            height="40px"
            padding="0 20px"
            :loading="addAddress_loadings"
          >
            保存收货地址
          </SrButton>
        </div>
      </div>
      <img
        class="motal_close"
        @click="closeMotal"
        src="@/assets/img/modal-close.png"
        alt=""
      />
    </div>
    <div class="motal" v-show="showMotal2">
      <div class="motal_box">
        <div class="motal_title">添加收货地址</div>
        <div class="motal_input_box">
          <div class="motal_input">
            <p><span>*</span>收货人：</p>
            <input
              type="text"
              v-model="editAddress.name"
              placeholder="请输入收货人"
            />
          </div>
          <div class="motal_input">
            <p><span>*</span>收货地址：</p>
            <select
              class="form-control"
              v-model="provinceModel"
              @change="provinceChange($event)"
            >
              <option v-for="(i, index) in province_List" :value="index">
                {{ i.name }}
              </option>
            </select>
            <select
              class="form-control"
              v-model="cityModel"
              @change="cityChange($event)"
            >
              <option v-for="(i, index2) in city_List" :value="index2">
                {{ i.name }}
              </option>
            </select>
            <select
              class="form-control"
              v-model="districtModel"
              @change="districtChange($event)"
            >
              <option v-for="(i, index3) in district_List" :value="index3">
                {{ i.name }}
              </option>
            </select>
          </div>
          <div class="motal_input">
            <p><span>*</span>详细地址：</p>
            <input
              type="text"
              v-model="editAddress.address"
              placeholder="请输入详细地址"
            />
          </div>
          <div class="motal_input">
            <p><span>*</span>联系电话：</p>
            <input
              type="number"
              v-model="editAddress.mobile"
              placeholder="请输入联系电话"
            />
          </div>
          <div class="motal_check">
            <input type="checkbox" @change="checkBoxss2" v-model="is_default" />
            <span>默认收货地址</span>
          </div>
          <SrButton
            style="width: 250px; margin: 0 auto; margin-top: 30px"
            @click="editAddress2"
            font-size="14px"
            height="40px"
            padding="0 20px"
            :loading="editAddress2_loading"
          >
            修改收货地址
          </SrButton>
        </div>
      </div>
      <img
        class="motal_close"
        @click="closeMotal"
        src="@/assets/img/modal-close.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
var timer = null;
import SrButton from "@/components/SrButton";
import {
  
  addressGetList,
  addAddress,
  delAddress,
  editAddress,
} from "@/api/user";
import { location } from "@/api/common";
export default {
  name: "Pintuan",
  components: {
    SrButton,
  },
  props: {
    course: {},
  },
  data() {
    return {
           addAddress_loadings: false,
      editAddress2_loading: false,
   
     
      province_id: null,
      province_name: null,
      city_id: null,
      city_name: null,
      district_id: null,
      district_name: null,
      cityModel: null,
      districtModel: null,
      province_List: [{ name: "请选择省" }],
      city_List: [{ name: "请先选省份" }],
      district_List: [{ name: "请先选择城市" }],
      showMotal: false,
      showMotal2: false,
      addressList: [],
      addressForm: {
        is_default: 0,
        district_id: "",
        name: "",
        address: "",
        mobile: "",
      },
      is_default: 0,
      provinceModel: 0,
      editAddress: {},
    };
  },
  filters: {},
  mounted() {
    this.location();
    this.cityModel = 0;
    this.districtModel = 0;
    this.addressGetList();
  },
  methods: {
    // 获取三级联动数据
    location() {
      location().then((res) => {
        var obj = {
          name: "请选择省",
          province_id: "",
        };
        this.province_List = res;
        this.province_List.unshift(obj);
      });
    },
    // 获取市
    provinceChange(event) {
      var index = event.target.value;
      console.log(this.province_List[index]);
      var obj = {
        name: "请选择市",
        city_id: "",
      };
      this.city_List = this.province_List[index].city;
      this.province_id = this.province_List[index].province_id;
      this.city_id = "";
      this.district_id = "";
      this.city_List.unshift(obj);
      this.cityModel = 0;
      this.districtModel = 0;
    },
    // 获取区县
    cityChange(event) {
      var index = event.target.value;
      let obj = {
        name: "请选择区",
        city_id: "",
      };
      this.district_List = this.city_List[index].district;
      this.city_id = this.city_List[index].city_id;
      this.district_id = "";
      console.log(this.city_id);
      this.district_List.unshift(obj);
      this.districtModel = 0;
    },
    // 区县选择
    districtChange(event) {
      var index = event.target.value;
      this.district_id = this.district_List[index].district_id;
      this.editAddress.district_id = this.district_List[index].district_id;
    },
    openMotal() {
      this.showMotal = true;
    },
    closeMotal() {
      this.showMotal = false;
      this.showMotal2 = false;
    },
    // 获取收货列表
    addressGetList() {
      let _this = this;
      addressGetList().then((res) => {
        console.log(res, "获取收货列表");
        _this.addressList = res.result;
      });
    },
       checkBoxss(e) {
      console.log(e);
      if (e.target.checked) {
        this.addressForm.is_default = 1;
      } else {
        this.addressForm.is_default = 0;
      }
    },
    checkBoxss2(e) {
      if (e.target.checked) {
        this.editAddress.is_default = 1;
      } else {
        this.editAddress.is_default = 0;
      }
    },
    // 添加收货地址
    addAddress() {
      let _this = this;
      this.addressForm.district_id = this.district_id;
      if (this.addressForm.name == "") {
        _this.$message.warning("请输入收货人姓名");
        return;
      }
      if (this.addressForm.address == "") {
        _this.$message.warning("请输入详细地址");
        return;
      }
      if (this.addressForm.mobile == "") {
        _this.$message.warning("请输入手机号");
        return;
      }
      if (this.addressForm.district_id == "") {
        _this.$message.warning("请选择收货地址");
        return;
      }
      this.addAddress_loadings = true;
      addAddress(_this.addressForm).then((res) => {
        if (res.status === 200) {
          _this.$message.success("操作成功");
          setTimeout(() => {
            _this.addAddress_loadings = false;
            _this.addressGetList();
            _this.showMotal = false;
            _this.city_List = [{ name: "请先选省份" }];
            _this.district_List = [{ name: "请先选城市" }];
            _this.addressForm = {
              is_default: 0,
              district_id: "",
              name: "",
              address: "",
              mobile: "",
            };
          }, 1000);
          _this.provinceModel = 0;
          _this.cityModel = 0;
          _this.districtModel = 0;
        } else {
          _this.$message.warning(res.msg);
          _this.editUserPassword_loading = false;
        }
      });
    },
    // 删除收货地址
    addressDel(id) {
      let _this = this;
      this.$srmsg
        .confirm({
          title: "提示",
          content: "确认删除收货地址吗",
        })
        .then(() => {
          delAddress({ shipping_address_id: id }).then((res) => {
            if (res.status === 200) {
              _this.$message.success("操作成功");
              setTimeout(() => {
                _this.addressGetList();
              }, 1000);
            } else {
              _this.$message.warning(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 设置默认收货地址
    isDefault(i) {
      let _this = this;
      console.log(i);
      var form = {};
      form.district_id = i.district_id;
      form.name = i.name;
      form.address = form.address;
      form.mobile = i.mobile;
      form.is_default = 1;
      form.shipping_address_id = i.shipping_address_id;
      this.$srmsg
        .confirm({
          title: "提示",
          content: "确定要设置为默认收货地址吗？",
        })
        .then(() => {
          editAddress(form).then((res) => {
            if (res.status === 200) {
              _this.$message.success("操作成功");
              setTimeout(() => {
                _this.addressGetList();
              }, 1000);
            } else {
              _this.$message.warning(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 编辑收货地址
    addressEdit(i) {
      let _this = this;
      console.log(i);
      this.showMotal2 = true;
      this.editAddress.name = i.name;
      this.editAddress.mobile = i.mobile;
      this.editAddress.address = i.address;
      this.editAddress.shipping_address_id = i.shipping_address_id;
      this.editAddress.is_default = i.is_default;
      this.editAddress.district_id = i.district_id
      let city1 = {
        name: "请选择市",
        city_id: "",
      };
      var dit = {
        name: "请选择区",
        city_id: "",
      };
      this.province_List.map(function (n, index) {
        if (n.province_id == i.province_id) {
          console.log(n, "地址");
          _this.provinceModel = index;
          console.log(index, "下标");
          _this.city_List = n.city;
          _this.city_List.unshift(city1);
          n.city.map(function (c, item) {
            if (c.city_id == i.city_id) {
              _this.cityModel = item;
              _this.district_List = c.district;
              _this.district_List.unshift(dit);
              _this.district_List.map(function (d, items) {
                if (d.district_id == i.district_id) {
                  _this.districtModel = items;
                }
              });
            }
          });
        }
      });
    },
    editAddress2() {
      let _this = this;
      _this.editAddress_loading = true;
      console.log(_this.editAddress.district_id, "默认");
      editAddress({
        district_id: _this.editAddress.district_id,
        name: _this.editAddress.name,
        address: _this.editAddress.address,
        mobile: _this.editAddress.mobile,
        is_default: _this.editAddress.is_default,
        shipping_address_id: _this.editAddress.shipping_address_id,
      }).then((res) => {
        if (res.status === 200) {
          _this.$message.success("操作成功");
          setTimeout(() => {
            _this.addressGetList();
            _this.showMotal2 = false;
            _this.editAddress_loading = false;
          }, 1000);
        } else {
          _this.$message.warning(res.msg);
          _this.editAddress_loading = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.motal_close {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
}
.wdtx img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 0;
}
.wdtx p {
  position: relative;
  width: 160px;
  height: 160px;
  display: inline-block;
  margin-left: 20px;
}
.wdtx input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  opacity: 0;
}
table {
  text-align: center;
}
.motal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  top: 0;
  left: 0;
}
.motal_box {
  width: 1000px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 120px;
  padding: 30px;
}
.motal_title {
  font-size: 20px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
}
.motal_input_box {
  margin: 0 auto;
  width: 700px;
  margin-top: 30px;
}
.motal_input {
  width: 100%;
  margin-bottom: 20px;
}
.motal_input p {
  display: inline-block;
  font-size: 16px;
  width: 100px;
}
.motal_input span {
  color: red;
  padding-right: 10px;
}
.motal_input input {
  display: inline-block;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  width: calc(100% - 100px);
}
.motal_input select {
  width: calc(100% / 3 - 40px);
  display: inline-block;
  border: 1px solid #eaeaea;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-radius: 4px;
  margin-right: 10px;
}
.motal_input select:last-child {
  margin-right: 0;
}

input[type="checkbox"] {
  outline: none;
  -webkit-appearance: none;
  position: relative;
  vertical-align: middle;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  min-height: 20px;
  min-width: 20px;
}
input[type="checkbox"]:checked {
  border: 1px solid #eaeaea;
  outline: none;
  background: #3561c7;
}
input[type="checkbox"]:checked::after {
  content: "\00a0";
  display: inline-block;
  border: 2px solid #fff;
  border-top-width: 0;
  border-right-width: 0;
  width: 16px;
  height: 8px;
  -webkit-transform: rotate(-50deg);
  position: absolute;
  top: 2px;
  left: 1px;
}
.motal_check span {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
