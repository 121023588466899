<template>
    <div>
        <div class="per3_right per_right">
				<div class="p_remind_title">
					<img src="@/assets/img/remind_icon.png" />
					<h6>{{detail.name}}</h6>
				</div>
				<div class="per5_instructions">
				   <p v-html="detail.text"></p>
				</div>
        <div v-if="type" class="btn" @click="openVip">同意协议</div>
				<!-- 页码 -->
				<!-- <div class="page mt20">
					<a href="">&lt;&lt;</a>
					<a class="active" href="">1</a>
					<a href="">2</a>
					<a href="">&gt;&gt;</a>
				</div> -->
			</div>
    </div>
</template>
<script>
import { userAbout } from "@/api/user";
export default {
  name: "MyAbout",
  data() {
    return {
        detail:{},
        type:null
    };
  },
  methods: {
    openVip(){
      this.$router.push({ path: "/user/center/payViper",query:{textCheck:true}});
    }

  },
  created() {
      let _this = this
      userAbout({id:_this.$route.query.id}).then((res) => {
        console.log(res,'协议')
        _this.detail = res.result
      }); 
      if(_this.$route.query.type){
        _this.type = _this.$route.query.type
      }
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
.btn{
  background: #3561C7;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  width: 180px;
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  cursor: pointer;
}
</style>