import request from '@/utils/request'
export function detail(parameter) {
    return request({
        url: '/api/user/corrects/show',
        method: 'post',
        data: parameter
    })
}
export function buy(parameter) {
    return request({
        url: '/api/user/corrects/buy',
        method: 'post',
        data: parameter
    })
}
export function bill(parameter) {
    return request({
        url: '/api/user/corrects/bill',
        method: 'post',
        data: parameter
    })
}
export function needEvaluationService(parameter) {
    return request({
        url: '/api/user/corrects/needEvaluationService',
        method: 'post',
        data: parameter
    })
}