<template>
    <div style="width:100%;height:100%">
        <iframe style="width:100%;height:100%;border: none"
                :src="src"></iframe>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                src: ''
            }
        },
        methods: {
            init(config) {
                this.src = 'http://e41611680.at.baijiayun.com/web/room/enter?room_id='
                    + config.room_id +
                    '&user_number='
                    + config.user.number +
                    '&user_name='
                    + encodeURI(config.user.name) +
                    '&user_role=0&user_avatar='
                    + this.UrlEncode(config.user.avatar) +
                    '&sign=' + config.sign;
                window.location.href = this.src
            },
            UrlEncode(str) {
                var ret = "";
                var strSpecial = "!\"#$%&'()*+,/:;<=>?[]^`{|}~%";
                var tt = "";

                for (var i = 0; i < str.length; i++) {
                    var chr = str.charAt(i);
                    var c = this.str2asc(chr);
                    tt += chr + ":" + c + "n";
                    if (parseInt("0x" + c) > 0x7f) {
                        ret += "%" + c.slice(0, 2) + "%" + c.slice(-2);
                    } else {
                        if (chr == " ")
                            ret += "+";
                        else if (strSpecial.indexOf(chr) != -1)
                            ret += "%" + c.toString(16);
                        else
                            ret += chr;
                    }
                }
                return ret;
            }, str2asc(strstr) {
                return ("0" + strstr.charCodeAt(0).toString(16)).slice(-2);
            }
        }
    }
</script>

<style scoped>

</style>
