<template>
  <div>
    <div class="lxbaogao-t">
      <div class="warp pos-rel te_c" style="height: 65px; line-height: 65px">
        <a class="color-666" @click="exit">&lt; 退出</a>
        <p>{{ title }}</p>
      </div>
    </div>
    <!-- 答题报告 -->
    <div class="warp mt30">
      <div class="baogao-box clearfix mt20">
        <div
          class="baogao-boxL"
          v-if="reportType == 1 || reportType == 7 || reportType == 3"
        >
          <!-- 答题卡 -->
          <div class="mokao-dtk-t">
            <span><img src="@/assets/img/mokao-dui.png" /></span> 对 &nbsp;
            <span><img src="@/assets/img/mokao-cuo.png" /></span> 错 &nbsp;
            <span><img src="@/assets/img/mokao-weizuo.png" /></span> 未做 &nbsp;
          </div>
          <p class="mt10">
            <small class="color-999">共{{ answerList.length }}题</small>
          </p>
          <div class="mokao-dtk-m mt10">
            <a
              :class="
                i.answer_situation == null
                  ? ''
                  : i.answer_situation.correct_flage == 1
                  ? 'mokao-dui'
                  : i.answer_situation.correct_flage == 2
                  ? 'mokao-dui'
                  : 'mokao-cuo'
              "
              v-for="(i, index) in answerList"
              @click="openAnswer(i, index)"
              >{{ index + 1 }}</a
            >
            <!-- <a class="mokao-cuo" href="">10</a> -->
          </div>
        </div>

        <div class="baogao-boxL" v-if="reportType == 4 || reportType == 6">
          <div class="shenlun">
            <img src="@/assets/img/shenlun-img.jpg" />
            <p>
              <a @click="openPopup(2)">我要批改</a
              ><a @click="openMyCourse">查看批改</a>
            </p>
          </div>
        </div>
        <div class="baogao-boxL" v-if="reportType == 5">
          <div class="shenlun">
            <img src="@/assets/img/mianshi-img.jpg" />
            <p class="te_r">
              <a @click="openPopup(1)">我要点评</a><a>查看点评</a>
            </p>
          </div>
        </div>
        <div
          class="baogao-boxR"
          v-if="reportType == 5 || reportType == 4 || reportType == 6"
        >
          <div class="shenglun-jg">
            <p>2′44″</p>
            <p>答题时间</p>
          </div>
          <div class="te_c mt20 color-666">
            <p>勤能补拙，您还需要加油！</p>
          </div>
          <div class="mokao-jiexi mt50 te_c">
            <a @click="openAnswer">答案解析</a>
            <a class="sanqi-fx" @click="openShare">分享得积分</a>
          </div>
        </div>
        <div class="baogao-boxR" v-if="reportType == 7 || reportType == 3">
          <div class="tabbox clearfix mt20">
            <div class="content">
              <ul>
                <a-progress
                  type="circle"
                  :percent="answerDetail.rate ? answerDetail.rate : 0"
                  :strokeWidth="10"
                  :stroke-color="{ '0%': '#35f88c', '100%': '#1dc5e3' }"
                />
                <p>勤能补拙，您还需要加油</p>
              </ul>
            </div>
            <div class="tab clearfix">
              <a href="javascript:;" class="on">
                <p>2′44″</p>
                <p>答题时间</p>
              </a>
              <a href="javascript:;">
                <p>
                  {{
                    answerDetail.correctNumber ? answerDetail.correctNumber : 0
                  }}
                </p>
                <p>正确数</p>
              </a>
              <a href="javascript:;">
                <p>
                  {{ answerDetail.errorNumber ? answerDetail.errorNumber : 0 }}
                </p>
                <p>错误数</p>
              </a>
            </div>
            <div class="lianxi-jiexi mt20">
              我们正在对您的考试做出分析，请及时参加测评分析课
            </div>
            <div class="mokao-jiexi mt50 te_c">
              <a @click="openAnswer">答案解析</a>
              <a class="sanqi-fx" @click="openShare">分享得积分</a>
            </div>
          </div>
        </div>
      </div>
      <!-- 分享得积分 -->
      <div class="kapian sanqi-fxTC" v-show="motalShow">
        <div class="as">
          <div class="txtx">
            <div class="txtx_wen">
              <p>分享后方可预约成功！</p>
            </div>
            <div class="txtx_zhuang">
              <a href="">分享</a>
              <a class="guanbi guanbi_a">取消</a>
            </div>
          </div>
        </div>
      </div>
      <!-- 推荐课程 -->
      <div v-if="list.length > 0">
        <div class="clearfix mt20">
          <p class="fn_l f22">
            <img class="dis-inb" src="@/assets/img/KC-tbg.png" /> 推荐课程
          </p>
        </div>
        <div class="clearfix mt20">
          <div class="kc-list kc-listBg fn_l" v-for="i in list">
            <a v-if="i.goods.type == 1" @click="toCourseInfo(i)">
              <div class="kc-listTex">
                <p
                  class="f12 color-main"
                  :style="'color:' + i.goods.relation.subtitle_color"
                >
                  {{ i.goods.relation.subtitle }}
                </p>
                <h3>{{ i.goods.name }}</h3>
                <p class="f14">
                  {{ i.goods.relation.start_at }}--{{ i.goods.relation.end_at }}
                </p>
                <!-- <ul class="kc-avatar clearfix">
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋江洋</p>
                </li>
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋</p>
                </li>
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋</p>
                </li>
              </ul> -->
                <p
                  class="clearfix f14 color-666"
                  style="position: absolute; bottom: 20px"
                >
                  <span class="fn_l"
                    >限售<em class="color-main">{{ i.goods.sale_amount }}人</em>
                  </span>
                  <span class="fn_r"
                    ><em class="f22 color-main"
                      >￥{{ i.goods.order_price }}</em
                    ></span
                  >
                </p>
              </div>
            </a>
            <a v-if="i.goods.type == 4" @click="toCampInfo(i)">
              <div class=""><img :src="i.goods.image.url" /></div>
              <div class="kc-listTex">
                <h3>{{ i.goods.name }}</h3>
                <p
                  class="f12 color-main"
                  :style="'color:' + i.goods.relation.subtitle_color"
                >
                  {{ i.goods.relation.subtitle }}
                </p>
                <p class="clearfix f14 color-666">
                  <span class="fn_l"
                    >限售<em class="color-main"
                      >{{ i.goods.sale_amount }}人</em
                    ></span
                  >
                  <span class="fn_r"
                    ><s class="color-999">￥{{ i.goods.real_price }}</s>
                    <em class="f22 color-main"
                      >￥{{ i.goods.order_price }}</em
                    ></span
                  >
                </p>
              </div>
            </a>
            <a v-if="i.goods.type == 3" @click="toRadioInfo(i)">
              <p class="dt-listImg">
                <el-image
                  style="height: 240px; width: 100%"
                  :src="i.goods.image.url"
                  fit="contain"
                ></el-image>
                <span v-if="i.goods.relation.audition == 1" class="dt-shiting"
                  ><img src="@/assets/img/DT-shiting.png"
                /></span>
              </p>
              <p class="tj_title">{{ i.goods.name }}</p>
              <p class="tj_ps f14 color-666">
                播放量{{ i.goods.relation.playback_volume }}次
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 测评弹窗 -->
    <div class="kapian kapian5 tc_show" v-show="motalShowFx">
      <div class="as">
        <div class="yao_friend in3_yao_friend">
          <div class="yao_share1">
            <div class="yao_share">
              <span>分享</span>
              <div class="yao_share_nr index3_yao_share_nr">
                <ul>
                  <li @click="copyShare">
                    <a>
                      <img src="@/assets/img/yao_share1.png" />
                    </a>
                  </li>
                  <!-- <li>
									<a href="">
										<img src="img/yao_share2.png" />
									</a>
								</li> -->
                  <li @click="copyShare">
                    <a>
                      <img src="@/assets/img/yao_share3.png" />
                    </a>
                  </li>
                  <li @click="copyShare">
                    <a>
                      <img src="@/assets/img/yao_share4.png" />
                    </a>
                  </li>
                  <!-- <li>
									<a href="">
										<img src="img/yao_share5.png" />
									</a>
								</li>
								<li>
									<a href="">
										<img src="img/yao_share6.png" />
									</a>
								</li> -->
                  <li @click="copyShare">
                    <a>
                      <img src="@/assets/img/yao_share7.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="yao_img">
            <img src="@/assets/img/yao.jpg" />
            <div class="yao_wen">
              <div class="yao_wen_l in3_yao_wen_l">
                <h6>我正在参加“{{ title }}”</h6>
              </div>
              <div class="yao_wen_r in3_yao_wen_r">
                <img src="@/assets/img/yao_er.jpg" />
                <p style="font-size: 12px; text-align: center">
                  圣儒教育 · 成公社
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 关闭 -->
        <div class="guanbi">
          <img src="@/assets/img/modal-close.png" @click="close" />
        </div>
      </div>
    </div>
    <Popup ref="popups" :type="type" :answer_order_id="answer_order_id"></Popup>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import Popup from "./modules/popup";
import { exerciseReport, situation } from "@/api/exam";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
    Popup,
  },
  data() {
    return {
      tabIndex: 0,
      motal: false,
      motalShow: false,
      motalShowFx: false,
      answer_order_id: null,
      reportType: null,
      type: 1,
      list: [],
      answerDetail: {},
      answerList: [],
    };
  },
  mounted() {},
  methods: {
    loadExerciseReport() {
      let _this = this;
      exerciseReport({
        answer_order_id: _this.answer_order_id,
      }).then((res) => {
        console.log(res, "报告");
        // 1模考客观2模考主观3客观题4主观题申论5主观题面试6主观题测评7客观题测评
        _this.reportType = res.result.report.type;
        _this.list = res.result.recommend;
        _this.answerDetail = res.result;
        if (_this.reportType == 7 || _this.reportType == 3) {
          _this.loadSituation();
        }
      });
    },
    loadSituation() {
      let _this = this;
      situation({
        answer_order_id: _this.answer_order_id,
      }).then((res) => {
        console.log(res, "答案");
        _this.answerList = res.result.paper_subject;
      });
    },
    openPopup(type) {
      this.type = type;
      this.$refs.popups.show();
    },
    openMyCourse() {
      this.$router.push({
        path: "/user/center/myCourse",
      });
    },
    openAnswer() {
      this.$router.push({
        path: "/answerDetail",
        query: {
          answer_order_id: this.answer_order_id,
        },
      });
    },
    exit() {
      this.$router.push({
        path: "/",
      });
    },
    toCampInfo(item) {
      this.$router.push({
        path: "/camp/info/" + item.goods.relation.camp_id,
      });
    },
    toCourseInfo(item) {
      //前往课程详情页
      this.$router.push({
        path: "/course/info/" + item.goods.relation.course_id,
      });
    },
    toRadioInfo(item) {
      this.$router.push({
        path: "/radio/info/" + item.goods.relation.radio_id,
      });
    },
    openShare() {
      this.motalShowFx = true;
    },
    copyShare() {
      let _this = this;
      var url = "http://srgongkaow.com/exam";
      _this.$copyText(url).then(() => {
        _this.$message.success("复制链接成功！");
        _this.motalShowFx = false;
      });
    },
    close() {
      this.motalShowFx = false;
    },
    openAnswer(i, index) {
      console.log(i, index);
      this.$router.push({
        path: "/answerDetail",
        query: {
          answer_order_id: this.answer_order_id,
          tiIndex: index,
        },
      });
    },
  },
  created() {
    this.answer_order_id = this.$route.query.answer_order_id;
    this.title = this.$route.query.title;
    this.loadExerciseReport();
  },
};
</script>
<style scoped>
.kc-list {
  height: 320px;
  margin-right: 20px;
  position: relative;
}
.tj_title {
  padding-left: 15px;
}
.tj_ps {
  padding-left: 15px;
}
</style>