<template>
  <div>
    <div class="login-bg"></div>
    <div class="login-box">
      <div class="clearfix">
        <p class="fn_l"><img src="@/assets/img/logo.jpg" /></p>
      </div>
      <div class="login mt30">
        <div class="tab-wrapper">
          <!-- 选择类别 -->
          <div class="xz_category">
            <div class="xz_category_top">
              <a @click="add([1])">跳过</a>
            </div>
            <div class="xz_category_nr">
              <h6>请选择类别</h6>
              <div class="xz_ca_nr">
                <ul>
                  <li
                    :class="i.checked ? 'active' : ''"
                    v-for="(i, index) in list"
                    @click="setLable(i.label_id, index)"
                  >
                    <p>{{ i.name }}</p>
                    <i></i>
                  </li>
                </ul>
              </div>

               <SrButton 
                      @click="add(label_id)"
                      :loading="add_loading">
                我选好了
            </SrButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import { label,addLabel } from "@/api/common";

export default {
  name: "RegisterBox",
  components: {
    SrButton,
  },
  data() {
    return {
      list: [],
      listIndex: null,
      label_id: [],
      add_loading:false
    };
  },
  created() {
    let _this = this;
    label().then((res) => {
      console.log(res, "分类");
      var data = res.result;
      data.map(function (n) {
        n.checked = false;
      });
      _this.list = data;
    });
  },
  methods: {
    setLable(id, index) {
      let _this = this;
      
      if (_this.list[index].checked) {
        _this.list[index].checked = false;
      console.log(index)
      _this.label_id.splice(index,1);
        console.log(_this.label_id);
        return;
      } else {
        _this.list[index].checked = true;
        _this.label_id.push(id);
        console.log(_this.label_id);
        return;
      }
    },
    add(id){
        let _this = this
        console.log(id)
        if(id.length == 0){
             _this.$message.warning('请选择类别')
             return
        }
         _this.login_loading = true
        addLabel({label:id}).then((res) =>{
            console.log(res,'跳转')
             if (res.status === 200) {
                 _this.$message.success('操作成功')
                  _this.login_loading = false
                 _this.$router.push('/')
             }else{
                  _this.login_loading = false
                  _this.$message.warning(res.msg)
             }
        })
    }
  },
};
</script>
<style scoped>
@import "../../assets/css/xz.css";
</style>