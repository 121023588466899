<template>
  <div>
    <SrNav></SrNav>
    <div class="warp clearfix mt50">
      <div class="personal">
        <div class="per_left">
          <div class="personal_l">
            <div class="per1">
              <div class="per1_i">
                <div class="per1_img1">
                  <el-upload
                    class="upload-demo"
                    action=""
                    drag
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="changeUpload"
                    style="width: 81px; height: 81px"
                  >
                    <img
                      style="border-radius: 50% 50%; width: 81px; height: 81px"
                      :src="$store.state.user.head_portrait.url"
                    />
                  </el-upload>
                </div>
                <div class="per1_photo">
                  <img src="@/assets/img/per1_photo.png" />
                </div>
                <div class="per1_vip" v-if="$store.state.user.now_user_member">
                  <img
                    :src="
                      $store.state.user.now_user_member.user_member_config_id ==
                      1
                        ? '/img/per1_vip.png'
                        : $store.state.user.now_user_member
                            .user_member_config_id == 2
                        ? '/img/per1_vip1.png'
                        : $store.state.user.now_user_member
                            .user_member_config_id == 3
                        ? '/img/per1_vip2.png'
                        : $store.state.user.now_user_member
                            .user_member_config_id == 4
                        ? '/img/per1_vip3.png'
                        : $store.state.user.now_user_member
                            .user_member_config_id == 5
                        ? '/img/per1_vip4.png'
                        : '/img/per1_vip5.png'
                    "
                  />
                </div>
              </div>
              <div class="per1_wen">
                <h6>{{ $store.state.user.nickname }}</h6>
                <p>极简学习，极速上岸</p>
              </div>
              <div class="per1_w1" v-if="$store.state.user.now_user_member">
                <div class="per1_w" style="float: none; display: inline-block">
                  <img
                    :src="
                      $store.state.user.now_user_member.user_member_config_id ==
                      1
                        ? '/img/w1.png'
                        : $store.state.user.now_user_member
                            .user_member_config_id == 2
                        ? '/img/w3.png'
                        : $store.state.user.now_user_member
                            .user_member_config_id == 3
                        ? '/img/w4.png'
                        : $store.state.user.now_user_member
                            .user_member_config_id == 4
                        ? '/img/w5.png'
                        : $store.state.user.now_user_member
                            .user_member_config_id == 5
                        ? '/img/w6.png'
                        : '/img/w7.png'
                    "
                  />
                </div>
                <p style="float: none; display: inline-block">·成公社</p>
                <!-- <a href="">终身</a> -->
              </div>
              <!-- <div class="per1_w2" v-if="$store.state.user.now_user_member">
                <p>{{ vipEndTime }}到期</p>
              </div>
              <div class="per1_w2" v-else>
                <p>您还不是会员</p>
              </div>
              <div class="per1_w3">
                <router-link
                  to="/user/center/payViper"
                  @click.native="getNav('')"
                >
                  <a href="">{{
                    $store.state.user.now_user_member ? "会员升级" : "会员购买"
                  }}</a>
                </router-link>
              </div> -->
            </div>
          </div>
          <div class="personal_l personal_l1">
            <ul>
              <li>
                <router-link
                  to="/user/center/myCamp"
                  @click.native="getNav('myCamp')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i1.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myCamp'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我的训练营</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myCamp' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/user/center/myCourse"
                  @click.native="getNav('myCourse')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i2.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myCourse'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我要学习</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myCourse' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/user/center/myRadioStation"
                  @click.native="getNav('myRadioStation')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i3.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myRadioStation'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我的电台</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myRadioStation'
                        ? 'jian personal_jian'
                        : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
                <li>
                <router-link
                  to="/user/center/myBook"
                  @click.native="getNav('myBook')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i7.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myBook'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我的图书</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myRadioStation'
                        ? 'jian personal_jian'
                        : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="personal_l personal_l1">
            <ul>
              <!-- <li>
                <router-link
                  to="/user/center/myWallet"
                  @click.native="getNav('myWallet')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i4.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myWallet'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我的钱包</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myWallet' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li> -->
              <li>
                <router-link
                  to="/user/center/myPoints"
                  @click.native="getNav('myPoints')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i5.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myPoints'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我的积分</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myPoints' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/user/center/myCorrects"
                  @click.native="getNav('corrects')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/index3_per_jia1.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'corrects'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>点评/批改</p>
                  </div>
                  <div
                    :class="
                      navActive == 'corrects' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
              <!-- <li>
                <router-link
                  to="/user/center/distribution"
                  @click.native="getNav('distribution')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i10.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'distribution'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>分销赚钱</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myPoints' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li> -->
              <li>
                <router-link
                  to="/user/center/myCoupon"
                  @click.native="getNav('myCoupon')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i11.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myCoupon'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我的优惠券</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myPoints' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="personal_l personal_l1">
            <ul>
              <li>
                <router-link
                  to="/user/center/myOrder"
                  @click.native="getNav('myOrder')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i6.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myOrder'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我的订单</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myOrder' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/user/center/recentLearning"
                  @click.native="getNav('recentLearning')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i7.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'recentLearning'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>最近学习</p>
                  </div>
                  <div
                    :class="
                      navActive == 'recentLearning'
                        ? 'jian personal_jian'
                        : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
            </ul>
          </div>

          <div class="personal_l personal_l1">
            <ul>
              <li>
                <router-link
                  to="/user/center/myCommunity"
                  @click.native="getNav('myCommunity')"
                >
                  <a>
                    <div class="personal_icon">
                      <img src="@/assets/img/collection.png" />
                    </div>
                    <div  :class="
                      navActive == 'myCommunity'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    ">
                      <p>我的收藏</p>
                    </div>
                    <div :class="
                      navActive == 'myCommunity'
                        ? 'jian personal_jian'
                        : 'jian'
                    ">
                      <img src="@/assets/img/jian.png" />
                    </div>
                  </a>
                </router-link>
              </li>
            </ul>
          </div>

          <div class="personal_l personal_l1">
            <ul>
              <li>
                <router-link
                  to="/user/center/myMessage"
                  @click.native="getNav('myMessage')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i8.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'myMessage'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>我的消息</p>
                  </div>
                  <div
                    :class="
                      navActive == 'myMessage' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/user/center/setting/userSetting"
                  @click.native="getNav('userSetting')"
                >
                  <div class="personal_icon">
                    <img src="@/assets/img/pre_i9.png" />
                  </div>
                  <div
                    :class="
                      navActive == 'userSetting'
                        ? 'personal_i_w personal_on'
                        : 'personal_i_w'
                    "
                  >
                    <p>设置</p>
                  </div>
                  <div
                    :class="
                      navActive == 'userSetting' ? 'jian personal_jian' : 'jian'
                    "
                  >
                    <img src="@/assets/img/jian.png" />
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <!-- 退出登录 -->
          <div class="log_out">
            <a @click="closeToken">退出登录</a>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
      </div>
      <p style="color: red; margin-top: 30px">提示：鼠标滚轮可以放大缩小图片</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finish" :loading="loading"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <SrFooter></SrFooter>
  </div>
</template>

<script>
import { uploadImage } from "@/api/common";
import { setHeadPortrait } from "@/api/user";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import storage from "@/utils/storage";
import router from "@/router";
export default {
  name: "UserView",
  components: {
    SrNav,
    SrFooter,
  },
  props: {},
  data() {
    return {
      navActive: "",
      vipEndTime: "",
      dialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [7, 7], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      picsList: [], //页面显示的数组
      loading: false,
    };
  },
  methods: {
    getNav(e) {
      console.log(e);
      this.navActive = e;
    },
    closeToken() {
      storage.removeItem("ACCESS_TOKEN");
      router.push("/");
      window.location.reload();
    },
    addImage(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("image", file);
      _self.upload_loading = true;
      uploadImage(formData).then((res) => {
        console.log(res, "------");
        _self.upload_loading = false;
        if (res.status === 200) {
          _self.nickImg = res.result.url;
          _self.nickImgId = res.result.image_id;
          _self.setHeadPortrait();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    addFile(file) {},
    // 提交头像
    setHeadPortrait() {
      let _this = this;
      if (_this.nickImgId == "") {
        this.$message.warning("请先上传头像");
        return;
      }
      _this.nickImg_loading = true;
      setHeadPortrait({ img: _this.nickImgId }).then((res) => {
        console.log(res, "------");
        _this.upload_loading = false;
        if (res.status === 200) {
          _this.nickImg_loading = false;
          _this.$message.success("修改成功");
          setTimeout(() => {
            _this.$router.go(0);
          }, 1000);
        } else {
          _this.$message.warning(res.msg);
          _this.nickImg_loading = false;
        }
      });
    },

    changeUpload(file, fileList) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        return false;
      }
      this.fileinfo = file;

      console.log(file);
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.option.img = window.URL.createObjectURL(file.raw);
        console.log(this.option.img, "图片");
        this.dialogVisible = true;
      });
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        var fileName = this.fileinfo.name;
        console.log(data);
        var file = new File([data], fileName, {
          type: data.type,
          lastModified: Date.now(),
        });
        console.log(file);
        this.loading = true;
        this.addImage(file);
        //上传阿里云服务器
        // client().put(fileName, data).then(result => {
        //   this.dialogVisible = false
        //   this.picsList.push(result.url)
        // }).catch(err => {
        //   console.log(err)
        //   this.loading = false
        // })
      });
    },
  },
  created() {
    if (this.$store.state.user.now_user_member) {
      this.vipEndTime = this.$store.state.user.now_user_member.failure_time.substring(
        0,
        10
      );
    }
  },
};
</script>
<style scoped>
.per1_img1 img {
  cursor: pointer;
}

.cropper {
  width: 500px;
  height: 500px;
}
.cropper img {
  width: 500px;
  height: 500px;
}
.cropper-content {
  width: 500px;
  height: 500px;
  margin: 0 auto;
}
>>> .el-upload-dragger {
  border: none !important;
  text-align: center;
  background-color: transparent !important;
}
ol,
ul,
dl {
  margin-bottom: 0;
}
</style>