import request from '@/utils/request'

/**
 * 获取讲义下载地址
 * @param parameter
 */
export function download(parameter) {
    return request({
        url: '/api/school/handout/download',
        method: 'post',
        data: parameter
    })
}
