<template>
  <div>
    <Pintuan
      v-if="course.goods.notend_pintuan && course.has_unlock == 0"
      :course="course"
    ></Pintuan>
    <!-- 课程 -->
    <div v-else class="warp clearfix mt50 kcxq-box">
      <div class="kcxq-l fn_l">
        <a-skeleton v-if="loading" :title="false" :paragraph="{ rows: 6 }" />
        <img v-else :src="course.first_cover.url" />
      </div>
      <div class="kcxq-r fn_r">
        <a-skeleton v-if="loading" :title="false" :paragraph="{ rows: 6 }" />
        <div v-else>
          <h3 class="mt10">{{ course.name }}</h3>
          <p class="color-666 mt20" style="font-size:14px">
            购买后有效期：{{ course.valid_days }}天 {{ course.period }}课时
          </p>
          <p class="f12 color-main" :style="{ color: course.subtitle_color }">
            {{ course.subtitle }}
          </p>
          <p style="font-size:14px">
            <span class="color-999">
              限售{{ course.goods.sales_restriction }}人 | 已报名{{
                course.goods.sale_amount
              }}人
            </span>
            <img
              v-if="course.goods.notend_seckill"
              src="@/assets/img/KC-time.png"
            />
            <span class="color-pink" v-if="course.goods.notend_seckill">
              距结束：<a-statistic-countdown
                :value="course.goods.notend_seckill.end_at | toTime"
                format="D 天 H 时 m 分 s 秒"
              />
            </span>
          </p>
          <p
            class="clearfix mt30"
            v-if="course.vip_free == 1 && $store.state.user.now_user_member"
          >
            <router-link
              class="fn_r bg-main color-fff"
              :to="{
                path: '/course/detail',
                query: {
                  course_id: course.course_id,
                  course_category_id: course_category_id,
                },
              }"
              >进入课程
            </router-link>
          </p>
          <p class="clearfix mt30" v-else>
            <span
              v-if="course.goods.order_price !== course.goods.real_price"
              class="fn_l f26 color-main"
              >￥{{ course.goods.order_price }}
              <s class="color-999" style="font-size:14px">￥{{ course.goods.real_price }}</s>
            </span>
            <span v-else class="fn_l f26 color-main"
              >￥{{ course.order_price }}
              <s class="color-999" style="font-size:14px">￥{{ course.goods.real_price }}</s>
            </span>
            <!--判断按钮状态-->
            <router-link
              v-if="buttonStatus === 1"
              class="fn_r bg-main color-fff"
              :to="{
                path: '/course/detail',
                query: { course_id: course.course_id,course_category_id: course_category_id },
              }"
              >进入课程
            </router-link>
            <a
              v-if="buttonStatus === 2"
              class="fn_r bg-999 border-none color-fff"
              href=""
              >报名结束</a
            >
            <a
              v-if="buttonStatus === 3"
              class="fn_r bg-999 border-none color-fff"
              href=""
              >人员已满</a
            >
            <!--判断秒杀状态-->
            <span v-if="buttonStatus === 4">
              <a
                v-if="course.goods.notend_seckill.status === 1"
                class="fn_r bg-main color-fff f14 yx-msBut"
                href=""
              >
                <span>抢购即将开始</span><br />
                <span class="seckill-countdown">
                  <a-statistic-countdown
                    :value="course.goods.notend_seckill.start_at | toTime"
                    format="D 天 H 时 m 分 s 秒"
                    @finish="seckillStart"
                  />
                </span>
              </a>
              <a
                v-if="course.goods.notend_seckill.status === 2"
                class="fn_r bg-main color-fff"
                @click="seckill"
                >{{course.order_price == 0?'免费报名':course.order_price == '0.00'?'免费报名':'立即秒杀'}} </a
              >
            </span>
            <a
              v-if="buttonStatus === 5"
              class="fn_r bg-main color-fff"
              @click="buy"
              >{{course.order_price == 0?'免费报名':course.order_price == '0.00'?'免费报名':'立即购买'}}</a
            >
            <SrButton
              v-if="buttonStatus === 5 && course.order_price > 0"
              @click="addCart"
              style="width: 130px; display: inline-block; float: right"
              height="42px"
              padding="0"
              font-size="14px"
              :loading="cart_btn_loading"
              type="hollow"
              >加入购物车
            </SrButton>
            <!--<a class="fn_r">加入购物车</a>-->
          </p>
        </div>
      </div>
    </div>
    <div class="warp clearfix mt30">
      <div class="kcxq-con fn_l">
        <SrTabs :list="tabs_list" @change="changeTab"></SrTabs>

        <div class="tab-content">
          <CourseIntroduce
            v-if="tag_index === 0"
            :loading="loading"
            :course="course"
          ></CourseIntroduce>
          <CourseChapter
            v-if="tag_index === 1"
            :loading="loading"
            :course_id="course.course_id"
            :list="course.course_chapter"
            :type="course.has_unlock"
            :vip_free="course.vip_free"
            :course_category_id="course_category_id"
          ></CourseChapter>
          <CommentList
            v-if="tag_index === 2"
            :course_id="course.course_id"
          ></CommentList>
          <HandoutList
            v-if="tag_index === 3"
            :list="course.handout"
          ></HandoutList>
        </div>
      </div>
      <ReCourse></ReCourse>
    </div>
  </div>
</template>

<script>
import SrNav from "@/layout/SrNav";
import { info } from "@/api/school/course";
import CourseIntroduce from "./modules/CourseIntroduce";
import CourseChapter from "../detail/modules/CourseChapter";
import CommentList from "./modules/CommentList";
import HandoutList from "@/views/course/modules/HandoutList";
import SrButton from "@/components/SrButton";
import { add } from "@/api/cart";
import Pintuan from "./modules/Pintuan";
import SrTabs from "@/components/SrTabs";
import ReCourse from "@/layout/recommend/ReCourse";
export default {
  name: "index",
  components: {
    SrNav,
    CourseIntroduce,
    CommentList,
    CourseChapter,
    HandoutList,
    SrButton,
    Pintuan,
    SrTabs,
    ReCourse,
  },
  data() {
    return {
      loading: true,
      cart_btn_loading: false,
      course_id: 0,
      course: {
        goods: {},
        first_cover: {},
      },
      tabs_list: ["课程介绍", "课程目录", "学员评价", "讲义下载"],
      tag_index: 0,
      invitation_user_id: null,
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  computed: {
    buttonStatus() {
      // 判断按钮状态 1已购买 2已过期 3已售完 4有秒杀 5可购买
      var course = this.course;
      if (course.has_unlock !== 0) {
        return 1;
      }
      if (new Date(course.end_at) < new Date()) {
        return 2;
      }
      if (course.goods.sale_amount >= course.goods.sales_restriction) {
        return 3;
      }
      if (course.goods.notend_seckill) {
        return 4;
      }
      return 5;
    },
  },
  created() {
    var _self = this;
    if (document.body.clientWidth <= 800) {
      window.location.href =
        "http://wap.srgongkaow.com/school/courseDetail?course_id=" +
        this.$route.params.course_id;
    }

    _self.course_id = this.$route.params.course_id;
    _self.course_category_id = this.$route.params.course_category_id;
    info({ course_id: _self.course_id }).then((res) => {
      console.log(res,'info')
      if (res.status === 200) {
        var data = res.result;
        if (data.goods.vip_price.length > 0) {
          data.goods.vip_price.map(function (n) {
            if (_self.$store.state.user.now_user_member) {
              if (
                n.user_member_config_id ==
                _self.$store.state.user.now_user_member.user_member_config
                  .user_member_config_id
              ) {
                data.order_price = n.price;
              }
            } else {
              data.order_price = data.goods.order_price;
            }
          });
        } else {
          data.order_price = data.goods.order_price;
        }
        _self.course = data;
        _self.loading = false;
        document.title = res.result.name + "-" + document.title;
      }
    });
    _self.invitation_user_id = this.$route.query.user_id;
  },
  watch: {
    $route() {
      var _self = this;
      _self.course_id = this.$route.params.course_id;
      info({ course_id: _self.course_id }).then((res) => {
        if (res.status === 200) {
          _self.course = res.result;
          _self.loading = false;
          document.title = res.result.name + "-" + document.title;
        }
      });
    },
  },
  methods: {
    changeTab(e) {
      this.tag_index = e;
    },
    seckillStart() {
      this.course.goods.notend_seckill.status = 2;
      this.$forceUpdate();
    },
    buy() {
      let routeData = this.$router.resolve({
        path: "/buy/settlement",
        query: {
          goods_id: this.course.goods.goods_id,
          invitation_user_id: this.invitation_user_id,
          sku_id: "",
          num: 1,
        },
      });
      window.open(routeData.href, "_blank");
    },
    seckill() {
      var seckill = this.course.goods.notend_seckill;
      let routeData = this.$router.resolve({
        path: "/buy/seckill",
        query: {
          goods_id: seckill.goods.goods_id,
          seckill_id: seckill.seckill_id,
          sku_id: "",
          num: 1,
        },
      });
      window.open(routeData.href, "_blank");
    },
    addCart() {
      // 加入购物车
      var _self = this;
      _self.cart_btn_loading = true;
      var goods = _self.course.goods;

      if (!goods) {
        return false;
      }
      add(goods).then((res) => {
        _self.cart_btn_loading = false;
        if (res.status === 200) {
          _self.$store.commit("ADD_CART", goods);
          _self.$message.success("添加成功");
        } else {
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}

.seckill-countdown >>> .ant-statistic {
  display: inline-block;
}

.seckill-countdown >>> .ant-statistic-content {
  display: inline-block;
  font-size: 14px;
}

.seckill-countdown >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #fff;
}
</style>
