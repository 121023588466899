<template>
    <div id="notice">
        <div class="placeholder"></div>
    </div>
</template>

<script>
    export default {
        name: "ZhiboNotice",
        mounted() {
            var _self = this
            setTimeout(() => {
                _self.init()
            }, 1000)
        },
        methods: {
            init() {
                var eventEmitter = BJY.eventEmitter;
                var notice = BJY.Notice.create({
                    element: $('#notice .placeholder'), // 父容器
                    replace: true,
                    defaultContent: '老师还没有发布公告', // 默认无公告时显示文本
                    maxLength: 140, // 公告文本最大长度
                    contentPlaceholder: '请输入公告正文内容', // 公告文本编辑框提示
                    linkPlaceholder: '请输入公告跳转链接（可选）', // 公告链接编辑框提示
                    linkErrorHint: 'URL 格式错误', // url 错误提示
                    foldDefault: false,  // 公告是否默认折叠(默认折叠后新公告不会主动展开)
                });
                eventEmitter.trigger(
                    eventEmitter.NOTICE_REQ
                );
            }
        }
    }
</script>

<style scoped>

</style>
