<template>
    <div>
        <SrNav></SrNav>
        <router-view/>
        <SrFooter></SrFooter>
    </div>
</template>

<script>
    import SrNav from '@/layout/SrNav'
    import SrFooter from '@/layout/SrFooter'

    export default {
        name: 'RouteView',
        components: {
            SrNav,
            SrFooter
        },
        props: {},
        data() {
            return {}
        }
    }
</script>
