<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的订单</h6>
      </div>
      <div class="per8_order">
        <ul>
          <li v-for="i in list">
            <router-link
              :to="{
                path: '/user/center/myOrderDetail',
                query: { id: i.order_id },
              }"
            >
              <div class="per8_order1">
                <p>
                  订单号：{{ i.num
                  }}<span>{{
                    i.status == 1
                      ? "未支付"
                      : i.status == 2
                      ? "已支付"
                      : i.status == 3
                      ? "已过期"
                      : i.status == 4
                      ? "已取消"
                      : i.status == 5
                      ? "已退款"
                      : i.status == 6
                      ? "退款审核"
                      : "拒绝退款"
                  }}</span>
                </p>
              </div>
            </router-link>
            <router-link
              :to="{
                path: '/user/center/myOrderDetail',
                query: { id: i.order_id },
              }"
            >
              <div class="per8_order2">
                <ul>
                  <li v-for="item in i.order_item">
                    <p>{{ item.goods.name }}</p>
                    <!-- <span>协议班</span> -->
                    <h6>￥{{ item.goods.order_price }}</h6>
                  </li>
                </ul>
              </div>
            </router-link>
            <div class="per8_order3 per8_order3_nr" v-if="i.status == 1">
              <p>{{ i.created_at }}</p>
              <span class="order_on2" @click="goPay(i.order_id)">去支付</span>
              <span class="order_on1" @click="delOrder(i.order_id)"
                >取消订单</span
              >
            </div>
            <div class="per8_order3" v-else>
              <p>{{ i.created_at }}</p>
              <span v-if="i.status != 4">实际付款：￥{{ i.pay_price }}</span>
            </div>
          </li>
          <!-- <li>
            <a href="">
              <div class="per8_order1">
                <p>订单号：201232323232<span>拒绝退款</span></p>
              </div>
              <div class="per8_order2">
                <ul>
                  <li>
                    <p>2021公务员申论课程</p>
                    <span>协议班</span>
                    <h6>￥99.00</h6>
                  </li>
                  <li>
                    <p>2021公务员申论课程</p>
                    <h6>￥99.00</h6>
                  </li>
                </ul>
              </div>
              <div class="per8_order3">
                <p>2020-09-10 10:00:00</p>
                <span>实际付款：￥30</span>
              </div>
              <div class="per8_order4">
                <p>拒绝原因：退款信息不真实哦~</p>
              </div>
            </a>
          </li> -->
          <div class="zanwu" v-if="list.length == 0">
            <img src="@/assets/img/zanwu.png" alt="" />
            暂无数据
          </div>
        </ul>
      </div>
      <!-- 页码 -->
      <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
      ></SrPaginate>

      <div class="motal" v-show="showMotal">
        <div class="motal_box">
          <div class="motal_title">请选择支付方式</div>
          <div class="motal_input_box">
            <div class="pay_bottom">
              <div class="pay_bo_l">
                <div class="pay_wechat">
                  <img src="@/assets/img/wechat1.png" />
                  <p>微信支付</p>
                </div>
                <div class="pay_wechat">
                  <img src="@/assets/img/wechat2.png" />
                  <p>支付宝支付</p>
                </div>
              </div>
              <div class="pay_bo_r">
                <div class="pay_1">
                  <div class="radio">
                    <input
                      type="radio"
                      id="radio1"
                      value="2"
                      v-model="payType"
                      name="pay"
                    />
                    <label for="radio1"></label>
                  </div>
                  <div class="radio radio1">
                    <input
                      type="radio"
                      id="radio2"
                      value="1"
                      v-model="payType"
                      name="pay"
                    />
                    <label for="radio2"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SrButton
            style="width: 250px; margin: 0 auto; margin-top: 20px"
            @click="orderPay"
            font-size="14px"
            height="40px"
            padding="0 20px"
            :loading="pay_loading"
          >
            立即支付
          </SrButton>
        </div>
        <img
          class="motal_close"
          @click="closeMotal"
          src="@/assets/img/modal-close.png"
          alt=""
        />
      </div>
    </div>
     <WechatScan ref="WechatScan"></WechatScan>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import SrPaginate from "@/components/SrPaginate";
import { order,orderCancel } from "@/api/user";
import { web } from "@/api/pay";
import WechatScan from "../../buy/settlement/modules/WechatScan";
export default {
  name: "MyOrder",
  components: {
    SrPaginate,
    SrButton,
     WechatScan
  },
  data() {
    return {
      list: [],
      pagination: {
        page: 1,
        page_size: 10,
      },
      showMotal: false,
      order_id: null,
      pay_price: null,
      payType: "",
      pay_loading: false,
    };
  },
  methods: {
    loadOrder() {
      let _this = this;
      order({ ...this.pagination }).then((res) => {
        console.log(res, "积分");
        _this.list = res.result.items;
        _this.pagination = res.result.pagination;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadOrder();
    },
    goPay(id) {
      this.showMotal = true;
      this.order_id = id;
    },
    closeMotal() {
      this.showMotal = false;
    },
    orderPay() {
      let _this = this;
      if (_this.payType == "") {
        _this.$message.warning("请选择支付方式");
      } else {
        _this.pay_loading = true;
        web({ order_id: _this.order_id, pay_type: _this.payType }).then(
          (res) => {
            if (res.status == 200) {
              if (_this.payType == 1) {
                document.querySelector("body").innerHTML = res.result;
                document.forms[0].submit();
              } else {
                _this.$refs.WechatScan.show(res.result);
              }
            } else {
              _this.$message.warning(res.msg);
              _this.pay_loading = false;
            }
          }
        );
      }
    },
    // 取消订单
    delOrder(id) {
      console.log(id,'-----')
      let _this = this
       this.$srmsg
        .confirm({
          title: "提示",
          content: "确定要取消订单吗",
        })
        .then(() => {
          orderCancel({order_id:id}).then((res) =>{
            console.log(res)
            if(res.status == 200){
              _this.$message.success("操作成功");
                setTimeout(() => {
                _this.loadOrder();
              }, 1000);
            }
          })
        })
        .catch(() => {});
    },
  },
  created() {
    this.loadOrder();
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
.zanwu {
  margin: 0 auto;
  text-align: center;
  margin-top: 130px;
}
.zanwu img {
  width: 200px;
  margin: 0 auto;
}
.per8_order > ul > li > .per8_order2 {
  cursor: pointer;
}
.order_on2 {
  cursor: pointer;
}
.order_on1 {
  cursor: pointer;
}
.motal_close {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
}
.wdtx img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 0;
}
.wdtx p {
  position: relative;
  width: 160px;
  height: 160px;
  display: inline-block;
  margin-left: 20px;
}
.wdtx input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  opacity: 0;
}
table {
  text-align: center;
}
.motal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  top: 0;
  left: 0;
}
.motal_box {
  width: 800px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 120px;
  padding: 30px;
  overflow: hidden;
}
.motal_title {
  font-size: 20px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
}
.motal_input_box {
  margin: 0 auto;
  width: 700px;
  overflow: hidden;
}
.motal_input {
  width: 100%;
  margin-bottom: 20px;
}
.motal_input p {
  display: inline-block;
  font-size: 16px;
  width: 100px;
}
.motal_input span {
  color: red;
  padding-right: 10px;
}
.motal_input input {
  display: inline-block;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  width: calc(100% - 100px);
}
.motal_input select {
  width: calc(100% / 3 - 40px);
  display: inline-block;
  border: 1px solid #eaeaea;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-radius: 4px;
  margin-right: 10px;
}
.motal_input select:last-child {
  margin-right: 0;
}

input[type="checkbox"] {
  outline: none;
  -webkit-appearance: none;
  position: relative;
  vertical-align: middle;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  min-height: 20px;
  min-width: 20px;
}
input[type="checkbox"]:checked {
  border: 1px solid #eaeaea;
  outline: none;
  background: #3561c7;
}
input[type="checkbox"]:checked::after {
  content: "\00a0";
  display: inline-block;
  border: 2px solid #fff;
  border-top-width: 0;
  border-right-width: 0;
  width: 16px;
  height: 8px;
  -webkit-transform: rotate(-50deg);
  position: absolute;
  top: 2px;
  left: 1px;
}
.motal_check span {
  margin-left: 10px;
  margin-top: 10px;
}
</style>