<template>
  <div>
    <div class="lxbaogao-t">
      <div class="warp pos-rel te_c">
        <a class="color-666" href="">&lt; 退出</a>
        <p>[ 单选题 ] 阅读理解-关联词-转折</p>
      </div>
    </div>
    <!-- 答题报告 -->
    <div class="warp mt30">
      <div class="baogao-box clearfix mt20">
        <div class="baogao-boxL">
          <div class="shenlun">
            <img src="@/assets/img/shenlun-img.jpg" />
            <p><a @click="open">我要批改</a><a href="">查看批改</a></p>
          </div>
        </div>
        <div class="baogao-boxR">
          <div class="shenglun-jg">
            <p>2′44″</p>
            <p>答题时间</p>
          </div>
          <div class="te_c mt20 color-666">
            <p>勤能补拙，您还需要加油！</p>
            <p class="mt10">班次名称/题目位置 狂刷700题资料分析</p>
          </div>
          <div class="lianxi-jiexi mt20">
            我们正在对您的考试做出分析，请及时参加测评分析课
          </div>
          <div class="mokao-jiexi mt50 te_c">
            <a href="">答案解析</a>
            <a class="sanqi-fx" @click="openShare">分享得积分</a>
          </div>
        </div>
      </div>

      <!-- 分享得积分 -->
      <div class="kapian sanqi-fxTC" v-show="motal">
        <div class="as">
          <div class="txtx">
            <div class="txtx_wen">
              <p>分享后方可预约成功！</p>
            </div>
            <div class="txtx_zhuang">
              <a>分享</a>
              <a class="guanbi guanbi_a">取消</a>
            </div>
          </div>
        </div>
      </div>

      <!-- 推荐课程 -->
      <div class="clearfix mt20">
        <p class="fn_l f22">
          <img class="dis-inb" src="@/assets/img/KC-tbg.png" /> 推荐课程
        </p>
      </div>
      <div class="clearfix mt20">
        <div class="kc-list kc-listBg fn_l ml0">
          <a href="课程-课程详情.html">
            <div class="kc-listTex">
              <p class="f12 color-main">9.9体验课</p>
              <h3>2021公务员课程包</h3>
              <p class="f14">2020.09.27--2021.03.15</p>
              <ul class="kc-avatar clearfix">
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋江洋</p>
                </li>
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋</p>
                </li>
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋</p>
                </li>
              </ul>
              <p class="clearfix f14 color-666">
                <span class="fn_l"
                  >限售<em class="color-main">1000人</em> | 已报名<em
                    class="color-pink"
                    >200人</em
                  ></span
                >
                <span class="fn_r"
                  ><em class="f22 color-main">￥99.00</em></span
                >
              </p>
            </div>
          </a>
        </div>
        <div class="kc-list fn_l">
          <a href="课程-课程包详情.html">
            <div class=""><img src="@/assets/img/CK-img.png" /></div>
            <div class="kc-listTex">
              <h3>2021公务员课程包</h3>
              <p class="f12 color-main">9.9体验课</p>
              <p class="clearfix f14 color-666">
                <span class="fn_l"
                  >限售<em class="color-main">1000人</em> | 已报名<em
                    class="color-pink"
                    >200人</em
                  ></span
                >
                <span class="fn_r"
                  ><s class="color-999">￥199.00</s>
                  <em class="f22 color-main">￥99.00</em></span
                >
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- 答案解析 -->
    <div class="kapian sanqi-fxJX" v-show="showMotal">
      <div class="as">
        <div class="txtx">
          <div class="txtx_wen">
            <h3 class="te_c">提示</h3>
            <p>本次消耗次数：1次</p>
            <p>剩余次数：10次</p>
          </div>
          <div class="txtx_zhuang">
            <a href="">购买次数</a>
            <a class="guanbi guanbi_a">消耗次数</a>
          </div>
        </div>
      </div>
    </div>
 <!-- 测评弹窗 -->
	<div class="kapian kapian5 tc_show" v-show="showShare">
		<div class="as">
			<div class="yao_friend  in3_yao_friend">
				<div class="yao_share1">
					<div class="yao_share">
						<span>分享</span>
						<div class="yao_share_nr  index3_yao_share_nr">
							<ul>
								<li>
									<a href="">
										<img src="@/assets/img/yao_share1.png" />
									</a>
								</li>
								<!-- <li>
									<a href="">
										<img src="img/yao_share2.png" />
									</a>
								</li> -->
								<li>
									<a href="">
										<img src="@/assets/img/yao_share3.png" />
									</a>
								</li>
								<li>
									<a href="">
										<img src="@/assets/img/yao_share4.png" />
									</a>
								</li>
								<!-- <li>
									<a href="">
										<img src="img/yao_share5.png" />
									</a>
								</li>
								<li>
									<a href="">
										<img src="img/yao_share6.png" />
									</a>
								</li> -->
								<li>
									<a href="">
										<img src="@/assets/img/yao_share7.png" />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="yao_img">
					<img src="@/assets/img/yao.jpg" />
					<div class="yao_wen">
						<div class="yao_wen_l  in3_yao_wen_l  in3_yao_wen_l2">
							<h6>我正在参加“模考试卷的名称”</h6>
							<p>考试类型</p>
						</div>
						<div class="yao_wen_r  in3_yao_wen_r">
							<img src="@/assets/img/yao_er.jpg" />
							<p>圣儒教育 · 成公社</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 关闭 -->
			<div class="guanbi" style="cursor: pointer;" @click="close">
				<img src="@/assets/img/modal-close.png" />
			</div>
		</div>
	</div>

    <SrFooter></SrFooter>
  </div>
</template>
<script>
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";

export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
  },
  data() {
    return {
      tabIndex: 0,
      motal: false,
      showMotal: false,
      showShare:false
    };
  },
  mounted() {},
  methods: {
    open() {
      this.showMotal = true;
    },
    openShare(){
      this.showShare = true;
    },
    close(){
      this.showShare = false;
    }
  },
  created() {},
};
</script>
<style scoped>
.kc-list {
  height: 100%;
  margin-right: 20px;
}
.yao_share_nr{
    width: auto!important;
}
</style>