<template>
    <div class="top-bg">
        <div class="warp clearfix">
            <div class="logo fn_l">
                <img src="@/assets/img/logo.jpg">
            </div>
            <div class="menu fn_l">
                <ul>
                    <li>
                        <router-link :class="[checkActive('index')?'active':'']"
                                     to="/">首页
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link :class="[checkActive('camp')?'active':'']"
                                     to="/camp">训练营
                        </router-link>
                    </li> -->
                    <li>
                        <router-link :class="[checkActive('course')?'active':'']"
                                     to="/course">课程
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link :class="[checkActive('radio')?'active':'']"
                                     to="/radio">电台
                        </router-link>
                    </li> -->
                       <li>
                        <router-link :class="[checkActive('storeIndex')?'active':'']"
                                     to="/store">商城
                        </router-link>
                    </li>
                     <!-- <li>
                        <router-link :class="[checkActive('community')?'active':'']"
                                     to="/community">社区
                        </router-link>
                    </li> -->
                    <li>
                        <router-link :class="[checkActive('app')?'active':'']"
                                     to="/appDownload/index">APP下载
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="top-r fn_r">
                <span class="a-span" v-if="$store.state.user.is_login">
                    <router-link to="/user" class="a-border">
                    <em><img class="top-avatar"
                             :src="$store.state.user.head_portrait.url"></em>
                    <span>hi，{{$store.state.user.nickname}}</span>
                    </router-link>
                    <!--<a-badge :count="$store.state.cart.list.length">-->
                        <router-link to="/buy/cart">购物车</router-link>
                    <!--</a-badge>-->
                </span>
                <span class="a-span" v-else>
                    <router-link :to="{path:'/login',query:{action:'login'}}">登录</router-link>
                    <router-link :to="{path:'/login',query:{action:'register'}}">注册</router-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SrNav",
        methods: {
            checkActive(route) {
                if (this.$route.meta.nav === route) {
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>

<style scoped>
.menu ul li {
    float: left;
    width: 100px;
    font-size: 18px;
    text-align: center;
}
</style>
