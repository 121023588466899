<template>
  <div class="warp clearfix mt50 kcxq-box">
    <div class="dtxq-l fn_l">
      <img style="width: 100%" :src="radio.image.url" />
    </div>
    <div class="dtxq-r fn_r">
      <h3>{{ radio.name }}</h3>
      <p class="color-666 mt10" style="font-size: 14px">
        <span>开课时间：</span>
        <span>{{ $moment(radio.start_at).format("YYYY.MM.DD") }}</span>
        <span>--</span>
        <span>{{ $moment(radio.end_at).format("YYYY.MM.DD") }}</span>
        <span>{{ radio.period }}</span>
        <span>课时</span>
      </p>
      <p class="f12 color-main" :style="{ color: radio.subtitle_color }">
        {{ radio.subtitle }}
      </p>
      <p style="font-size: 14px">
        <span class="color-999">
          限售{{ radio.goods.sales_restriction }}人 | 已报名{{
            radio.goods.sale_amount
          }}人
        </span>
      </p>
      <div class="pintuan-box mt10">
        <div class="clearfix pintuan-t">
          <p class="fn_l">{{ group_list.length }}人在拼单，可直接参与</p>
          <span
            class="fn_r color-999 modal-more"
            @click="$refs.PintuanUserList.show(group_list)"
            >查看更多&gt;&gt;</span
          >
        </div>
        <div class="pintuan-m">
          <a-empty
            v-if="group_list.length === 0"
            :imageStyle="{ width: '100%' }"
            description="暂时无人发起拼团"
          />
          <ul v-else>
            <li
              v-for="item in group_list.slice(0, 2)"
              :key="item.pintuan_group_id"
            >
              <p>
                <img class="pintuan-mImg" :src="item.chief.head_portrait.url" />
              </p>
              <p>{{ item.chief.nickname }}</p>
              <p class="te_r">
                还差{{
                  pintuan.number - item.pintuan_user_count
                }}人拼成<br />剩余
                <span>
                  <a-statistic-countdown
                    :value="item.expire_at | toTime"
                    format="D 天 H 时 m 分 s 秒"
                  />
                </span>
              </p>
              <p class="te_r">
                <SrButton
                  style="width: 100px; float: right"
                  height="40px"
                  padding="0"
                  font-size="14px"
                  @click="$refs.JoinPintuan.show(item, pintuan)"
                  >去拼团
                </SrButton>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <p class="clearfix mt20">
        <span class="fn_l f26 color-main"
          >￥{{ radio.goods.order_price }}
          <s class="color-999" style="font-size:14px">￥{{ radio.goods.real_price }}</s>
        </span>
        <a @click="createPintuan" class="fn_r bg-main color-fff yx-msBut" style="font-size:14px">
          <span>三人成团</span><br />
          <span>立即购买</span>
        </a>
        <a @click="addCart" class="fn_r yx-msBut" style="font-size:14px">
          <span>￥{{ radio.goods.real_price }}</span
          ><br />
          <span>加入购物车</span>
        </a>
      </p>
    </div>
    <!--拼团列表窗口-->
    <PintuanUserList :pintuan="pintuan" ref="PintuanUserList">
      <div class="pindan-kc clearfix mt30">
        <div class="pindan-kcVideo fn_l">
          <img class="ml0" :src="radio.image.url" />
        </div>
        <div class="pindan-kcTex fn_r">
          <h3>{{ radio.name }}</h3>
          <p class="color-666 mt10" style="font-size:14px">
            开课时间：
            {{ $moment(radio.start_at).format("YYYY.MM.DD") }}
            --
            {{ $moment(radio.end_at).format("YYYY.MM.DD") }}
            {{ radio.period }}
            课时
          </p>
          <h4 class="mt50 f16 price">
            拼团价：
            <span class="color-red">￥{{ radio.goods.order_price }}</span>
            <s class="color-999">￥{{ radio.goods.real_price }}</s>
          </h4>
        </div>
      </div>
    </PintuanUserList>
    <!--加入拼团窗口-->
    <JoinPintuan ref="JoinPintuan">
      <div class="pindan-kc clearfix mt30">
        <div class="pindan-kcVideo fn_l">
          <img class="ml0" :src="radio.image.url" />
        </div>
        <div class="pindan-kcTex fn_r">
          <h3>{{ radio.name }}</h3>
          <p class=" color-666 mt10" style="font-size:14px">
            开课时间：
            {{ $moment(radio.start_at).format("YYYY.MM.DD") }}
            --
            {{ $moment(radio.end_at).format("YYYY.MM.DD") }}
            {{ radio.period }}
            课时
          </p>
          <h4 class="mt50 f16 price">
            拼团价：
            <span class="color-red">￥{{ radio.goods.order_price }}</span>
            <s class="color-999">￥{{ radio.goods.real_price }}</s>
          </h4>
        </div>
      </div>
    </JoinPintuan>
  </div>
</template>

<script>
import { groupList } from "@/api/pintuan";
import SrButton from "@/components/SrButton";
import PintuanUserList from "@/components/Pintuan/PintuanUserList";
import JoinPintuan from "@/components/Pintuan/JoinPintuan";
import { add } from "@/api/cart";

export default {
  name: "Pintuan",
  components: {
    PintuanUserList,
    JoinPintuan,
    SrButton,
  },
  props: {
    radio: {},
  },
  data() {
    return {
      pintuan: this.radio.goods.notend_pintuan,
      group_list: [],
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  mounted() {
    this.getGroupList();
  },
  methods: {
    getGroupList() {
      var _self = this;
      groupList({ pintuan_id: this.pintuan.pintuan_id }).then((res) => {
        _self.group_list = res.result;
      });
    },
    createPintuan() {
      let routeData = this.$router.resolve({
        path: "/buy/pintuan",
        query: {
          goods_id: this.pintuan.goods.goods_id,
          pintuan_id: this.pintuan.pintuan_id,
          sku_id: "",
          sku_num: 1,
        },
      });
      window.open(routeData.href, "_blank");
    },
    addCart() {
      // 加入购物车
      var _self = this;
      _self.cart_btn_loading = true;
      var goods = _self.radio.goods;

      if (!goods) {
        return false;
      }
      add(goods).then((res) => {
        _self.cart_btn_loading = false;
        if (res.status === 200) {
          _self.$store.commit("ADD_CART", goods);
          _self.$message.success("添加成功");
        } else {
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}

.seckill-countdown >>> .ant-statistic {
  display: inline-block;
}

.seckill-countdown >>> .ant-statistic-content {
  display: inline-block;
  font-size: 14px;
}

.seckill-countdown >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #fff;
}

.pintuan-m >>> .ant-empty-image svg {
  display: block;
  width: 100%;
}

/*拼团倒计时*/
.te_r {
  font-size: 16px;
}

.te_r >>> .ant-statistic {
  display: inline-block;
}

.te_r >>> .ant-statistic-content {
  display: inline-block;
}

.te_r >>> .ant-statistic-content-value {
  font-size: 16px;
}
</style>
