<template>
    <div>
        	<div class="per3_right per_right">
				<div class="p_remind_title">
					<img src="@/assets/img/remind_icon.png" />
					<h6>已邀请的人</h6>
				</div>

				<div class="per7_integral">
					<!-- tab -->
					<div class="per7_in2">
						<div class="tab-wrapper">
							<!-- <ul class="tab-menu tab-menu-form tab-menu1">
								<li class="active">已邀请人数20</li>
								<li>分销赚钱明细￥200.00</li>
							</ul> -->
                            <div class="tab_u">
                                <div :class="index == 0?'active':''" @click="getTab(0)">已邀请人数{{detail.invitation}}</div>
                                <div :class="index == 1?'active':''" @click="getTab(1)">分销赚钱明细{{detail.rebate_money}}</div>
                            </div>
							<div class="tab-content" v-if="index == 0">
								<div>
									<div class="invited">
										<ul v-if="list.length > 0">
											<li v-for="i in list">
												<a>
													<div class="invited_left">
														<img :src="i.user.head_portrait.url" />
														<p>{{i.user.nickname}}</p>
													</div>
													<div class="invited_right">
														<p>注册时间：{{i.time}}</p>
													</div>
												</a>
											</li>
										</ul>
                                        <div class="zanwu" v-if="list.length == 0">
                                            <div>暂无数据</div>
                                        </div>
									</div>
								</div>
							</div>
                            	<div class="tab-content" v-else>
								<div>
									<div class="invited">
										<ul v-if="list.length > 0">
											<li v-for="i in commissionList">
												<a>
													<div class="invited_left">
														<img :src="i.user.head_portrait.url" />
														<p>{{i.user.nickname}}</p>
													</div>
													<div class="invited_right">
														<p style="color:red">+{{i.money}}</p>
													</div>
												</a>
											</li>
										</ul>
                                        <div class="zanwu" v-if="list.length == 0">
                                            <div>暂无数据</div>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 页码 -->
		
			</div>
    </div>
</template>
<script>
import { statistical,invitation,commission } from "@/api/user";
export default {
  name: "Distribution",
   components: {
  },
  data() {
    return {
        detail:{},
        list:[],
        type:1,
        index:0,
        commissionList:[]
    };
  },
  methods: {
      loadStatistical(){
          let _this = this
          statistical().then((res) =>{
              console.log(res,'-------')
              _this.detail = res.result
          })
      },
      loadInvitation(){
          let _this = this
          invitation().then((res) =>{
              console.log(res,'分销')
              _this.list = res.result.items
              if(_this.list.length > 0){
                  _this.list.map(function(n){
                      n.time = n.created_at.substring(0,10)
                  })
              }
          })
      },
      loadCommission(){
          let _this = this
           commission().then((res) =>{
              console.log(res,'佣金')
              _this.commissionList = res.result.items
              
          })
      },
      getTab(index){
          this.index = index
          if(index == 0){
              this.loadInvitation()
          }else{
              this.loadCommission()
          }
      }
  },
  created() {
    this.loadStatistical()
    this.loadInvitation()
  },
};
</script>
<style scoped>
    .talent_l img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .per2_fxkc_l img{
        width: 200px;
    }
.tab-menu li {
    float: left;
    width: 108px;
    margin-right: 50px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
}
.tab-menu1 li.active{
    color: #ffffff;
}
.tab_u{
    overflow: hidden;
    margin-bottom: 20px;
}
.tab_u div{
    float: left;
    margin-right: 10px;
    padding: 10px 40px;
    border-radius: 60px;
    font-weight: bold;
    cursor: pointer;
}
.tab_u .active{
    background: #3561c7;
    color: #ffffff;
}
.invited {
    width: 100%;
    float: none!important;
    margin-top: 0;
    margin-right: 0;
    overflow: hidden;
}
.invited ul li{
    float: left;
    width: 50%;
    padding: 0 10px 25px 10px;
}
.invited_left img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
</style>