<template>
  <div class="warp clearfix mt50">
    <div class="xun_zong">
      <!-- 训练营标题 -->
      <div class="xun_ren">
        <a-icon
          type="left"
          @click="goPrev"
          style="
            float: left;
            font-size: 26px;
            margin-right: 10px;
            cursor: pointer;
          "
        />
        <div class="xun_zong_tit">
          <img src="@/assets/img/remind_icon.png" />
          <h6>训练营</h6>
        </div>
        <div class="xun_zong_r">
          <a @click="share">
            <img src="@/assets/img/xun_share.png" />
          </a>
        </div>
      </div>

      <div class="xun_ren_nr">
        <div class="xun_ren_left">
          <!-- 列表 -->
          <div class="ren_list1">
            <ul>
              <li
                v-for="item in list"
                :key="item.camp_theme_task_id"
                :class="[item.user_task_count === 0 ? '' : 'active']"
              >
                <h6>
                  <span>{{ item.label }}</span>
                  {{ item.name }}
                </h6>
                <p>{{ item.introduce }}</p>
                <div class="ren_list1_a te_r ren_list2_a">
                  <a @click="toStudy(item)">去学习</a>
                </div>
              </li>
            </ul>
          </div>
          <!-- 打卡 -->
          <div class="xun_da">
            <div
              v-if="user_clock_theme === 1"
              style="filter: grayscale(100%)"
              class="xun_da1"
            >
              <span>已打卡</span>
            </div>
            <div v-else class="xun_da1" @click="clock">
              <a-icon
                style="color: #fff; font-size: 20px; line-height: 80px"
                v-if="clock_loading"
                type="loading"
              />
              <span v-else>打卡</span>
            </div>
            <a class="shuoming">训练营说明</a>
          </div>
        </div>

        <div class="xun_ren_right">
          <CommentList
            :camp_id="camp_id"
            :camp_theme_id="camp_theme_id"
            :user_clock_theme="user_clock_theme"
          ></CommentList>
        </div>
      </div>
    </div>
    <Share ref="Share"></Share>
  </div>
</template>

<script>
import { taskList, clock } from "@/api/camp";
import CommentList from "./modules/CommentList";
import Share from "../theme/module/Share";

export default {
  name: "index",
  components: {
    CommentList,
    Share,
  },
  data() {
    return {
      camp_id: 0,
      camp_theme_id: 0,
      list: [],
      user_clock_theme: 0,
      clock_loading: false,
    };
  },
  created() {
    this.camp_id = this.$route.params.camp_id;
    this.camp_theme_id = this.$route.params.camp_theme_id;
    this.getData();
  },
  methods: {
    getData() {
      var _self = this;
      taskList({
        camp_theme_id: _self.camp_theme_id,
        camp_id: _self.camp_id,
      }).then((res) => {
        console.log(res, "-----");
        if (res.status === 200) {
          _self.list = res.result.list;
          _self.user_clock_theme = res.result.user_clock_theme;
        } else {
          _self.$srmsg
            .info({
              title: "提示",
              content: res.msg,
            })
            .then(() => {
              // window.history.back()
            });
        }
      });
    },
    clock() {
      // 打卡
      var _self = this;
      _self.clock_loading = true;
      clock({
        camp_id: _self.camp_id,
        camp_theme_id: _self.camp_theme_id,
      }).then((res) => {
        if (res.status === 200) {
          _self.$srmsg.info({
            title: "提示",
            content: "打卡成功！",
          });
          _self.getData();
        } else {
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
        _self.clock_loading = false;
      });
    },
    toStudy(item) {
      if (item.type == 25) {
        this.$router.push({
          path: "/exam/examination",
          query: { mock_id: item.camp_theme_task_id, type: 104 },
        });
        return;
      }
      if (item.class_type === 1) {
        this.$router.push({
          path:
            "/camp/pdf/" +
            this.camp_id +
            "/" +
            this.camp_theme_id +
            "/" +
            item.camp_theme_task_id,
        });
      } else {
        this.$router.push({
          path:
            "/camp/video/" +
            this.camp_id +
            "/" +
            this.camp_theme_id +
            "/" +
            item.camp_theme_task_id +
            "/" +
            item.user_task_count,
        });
      }
    },
    share() {
      this.$refs.Share.show(this.camp);
    },
    goPrev() {
      history.go(-1);
    },
  },
};
</script>

<style scoped>
.xun_zong_tit {
  width: 90%;
}
</style>
