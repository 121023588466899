import Vue from 'vue'
import Router from 'vue-router'
import RouteView from '@/layout/RouteView'
import UserView from '@/layout/UserView'
import Login from '@/views/login/index.vue' //登录
import Index from '@/views/index.vue' //首页

import CourseList from '@/views/course/list/index.vue' //课程列表页
import CourseInfo from '@/views/course/info/index.vue' //课程详情页
import CourseDetail from '@/views/course/detail/index.vue' //进入课程
import CourseSubchapter from '@/views/course/subchapter/index.vue' //课程子章节
import CourseVideo from '@/views/course/video/index.vue' //课程点播
import CourseZhibo from '@/views/course/zhibo/index.vue' //课程直播
import courseGroupList from '@/views/course/courseGroupList/index.vue' //课程包列表样式页
import courseGroupL from '@/views/course/courseGroupList/loading.vue' //课程包跳转中间判断页

import CoursePackageInfo from '@/views/course_package/info/index.vue' //课程包详情页

import RadioList from '@/views/radio/list' //电台课程列表
import RadioInfo from '@/views/radio/info' //电台课程详情
import RadioPlay from '@/views/radio/play' //电台课程播放

import CampList from '@/views/camp/list' //训练营列表
import CampInfo from '@/views/camp/info' //训练营详情
import CampTask from '@/views/camp/task' //训练营任务列表
import CampTheme from '@/views/camp/theme' //训练营主题详情
import CampVideo from '@/views/camp/video' //训练营视频观看
import CampPdf from '@/views/camp/pdf' //训练营pdf观看


import BuySettlement from '@/views/buy/settlement' //下单结算页
import BuySeckill from '@/views/buy/settlement/seckill' //秒杀结算页
import BuyPintuan from '@/views/buy/settlement/pintuan' //拼团结算页
import BuyCart from '@/views/buy/cart/index' // 购物车管理

import UserCenter from '@/views/user/center/index' //用户中心页
import UserSetting from '@/views/user/center/setting/userSetting' //用户设置
import MyCamp from '@/views/user/center/myCamp' //我的训练营
import MyCourse from '@/views/user/center/myCourse' //我的课程
import MyRadioStation from '@/views/user/center/myRadioStation' //我的电台
import MyWallet from '@/views/user/center/myWallet' //我的钱包
import MyWalletDetail from '@/views/user/center/myWalletDetail' //我的钱包--账单明细
import MyPoints from '@/views/user/center/myPoints' //我的积分
import MyOrder from '@/views/user/center/myOrder' //我的订单
import myCommunity from '@/views/user/center/myCommunity' //我的收藏
import MyOrderDetail from '@/views/user/center/myOrderDetail' //我的订单详情
import OrderRefund from '@/views/user/center/orderRefund' //订单退款
import RecentLearning from '@/views/user/center/recentLearning' //最近学习
import PayViper from '@/views/user/center/payViper' //会员购买
import PayViperRecord from '@/views/user/center/payViperRecord' //会员购买记录
import MyMessage from '@/views/user/center/myMessage' //我的消息
import MyMessageDetail from '@/views/user/center/myMessageDetail' //我的消息详情
import myCorrects from '@/views/user/center/myCorrects' //我的 点评批改
import myCorrectsList from '@/views/user/center/myCorrectsList' //点评账单
import MyAbout from '@/views/user/center/myAbout' //个人中心协议通用页面
import PlanList from '@/views/new/planList' //考试计划
import Detail from '@/views/new/detail' //详情通用页
import Test from '@/views/new/test' //备考资料
import TeacherList from '@/views/teacher/list/index' //名师列表
import appDownload from '@/views/appDownload/index' //APP下载页
import Selections from '@/views/selection/selection' //分类
import BindPhone from '@/views/bindPhone/index' //绑定手机
import LoginLoading from '@/views/loginLoading/index' //绑定手机
import Distribution from '@/views/user/center/distribution'
import InvitedUser from '@/views/user/center/invitedUser'
import myBook from '@/views/user/center/myBook'
import Agreement from '@/views/common/agreement'
import MyCoupon from '@/views/user/center/myCoupon'
import NotFound from '@/layout/NotFound'
import community from '@/views/community/index'
import communityList from '@/views/community/list/list'
import communityDetail from '@/views/community/detail/detail'
import storeIndex from '@/views/store/index'
import storeDetail from '@/views/store/detail/detail'
import storeDetailPdf from '@/views/store/detail/modules/pdf'
import myQuestion from '@/views/question/myQuestion'
import exam from '@/views/exam/index'
import examReport from '@/views/exam/report'
import reportObjective from '@/views/exam/reportObjective'
import reportSubjective from '@/views/exam/reportSubjective'
import makeQuestion from '@/views/question/makeQuestion'
import examination from '@/views/exam/examination'
import answerDetail from '@/views/exam/answerDetail'
import about from '@/views/about/index'
Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [{ //首页
            path: '/',
            name: 'Index',
            component: Index,
            meta: {
                nav: 'index',
                title: '圣儒公考/私教/1对1/圣儒教育_成公社/事业单位/军队文职/公务员考试网_2022国考公务员考试网_国考/公务员考试面试培训辅导_圣儒教育官网',
                content: {
                    keywords: '圣儒公考,私教,1对1,成公社,圣儒教育,圣儒教育成公社,公务员,公务员考试网,国家公务员,国家公务员考试网,2022国家公务员考试网,2022省公务员考试网,事业单位,军队文职',
                    description: '【圣儒教育成公社】提供2022国家公务员考试网、2022国考公告、国考报名时间/入口/职位表、2022省公务员考试网、公务员笔试面试培训等公务员招考公告/大纲,成绩查询,笔试面试备考辅导资料,公考视频,历年试题及答案下载等公务员考试辅导培训机构。'
                }
            }
        },
        { //404
            path: '*',
            name: '404',
            component: NotFound,
            meta: {
                nav: 'NotFound',
                title: '页面未找到'
            }
        },
        { //登录注册
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                title: '欢迎登录'
            },
        },
        { //隐私政策
            path: '/agreement',
            name: 'Agreement',
            component: Agreement,
            meta: {
                title: '隐私政策'
            },
        },
        { //分类选择
            path: '/selection',
            name: 'Selections',
            component: Selections,
            meta: {
                title: '分类选择'
            },
        },
        { //绑定手机
            path: '/bindPhone',
            name: 'BindPhone',
            component: BindPhone,
            meta: {
                title: '绑定手机'
            },
        },
        { //中间页
            path: '/loginLoading',
            name: 'LoginLoading',
            component: LoginLoading,
            meta: {
                title: '登录跳转中...'
            },
        },
        { //网校相关
            path: '/course',
            redirect: '/course/list',
            component: RouteView,
            meta: {
                nav: 'course',
                title: '圣儒网校'
            },
            children: [{ //课程列表页
                    path: '/course/list',
                    name: 'CourseList',
                    component: CourseList,
                    meta: {
                        nav: 'course',
                        title: '课程列表'
                    }
                },
                { //课程包列表样式页
                    path: '/course/courseGroupList/:course_id',
                    name: 'courseGroupList',
                    component: courseGroupList,
                    meta: {
                        nav: 'courseGroupList',
                        title: '课程包列表'
                    }
                },
                { //课程包列表样式页
                    path: '/course/courseGroupL/:course_id',
                    name: 'courseGroupL',
                    component: courseGroupL,
                    meta: {
                        nav: 'courseGroupL'
                    }
                },
                { //课程详情页
                    path: '/course/info/:course_id',
                    name: 'CourseInfo',
                    component: CourseInfo,
                    meta: {
                        nav: 'course'
                    }
                },
                { //课程包详情页
                    path: '/course-package/info/:course_package_id',
                    name: 'CoursePackageInfo',
                    component: CoursePackageInfo,
                    meta: {
                        nav: 'course'
                    }
                },
                { //进入课程
                    path: '/course/detail',
                    name: 'CourseDetail',
                    component: CourseDetail,
                    meta: {
                        nav: 'course'
                    }
                },
                { //课程子章节
                    path: '/course/:course_id/subchapter/:course_subchapter_id',
                    name: 'CourseSubchapter',
                    component: CourseSubchapter,
                    meta: {
                        nav: 'course'
                    }
                },
                { //课程子章节
                    path: '/course/video',
                    name: 'CourseVideo',
                    component: CourseVideo,
                    meta: {
                        nav: 'course'
                    }
                },
                { //课程直播
                    path: '/course/zhibo',
                    name: 'CourseZhibo',
                    component: CourseZhibo,
                    meta: {
                        nav: 'course'
                    }
                }
            ]
        },
        { //电台
            path: '/radio',
            redirect: '/radio/list',
            component: RouteView,
            children: [{
                    //电台列表
                    path: '/radio/list',
                    name: 'RadioList',
                    component: RadioList,
                    meta: {
                        nav: 'radio',
                        title: '电台列表'
                    }
                },
                {
                    //电台详情
                    path: '/radio/info/:radio_id',
                    name: 'RadioInfo',
                    component: RadioInfo,
                    meta: {
                        nav: 'radio',
                        title: '电台课程详情'
                    }
                },
                {
                    //电台课程播放
                    path: '/radio/play',
                    name: 'RadioPlay',
                    component: RadioPlay,
                    meta: {
                        nav: 'radio',
                        title: '电台课程'
                    }
                }
            ]
        },
        { //训练营
            path: '/camp',
            redirect: '/camp/list',
            component: RouteView,
            children: [{
                    //训练营列表
                    path: '/camp/list',
                    name: 'CampList',
                    component: CampList,
                    meta: {
                        nav: 'camp',
                        title: '训练营列表'
                    }
                },
                {
                    //训练营详情
                    path: '/camp/info/:camp_id',
                    name: 'CampInfo',
                    component: CampInfo,
                    meta: {
                        nav: 'camp',
                        title: '训练营详情'
                    }
                },
                {
                    //主题详情
                    path: '/camp/theme/:camp_id/:goods_id',
                    name: 'CampTheme',
                    component: CampTheme,
                    meta: {
                        nav: 'camp',
                        title: '训练营主题'
                    }
                },
                {
                    //训练营任务
                    path: '/camp/task/:camp_id/:camp_theme_id',
                    name: 'CampTask',
                    component: CampTask,
                    meta: {
                        nav: 'camp',
                        title: '任务列表'
                    }
                },
                {
                    //训练营视频
                    path: '/camp/video/:camp_id/:camp_theme_id/:camp_theme_task_id/:user_task_count',
                    name: 'CampVideo',
                    component: CampVideo,
                    meta: {
                        nav: 'camp',
                        title: '视频观看'
                    }
                },
                {
                    //训练营PDF
                    path: '/camp/pdf/:camp_id/:camp_theme_id/:camp_theme_task_id',
                    name: 'CampPdf',
                    component: CampPdf,
                    meta: {
                        nav: 'camp',
                        title: 'PDF'
                    }
                }
            ]
        },
        { //下单
            path: '/buy',
            redirect: '/buy/cart',
            component: RouteView,
            children: [{
                    //购物车
                    path: '/buy/cart',
                    name: 'BuyCart',
                    component: BuyCart
                },
                {
                    //下单结算
                    path: '/buy/settlement',
                    name: 'BuySettlement',
                    component: BuySettlement
                },
                {
                    //秒杀下单
                    path: '/buy/seckill',
                    name: 'BuySeckill',
                    component: BuySeckill
                },
                {
                    //拼团下单
                    path: '/buy/pintuan',
                    name: 'BuyPintuan',
                    component: BuyPintuan
                }
            ]
        },
        { //用户中心
            path: '/user',
            redirect: '/user/center',
            component: UserView,
            children: [{
                    //用户中心首页
                    path: '/user/center',
                    name: 'UserCenter',
                    component: UserCenter
                },
                {
                    //用户设置页
                    path: '/user/center/setting/userSetting',
                    name: 'UserSetting',
                    component: UserSetting
                },
                {
                    //我的训练营
                    path: '/user/center/myCamp',
                    name: 'MyCamp',
                    component: MyCamp
                },
                {
                    //我的课程
                    path: '/user/center/myCourse',
                    name: 'MyCourse',
                    component: MyCourse
                },
                {
                    //我的电台
                    path: '/user/center/myRadioStation',
                    name: 'MyRadioStation',
                    component: MyRadioStation
                },
                {
                    //我的钱包
                    path: '/user/center/myWallet',
                    name: 'MyWallet',
                    component: MyWallet
                },
                {
                    //我的钱包--账单明细
                    path: '/user/center/myWalletDetail',
                    name: 'MyWalletDetail',
                    component: MyWalletDetail
                },
                {
                    //我的积分
                    path: '/user/center/myPoints',
                    name: 'MyPoints',
                    component: MyPoints
                },
                {
                    //我的订单
                    path: '/user/center/myOrder',
                    name: 'MyOrder',
                    component: MyOrder
                },
                {
                    //我的订单详情
                    path: '/user/center/myOrderDetail',
                    name: 'MyOrderDetail',
                    component: MyOrderDetail
                },
                {
                    //订单退款
                    path: '/user/center/orderRefund',
                    name: 'OrderRefund',
                    component: OrderRefund
                },
                {
                    //最近学习
                    path: '/user/center/recentLearning',
                    name: 'RecentLearning',
                    component: RecentLearning
                },
                {
                    //会员升级
                    path: '/user/center/payViper',
                    name: 'PayViper',
                    component: PayViper
                },
                {
                    //会员购买记录
                    path: '/user/center/payViperRecord',
                    name: 'PayViperRecord',
                    component: PayViperRecord
                },
                {
                    //我的消息
                    path: '/user/center/myMessage',
                    name: 'MyMessage',
                    component: MyMessage
                },
                {
                    //我的消息详情
                    path: '/user/center/myMessageDetail',
                    name: 'MyMessageDetail',
                    component: MyMessageDetail
                },
                {
                    //协议通用页面
                    path: '/user/center/myAbout',
                    name: 'MyAbout',
                    component: MyAbout
                },
                {
                    //分销赚钱
                    path: '/user/center/distribution',
                    name: 'Distribution',
                    component: Distribution
                },
                {
                    //分销赚钱 邀请人数
                    path: '/user/center/invitedUser',
                    name: 'InvitedUser',
                    component: InvitedUser
                },
                {
                    //我的优惠券
                    path: '/user/center/myCoupon',
                    name: 'MyCoupon',
                    component: MyCoupon
                },
                {
                    //我的收藏
                    path: '/user/center/myCommunity',
                    name: 'myCommunity',
                    component: myCommunity
                },
                {
                    //点评批改
                    path: '/user/center/myCorrects',
                    name: 'myCorrects',
                    component: myCorrects
                },
                {
                    //点评批改账单
                    path: '/user/center/myCorrectsList',
                    name: 'myCorrectsList',
                    component: myCorrectsList
                },
                {
                    //点评批改账单
                    path: '/user/center/myBook',
                    name: 'myBook',
                    component: myBook
                }
            ]
        },
        { //考试计划
            path: '/new/planList',
            name: 'PlanList',
            component: PlanList,
            meta: {
                title: '考试计划'
            }
        },
        { //名师列表
            path: '/teacher',
            name: 'Teacher/List',
            component: TeacherList,
            meta: {
                title: '名师列表'
            }
        },
        { //通用详情页
            path: '/new/detail',
            name: 'Detail',
            component: Detail,
            meta: {
                title: '详情'
            }
        },
        { //备考资料
            path: '/new/test',
            name: 'Test',
            component: Test,
            meta: {
                title: '备考资料'
            }
        },
        { //APP下载页
            path: '/appDownload/index',
            name: 'appDownload',
            component: appDownload,
            meta: {
                nav: 'app',
                title: '备考资料'
            }
        },
        { //社区
            path: '/community',
            name: 'community',
            component: community,
            meta: {
                nav: 'community',
                title: '社区'
            }
        },
        { //社区列表
            path: '/community/list',
            name: 'communityList',
            component: communityList,
            meta: {
                nav: 'communityList',
                title: '社区'
            }
        },
        { //社区详情
            path: '/community/detail',
            name: 'communityDetail',
            component: communityDetail,
            meta: {
                nav: 'communityDetail',
                title: '社区'
            }
        },
        { //商城首页
            path: '/store',
            name: 'storeIndex',
            component: storeIndex,
            meta: {
                nav: 'storeIndex',
                title: '商城'
            }
        },
        { //商城详情
            path: '/store/detail',
            name: 'storeDetail',
            component: storeDetail,
            meta: {
                nav: 'storeDetail',
                title: '商城'
            }
        },
        { //商城详情
            path: '/store/detail/pdf',
            name: 'storeDetailPdf',
            component: storeDetailPdf,
            meta: {
                nav: 'storeDetailPdf',
                title: '详情'
            }
        },
        { //我的错题
            path: '/question/myQuestion',
            name: 'myQuestion',
            component: myQuestion,
            meta: {
                nav: 'myQuestion',
                title: '我的错题'
            }
        },
        { //模考
            path: '/exam',
            name: 'exam',
            component: exam,
            meta: {
                nav: 'myQuestion',
                title: '模考'
            }
        },
        { //模考报告
            path: '/exam/report',
            name: 'examReport',
            component: examReport,
            meta: {
                nav: 'examReport',
                title: '模考报告'
            }
        },
        { //模考报告-客观
            path: '/exam/reportObjective',
            name: 'reportObjective',
            component: reportObjective,
            meta: {
                nav: 'reportObjective',
                title: '模考报告'
            }
        },
        { //模考报告-主观
            path: '/exam/reportSubjective',
            name: 'reportSubjective',
            component: reportSubjective,
            meta: {
                nav: 'reportSubjective',
                title: '模考报告'
            }
        },
        { //做题
            path: '/question/makeQuestion',
            name: 'makeQuestion',
            component: makeQuestion,
            meta: {
                nav: 'makeQuestion',
                title: '做题'
            }
        },
        { //做题
            path: '/exam/examination',
            name: 'examination',
            component: examination,
            meta: {
                nav: 'examination',
                title: '模考'
            }
        },
        { //答案
            path: '/answerDetail',
            name: 'answerDetail',
            component: answerDetail,
            meta: {
                nav: 'answerDetail',
                title: '答案解析'
            }
        },
        { //答案
            path: '/about',
            name: 'about',
            component: about,
            meta: {
                nav: 'about',
                title: '关于我们'
            }
        }
    ]
})