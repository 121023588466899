<template>
  <div class="warp clearfix mt50 kcxq-box">
    <div class="kcxq-l fn_l">
      <img style="width: 100%" :src="course.image.url" />
    </div>
    <div class="kcxq-r fn_r mall2_data">
      <h3 class="mt10">
        {{ course.goods.name
        }}<span class="bqxs">{{
          course.status == 1 ? "纸质资料" : "电子资料"
        }}</span>
      </h3>
      <p class="mall2_data_wen" :style="'color:' + course.subtitle_color">
        {{ course.description }}
      </p>
      <div class="mall2_cheng_wen" v-if="course.sku">
        <span>规格：</span>
        <div class="mall2_cheng_a">
          <a
            :class="cheng_on == index ? 'cheng_on' : ''"
            v-for="(i, index) in course.sku"
            @click="getSku(i, index)"
            >{{ i.name }}</a
          >
        </div>
      </div>
      <div class="mall2_cheng1_wen" v-if="course.sku">
        <span>数量：</span>
        <div class="mall2_cheng1_wenzi">
          <input type="number" v-model="sku_num" />
          <i>套</i>
        </div>
      </div>

      <p class="f14">
        <span class="color-999">
          限售{{ course.goods.sales_restriction }}人 | 已报名{{
            course.goods.sale_amount
          }}人
        </span>
        <img src="@/assets/img/KC-time.png" />
        <span class="color-pink"
          >距拼团结束：<a-statistic-countdown
            :value="pintuan.end_at | toTime"
            format="D 天 H 时 m 分 s 秒"
        /></span>
      </p>
      <div class="pintuan-box mt10">
        <div class="clearfix pintuan-t">
          <p class="fn_l">{{ group_list.length }}人在拼单，可直接参与</p>
          <a
            class="fn_r color-999 modal-more"
            @click="$refs.PintuanUserList.show(group_list)"
            >查看更多&gt;&gt;</a
          >
        </div>
        <div class="pintuan-m">
          <a-empty
            v-if="group_list.length === 0"
            :imageStyle="{ width: '100%' }"
            description="暂时无人发起拼团"
          />
          <ul v-else>
            <li
              v-for="item in group_list.slice(0, 2)"
              :key="item.pintuan_group_id"
            >
              <p>
                <img class="pintuan-mImg" :src="item.chief.head_portrait.url" />
              </p>
              <p>{{ item.chief.nickname }}</p>
              <p class="te_r">
                还差{{
                  pintuan.number - item.pintuan_user_count
                }}人拼成<br />剩余
                <span>
                  <a-statistic-countdown
                    :value="item.expire_at | toTime"
                    format="D 天 H 时 m 分 s 秒"
                  />
                </span>
              </p>
              <p class="te_r">
                <a
                  class="modal-pd"
                  @click="$refs.JoinPintuan.show(item, pintuan)"
                  >去拼单</a
                >
              </p>
            </li>
          </ul>
        </div>
      </div>
      <p class="clearfix mt20">
        <span class="fn_l f26 color-main"
          >￥{{ vip_price }}
          <s class="f14 color-999">￥{{ real_price }}</s>
        </span>
        <a @click="createPintuan" class="fn_r bg-main color-fff f14 yx-msBut">
          <span>{{ group_list.length }}人成团</span><br />
          <span>立即购买</span>
        </a>
        <a @click="addCart" class="fn_r yx-msBut f14">
          <span>￥{{ vip_price }}</span
          ><br />
          <span>加入购物车</span>
        </a>
      </p>
    </div>

    <!--拼团列表窗口-->
    <PintuanUserList :pintuan="pintuan" ref="PintuanUserList">
      <div class="pindan-kc clearfix mt30">
        <div class="pindan-kcVideo fn_l">
          <img class="ml0" :src="course.image.url" />
        </div>
        <div class="pindan-kcTex fn_r">
          <h3>{{ course.name }}</h3>
          <p class="f14 color-666 mt10">
            开课时间：
            {{ $moment(course.start_at).format("YYYY.MM.DD") }}
            --
            {{ $moment(course.end_at).format("YYYY.MM.DD") }}
            {{ course.period }}
            课时
          </p>
          <h4 class="mt50 f16 price">
            拼团价：
            <span class="color-red">￥{{ course.goods.order_price }}</span>
            <s class="color-999">￥{{ course.goods.real_price }}</s>
          </h4>
        </div>
      </div>
    </PintuanUserList>
    <!--加入拼团窗口-->
    <JoinPintuan ref="JoinPintuan">
      <div class="pindan-kc clearfix mt30">
        <div class="pindan-kcVideo fn_l">
          <img class="ml0" :src="course.image.url" />
        </div>
        <div class="pindan-kcTex fn_r">
          <h3>{{ course.name }}</h3>
          <p class="f14 color-666 mt10">
            开课时间：
            {{ $moment(course.start_at).format("YYYY.MM.DD") }}
            --
            {{ $moment(course.end_at).format("YYYY.MM.DD") }}
            {{ course.period }}
            课时
          </p>
          <h4 class="mt50 f16 price">
            拼团价：
            <span class="color-red">￥{{ course.goods.order_price }}</span>
            <s class="color-999">￥{{ course.goods.real_price }}</s>
          </h4>
        </div>
      </div>
    </JoinPintuan>
  </div>
</template>

<script>
import { groupList } from "@/api/pintuan";
import PintuanUserList from "@/components/Pintuan/PintuanUserList";
import JoinPintuan from "@/components/Pintuan/JoinPintuan";
import { add } from "@/api/cart";

export default {
  name: "Pintuan",
  components: {
    PintuanUserList,
    JoinPintuan,
  },
  props: {
    course: {},
  },
  data() {
    return {
      pintuan: this.course.goods.notend_pintuan,
      group_list: [],
      cheng_on: null,
      vip_price: "",
      sku_num: 1,
      sku_id: "",
      real_price:''
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  mounted() {
    this.getGroupList();
    this.vip_price = this.course.goods.order_price;
    console.log(this.course,'拼团详情')
    this.real_price = this.course.goods.real_price;
  },
  methods: {
    getGroupList() {
      var _self = this;
      groupList({ pintuan_id: this.pintuan.pintuan_id }).then((res) => {
        _self.group_list = res.result;
      });
    },
    createPintuan() {
      if (this.course.sku.length > 0) {
        if (this.sku_id == "") {
          this.$message.warning("请选择规格");
        } else {
          let routeData = this.$router.resolve({
            path: "/buy/pintuan",
            query: {
              goods_id: this.pintuan.goods.goods_id,
              pintuan_id: this.pintuan.pintuan_id,
              sku_num: this.sku_num,
              sku_id: this.sku_id,
            },
          });
          window.open(routeData.href, "_blank");
        }
      } else {
        let routeData = this.$router.resolve({
          path: "/buy/pintuan",
          query: {
            goods_id: this.pintuan.goods.goods_id,
            pintuan_id: this.pintuan.pintuan_id,
            sku_num: this.sku_num,
            sku_id: this.sku_id,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    addCart() {
      // 加入购物车
      var _self = this;
      _self.cart_btn_loading = true;
      var goods = _self.course.goods;

      if (!goods) {
        return false;
      }
      add(goods).then((res) => {
        _self.cart_btn_loading = false;
        if (res.status === 200) {
          _self.$store.commit("ADD_CART", goods);
          _self.$message.success("添加成功");
        } else {
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
    getSku(i, index) {
      console.log(i);
      let _this = this;
      // 计算选中sku后的会员价格
      var data = i.goods_sku_price;
      this.sku_id = i.sku_id;
      this.real_price = i.goods_sku_price[0].real_price
      // if (this.$store.state.user.now_user_member) {
      //   data.map(function (n) {
      //     if (
      //       n.user_member_config_id ==
      //       _this.$store.state.user.now_user_member.user_member_config
      //         .user_member_config_id
      //     ) {
      //       _this.vip_price = n.real_price;
      //     }
      //   });
      // }
      this.cheng_on = index;
    },
  },
};
</script>

<style scoped>
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}

.seckill-countdown >>> .ant-statistic {
  display: inline-block;
}

.seckill-countdown >>> .ant-statistic-content {
  display: inline-block;
  font-size: 14px;
}

.seckill-countdown >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #fff;
}

.pintuan-m >>> .ant-empty-image svg {
  display: block;
  width: 100%;
}

/*拼团倒计时*/
.te_r {
  font-size: 16px;
}

.te_r >>> .ant-statistic {
  display: inline-block;
}

.te_r >>> .ant-statistic-content {
  display: inline-block;
}

.te_r >>> .ant-statistic-content-value {
  font-size: 16px;
}
.cheng_on {
  color: #3561c7 !important;
  border: 1px solid #3561c7 !important;
}
.mall2_cheng1_wenzi input {
  background: #3561c7;
  border: none;
  width: 65px;
  height: 26px;
  color: #ffffff;
  text-align: center;
}
.mall2_data .mall2_cheng_a a {
  margin-left: 0;
  margin-right: 20px;
}
.bqxs {
  font-size: 12px;
  color: #fff;
  background: #3561c7;
  padding: 0 8px;
  font-weight: 400;
  margin: -25px 0 0 15px;
  display: inline-block;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
