<template>
  <div>
    <Pintuan
      v-if="camp.goods.notend_pintuan && camp.user_unlock_count == 0"
      :camp="camp"
    ></Pintuan>
    <div v-else class="warp clearfix mt50 kcxq-box">
      <div class="kcxq-l fn_l">
        <img :src="camp.image.url" />
      </div>
      <div class="kcxq-r fn_r">
        <p class="f26">{{ camp.name }}</p>
        <p class="f12 color-main" :style="{ color: camp.subtitle_color }">
          {{ camp.subtitle }}
        </p>
        <p class="f12 color-666">
          已报名{{ camp.goods.sale_amount }}人 | 剩余席位{{
            camp.goods.surplus_seat
          }}人
        </p>
        <p class="">
          <span class="color-999">购买后有效期：{{ camp.valid_days }}天 </span>
          <img
            v-if="camp.goods.notend_seckill"
            src="@/assets/img/KC-time.png"
          />
          <span class="color-pink" v-if="camp.goods.notend_seckill">
            距结束：<a-statistic-countdown
              :value="camp.end_at | toTime"
              format="D 天 H 时 m 分 s 秒"
            />
          </span>
        </p>
        <!--老師列表-->
        <p class="clearfix dt-teachlist xly-teachlist">
          <a v-for="item in camp.teacher" :key="camp.teacher_id">
            <img
              style="width: 55px; height: 55px; border-radius: 50% 50%"
              :src="item.avatar.url"
            /><br />{{ item.name }}
          </a>
        </p>
        <!--老师列表edn-->
        <p class="clearfix">
          <span class="fn_l f26 color-main"
            >￥{{ camp.order_price }}</span>
             <s class="color-999">￥{{camp.goods.real_price}}</s> 
          <a
            v-if="camp.user_unlock_count == 0"
            class="fn_r bg-main color-fff"
            @click="buy"
            >{{camp.order_price == 0?'免费报名': camp.order_price == '0.00'?'免费报名':'立即购买'}}</a
          >
          <a
            v-if="camp.try_learn > 0 && camp.user_unlock_count == 0"
            class="fn_r"
            @click="toStudy"
            >免费试学</a
          >
          <SrButton
            v-if="camp.user_unlock_count == 1"
            @click="toStudy"
            style="width: 130px; display: inline-block; float: right"
            height="42px"
            padding="0"
            font-size="14px"
            :loading="cart_btn_loading"
            >去学习
          </SrButton>
          <SrButton
            v-if="camp.user_unlock_count == 4"
            @click="seckill"
            style="width: 130px; display: inline-block; float: right"
            height="42px"
            padding="0"
            font-size="14px"
            :loading="cart_btn_loading"
            >立即秒杀
          </SrButton>
          <SrButton
            v-if="camp.user_unlock_count == 0 && camp.order_price > 0"
            @click="addCart"
            style="width: 130px; display: inline-block; float: right"
            height="42px"
            padding="0"
            font-size="14px"
            :loading="cart_btn_loading"
            type="hollow"
            >加入购物车
          </SrButton>
        </p>
      </div>
    </div>

    <div class="warp clearfix mt30">
      <div class="kcxq-con fn_l">
        <div class="tab-wrapper">
          <SrTabs ref="SrTabs" :list="tabs_list" @change="changeTab"></SrTabs>
          <div class="tab-content">
            <CampIntroduce v-if="active_tab === 0" :camp="camp"></CampIntroduce>
            <CommentList
              v-if="active_tab === 1"
              :camp_id="camp_id"
            ></CommentList>
            <ThemeList
              ref="ThemeList"
              v-show="active_tab === 2"
              :camp_id="camp_id"
              :in_order="camp.in_order"
              :user_unlock_count="camp.user_unlock_count"
            ></ThemeList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { detail } from "@/api/camp";
import SrTabs from "@/components/SrTabs";
import CampIntroduce from "./modules/CampIntroduce";
import CommentList from "./modules/CommentList";
import ThemeList from "./modules/ThemeList";
import SrButton from "@/components/SrButton";
import { add } from "@/api/cart";
import Pintuan from "./modules/Pintuan";

export default {
  name: "index",
  components: {
    SrTabs,
    CampIntroduce,
    CommentList,
    ThemeList,
    SrButton,
    Pintuan,
  },
  data() {
    return {
      tabs_list: ["课程介绍", "学员笔记", "训练主题"],
      cart_btn_loading: false,
      camp_id: 0,
      active_tab: 0,
      camp: {
        goods: {},
        image: {},
      },
      invitation_user_id: null,
      order_price:0
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  created() {
    var _self = this;
      if (document.body.clientWidth <= 800) {
      window.location.href = "http://wap.srgongkaow.com/school/detail?id=" + this.$route.params.camp_id;
    }
    _self.camp_id = this.$route.params.camp_id;
    _self.getData();
    _self.invitation_user_id = this.$route.query.user_id;
  },
  computed: {
    buttonStatus() {
      // 判断按钮状态 1已购买 2已过期 3已售完 4有秒杀 5可购买
      var camp = this.camp;
      if (camp.user_unlock_count !== 0) {
        return 1;
      }
      if (new Date(camp.registration_at) < new Date()) {
        return 2;
      }
      if (camp.goods.sale_amount >= camp.goods.sales_restriction) {
        return 3;
      }
      if (camp.goods.notend_seckill) {
        return 4;
      }
      return 5;
    },
  },
  methods: {
    getData() {
      var _self = this;
      detail({ camp_id: _self.camp_id }).then((res) => {
        console.log(res,'详情')
        if (res.status === 200) {
          var data = res.result;
          if(data.goods.vip_price.length>0){
            data.goods.vip_price.map(function(n){
              if(_self.$store.state.user.now_user_member){
                if(n.user_member_config_id == _self.$store.state.user.now_user_member.user_member_config.user_member_config_id){
                  data.order_price = n.price
                }
              }else{
                data.order_price = data.goods.order_price
              }
            })
          }else{
            data.order_price = data.goods.order_price
          }
          _self.camp = data;
          document.title = res.result.name + "-" + document.title;
        } else {
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
    buy() {
      let routeData = this.$router.resolve({
        path: "/buy/settlement",
        query: {
          goods_id: this.camp.goods.goods_id,
          invitation_user_id: this.invitation_user_id,
            num:1,
          sku_id:''
        },
      });
      window.open(routeData.href, "_blank");
    },
    changeTab(e) {
      this.active_tab = e;
    },
    toStudy() {
      this.$router.push({
        path:
          "/camp/theme/" + this.camp.camp_id + "/" + this.camp.goods.goods_id,
      });
    },
    seckill() {
      var seckill = this.camp.goods.notend_seckill;
      let routeData = this.$router.resolve({
        path: "/buy/seckill",
        query: {
          goods_id: seckill.goods.goods_id,
          seckill_id: seckill.seckill_id,
          num:1,
          sku_id:''
        },
      });
      window.open(routeData.href, "_blank");
    },
    addCart() {
      // 加入购物车
      var _self = this;
      _self.cart_btn_loading = true;
      var goods = _self.camp.goods;

      if (!goods) {
        return false;
      }
      add(goods).then((res) => {
        _self.cart_btn_loading = false;
        if (res.status === 200) {
          _self.$store.commit("ADD_CART", goods);
          _self.$message.success("添加成功");
        } else {
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}
</style>
