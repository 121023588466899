<template>
  <div>
    <Pintuan
      v-if="course_package.goods.notend_pintuan"
      :course_package="course_package"
    ></Pintuan>
    <div class="warp clearfix mt50 kcxq-box">
      <div v-if="!course_package.goods.notend_pintuan" class="kcxq-l fn_l">
        <a-skeleton v-if="loading" :title="false" :paragraph="{ rows: 6 }" />
        <img v-else :src="course_package.first_cover.url" />
      </div>
      <div v-if="!course_package.goods.notend_pintuan" class="kcxq-r fn_r">
        <a-skeleton v-if="loading" :title="false" :paragraph="{ rows: 6 }" />
        <div v-else>
          <h3 class="mt10">{{ course_package.name }}</h3>
          <p class="color-666 mt20" style="font-size:14px">{{ course_package.period }}课时</p>
          <p
            class="f12 color-main"
            :style="{ color: course_package.subtitle_color }"
          >
            {{ course_package.subtitle }}
          </p>
          <p style="font-size:14px">
            <span class="color-999">
              限售{{ course_package.goods.sales_restriction }}人 | 已报名{{
                course_package.goods.sale_amount
              }}人
            </span>
            <img
              src="@/assets/img/KC-time.png"
              v-if="course_package.goods.notend_seckill"
            />
            <span class="color-pink" v-if="course_package.goods.notend_seckill">
              距秒杀结束：<a-statistic-countdown
                :value="course_package.goods.notend_seckill.end_at | toTime"
                format="D 天 H 时 m 分 s 秒"
              />
            </span>
          </p>
          <p class="clearfix mt30">
            <span class="fn_l f26 color-main"
              >￥{{ course_package.goods.order_price }}</span
            >
            <!-- 状态1 -->
            <!--<a class="fn_r bg-main color-fff" href="">立即购买</a> -->
            <!-- 状态2 -->
            <a
              class="fn_r bg-main color-fff"
              v-if="buttonStatus === 5 || buttonStatus === 4"
              @click="buy"
              >立即购买</a
            >
            <!-- 状态3 -->
            <a
              class="fn_r bg-999 border-none color-fff"
              v-if="buttonStatus === 2"
              >报名结束</a
            >
            <!-- 状态4 -->
            <a
              class="fn_r bg-999 border-none color-fff"
              v-if="buttonStatus === 3"
              >人员已满</a
            >
            <SrButton
              v-if="buttonStatus === 5"
              @click="addCart"
              style="width: 130px; display: inline-block; float: right"
              height="42px"
              padding="0"
              font-size="14px"
              :loading="cart_btn_loading"
              type="hollow"
              >加入购物车
            </SrButton>
          </p>
        </div>
      </div>
      <div class="clear-both"></div>

      <div class="kc-bao mt20">
        <div class="kc-baoT"><img src="@/assets/img/KC-tbg.png" /> 课程</div>
        <a-skeleton
          style="margin-top: 20px"
          v-if="loading"
          :title="false"
          :paragraph="{ rows: 3 }"
        />
        <div
          v-else
          style="
            margin-top: 20px;
            position: relative;
            padding: 0 20px;
            box-sizing: border-box;
          "
        >
          <div @click="$refs.carousel.prev()" class="swiper-button-prev">
            <img src="@/assets/img/kc-butL.png" />
          </div>
          <div @click="$refs.carousel.next()" class="swiper-button-next">
            <img src="@/assets/img/kc-butR.png" />
          </div>
          <a-carousel :dots="false" ref="carousel">
            <div v-for="(item, index) in courseGroup" :key="index">
              <span
                style="width: 21%; display: inline-block; margin: 0 2%"
                v-for="subitem in item"
              >
                <div style="cursor: pointer" @click="toGoods(subitem)">
                  <div>
                    <img
                      width="235px"
                      height="132px"
                      style="border-radius: 5px"
                      :src="subitem.image.url"
                      alt=""
                    />
                  </div>
                  <div style="text-align: center" class="yihang">
                    {{ subitem.name }}
                  </div>
                </div>
              </span>
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
    <div class="warp clearfix mt30">
      <div class="kcxq-con fn_l">
        <SrTabs
          :list="['课程介绍', '学员评价']"
          @change="
            (e) => {
              tag_index = e;
            }
          "
        ></SrTabs>

        <div class="tab-content">
          <CourseIntroduce
            v-if="tag_index === 0"
            :loading="loading"
            :course_package="course_package"
          ></CourseIntroduce>
          <CommentList
            v-show="tag_index === 1"
            :course_package_id="course_package.course_package_id"
          ></CommentList>
        </div>
      </div>
      <ReCoursePackage></ReCoursePackage>
    </div>
  </div>
</template>

<script>
import { Carousel } from "ant-design-vue";
import { info } from "@/api/school/course_package";
import CourseIntroduce from "./modules/CourseIntroduce";
import CommentList from "./modules/CommentList";
import { add } from "@/api/cart";
import SrButton from "@/components/SrButton";
import Pintuan from "./modules/Pintuan";
import SrTabs from "@/components/SrTabs";
import ReCoursePackage from "@/layout/recommend/ReCoursePackage";

export default {
  name: "index",
  components: {
    "a-carousel": Carousel,
    CourseIntroduce,
    CommentList,
    SrButton,
    Pintuan,
    SrTabs,
    ReCoursePackage,
  },
  data() {
    return {
      loading: false,
      course_package_id: 0,
      course_package: {
        first_cover: {},
        goods: {},
        course: [],
      },
      tag_index: 0,
      cart_btn_loading: false,
      invitation_user_id: null,
    };
  },
  created() {
    var _self = this;
    if (document.body.clientWidth <= 800) {
      window.location.href = "http://wap.srgongkaow.com/school/courseGroupDetail?course_package_id=" + _self.$route.params.course_package_id;
    }
    _self.course_package_id = _self.$route.params.course_package_id;
    _self.getData();
    _self.invitation_user_id = this.$route.query.user_id;
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  computed: {
    courseGroup() {
      //课程分组
      var _self = this;
      var goods = _self.course_package.relation_goods;
      console.log(goods);
      var arr = [];
      goods.forEach((item, index) => {
        var arr_index = Math.floor(index / 4);

        if (arr[arr_index]) {
          arr[arr_index].push(item);
        } else {
          arr[arr_index] = [];
          arr[arr_index].push(item);
        }
      });
      return arr;
    },
    buttonStatus() {
      // 判断按钮状态 1已购买 2已过期 3已售完 4有秒杀 5可购买
      var course_package = this.course_package;
      if (course_package.has_unlock !== 0) {
        return 1;
      }
      if (new Date(course_package.end_at) < new Date()) {
        return 2;
      }
      if (
        course_package.goods.sale_amount >=
        course_package.goods.sales_restriction
      ) {
        return 3;
      }
      if (course_package.goods.notend_seckill) {
        return 4;
      }
      return 5;
    },
  },
  methods: {
    getData() {
      var _self = this;
      _self.loading = true;
      info({ course_package_id: _self.course_package_id }).then((res) => {
        console.log(res, "详情");
        _self.loading = false;
        _self.course_package = res.result;
        document.title = res.result.name + "-" + document.title;
        console.log(_self.buttonStatus);
      });
    },
    addCart() {
      // 加入购物车
      var _self = this;
      _self.cart_btn_loading = true;
      var goods = _self.course_package.goods;

      if (!goods) {
        return false;
      }
      add(goods).then((res) => {
        _self.cart_btn_loading = false;
        if (res.status === 200) {
          _self.$store.commit("ADD_CART", goods);
        } else {
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
    buy() {
      let routeData = this.$router.resolve({
        path: "/buy/settlement",
        query: {
          goods_id: this.course_package.goods.goods_id,
          invitation_user_id: this.invitation_user_id,
          num: 1,
          sku_id: "",
        },
      });
      window.open(routeData.href, "_blank");
    },
    toGoods(item) {
      if (item.type === 1) {
        this.$router.push({ path: "/course/info/" + item.relation_id });
      } else if (item.type === 3) {
        this.$router.push({ path: "/radio/info/" + item.relation_id });
      } else {
        this.$router.push({ path: "/camp/info/" + item.relation_id });
      }
    },
  },
};
</script>

<style scoped>
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}

.yihang {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.swiper-button-prev:after {
  content: none;
}

.swiper-button-next:after {
  content: none;
}

.swiper-button-prev {
  position: absolute;
  left: 0;
  top: 50px;
  cursor: pointer;
}

.swiper-button-next {
  position: absolute;
  right: 0;
  top: 50px;
  cursor: pointer;
}
</style>
