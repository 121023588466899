<template>
  <div class="">
    <div class="clearfix mt20">
      <p class="fn_l">
        <a-checkbox @change="onlyClock"> 只显示未打卡 </a-checkbox>
      </p>
      <SrSwitch
        style="float: right"
        @change="orderChange"
        :list="['正序', '倒序']"
      ></SrSwitch>
    </div>
    <div class="mt20">
      <a-spin :spinning="loading">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
        <div
          v-if="loading && list.length === 0"
          style="width: 100%; height: 100px"
        ></div>
        <dl v-for="(item, index) in list" class="xly-ztList clearfix">
          <!--{{item.user_clock_count}}-->
          <div
            v-if="in_order == 0"
            @click="
              goDetails(
                item.camp_id,
                item.camp_theme_id,
                item.user_clock_count,
                index
              )
            "
          >
            <dt class="fn_l">
              <p>{{ item.name }}</p>
              <p class="f14 color-999">
                {{ item.clock_count }}人已打卡 |{{
                  $moment(item.start_at).format("YYYY.MM.DD")
                }}
              </p>
            </dt>
            <dd class="fn_r te_r">
              <p>
                <img class="dis-inb" src="@/assets/img/xly-js.png" /><br />
                去学习
              </p>
            </dd>
          </div>
          <div
            v-else
            @click="
              goDetail(
                item.camp_id,
                item.camp_theme_id,
                item.user_clock_count,
                index
              )
            "
          >
            <dt class="fn_l">
              <p>{{ item.name }}</p>
              <p class="f14 color-999">
                {{ item.clock_count }}人已打卡 |{{
                  $moment(item.start_at).format("YYYY.MM.DD")
                }}
              </p>
            </dt>

            <dd class="fn_r te_r" v-if="item.type == 1">
              <p>
                <img class="dis-inb" src="@/assets/img/xly-js.png" /><br />
                试学
              </p>
            </dd>
            <dd class="fn_r te_r" v-else>
              <p v-if="user_unlock_count == 1">
                <img class="dis-inb" src="@/assets/img/xly-js.png" /><br />
                已解锁
              </p>
              <p v-else>
                <img class="dis-inb" src="@/assets/img/xly-wjs.png" />
                <br />
                未解锁
              </p>
            </dd>
          </div>
        </dl>
      </a-spin>
    </div>
  </div>
</template>

<script>
import SrSwitch from "@/components/SrSwitch";
import { themeList } from "@/api/camp";

export default {
  name: "ThemeList",
  components: {
    SrSwitch,
  },
  props: {
    camp_id: {},
    user_unlock_count: Number,
    in_order: Number,
  },
  data() {
    return {
      loading: false,
      query: {
        order_by: "asc",
        page_size: 1000,
      },
      list: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      var _self = this;
      _self.loading = true;
      themeList({
        camp_id: this.camp_id,
        ..._self.query,
      }).then((res) => {
        console.log(res, "训练主题");
        _self.list = res.result.items;
        _self.loading = false;
      });
    },
    startClass(time) {
      if (new Date(time) > new Date()) {
        return false;
      } else {
        return true;
      }
    },
    orderChange(index) {
      if (index === 0) {
        this.query.order_by = "asc";
      } else {
        this.query.order_by = "desc";
      }
      this.getData();
    },
    onlyClock(e) {
      if (e.target.checked) {
        this.query.type = 1;
      } else {
        this.query.type = 0;
      }
      this.getData();
    },
    goDetail(id, ids, type, index) {
      if (this.query.order_by === "asc") {
        //如果是正序 第一个解锁
        if (index === 0) {
          this.$router.push({ path: "/camp/task/" + id + "/" + ids });
        } else {
          //判断上一个是否解锁
          if (this.list[index - 1].user_clock_count) {
            this.$router.push({ path: "/camp/task/" + id + "/" + ids });
          } else {
            this.$message.warning("还未解锁课程");
          }
        }
      } else {
        if (index === this.list.length - 1) {
          this.$router.push({ path: "/camp/task/" + id + "/" + ids });
        } else {
          //判断上一个是否解锁
          if (this.list[index + 1].user_clock_count) {
            this.$router.push({ path: "/camp/task/" + id + "/" + ids });
          } else {
            this.$message.warning("还未解锁课程");
          }
        }
      }
    },
    goDetails(id, ids, type, index) {
      this.$router.push({ path: "/camp/task/" + id + "/" + ids });
    },
  },
};
</script>

<style scoped>
.fn_l {
  cursor: pointer;
}
</style>
