<template>
  <div>
    <div class="index3_dx_one">
      <p v-html="detail.title" :style="fontType == 14?'font-size:14px':fontType == 18?'font-size:18px':'font-size:16px'"></p>
      <img v-if="detail.image.url" :src="detail.image.url" alt="">
    </div>
    <!-- <div class="index3_dx_two">
				<img src="img/in3_yin.png" />
			</div>
			<div class="index3_dx_three">
				<ul>
					<li>
						<img src="img/in3_three_1.jpg" />
					</li>
					<li>
						<img src="img/in3_three_2.jpg" />
					</li>
				</ul>
			</div> -->

    <div class="index3_dx_four">
      <ul>
        <li v-for="(i, index) in detail.subject_option">
          <span :class="i.check ? 'active' : ''" @click="getOption(i, index)">{{ i.option_key }}</span>
          <p v-if="i.title">{{ i.title }}</p>
          <img v-if="i.image.url" :src="i.image.url" alt="">
        </li>
      </ul>
      <!-- <ul v-else>
        <li v-for="(i, index) in detail.subject_option">
          <span
            :class="tiIndex === index ? 'active' : ''"
            @click="getOption(i, index)"
            >{{ i.option_key }}</span
          >
          <p :style="fontType == 14?'font-size:14px':fontType == 18?'font-size:18px':'font-size:16px'">{{ i.title }}</p>
        </li>
      </ul> -->
    </div>
  </div>
</template>
<script>
import {
  submit
} from "@/api/exam";
export default {
  name: "index",
  components: {},
  props: {
    detail: Object,
  },
  data() {
    return {
      paperDetail: {},
      tiIndex: null,
      fontType:16
    };
  },
  mounted() {},
  methods: {
    getOption(i, index) {
      let _this = this;
      console.log(i,index);
      this.tiIndex = index;
      var arr = [];
      arr.push(i.subject_option_id);
      this.detail.subject_option.map(function(n,indexs){
        n.check = null;
        if(index == indexs){
          console.log(indexs)
          _this.$set(n, 'check', true)
        }else{
          _this.$set(n, 'check', false)
        }
      })
      console.log(this.detail.subject_option,'选项')
      this.$emit("getRadio", arr);
    },
    init() {
      let _this = this;
      if (this.detail.answer_situation) {
        var str1 = this.detail.answer_situation.choice.substr(1);
        var str2 = str1.substr(0, str1.length - 1);
        console.log(str2);
        _this.detail.subject_option.map(function (n) {
          if (n.subject_option_id == str2) {
            console.log(n, "符合s");
            _this.$set(n, 'check', true)
          } else {
            n.check = false;
          }
        });
      }else{
        _this.tiIndex = null;
        _this.detail.subject_option.map(function(n){
          _this.$set(n, 'check', false)
        })
      }
      console.log(this.detail,'单选题渲染')
    },
    fontInit(type){
      this.fontType = type
    }
  },
  created() {},
  watch: {
    // detail(val){
    //   let _this = this
    //   console.log(val,'传入题目')
    //   if(val.answer_situation){
    //    var str1 = val.answer_situation.choice.substr(1);
    //   var str2 = str1.substr(0, str1.length - 1);
    //   val.subject_option.map(function(n){
    //     if(n.subject_option_id == str2){
    //       console.log(n,'符合')
    //        _this.$set(n, 'check', true)
    //     }
    //   })
    //   this.detail = val;
    // }else{
    //    val.subject_option.map(function(n){
    //       n.check = false;
    //   })
    //   this.detail = val;
    // }
    // }
  },
};
</script>
<style scoped>
.index3_dx_four ul li span {
  cursor: pointer;
}
.index3_dx_four ul li span.active {
  border: none;
}
</style>