const common = {
    state: {
        page_loading: false,
        config: {}
    },

    mutations: {
        SET_PAGE_LOADING: (state, info) => {
            state.page_loading = info
        },
        SET_CONFIG: (state, info) => {
            state.config = info
        }
    }
}

export default common
