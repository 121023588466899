<template>
    <div>
        <SrNav></SrNav>
        <!-- banner -->
        <div class="download_banner">
            <!-- Swiper -->
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="download_ban">
                            <img src="@/assets/img/download_ban1.jpg"/>
                            <div class="down_icon">
                                <div class="down_an">
                                    <a href="/app.apk">
                                        <img src="@/assets/img/android_icon.png"/>
                                        <div class="an_wen">
                                            <h6>Android</h6>
                                            <p>下载</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="down_an">
                                    <a @click="toIos">
                                        <img src="@/assets/img/apple_icon.png"/>
                                        <div class="an_wen">
                                            <h6>App Store</h6>
                                            <p>下载</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
            </div>
        </div>

        <!-- 内容 -->
        <div class="download_nr">
            <div class="down-container hd-download"></div>
            <div class="page-download">
                <div class="part-download">
                    <div class="down-container">
                        <div class="hd-part wow fadeInLeft"
                             data-wow-offset="10">
                            成公训练营<br/>
                            <div class="small">
                                专项提分、题型攻克、刷题直播<br/>闯关模式，让学习变得更高效
                            </div>
                        </div>
                        <img
                                src="@/assets/img/part1.png"
                                alt="大咖课程"
                                class="bd-part wow fadeInRight"
                        />
                    </div>
                </div>
                <div class="part-download">
                    <div class="down-container">
                        <div class="hd-part wow fadeInRight">
                            成公电台<br/>
                            <div class="small">
                                高温文章、常识积累、面试答题<br/>音频课程、把成公知识装进你的耳机
                            </div>
                        </div>
                        <img
                                src="@/assets/img/part2.png"
                                alt="大咖课程"
                                class="bd-part wow fadeInLeft"
                        />
                    </div>
                </div>
                <div class="part-download">
                    <div class="down-container">
                        <div class="hd-part wow fadeInLeft">
                            掌门私教班<br/>
                            <div class="small">
                                科学测评、随学随问、极速上岸<br/>闯关模式，让学习变得更高效
                            </div>
                        </div>
                        <img
                                src="@/assets/img/part3.png"
                                alt="大咖课程"
                                class="bd-part wow fadeInRight"
                        />
                    </div>
                </div>

                <div class="tu_icon">
                    <img src="@/assets/img/tu_icon.jpg"
                         class="wow fadeInDown"/>
                </div>
            </div>
        </div>
        <SrFooter></SrFooter>
    </div>
</template>
<script>
    import Swiper from "@/assets/js/swiper.min.js";
    import WOW from "@/assets/js/wow.min.js";
    import SrNav from "@/layout/SrNav";
    import SrBanner from "@/layout/SrBanner";
    import SrFooter from "@/layout/SrFooter";

    export default {
        name: "index",
        components: {
            SrNav,
            SrBanner,
            SrFooter,
        },
        data() {
            return {};
        },
        mounted() {
            var swiper = new Swiper('.swiper-container', {
                spaceBetween: 30,
                centeredSlides: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

            // 初始化wow
            // new WOW().init();
        },
        methods:{
            toIos(){
                window.open(this.$store.state.common.config[26].text);
            }
        }
    };
</script>
<style>
    @import "../../assets/css/swiper.min.css";
    @import "../../assets/css/animate.css";
    @import "../../assets/css/xia.css";
</style>
