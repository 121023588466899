<template>
    <div>
        <div class="warp">
            <a-skeleton style="margin-top: 50px"
                        v-if="loading"
                        :title="false"
                        :paragraph="{rows:3}"/>
            <div v-else
                 class="course-fl1 mt50">
                <a v-for="(item,index) in list"
                   :index="item.radio_category_id"
                   @click="selectCategory(item,index)"
                   :class="[active_index===index?'active':'']">
                    {{item.name}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {typeList} from "@/api/camp"

    export default {
        name: "CampTag",
        data() {
            return {
                loading: false,
                list: [],
                active_index: 0
            }
        },
        created() {
            var _self = this
            _self.loading = true
            typeList().then((res) => {
                _self.loading = false
                _self.list = res.result
                this.$emit('change', res.result[0])
            })
        },
        methods: {
            selectCategory(item, index) {
                this.active_index = index
                this.$emit('change', item)
            }
        }
    }
</script>

<style scoped>

    .warp a {
        color: #000;
    }

    .course-fl1 .active {
        color: #fff;
    }

    .course-fl1 a:hover {
        color: #fff;
    }

    .course-fl2 .active {
        color: #3561C7;
    }

    .course-fl2 a:hover {
        color: #3561C7;
    }
</style>
