<template>
    <div>
        <a-modal :visible="visible"
                 :footer="false"
                 :closable="false"
                 :bodyStyle="{padding:0}"
                 width="800px"
                 wrapClassName="sr-dialog">
            <div class="motal_box">
                <div class="motal_title">
                    <span v-if="action==='add'">添加收货地址</span>
                    <span v-else>编辑收货地址</span>
                </div>
                <div class="motal_input_box">
                    <div class="motal_input">
                        <p><span>*</span>收货人：</p>
                        <input
                                type="text"
                                v-model="addressForm.name"
                                placeholder="请输入收货人"
                        />
                    </div>
                    <div class="motal_input">
                        <p><span>*</span>收货地址：</p>
                        <a-select style="width: 27%;margin: 0 11px 0 0"
                                  placeholder="请选择"
                                  v-model="addressForm.province_id"
                                  @change="changeProvince">
                            <a-select-option v-for="item in province_list"
                                             :value="item.province_id">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                        <a-select
                                style="width: 27%;margin: 0 11px 0 0"
                                v-model="addressForm.city_id"
                                placeholder="请选择"
                                @change="changeCity">
                            <a-select-option v-for="item in city_list"
                                             :value="item.city_id">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                        <a-select
                                style="width: 28%;"
                                v-model="addressForm.district_id"
                                placeholder="请选择">
                            <a-select-option v-for="item in district_list"
                                             :value="item.district_id">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="motal_input">
                        <p><span>*</span>详细地址：</p>
                        <input
                                type="text"
                                v-model="addressForm.address"
                                placeholder="请输入详细地址"
                        />
                    </div>
                    <div class="motal_input">
                        <p><span>*</span>联系电话：</p>
                        <input
                                type="number"
                                v-model="addressForm.mobile"
                                placeholder="请输入联系电话"
                        />
                    </div>
                    <!--<div class="motal_check">-->
                    <!--<input type="checkbox"-->
                    <!--@change="checkBoxss"-->
                    <!--v-model="is_default"/>-->
                    <!--<span>默认收货地址</span>-->
                    <!--</div>-->
                    <SrButton
                            style="width: 250px; margin: 0 auto; margin-top: 30px"
                            @click="submit"
                            font-size="14px"
                            height="40px"
                            padding="0 20px"
                            :loading="loading"
                    >
                        保存收货地址
                    </SrButton>
                </div>
            </div>
            <a class="modal-close">
                <img @click="visible = false"
                     src="@/assets/img/modal-close.png">
            </a>
        </a-modal>
    </div>
</template>

<script>
    import SrButton from '@/components/SrButton'
    import {location} from "@/api/common";
    import {
        addAddress,
        editAddress
    } from "@/api/user";

    export default {
        name: "ItemForm",
        components: {
            SrButton
        },
        data() {
            return {
                visible: false,
                pintuan: {},
                pintuan_group: {},
                list: [],
                action: 'add',
                loading: false,
                addressForm: {
                    is_default: 0
                },
                province_list: [],
                city_id: null,
                city_list: [],
                district_list: []
            }
        },
        created() {
            location().then((res) => {
                this.province_list = res;
            });
        },
        watch: {
            // 'addressForm.province_id': (value) => {
            //     console.log(this)
            //     var city_list = []
            //     this.province_list.forEach((item) => {
            //         if (item.province_id === value) {
            //             city_list = item.city
            //         }
            //     })
            //     this.city_list = city_list
            // }
            'addressForm.province_id': {
                handler: function (value) {
                    var city_list = []
                    this.province_list.forEach((item) => {
                        if (item.province_id === value) {
                            city_list = item.city
                        }
                    })
                    this.city_list = city_list
                }
            },
            'addressForm.city_id': {
                handler: function (value) {
                    var district_list = []
                    this.city_list.forEach((item) => {
                        if (item.city_id === value) {
                            district_list = item.district
                        }
                    })
                    this.district_list = district_list
                }
            }
        },
        methods: {
            show(item) {
                var _self = this
                _self.visible = true
                if (item) {
                    this.action = 'edit'
                    this.addressForm = JSON.parse(JSON.stringify(item))
                } else {
                    this.action = 'add'
                    this.addressForm = {
                        is_default: 0
                    }
                }
            },
            changeProvince(value) {
                delete this.addressForm.city_id
                delete this.addressForm.district_id
                var city_list = []
                this.province_list.forEach((item) => {
                    if (item.province_id === value) {
                        city_list = item.city
                    }
                })
                this.city_list = city_list
            },
            checkBoxss(e) {
                if (e.target.checked) {
                    this.addressForm.is_default = 1;
                } else {
                    this.addressForm.is_default = 0;
                }
            },
            changeCity(value) {
                delete this.addressForm.district_id
                var district_list = []
                this.city_list.forEach((item) => {
                    if (item.city_id === value) {
                        district_list = item.district
                    }
                })
                this.district_list = district_list
            },
            submit() {
                var _self = this
                _self.loading = true
                if (_self.action === 'add') {
                    addAddress(this.addressForm).then((res) => {
                        if (res.status === 200) {
                            _self.$emit('ok')
                            _self.$message.success('添加成功');
                            _self.visible = false
                        } else {
                            _self.$srmsg.info({
                                title: '提示',
                                content: res.msg
                            })
                        }
                        _self.loading = false
                    })
                } else {
                    editAddress(this.addressForm).then((res) => {
                        if (res.status === 200) {
                            _self.$emit('ok')
                            _self.$message.success('添加成功');
                            _self.visible = false
                        } else {
                            _self.$srmsg.info({
                                title: '提示',
                                content: res.msg
                            })
                        }
                        _self.loading = false
                    })
                }

            }
        }
    }
</script>

<style scoped>

    .motal {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 10000;
        top: 0;
        left: 0;
    }

    .motal_box {
        width: 1000px;
        background: #ffffff;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 120px;
        padding: 30px;
    }

    .motal_title {
        font-size: 20px;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 20px;
    }

    .motal_input_box {
        margin: 0 auto;
        width: 700px;
        margin-top: 30px;
    }

    .motal_input {
        width: 100%;
        margin-bottom: 20px;
    }

    .motal_input p {
        display: inline-block;
        font-size: 16px;
        width: 100px;
    }

    .motal_input span {
        color: red;
        padding-right: 10px;
    }

    .motal_input input {
        display: inline-block;
        border: 1px solid #eaeaea;
        border-radius: 4px;
        height: 50px;
        line-height: 50px;
        padding-left: 20px;
        width: calc(100% - 100px);
    }

    .motal_input select {
        width: calc(100% / 3 - 40px);
        display: inline-block;
        border: 1px solid #eaeaea;
        height: 50px;
        line-height: 50px;
        padding-left: 20px;
        border-radius: 4px;
        margin-right: 10px;
    }

    .motal_input select:last-child {
        margin-right: 0;
    }

    input[type="checkbox"] {
        outline: none;
        -webkit-appearance: none;
        position: relative;
        vertical-align: middle;
        border: 1px solid #eaeaea;
        border-radius: 50%;
        min-height: 20px;
        min-width: 20px;
    }

    input[type="checkbox"]:checked {
        border: 1px solid #eaeaea;
        outline: none;
        background: #3561c7;
    }

    input[type="checkbox"]:checked::after {
        content: "\00a0";
        display: inline-block;
        border: 2px solid #fff;
        border-top-width: 0;
        border-right-width: 0;
        width: 16px;
        height: 8px;
        -webkit-transform: rotate(-50deg);
        position: absolute;
        top: 2px;
        left: 1px;
    }

    .motal_check span {
        margin-left: 10px;
        margin-top: 10px;
    }

    .motal_input >>> .ant-select-selection__rendered {
        height: 50px;
        line-height: 50px;
    }

    .motal_input >>> .ant-select {
        height: 50px;
        line-height: 50px;
    }

    .motal_input >>> .ant-select-selection--single {
        height: 50px;
        line-height: 50px;
    }

    .motal_input >>> .ant-select-selection {
        height: 50px;
        line-height: 50px;
    }
</style>
