<template>
    <div>
        <div v-if="!loading">
            <a-empty v-if="list.length===0"
                     description="暂无评论"/>
            <div v-else>
                <transition-group
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                        tag="div">
                    <dl v-for="item in list"
                        :key="item.comment_id"
                        class="kc-evaluate-list clearfix">
                        <dt class="fn_l"><img :src="item.user.head_portrait.url"></dt>
                        <dd class="fn_r">
                            <div class="">
                                <h3>
                                    <span>{{item.user.nickname}}</span>
                                    <img src="@/assets/img/hyjb.png">
                                    <span @click="like(item)"
                                          :class="[item.is_like?'kc-like-hover':'','kc-like']">
                                        {{item.likes}}
                                    </span>
                                </h3>
                                <p class="color-666">{{item.content}}</p>

                                <div class="clearfix">
                                    <div v-if="item.video_id"
                                         class="fn_l kc-evaluate-listImg video-play">
                                        <img @click="showVideo(item.video.url)"
                                             :src="item.video.cover">
                                    </div>
                                    <div :key="image_index"
                                         v-for="(image_item,image_index) in item.image"
                                         class="fn_l kc-evaluate-listImg">
                                        <img height="127px"
                                             :src="image_item.url">
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </transition-group>
            </div>
        </div>
        <div v-else>
            <a-spin style="text-align: center;width: 100%;margin-top: 90px;color: #3561C7">
                <a-icon slot="indicator"
                        type="loading"
                        style="font-size: 30px"
                        spin/>
            </a-spin>
        </div>
        <!-- 页码 -->
        <SrPaginate style="margin: 0 auto;text-align: center;margin-top: 50px"
                    :current="pagination.current_page"
                    :total="pagination.total"
                    :pageSize="pagination.page_size"
                    @change="changePage"></SrPaginate>
        <VideoModal ref="VideoModal"></VideoModal>
    </div>
</template>

<script>
    import SrPaginate from '@/components/SrPaginate'
    import SrButton from '@/components/SrButton'
    import {commentList, comment} from "@/api/school/course_package"
    import {like} from "@/api/comment"
    import Velocity from 'velocity-animate'
    import {uploadVideo, uploadImage} from "@/api/common"
    import VideoModal from '@/components/VideoModal'

    export default {
        name: "CommentList",
        props: {
            course_package_id: {}
        },
        components: {
            SrPaginate,
            SrButton,
            VideoModal
        },
        data() {
            return {
                loading: false,
                list: [],
                pagination: {
                    page: 1,
                    page_size: 6
                },
                comment_loading: false,
                upload_loading: false,
                form: {
                    video: {},
                    comment_enclosure_id: [],
                    image: []
                }
            }
        },
        watch: {
            course_package_id(value) {
                if (value){
                    this.getData()
                }
            }
        },
        methods: {
            getData() {
                var _self = this
                _self.loading = true
                commentList({course_package_id: this.course_package_id, ...this.pagination}).then((res) => {
                    _self.list = res.result.items
                    _self.loading = false
                    _self.pagination = res.result.pagination
                })
            },
            changePage(page, page_size) {
                this.pagination.page = page
                this.getData()
            },
            chooseImage() {
                this.$refs.file.click()
            },
            beforeEnter: function (el) {
                el.style.opacity = 0
            },
            enter: function (el, done) {
                var delay = el.dataset.index * 150
                setTimeout(function () {
                    Velocity(
                        el,
                        {opacity: 1},
                        {complete: done}
                    )
                }, delay)
            },
            leave: function (el, done) {
                var delay = el.dataset.index * 150
                setTimeout(function () {
                    Velocity(
                        el,
                        {opacity: 0},
                        {complete: done}
                    )
                }, delay)
            },
            comment() { // 提交评论
                var _self = this
                _self.comment_loading = true
                comment({course_id: _self.course_id, ..._self.form}).then((res) => {
                    _self.comment_loading = false
                    if (res.status === 200) {
                        _self.$message.success('提交成功')
                        _self.list.unshift(res.result)
                    } else {
                        _self.$message.warning(res.msg)
                    }
                })
            },
            fileChange() {
                var file = this.$refs.file.files[0]
                if (file) {
                    var allow_img = ['image/jpeg', 'image/png', 'image/bmp'];
                    var allow_video = ['video/mp4', 'video/msvideo', 'video/ms-wmv', 'video/flv'];
                    if (allow_img.indexOf(file.type) !== -1) {
                        this.addImage(file)
                        return true
                    }
                    if (allow_video.indexOf(file.type) !== -1) {
                        this.addVideo(file)
                        return true
                    }
                    this.$message.warning('所选文件格式不支持')
                }
            },
            addImage(file) {
                var _self = this
                var formData = new FormData()
                formData.append('image', file)
                _self.upload_loading = true
                uploadImage(formData).then((res) => {
                    _self.upload_loading = false
                    if (res.status === 200) {
                        _self.form.comment_enclosure_id.push(res.result.image_id)
                        _self.form.image.push(res.result)
                    } else {
                        _self.$message.warning(res.msg)
                    }
                })
            },
            addVideo(file) {
                var _self = this
                var formData = new FormData()
                formData.append('video', file)
                _self.upload_loading = true
                uploadVideo(formData).then((res) => {
                    _self.upload_loading = false
                    if (res.status === 200) {
                        _self.form.video_id = res.result.video_id
                        _self.form.video = res.result
                    } else {
                        _self.$message.warning(res.msg)
                    }
                })
            },
            showVideo(url) { // 视频窗口
                this.$refs.VideoModal.show(url)
            },
            like(item) { // 点赞
                like({comment_id: item.comment_id, status: !item.is_like})
                if (item.is_like) {
                    item.likes--
                } else {
                    item.likes++
                }
                item.is_like = !item.is_like
            }
        }
    }
</script>

<style scoped>
    .choose-image-btn {
        cursor: pointer;
    }

    .list-complete-item {
        transition: all 1s;
        display: inline-block;
        margin-right: 10px;
    }

    .list-complete-enter, .list-complete-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }

    .video-play {
        cursor: pointer;
    }
</style>
