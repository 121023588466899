<template>
    <div style="position: relative">
        <a-skeleton v-if="loading"
                    :active="true"
                    :paragraph="{rows:8}"
                    :title="false"/>
        <div v-else>
            <a-carousel :autoplay="true"
                        :dots="false"
                        ref="banner"
                        :before-change="onChange">
                <div v-for="(item,index) in list">
                    <img @click="jump(item)"
                         style="cursor: pointer;width:100%"
                         v-if="item.banner_type===1"
                         :src="item.pc_image.url"
                         alt="">
                    <video width="100%"
                           style="max-height: 560px"
                           v-if="item.banner_type===2"
                           controls
                           :src="item.video.url"
                           alt=""></video>
                </div>
            </a-carousel>
            <div class="dot">
                <div @click="changeSlide(index)"
                     class="dot-item"
                     :class="[active_index===index?'dot-active':'']"
                     v-for="(item,index) in list"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Carousel, Skeleton} from 'ant-design-vue'
    // import {banner} from "@/api/store";
    import {banner} from "@/api/store";

    export default {
        name: "SrNav",
        components: {
            'a-carousel': Carousel,
            'a-skeleton': Skeleton
        },
        data() {
            return {
                loading: true,
                active_index: 0,
                list: [],
                disabled: false
            }
        },
        created() {
            var _self = this
            banner({
                site: 2
            }).then((res) => {
                console.log(res,'banner')
                _self.list = res.result
                _self.loading = false
            })
        },
        methods: {
            changeSlide(index) {
                var _self = this
                if (!_self.disabled) {
                    _self.active_index = index
                    _self.$refs.banner.goTo(index)
                    _self.disabled = true
                    setTimeout(() => {
                        _self.disabled = false
                    }, 500)
                }

            },
            onChange(form, to,) {
                this.active_index = to
            },
            jump(item) {
               // console.log(item)
                if (item.jump === 1) {
                    this.$router.push({path: '/course/info/' + item.jump_id})
                } else if (item.jump === 2) {
                    this.$router.push({path: '/course-package/info/' + item.jump_id})
                } else if (item.jump === 3) {
                    this.$router.push({path: '/radio/info/' + item.jump_id})
                } else if (item.jump === 4) {
                    this.$router.push({path: '/camp/info/' + item.jump_id})
                } else {

                }
            }
        }
    }
</script>

<style scoped>
    .dot {
        position: absolute;
        bottom: 30px;
        width: 100%;
        height: 15px;
        display: flex;
        justify-content: center;
    }

    .dot-item {
        background: #FFFFFF;
        opacity: 1;
        width: 15px;
        height: 15px;
        margin: 0 8px !important;
        border-radius: 8px 8px;
        transition: all .3s;
        cursor: pointer;
    }

    .dot-active {
        background: #eb98c2;
        width: 40px;
        border-radius: 8px;
    }

</style>
