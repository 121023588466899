<template>
    <div>
        <ul class="tab-menu">
            <li @click="selectActive(index)"
                v-for="(item,index) in list"
                :style="{left:(index*160)+'px'}"
                :class="[active_index===index?'li-active':'']"
                :key="index">
                {{item}}
            </li>
            <div :style="{left:(active_index*160)+'px'}"
                 id="active"></div>
        </ul>

    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            list: {},
            value: {}
        },
        data() {
            return {
                active_index: 0
            }
        },
        methods: {
            selectActive(index) {
                this.active_index = index
                this.$emit('change',index)
            }
        }
    }
</script>

<style scoped>
    .tab-menu {
        position: relative;
    }

    li {
        position: absolute;
        z-index: 5;
    }

    .li-active {
        font-weight: bold;
        color: #3561C7;
    }

    #active {
        content: "";
        position: absolute;
        left: 1px;
        bottom: 18px;
        width: 78px;
        height: 8px;
        border-radius: 4px;
        z-index: 4;
        background: #d7dff4;
        transition: all .3s;
    }
</style>
