import request from '@/utils/request'

export function category(parameter) {
    return request({
        url: '/api/mock/category',
        method: 'post',
        data: parameter
    })
}
export function lists(parameter) {
    return request({
        url: '/api/mock/lists',
        method: 'post',
        data: parameter
    })
}
export function unlockExam(parameter) {
    return request({
        url: '/api/mock/unlockExam',
        method: 'post',
        data: parameter
    })
}
export function paper(parameter) {
    return request({
        url: '/api/mock/paper',
        method: 'post',
        data: parameter
    })
}
export function subject(parameter) {
    return request({
        url: 'api/exam/paper/subject',
        method: 'post',
        data: parameter
    })
}
export function createOrder(parameter) {
    return request({
        url: 'api/answer/createOrder',
        method: 'post',
        data: parameter
    })
}
export function submit(parameter) {
    return request({
        url: 'api/answer/submit',
        method: 'post',
        data: parameter
    })
}
export function mockPaper(parameter) {
    return request({
        url: 'api/mock/paper',
        method: 'post',
        data: parameter
    })
}
export function hand(parameter) {
    return request({
        url: 'api/answer/hand',
        method: 'post',
        data: parameter
    })
}
export function report(parameter) {
    return request({
        url: 'api/mock/report',
        method: 'post',
        data: parameter
    })
}
export function exerciseReport(parameter) {
    return request({
        url: 'api/subject/exerciseReport',
        method: 'post',
        data: parameter
    })
}
export function situation(parameter) {
    return request({
        url: 'api/exam/situation',
        method: 'post',
        data: parameter
    })
}
export function addMake(parameter) {
    return request({
        url: 'api/subject/addMake',
        method: 'post',
        data: parameter
    })
}
export function history(parameter) {
    return request({
        url: 'api/paper/answer/history',
        method: 'post',
        data: parameter
    })
}
export function wrongSet(parameter) {
    return request({
        url: 'api/exam/wrong/wrongSet',
        method: 'post',
        data: parameter
    })
}
export function wrongSetInfo(parameter) {
    return request({
        url: 'api/exam/wrong/wrongSetInfo',
        method: 'post',
        data: parameter
    })
}
export function removeWrongSet(parameter) {
    return request({
        url: 'api/exam/wrong/removeWrongSet',
        method: 'post',
        data: parameter
    })
}
export function addNote(parameter) {
    return request({
        url: 'api/subject/note/add',
        method: 'post',
        data: parameter
    })
}
export function collList(parameter) {
    return request({
        url: 'api/subject/coll/list',
        method: 'post',
        data: parameter
    })
}
export function operate(parameter) {
    return request({
        url: 'api/subject/coll/operate',
        method: 'post',
        data: parameter
    })
}
export function subjectProblem(parameter) {
    return request({
        url: 'api/exam/paper/subjectProblem',
        method: 'post',
        data: parameter
    })
}
export function collDetail(parameter) {
    return request({
        url: 'api/subject/coll/collDetail',
        method: 'post',
        data: parameter
    })
}