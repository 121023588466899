<template>
  <div>
    <SrNav></SrNav>
    <div class="warp">
      <!-- 面包屑 -->
      <div class="xun1_mbx">
        <div class="xun1_mbx_nr">
          <i></i>
          <div class="xun1_mbx_a">
            <a @click="goIndex">首页 -</a>
            <a @click="goCommunity">社区 -</a>
            <a class="xun1_mbx_on">详情</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 考试计划列表 -->
    <div class="warp clearfix mt30">
      <div class="xun_plan">
        <div class="xun_plan_l">
          <div class="community2_project">
            <div class="community2_pro_tit">
              <h6>{{ detail.name }}</h6>
              <p>发布时间：{{ detail.created_at }}</p>
            </div>
            <div class="community2_pro_wen">
              <div class="pro2_wen1">
                <p v-html="detail.content"></p>
              </div>
              <div class="community2_tea">
                <div class="coll_r_nei">
                  <!-- <div class="coll_n_one">
                    <img src="@/assets/img/coll_tea.jpg" />
                    <span>江洋</span>
                  </div> -->
                  <div class="comm2_coll">
                    <div class="coll_n_one coll_n_one1 coll_n_one2">
                      <img src="@/assets/img/coll_liu.png" />
                      <span
                        >浏览量：<i>{{ detail.browse }}</i></span
                      >
                    </div>
                    <div class="coll_n_one coll_n_one1 coll_n_one2">
                      <img src="@/assets/img/comm2_dian.png" />
                      <span
                        >点赞数：<i>{{ detail.like_count }}</i></span
                      >
                    </div>
                    <div class="coll_n_one coll_n_one1 coll_n_one2">
                      <img src="@/assets/img/coll_ping.png" />
                      <span
                        >评论量：<i>{{ detail.comment.length }}</i></span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="comm2_collection">
                <div
                  :class="
                    detail.is_like == 0
                      ? 'comm2_collection1'
                      : 'comm2_collection2'
                  "
                  @click="getLike"
                >
                  <div class="comm2_collection1_wen">
                    <div class="comm2_collection1_img">
                      <img
                        v-if="detail.is_like == 0"
                        src="@/assets/img/comm2_dian1.png"
                      />
                      <img v-else src="@/assets/img/comm2_dian2.png" />
                    </div>
                    <span>{{ detail.like_count }}</span>
                  </div>
                </div>
                <div
                  :class="
                    detail.is_collection == 0
                      ? 'comm2_collection1'
                      : 'comm2_collection2'
                  "
                  @click="getCollection"
                >
                  <div class="comm2_collection1_wen comm2_collection1_wen1">
                    <div class="comm2_collection1_img">
                      <img
                        v-if="detail.is_collection == 0"
                        src="@/assets/img/comm2_shou.png"
                      />
                      <img v-else src="@/assets/img/comm2_shou2.png" />
                    </div>
                    <span>{{
                      detail.is_collection == 0 ? "收藏" : "已收藏"
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="comm2_download" v-if="detail.file.length > 0">
                <span>相关文件下载：</span>
                <div class="down2_a" v-for="(i, index) in detail.file">
                  <div>文件下载：</div>
                  <a :href="i.url" 
                    >文件{{ index + 1 }}</a
                  >
                </div>
              </div>

              <div class="comm2_course">
                <div class="p_remind_title p_remind_title1">
                  <img src="@/assets/img/remind_icon.png" />
                  <h6>相关课程</h6>
                </div>

                <div class="kc-baoM">
                  <div class="swiper-container kc-baoList mt20">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide fontss"
                        v-for="i in relation_about_goods"
                      >
                        <a @click="jump(i.goods)"
                          ><img :src="i.goods.image.url" />
                          <span>{{ i.goods.name }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-button-prev">
                    <img src="@/assets/img/kc-butL.png" />
                  </div>
                  <div class="swiper-button-next">
                    <img src="@/assets/img/kc-butR.png" />
                  </div>
                </div>
              </div>

              <div class="comm2_zhuan">
                <div class="comm2_zhuan_one">
                  <dl class="kc-evaluate clearfix">
                    <!-- <dt class="fn_l">
                      <img src="@/assets/img/KC-avatar.jpg" />
                    </dt> -->
                    <dd style="width: 100%">
                      <div class="clearfix">
                        <input
                          class="fn_l"
                          type="text"
                          id=""
                          value=""
                          v-model="content"
                        />
                        <a class="fn_r" @click="addComment">评论</a>
                      </div>
                      <div class="clearfix mt20">
                        <p class="kc-upload fn_l" v-if="video">
                          <img :src="video.url" />
                          <span @click="delVideo()"></span>
                        </p>
                        <p
                          class="kc-upload fn_l"
                          v-for="(i, index) in imgList"
                          v-if="imgList.length > 0"
                        >
                          <img :src="i.url" />
                          <span @click="delImg2(index)"></span>
                        </p>
                        <p
                          class="kc-upload fn_l choose-image-btn"
                          @click="chooseImage"
                        >
                          <img src="@/assets/img/upload-image.jpg" />
                          <input
                            type="file"
                            style="display: none"
                            ref="file2"
                            @change="fileChange"
                          />
                        </p>
                      </div>
                    </dd>
                  </dl>
                </div>

                <div class="comm2_zhuan_two">
                  <dl
                    class="kc-evaluate-list clearfix"
                    v-for="i in detail.comment"
                  >
                    <dt class="fn_l">
                      <img
                        style="width: 44px; height: 44px"
                        :src="i.user.head_portrait.url" v-if="i.user"
                      />
                    </dt>
                    <dd class="fn_r">
                      <div class="">
                        <h3>
                          {{ i.user.nickname }}
                          <img
                            :src="
                              i.user.now_user_member.user_member_config_id == 1
                                ? '/img/w1.png'
                                : i.user.now_user_member
                                    .user_member_config_id == 2
                                ? '/img/w3.png'
                                : i.user.now_user_member
                                    .user_member_config_id == 3
                                ? '/img/w4.png'
                                : i.user.now_user_member
                                    .user_member_config_id == 4
                                ? '/img/w5.png'
                                : i.user.now_user_member
                                    .user_member_config_id == 5
                                ? '/img/w6.png'
                                : '/img/w6.png'
                            "
                            v-if="i.user.now_user_member"
                          />
                          <span class="xly-jy xly-jy1" v-if="i.is_digest == 1"
                            >加精</span
                          >
                          <span
                            @click="
                              openReply(i.community_comment_id, i.user.nickname)
                            "
                            class="xly-hfBut fn_r"
                            ><img src="@/assets/img/xly-pl.png"
                          /></span>
                          <span
                            :class="i.is_like == 0 ? 'kc-like' : 'kc-like2'"
                            @click="is_like(i.community_comment_id, i.is_like)"
                            >{{ i.likes }}</span
                          >
                        </h3>
                        <p class="color-666">
                          {{ i.content }}
                        </p>
                        <div class="clearfix" v-if="i.image.length > 0">
                          <div class="fn_l kc-evaluate-listImg" v-for="item in i.image">
                            <img :src="item.url" />
                          </div>
                        </div>
                        <!-- <div class="mt10">
                          <img src="@/assets/img/xly-yp.png" />
                        </div> -->
                      </div>
                      <!-- 评论回复弹窗结束 -->
                      <div class="xly-hfBox mt10" v-if="i.reply.length > 0">
                        <div class="" v-for="item in i.reply">
                          <p class="f14 color-666">
                            <span v-if="item.user">{{item.user.nickname}}回复：</span> {{item.content}}
                          </p>
                          <div class="clearfix" v-if="item.image.length>0">
                            <div class="fn_l kc-evaluate-listImg" v-for="items in item.image">
                              <img :src="items.url" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="xun_plan_r community2">
          <a v-for="i in relation_goods" @click="jump(i.goods)">
            <img :src="i.goods.image.url" />
            <p>{{ i.goods.name }}</p>
          </a>
        </div>
      </div>
    </div>
    <!-- 评论回复弹窗开始 -->
    <div class="xly-modalBg" v-show="isShow">
      <div class="xly-modalBox">
        <div class="">
          <span class="color-999">回复“{{ replyName }}”</span>
          <!-- <span class="f22">主题名称</span> -->
        </div>
        <div class="mt20">
          <textarea rows="7" v-model="replyContent" cols="7"> </textarea>
        </div>
        <div class="clearfix mt20">
          <p class="kc-upload fn_l" v-if="replyVideo">
            <img :src="replyVideo.url" />
            <span @click="delVideo2()"></span>
          </p>
          <p
            class="kc-upload fn_l"
            v-for="(i, index) in replayimgList"
            v-if="replayimgList.length > 0"
          >
            <img :src="i.url" />
            <span @click="delImg2(index)"></span>
          </p>
          <p class="kc-upload fn_l choose-image-btn" @click="chooseImage2">
            <img src="@/assets/img/upload-image.jpg" />
            <input
              type="file"
              style="display: none"
              ref="file"
              @change="fileChange2"
            />
          </p>
        </div>
        <a class="modal-close mt80" @click="closeMotal"
          ><img src="@/assets/img/modal-close.png"
        /></a>
        <SrButton
          style="width: 80%; margin: 0 auto; margin-top: 30px"
          @click="addReply"
          :loading="reply_loading"
        >
          回复
        </SrButton>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import {
  category,
  list,
  info,
  specifiedCategory,
  comment,
  reply,
  like,
  collection,
} from "@/api/community";
import { uploadVideo, uploadImage } from "@/api/common";
import SrPaginate from "@/components/SrPaginate";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import Swiper from "@/assets/js/swiper.min.js";
import SrButton from "@/components/SrButton";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
    SrPaginate,
    SrButton,
  },
  data() {
    return {
      title: "",
      detail: {},
      relation_goods: [],
      relation_about_goods: [],
      upload_loading: false,
      comment_enclosure_id: [],
      comment_reply_enclosure_id: [],
      replayimgList: [],
      video_id: null,
      imgList: [],
      video: null,
      content: "",
      audio_id: null,
      isShow: false,
      community_comment_id: "",
      reply_loading: false,
      replyName: "",
      replyVideo: "",
      replyVideo_id: null,
      replyContent: "",
    };
  },
  mounted() {},

  methods: {
    loadDetail() {
      let _this = this;
      info({
        community_id: _this.$route.query.id,
      }).then((res) => {
        console.log(res, "详情");
        _this.detail = res.result;
        setTimeout(() => {
          var swiper = new Swiper(".kc-baoList", {
            slidesPerView: 4,
            spaceBetween: 30,
            slidesPerGroup: 4,
            loop: true,
            loopFillGroupWithBlank: true,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        }, 500);
      });
    },
    // 获取指定分类下的推荐课程和相关课程
    loadSpecifiedCategory() {
      let _this = this;
      specifiedCategory({
        community_category_id: _this.$route.query.community_category_id,
      }).then((res) => {
        console.log(res,'相关')
        _this.relation_goods = res.result.relation_goods;
        _this.relation_about_goods = res.result.relation_about_goods;
      });
    },
    jump(item) {
      if (item.type === 1) {
        this.$router.push({ path: "/course/info/" + item.relation_id });
      } else if (item.type === 2) {
        this.$router.push({ path: "/course-package/info/" + item.relation_id });
      } else if (item.type === 3) {
        this.$router.push({ path: "/radio/info/" + item.relation_id });
      } else if (item.type === 4) {
        this.$router.push({ path: "/camp/info/" + item.relation_id });
      } else {
         this.$router.push({ path: "/store/detail?wares_id=" + item.relation_id });
      }
    },
    addImage(file) {
      var _self = this;
      console.log(1)
      var formData = new FormData();
      formData.append("image", file);
      _self.upload_loading = true;
      uploadImage(formData).then((res) => {
        console.log(res, "上传图片");
        _self.upload_loading = false;
        if (res.status === 200) {
          _self.imgList.push(res.result);
          _self.comment_enclosure_id.push(res.result.image_id);
          console.log(_self.comment_enclosure_id, "图片id");
        } else {
          _self.$message.warning(res.msg);
        }
      });
    },
    addVideo(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("video", file);
      _self.upload_loading = true;
      uploadVideo(formData).then((res) => {
        console.log(res, "视频");
        _self.upload_loading = false;
        if (res.status === 200) {
          _self.video_id = res.result.video_id;
          _self.video = res.result;
        } else {
          _self.$message.warning(res.msg);
        }
      });
    },
    fileChange() {
      
      var file = this.$refs.file2.files[0];
      console.log(file)
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage(file);
          return true;
        }
        if (allow_video.indexOf(file.type) !== -1) {
          this.addVideo(file);
          return true;
        }
        this.$message.warning("所选文件格式不支持");
      }
    },

    fileChange2() {
      var file = this.$refs.file.files[0];
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage2(file);
          return true;
        }
        if (allow_video.indexOf(file.type) !== -1) {
          this.addVideo2(file);
          return true;
        }
        this.$message.warning("所选文件格式不支持");
      }
    },
    addImage2(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("image", file);
      _self.upload_loading = true;
      uploadImage(formData).then((res) => {
        console.log(res, "上传图片");
        _self.upload_loading = false;
        if (res.status === 200) {
          _self.replayimgList.push(res.result);
          _self.comment_reply_enclosure_id.push(res.result.image_id);
          console.log(_self.comment_enclosure_id, "回复评论图片上传");
        } else {
          _self.$message.warning(res.msg);
        }
      });
    },
    addVideo2(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("video", file);
      _self.upload_loading = true;
      uploadVideo(formData).then((res) => {
        console.log(res, "视频");
        _self.upload_loading = false;
        if (res.status === 200) {
          _self.replyVideo_id = res.result.video_id;
          _self.replyVideo = res.result;
        } else {
          _self.$message.warning(res.msg);
        }
      });
    },
    chooseImage() {
      this.$refs.file2.click();
    },
    chooseImage2() {
      this.$refs.file.click();
    },
    delImg(index) {
      this.imgList.splice(index, 1);
      this.comment_enclosure_id.splice(index, 1);
      console.log(this.comment_enclosure_id);
    },
    delImg2(index) {
      this.replayimgList.splice(index, 1);
      this.comment_reply_enclosure_id.splice(index, 1);
    },
    delVideo() {
      this.video = null;
      this.video_id = "";
    },
    delVideo2() {
      this.replyVideo = null;
      this.replyVideo_id = "";
    },
    addComment() {
      let _this = this;
      if (_this.content == "") {
        this.$message.warning("请输入内容");
      } else {
        _this.reply_loading = true
        comment({
          community_id: _this.$route.query.id,
          content: _this.content,
          video_id: _this.video_id,
          comment_enclosure_id: _this.comment_enclosure_id,
          audio_id: _this.audio_id,
        }).then((res) => {
          console.log(res, "评价");
          _this.reply_loading = false;
          if (res.status == 200) {
            _this.$message.success("评价成功");
            _this.content = "";
            _this.video_id = null;
            _this.imgList = [];
            _this.comment_enclosure_id = [];
            _this.audio_id = null;
            _this.video = null;
            _this.loadDetail();
          } else {
            _this.$message.warning(res.msg);
          }
        });
      }
    },
    // 回复评论
    openReply(id, name) {
      this.community_comment_id = id;
      this.replyName = name;
      this.isShow = true;
      this.replyContent = "";
      this.replyVideo_id = null;
      this.replayimgList = [];
      this.comment_reply_enclosure_id = [];
      this.audio_id = null;
      this.replyVideo = null;
    },
    closeMotal() {
      this.isShow = false;
    },
    addReply() {
      let _this = this;
      if (_this.replyContent == "") {
        _this.$message.warning("请输入内容");
      } else {
        reply({
          community_id: _this.$route.query.id,
          community_comment_id: _this.community_comment_id,
          content: _this.replyContent,
          video_id: _this.replyVideo_id,
          comment_reply_enclosure_id: _this.comment_reply_enclosure_id,
          audio_id: _this.audio_id,
        }).then((res) => {
          console.log(res, "评价");
          if (res.status == 200) {
            _this.$message.success("评价成功");
            _this.replyContent = "";
            _this.replyVideo_id = null;
            _this.replayimgList = [];
            _this.comment_reply_enclosure_id = [];
            _this.audio_id = null;
            _this.replyVideo = null;
            _this.loadDetail();
            _this.isShow = false;
          } else {
            _this.$message.warning(res.msg);
          }
        });
      }
    },
    // 社区点赞
    getLike() {
      let _this = this;
      like({ community_id: _this.$route.query.id }).then((res) => {
        console.log(res, "点赞");
        if (res.status == 200) {
          _this.loadDetail();
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    // 收藏
    getCollection() {
      let _this = this;
      collection({ community_id: _this.$route.query.id }).then((res) => {
        if (res.status == 200) {
          _this.loadDetail();
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    goCommunity(){
       this.$router.push({ path: "/community" });
    },
    goIndex(){
       this.$router.push({ path: "/" });
    }
  },
  created() {
    this.loadDetail();
    this.loadSpecifiedCategory();
    console.log(this.$route.query);
  },
};
</script>
<style scoped>
.down2_a div{
  float: left;
}
.down2_a{
  display: block;
  margin-top: 10px;
  overflow: hidden;
}
.comm2_download span{
  display: block;
  float: none;
}
.fontss span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}
.kc-like2 {
  cursor: pointer;
  float: right;
  height: 21px;
  background: url(../../../assets/img/praise-hover.png) no-repeat left;
  padding-left: 28px;
  font-size: 16px;
  color: #999999;
}
.xly-modalBg {
  display: block;
}
.comm2_collection1 {
  cursor: pointer;
}
.comm2_collection2 {
  background: #3561c7;
  color: #ffffff;
  width: 46%;
  float: left;
  margin-right: 15px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.comm2_collection1_wen1 span {
  margin-top: -3px;
}
.comm2_collection2 span {
  font-size: 16px;
  color: #fff;
  float: left;
  margin-left: 10px;
}
.comm2_collection2 img {
  float: left;
}
a.modal-close {
    position: absolute;
    left: 50%;
    width: 66px !important;
    height: 66px !important;
    margin-left: -33px !important;
    padding: 0 !important;
    margin-top: 40px;
    background: none !important;
    border: none !important;
    top: 90%;
}
.xly-modalBox {
    position: fixed;
    width: 50%;
    padding: 30px 50px;
    background: #FFFFFF;
    border-radius: 30px;
    left: 46%;
    margin-left: -400px;
    top: 10%;
    box-sizing: border-box;
}
</style>