<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>
          我的积分<router-link
            :to="{ path: '/user/center/myAbout', query: { id: 8 } }"
            ><a href="">积分规则</a></router-link
          >
        </h6>
      </div>

      <div class="per7_integral">
        <div class="per7_in1">
          <ul>
            <li>
              <img src="@/assets/img/integral_img1.png" />
              <div class="integral_wen">
                <p>累计积分</p>
                <h6>{{ points.count_credit }}</h6>
              </div>
            </li>
            <li>
              <img src="@/assets/img/integral_img2.png" />
              <div class="integral_wen">
                <p>剩余积分</p>
                <h6>{{ points.credit }}</h6>
              </div>
            </li>
          </ul>
        </div>
        <!-- tab -->
        <div class="per7_in2">
          <div class="tab-wrapper">
            <ul class="tab-menu tab-menu-form">
              <li :class="tabIndex == 0 ? 'active' : ''" @click="getTab(0)">
                全部
              </li>
              <li :class="tabIndex == 1 ? 'active' : ''" @click="getTab(1)">
                获得
              </li>
              <li :class="tabIndex == 2 ? 'active' : ''" @click="getTab(2)">
                消耗
              </li>
            </ul>
            <div class="tab-content">
              <div>
                <table border="0" cellspacing="0" cellpadding="0" class="form">
                  <tr>
                    <th>账单名称</th>
                    <th>交易金额</th>
                    <th>余</th>
                    <th>获取时间</th>
                  </tr>
                  <tr v-if="list.length > 0" v-for="i in list">
                    <td>{{ i.marker }}</td>
                    <td class="red" v-if="i.status == 0">+{{ i.credit }}</td>
                    <td class="green" v-if="i.status == 1">-{{ i.credit }}</td>
                    <td>{{ i.user_credit }}</td>
                    <td>{{ i.created_at }}</td>
                  </tr>
                  <tr v-if="list.length == 0">
                    <td colspan="4" style="height: 100px; line-height: 100px">
                      暂无数据
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 页码 -->
      <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
      ></SrPaginate>
      <!-- <div class="page mt20">
        <a href="">&lt;&lt;</a>
        <a class="active" href="">1</a>
        <a href="">2</a>
        <a href="">&gt;&gt;</a>
      </div> -->
    </div>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import SrPaginate from "@/components/SrPaginate";
import { credit, getUserCredit } from "@/api/user";
export default {
  name: "MyPoints",
  components: {
    SrPaginate,
  },
  data() {
    return {
      status: "",
      list: [],
      points: {},
      tabIndex: 0,
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
  },
  methods: {
    loadCredit() {
      let _this = this;
      credit({ status: _this.status,..._this.pagination }).then((res) => {
        console.log(res, "积分列表");
        _this.list = res.result.items;
        _this.pagination = res.result.pagination;
      });
    },
    loadUserCredit() {
      let _this = this;
      getUserCredit({}).then((res) => {
        console.log(res, "积分");
        _this.points = res.result;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadCredit();
    },
    getTab(e) {
      this.tabIndex = e;
      if (e == 0) {
        this.status = "";
      } else {
        this.status = e;
      }
      this.loadCredit();
    },
  },
  created() {
    this.loadUserCredit();
    this.loadCredit();
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
</style>