import router from '@/router'
import store from '@/store'
import {userAbout} from "@/api/user";
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
NProgress.configure({showSpinner: false}) // NProgress Configuration

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.meta.title) {
        document.title = `${to.meta.title} - 成公社`
    } else {
        document.title = `成公社`
    }
    store.commit('SET_PAGE_LOADING', true)
    userAbout().then((res) => {
        store.commit('SET_CONFIG', res.result)
    })

    //初始化用户信息
    store.dispatch('getUserInfo').then((res) => {
        if (res) {
            //获取购物车信息
            store.dispatch('getCart').then(() => {
                store.commit('SET_PAGE_LOADING', false)
                NProgress.done()
                next()
            })
        } else {
            store.commit('SET_PAGE_LOADING', false)
            NProgress.done()
            next()
        }

    })

})
