<template>
  <div>
    <div class="set set1">
      <div class="set1_ni">
        <span>我的昵称</span>
        <input placeholder="昵称" v-model="nickname" class="ss_ni ss_ni1" />
      </div>
      <div class="set1_p">
        <p>请输入2-12字符的昵称</p>
      </div>
      <SrButton
        style="width: 55%; margin: 0 auto; margin-top: 50px"
        @click="setNickname"
        height="33px"
        width="50px"
        color="#ffffff"
        padding="0 20px"
        font-size="14px"
        :loading="nickName_loading"
      >
        修改昵称
      </SrButton>
      <div class="set1_ni1">
        <div class="wdtx">
          <span>我的头像</span>
          <!-- <p>
            <img :src="nickImg" />
            <input type="file" ref="file" @change="fileChange" />
          </p> -->

          <el-upload
            class="upload-demo"
            action=""
            drag
            :auto-upload="false"
            :show-file-list="false"
            :on-change="changeUpload"
          >
          <img :src="nickImg" alt="">
            <!-- <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击上传</div>
            <div class="el-upload__tip">
              支持绝大多数图片格式，单张图片最大支持5MB
            </div> -->
          </el-upload>
        </div>
      </div>
      <!-- <div class="set1_a" style="height: auto">
        <SrButton
          style=""
          @click="setHeadPortrait"
          height="33px"
          width="50px"
          color="#ffffff"
          padding="0 20px"
          font-size="14px"
          :loading="nickImg_loading"
        >
          上传头像
        </SrButton>
      </div> -->
    </div>

    <!-- vueCropper 剪裁图片实现-->
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
      </div>
      <p style="color: red; margin-top: 30px">提示：鼠标滚轮可以放大缩小图片</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finish" :loading="loading"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import { setNickname, setHeadPortrait } from "@/api/user";
import { uploadImage } from "@/api/common";
export default {
  name: "Pintuan",
  components: {
    SrButton,
  },
  props: {
    course: {},
  },
  data() {
    return {
      nickName_loading: false,
      nickImg_loading: false,
      nickname: "",
      nickImg: "",
      nickImgId: "",
      dialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [7, 7], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      picsList: [], //页面显示的数组
      loading: false,
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  mounted() {
    this.nickImg = this.$store.state.user.head_portrait.url;
    this.nickname = this.$store.state.user.nickname;
  },
  methods: {
    setNickname() {
      let _this = this;
      if (_this.nickname == "") {
        _this.$message.warning("请填写昵称");
        return;
      }
      _this.nickName_loading = true;
      setNickname({ nickname: _this.nickname }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          _this.login_loading = false;
          _this.$message.success("修改成功");
          setTimeout(() => {
            _this.$router.go(0);
          }, 1000);
        } else {
          _this.$message.warning(res.msg);
          _this.login_loading = false;
        }
      });
    },
    //   上传头像
    fileChange() {
      var file = this.$refs.file.files[0];
      console.log(file);
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage(file);
          return true;
        }
        if (allow_video.indexOf(file.type) !== -1) {
          this.addFile(file);
          return true;
        }
        this.$message.warning("所选文件格式不支持");
      }
    },
    addImage(file) {
      var _self = this;
      var formData = new FormData();
      formData.append("image", file);
      _self.upload_loading = true;
      uploadImage(formData).then((res) => {
        console.log(res, "------");
        _self.upload_loading = false;
        if (res.status === 200) {
          _self.nickImg = res.result.url;
          _self.nickImgId = res.result.image_id;
          _self.setHeadPortrait()
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    addFile(file) {},
    // 提交头像
    setHeadPortrait() {
      let _this = this;
      if (_this.nickImgId == "") {
        this.$message.warning("请先上传头像");
        return;
      }
      _this.nickImg_loading = true;
      setHeadPortrait({ img: _this.nickImgId }).then((res) => {
        console.log(res, "------");
        _this.upload_loading = false;
        if (res.status === 200) {
          _this.nickImg_loading = false;
          _this.$message.success("修改成功");
          setTimeout(() => {
            _this.$router.go(0);
          }, 1000);
        } else {
          _this.$message.warning(res.msg);
          _this.nickImg_loading = false;
        }
      });
    },

    changeUpload(file, fileList) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        return false;
      }
      this.fileinfo = file;

      console.log(file);
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.option.img = window.URL.createObjectURL(file.raw);
        console.log(this.option.img, "图片");
        this.dialogVisible = true;
      });
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        var fileName = this.fileinfo.name;
        console.log(data)
        var file = new File([data], fileName, {type: data.type, lastModified: Date.now()});
        console.log(file)
        this.loading = true;
        this.addImage(file)
        //上传阿里云服务器
        // client().put(fileName, data).then(result => {
        //   this.dialogVisible = false
        //   this.picsList.push(result.url)
        // }).catch(err => {
        //   console.log(err)
        //   this.loading = false
        // })
      });
    },
  },
};
</script>
<style scoped>
.wdtx img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 0 auto;
  margin-left: 100px;
}
.wdtx p {
  position: relative;
  width: 160px;
  height: 160px;
  display: inline-block;
  margin-left: 20px;
}
.wdtx input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  opacity: 0;
}
.cropper {
  width: 500px;
  height: 500px;
}
.cropper img {
  width: 500px;
  height: 500px;
}
.cropper-content {
  width: 500px;
  height: 500px;
  margin: 0 auto;
}
.upload-demo{
  width: 160px;
  height: 190px;
  border: none;
}
>>> .el-upload-dragger{
  border: none!important;
  text-align: center;

}
</style>