<template>
  <div>
    <div class="warp">
      <a-skeleton
        style="margin-top: 50px"
        v-if="loading"
        :title="false"
        :paragraph="{ rows: 3 }"
      />
      <div v-else>
        <div class="course-fl1 mt50">
          <a
            @click="selectCourseCategory(index)"
            :class="[index === active_course_category_index ? 'active' : '']"
            v-for="(item, index) in list"
            >{{ item.name }}</a>
        </div>
        <div class="course-fl2 mt30">
          <a @click="selectTop('top')" :class="[active_course_type_index == 't'?'active':'']">推荐</a>
          <a
            @click="selectCourseType(index, item)"
            :class="[index === active_course_type_index ? 'active' : '']"
            v-for="(item, index) in list[active_course_category_index]
              .course_type"
            >{{ item.name }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { list } from "@/api/school/course_category";

export default {
  name: "CourseTag",
  data() {
    return {
      loading: true,
      active_course_category_index: 0,
      active_course_type_index: "t",
      list: [
        {
          course_type: [],
        },
      ],

    };
  },
  created() {
    var _self = this;
    list().then((res) => {
      console.log(res, "re");
      _self.list = res.result;
      _self.loading = false;
      _self.$emit("change", res.result[0].course_type[0],'t');
    });
  },
  methods: {
    selectCourseCategory(index) {
      this.active_course_category_index = index;
      this.active_course_type_index = 't';
      this.$emit("change", this.list[index].course_type[0],'t');
    },
    selectCourseType(index, item) {
      this.active_course_type_index = index;
      this.$emit("change", item,'l');
    },
    selectTop(){    
        console.log(this.list,'分类列表')
         this.$emit("change", this.list[this.active_course_category_index].course_type[0],'t');
        this.active_course_type_index = 't'
    }
  },
};
</script>

<style scoped>
.warp a {
  color: #000;
}

.course-fl1 .active {
  color: #fff;
}

.course-fl1 a:hover {
  color: #fff;
}

.course-fl2 .active {
  color: #3561c7;
}
.course-fl2 p {
  display: inline-block;
  padding: 0 20px;
  line-height: 24px;
  text-align: center;
  color: #333333;
  position: relative;
  z-index: 1;
  font-size: 16px;
}
.course-fl2 a:hover {
  color: #3561c7;
}
</style>
