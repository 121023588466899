<template>
  <div>
    <p>
      <input v-model="form.phone" type="text" placeholder="请输入手机号" />
    </p>
    <p v-if="login_type === 1">
      <input
        v-model="form.code"
        type="text"
        placeholder="请输入验证码"
        name=""
        value=""
      />
      <SrButton
        :disabled="sms_time !== 0"
        @click="sendMessage"
        class="login-hqyzm"
        height="33px"
        padding="0 20px"
        font-size="14px"
        :loading="sms_loading"
      >
        <span v-if="sms_time === 0">获取验证码</span>
        <span v-else>重新发送({{ sms_time }})</span>
      </SrButton>
    </p>
    <p v-if="login_type === 2">
      <input v-model="form.password" type="password" placeholder="请输入密码" />
    </p>
    <p v-if="login_type === 1" class="clearfix">
      <a @click="login_type = 2" class="fn_l">密码登录</a>
      <a class="fn_r login-modalBut"  @click="openShow">登录遇到问题</a>
    </p>
    <p v-else class="clearfix">
      <a @click="login_type = 1" class="fn_l">验证码登录</a>
      <a class="fn_r login-modalBut" @click="openShow">登录遇到问题</a>
    </p>
    <p>
      <SrButton style="width: 100%" @click="login" :loading="login_loading">
        登录
      </SrButton>
    </p>
    <!--<p class="te_c f14">-->
    <!--<input type="radio"-->
    <!--:value="true"-->
    <!--v-model="form.is_read"/>-->
    <!--我已阅读并同意-->
    <!--<span class="color-main">-->
    <!--《用户协议》-->
    <!--</span>-->
    <!--和-->
    <!--<span class="color-main">-->
    <!--《隐私政策》-->
    <!--</span>-->
    <!--</p>-->
    <p class="login-kjdl te_c">
      <span>快捷登录</span>
    </p>
    <p class="login-kjdlImg te_c">
      <a @click="openWechat">
        <img src="@/assets/img/login-weixin.png" />
      </a>
      <a @click="openQQ">
        <img src="@/assets/img/login-qq.png" />
      </a>
    </p>

    <!-- 问题弹窗开始 -->
    <div class="motal" v-show="showQuestion">
      <div class="motal_box">
        <h3>登录遇到问题？</h3>
        <p>您可以直接拨打我们的客户电话进行反馈，我们将全心全意提供服务！</p>
        <p>联系电话：400-1380095；</p>
        <p>工作时间：早上9点-晚上6点；</p>
      </div>
      <img @click="closeShow" src="@/assets/img/modal-close.png" alt="" />
    </div>
  </div>
</template>

<script>
import storage from "@/utils/storage";
import SrButton from "@/components/SrButton";
import { mobileLogin, login } from "@/api/user";
import { sendMessage, weixinweb } from "@/api/common";

export default {
  name: "LoginBox",
  components: {
    SrButton,
  },
  data() {
    return {
      login_type: 2, //1验证码登录 2密码登录
      login_loading: false,
      sms_loading: false,
      form: {
        phone:''
      },
      sms_time: 0,
      showQuestion: false,
    };
  },
  created() {
    var _self = this;
    var sms_time = storage.getItem("sms_time");
    if (sms_time > 0) {
      _self.sms_time = sms_time;
      var timer = setInterval(function () {
        _self.sms_time--;
        storage.setItem("sms_time", _self.sms_time);
        if (_self.sms_time <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  },
  watch: {
    login_type(value) {
      if (value === 1) {
        this.$emit("change", "验证码登录");
      } else {
        this.$emit("change", "密码登录");
      }
    },
  },
  methods: {
    sendMessage() {
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      if(_self.form.phone == ''){
        _self.$message.warning('请输入手机号');
        return
      }
      _self.sms_loading = true;
      var char = new Date().getTime();
      var key = 'xCf188EYN4arKCXqo3nUYDumTccz7'
      var sign = this.$md5('char='+ char +'&phone=' + _self.form.phone + '&scenario=LoginCode'+ key)
      sendMessage({ phone:_self.form.phone, scenario: "LoginCode",char:char,sign:sign.toUpperCase() }).then((res) => {
        if (res.status === 200) {
          _self.sms_loading = false;
          _self.sms_time = 60;
          var timer = setInterval(function () {
            _self.sms_time--;
            storage.setItem("sms_time", _self.sms_time);
            if (_self.sms_time <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          _self.$message.warning(res.msg);
          _self.sms_loading = false;
        }
      });
    },
    login() {
      var _self = this;
      //获取跳转页
      var url = storage.getItem("LOGIN_SKIP");
      if (!url || url === "/login?action=login") {
        url = "/";
      }
      if (_self.login_loading) {
        return false;
      }
      _self.login_loading = true;
      if (_self.login_type === 1) {
        //验证码登录
        mobileLogin(this.form).then((res) => {
          if (res.status === 200) {
            _self.login_loading = false;
            _self.$message.success("登录成功");
            storage.setItem("ACCESS_TOKEN", res.result);
            _self.$router.push(url);
          } else {
            _self.$message.warning(res.msg);
            _self.login_loading = false;
          }
        });
      } else {
        login(this.form).then((res) => {
          if (res.status === 200) {
            _self.login_loading = false;
            _self.$message.success("登录成功");
            storage.setItem("ACCESS_TOKEN", res.result);
            _self.$router.push(url);
          } else {
            _self.$message.warning(res.msg);
            _self.login_loading = false;
          }
        });
      }
    },
    // 微信登录
    openWechat() {
      window.location.href =
        "https://api.srgongkaow.com/web/socialite/weixinweb";
    },
    openQQ() {
      window.location.href = "https://api.srgongkaow.com/web/socialite/qq";
    },
    openShow() {
      //console.log(1)
      this.showQuestion = true;
    },
    closeShow() {
      this.showQuestion = false;
    },
  },
};
</script>

<style scoped>
.motal {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.motal_box {
  background: url(../../../assets/img/txtx.png) no-repeat;
  margin: 0 auto;
  width: 455px;
  height: 322px;
  padding: 60px 50px;
  margin-top: 200px;
}
.motal_box h3 {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}
.motal_box p {
  margin-bottom: 10px;
}
.motal img {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
}
</style>
