<template>
    <div style="line-height: 500px;width: 100%;text-align: center;font-size: 100px">
        404 Not Found
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>
