<template>
  <div>
    <SrNav></SrNav>
    <!-- 2-4模考题库-报名 -->
    <div class="index3_mktk">
      <!-- 分类 -->
      <div class="warp">
        <div class="course-fl1 mt50 xun_fl1 index3_mktk_zong">
          <a
            v-for="(i, index) in categoryList"
            :class="tabIndex == index ? 'active' : ''"
            @click="getTab(i, index)"
            >{{ i.name }}</a
          >
        </div>
      </div>

      <!-- banner -->
      <div class="index3_mktk_bannner warp">
        <!-- Swiper -->
        <div class="swiper-container swi_mktk_ban">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="index3_mktk_ban">
                <img src="@/assets/img/in3_mktk_ban.jpg" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index3_mktk_ban">
                <img src="@/assets/img/in3_mktk_ban.jpg" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index3_mktk_ban">
                <img src="@/assets/img/in3_mktk_ban.jpg" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index3_mktk_ban">
                <img src="@/assets/img/in3_mktk_ban.jpg" />
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>
      </div>

      <!-- 考试内容 -->
      <div class="index3_mktk_ksnr">
        <div class="index3_mktk_ksnr_zong warp">
          <div class="index3_mktk_ksnr_l index3_mktk_con">
            <ul class="index3_mktk_ksnr_lss" v-if="list.length > 0">
              <li v-for="i in list">
                <div class="index3_mktk_ksnr_l_wen index3_mktk_ksnr_l_wen1">
                  <h6>{{ i.name }}</h6>
                  <p>{{ i.start_at }}</p>
                  <a href=""></a>
                </div>
                <a-button @click="bm(i)" v-if="i.type == 1" class="mk_btn"
                  >立即报名</a-button
                >
                <a-button v-if="i.type == 2" class="mk_btn">已报名</a-button>
                <a-button v-if="i.type == 3" class="mk_btn" @click="goPaper(i)"
                  >开始考试</a-button
                >
              </li>
            </ul>

            <div class="index3_mktk" v-else>
              <div class="in3_mktk in3_mktk_zw">
                <img src="@/assets/img/index3_mktk_tu1.png" />
                <p>暂无模考</p>
              </div>
            </div>

            <SrPaginate
              v-if="list.length > 0"
              style="margin: 0 auto; text-align: center; margin-top: 50px"
              :current="pagination.current_page"
              :total="pagination.total"
              :pageSize="pagination.page_size"
              @change="changePage"
            ></SrPaginate>
          </div>

          <div class="index3_mktk_ksnr_r">
            <div class="index3_mktk_ksnr_r_one">
              <div class="index3_mktk_ksnr_r_one_nr">
                <img src="@/assets/img/in3_mktk_r_tu.jpg" />
                <div class="index3_mktk_ksnr_r_wen">
                  <h6>我的模考报告</h6>
                  <p>把握考前实战机会，提前了解岗位竞争</p>
                  <a @click="goReport">查看更多</a>
                </div>
              </div>
            </div>
            <!-- 考试信息 -->
            <div class="in3_kao">
              <div class="in3_kao_con">
                <div class="in3_kao_tit">
                  <i></i>
                  <p>考试信息</p>
                </div>
                <div class="in3_kao_nr">
                  <p v-html="xzDetail.text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
    <!-- 报考职位信息弹窗 -->
    <div class="kapian kapian3" v-if="motal">
      <div class="as">
        <div class="txtx">
          <img
            class="txtx_close"
            @click="close"
            src="@/assets/img/in3_guan.png"
            alt=""
          />
          <div class="txtx_wen txtx_wen_in3">
            <h6>添加职位信息</h6>
            <div class="in3_xzzw">
              <select @change="changes">
                <option
                  :value="i.exam_category.exam_category_id"
                  v-for="i in exam_about_category"
                >
                  {{ i.exam_category.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="index3_tjzw">
            <a-button class="bmbtn" @click="setUnlockExam">立即报名</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/assets/js/swiper.min.js";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import SrPaginate from "@/components/SrPaginate";
import { category, lists, unlockExam } from "@/api/exam";
import { userAbout } from "@/api/user";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
    SrPaginate,
  },
  data() {
    return {
      tabIndex: 0,
      motal: false,
      categoryList: [],
      mock_category_id: null,
      pagination: {
        page: 1,
        page_size: 4,
      },
      list: [],
      exam_about_category: [],
      exam_category_id: null,
      mock_id: null,
      xzDetail:{}
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        var swiper = new Swiper(".swi_mktk_ban", {
          pagination: {
            el: ".swiper-pagination",
          },
        });
      }, 300);
    });
  },
  methods: {
    getTab(i, index) {
      this.tabIndex = index;
      this.mock_category_id = i.mock_category_id;
      this.loadList();
    },
    loadCategory() {
      let _this = this;
      category({}).then((res) => {
        _this.categoryList = res.result.items;
        _this.mock_category_id = res.result.items[0].mock_category_id;
        _this.loadList();
      });
    },
    loadAbout(){
      let _this = this;
        userAbout({
          id:31
        }).then((res) => {
          console.log(res,'大萨达大萨达是')
          _this.xzDetail = res.result
      });
    },
    loadList() {
      let _this = this;
      lists({
        mock_category_id: _this.mock_category_id,
      }).then((res) => {
        console.log(res, "list");
        var obj = {
          exam_category: {
            exam_category_id: "",
            name: "请选择",
          },
        };
        // type 1为立即报名 2为已报名 3为开始考试 4为考试结束
        var today = new Date().getTime();
        _this.list = res.result.items;
        _this.list.map(function (n) {
          // var time = new Date(n.start_at).getTime();
          // if (n.sign_exam) {
          //   if(time < today){
          //     n.type = 2
          //   }else{
          //     n.type = 3
          //   }
          // } else {
          //   n.type = 1;
          // }
          if (n.sign_exam) {
            n.type = 3;
          } else {
            n.type = 1;
          }
          n.exam_about_category.splice(0, 0, obj);
        });
        console.log(_this.list, "考试");
        _this.pagination = res.result.pagination;
      });
    },
    close() {
      this.motal = false;
    },
    changes(e) {
      this.exam_category_id = e.target.value;
    },
    bm(i) {
      console.log(i);
      this.exam_about_category = i.exam_about_category;
      this.mock_id = i.mock_id;
      this.motal = true;
    },
    setUnlockExam() {
      let _this = this;
      if (this.exam_category_id == "") {
        _this.$message.warning("请先选择分类");
        return;
      }
      unlockExam({
        exam_category_id: _this.exam_category_id,
        mock_id: _this.mock_id,
      }).then((res) => {
        console.log(res, "-----提交");
        if (res.status == 200) {
          _this.motal = false;
          _this.$message.success("操作成功");
          _this.loadList();
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    baoming() {
      this.$srmsg
        .confirm({
          title: "添加到日历提醒",
          content: "开考前10分钟提醒入场，不要错过实战机会",
        })
        .then(() => {
          this.motal = true;
        });
    },
    goReport() {
      this.$router.push({
        path: "/exam/report"
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
    handleChange(value) {
      console.log(value);
    },
    goPaper(item) {
      this.$router.push({
        path: "/exam/examination",
        query: { mock_id: item.mock_id, type: item.sign_exam.type },
      });
    },
  },
  created() {
    this.loadCategory();
    this.loadAbout();
  },
};
</script>
<style scoped>
.course-fl1 a {
  width: 18% !important;
}
.index3_mktk_ksnr_l ul li {
  width: 48%;
  height: 240px;
}
.index3_mktk_ksnr_l_wen {
  margin-bottom: 45px;
}
.index3_mktk_ksnr_r {
  width: 30%;
}
.kapian {
  display: block;
}
.txtx_close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.in3_mktk_ljbm:hover {
  color: #fff;
  text-decoration: none;
}
.index3_mktk_ksnr_lss {
  overflow: hidden;
}
.in3_mktk {
  margin-top: 30px;
}
.mk_btn {
  background: none;
  color: #fff;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid #fff;
  border-radius: 30px;
  font-size: 16px;
}
.index3_tjzw {
  text-align: center;
}
.bmbtn {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  background: #3561c7;
  color: #fff;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  font-size: 16px;
}
</style>
