<template>
  <div class="warp mt30">
    <h3 class="kc-tuijian"><img src="@/assets/img/KC-tbg.png" />推荐课程</h3>
    <div class="clearfix mt20">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="kc-list kc-listBg fn_l ml0"
      >
        <router-link :to="{ path: '/course/info/' + item.course_id + '/' + course_category_id  }">
          <div class=""><img :src="item.first_cover.url" /></div>
          <div class="kc-listTex">
            <h3>{{ item.goods.name }}</h3>
            <p
              class="f12 color-main"
              :style="{ color: item.subtitle_color }"
            >
              {{ item.subtitle }}
            </p>
            <p class="clearfix f14 color-666">
              <span class="fn_l"
                >限售<em class="color-main">
                  {{ item.goods.sales_restriction }}人</em
                >
                | 已报名<em class="color-pink"
                  >{{ item.goods.sale_amount }}人</em
                ></span
              >
              <span class="fn_r">
                <s
                  v-if="
                    item.goods.order_price !==
                    item.goods.real_price
                  "
                  class="color-999"
                  >￥{{ item.goods.real_price }}</s
                >
                <em class="f22 color-main"
                  >￥{{ item.goods.order_price }}</em
                >
              </span>
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { recommend } from "@/api/school/course";

export default {
  name: "ReCourseBig",
  props: {
    course_category_id: Number,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    var _self = this;
    recommend({ course_category_id: _self.course_category_id, page_size: 6 }).then((res) => {
      console.log(res, "推荐课程----");
      _self.list = res.result.items;
    });
  },
};
</script>

<style scoped>
.kc-list {
  height: 100%;
  margin: 20px;
}
</style>
