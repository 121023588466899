<template>
  <div>
    <div class="per_right">
      <!-- <router-link to="/user/center/distribution">
          <div class="per2_top">
        <div class="per2_top_nr">
          <div class="per2_r_img">
            <img src="@/assets/img/la.png" />
          </div>
          <span>分享课程 赚现金 可提现</span>
        </div>
      </div>
      </router-link> -->

      <div class="per2_nr">
        <div class="per2_nr_l">
          <div class="per2_nn">
            <div class="per2_remind box2" style="magin-top: 1px">
              <div class="p_remind_title">
                <img src="@/assets/img/remind_icon.png" />
                <h6>课程提醒</h6>
              </div>
              <div class="p_remind_nr">
                <ul>
                  <li v-for="i in classesRemindList">
                    <div class="remind_l1">
                      <img :src="i.image.url" />
                      <div class="per2_r_wen">
                        <h6>{{ i.name }}</h6>
                        <p>{{ i.children_name }}</p>
                      </div>
                    </div>
                    <div class="remind_r1">
                      <a @click="goClassDetail(i.type, i.parent_id)">去学习</a>
                    </div>
                  </li>
                  <li v-for="i in homeRemindList">
                    <div class="remind_l1">
                      <img :src="i.image.url" />
                      <div class="per2_r_wen">
                        <h6>{{ i.goods.name }}</h6>
                        <!-- <p>{{ i.goods.children_name }}</p> -->
                      </div>
                    </div>
                    <div class="remind_r1">
                      <a @click="goClassDetail(i.type, i.relation_id)"
                        >去看看</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="per2_nn">
            <div class="per2_remind">
              <div class="p_remind_title">
                <img src="@/assets/img/remind_icon.png" />
                <h6>最近学习</h6>
              </div>
              <div class="per2_study_nr">
                <div class="study">
                  <h6 class="study_h6">今日</h6>
                  <div class="study_list">
                    <ul v-if="today.length > 0">
                      <li v-for="i in today" @click="goDetail(i)">
                        <a>
                          <img :src="i.relation.baijia_video.preface_url" />
                          <div class="study_wen">
                            <h6>{{ i.relation.name }}</h6>
                            <span>时长：{{ i.videoTime }}</span>
                            <p>
                              观看进度{{
                                i.relation.baijia_video.user_progress
                              }}%
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div class="zanwu" v-if="today.length == 0">
                      <!-- <img src="@/assets/img/zanwu.png" alt="" /> -->
                      暂无学习记录
                    </div>
                  </div>
                </div>
                <div class="study">
                  <h6 class="study_h6">一周</h6>
                  <div class="study_list">
                    <ul v-if="week.length > 0">
                      <li v-for="i in week" @click="goDetail(i)">
                        <a>
                          <img :src="i.relation.baijia_video.preface_url" />
                          <div class="study_wen">
                            <h6>{{ i.relation.name }}</h6>
                            <span>时长：{{ i.videoTime }}</span>
                            <p>
                              观看进度{{
                                i.relation.baijia_video.user_progress
                              }}%
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div class="zanwu" v-if="week.length == 0">
                      <!-- <img src="@/assets/img/zanwu.png" alt="" /> -->
                      暂无学习记录
                    </div>
                  </div>
                </div>
                <div class="study">
                  <h6 class="study_h6">更早</h6>
                  <div class="study_list">
                    <ul v-if="old.length > 0">
                      <li
                        v-for="i in old"
                        @click="goDetail(i)"
                        v-if="i.relation"
                      >
                        <a>
                          <img
                            v-if="i.relation.baijia_video.preface_url"
                            :src="i.relation.baijia_video.preface_url"
                          />
                          <div class="study_wen">
                            <h6>{{ i.relation.name }}</h6>
                            <span>时长：{{ i.videoTime }}</span>
                            <p v-if="i.relation.baijia_video">
                              观看进度{{
                                i.relation.baijia_video.user_progress
                              }}%
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div class="zanwu" v-if="old.length == 0">
                      <!-- <img src="@/assets/img/zanwu.png" alt="" /> -->
                      暂无学习记录
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="per2_nr_r" style="margin-top: 1px">
          <div class="calendar">
            <div class="calendar_title">
              <p>课程日历</p>
            </div>
            <!-- 日历 -->
            <div id="calendar">
              <Calendar
                v-on:choseDay="clickDay"
                v-on:changeMonth="changeDate"
                :markDate="arr"
                style="background: #ffffff"
              ></Calendar>
            </div>

            <div class="calendar_name">
              <ul v-if="courseList.length > 0">
                <li
                  v-for="i in courseList"
                  @click="goClassDetail(i.type, i.parent_id)"
                >
                  <a>
                    <div class="ca_name_l">
                      <img src="@/assets/img/name1.png" />
                      <div class="name_wen">
                        <p>{{ i.time }}</p>
                        <span>{{ i.status == 0 ? "未观看" : "已观看" }}</span>
                      </div>
                    </div>
                    <div class="ca_name_r">
                      <h6>{{ i.name }}</h6>
                      <p>{{ i.children_name }}</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="no_name" v-if="courseList.length == 0">
              <p>今日暂无课程</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "vue-calendar-component";
import "moment/locale/zh-cn";
import { recentList, calendar, classesRemind, homeRemind } from "@/api/user";
export default {
  name: "UserCenter",
  components: {
    Calendar,
  },
  data() {
    return {
      login_loading: false,
      sms_loading: false,
      form: {
        is_read: false,
      },
      sms_time: 0,
      old: [],
      today: [],
      week: [],
      signList: [{ year: "2020", month: "1", day: "1" }],
      signData: {
        start: "2021-01-01", // 开始的日期（yyyy-mm-dd），默认‘2019-08-01’
        ismake: true, // 是否开启补签
        type: "month", // 日历模式（月 => month，周 => week）
      },
      arr: [],
      date: "",
      calendarData: [],
      courseList: [],
      classesRemindList: [],
      homeRemindList: [],
    };
  },
  methods: {
    loadList() {
      let _this = this;
      recentList().then((res) => {
        // console.log(res,'------最近学习')
        res.result.old.map(function (s) {
          if (s.relation) {
            _this.old.push(s);
          }
        });
        _this.old.map(function (n) {
          if (n.relation) {
            var seconds = parseInt(n.relation.baijia_video.length);
            n.videoTime = _this.formatSeconds(seconds);
          }
        });

        res.result.today.map(function (s) {
          if (s.relation) {
            _this.today.push(s);
          }
        });
        _this.today.map(function (n) {
          if (n.relation) {
            var seconds = parseInt(n.relation.baijia_video.length);
            n.videoTime = _this.formatSeconds(seconds);
          }
        });
        res.result.week.map(function (s) {
          if (s.relation) {
            _this.week.push(s);
          }
        });
        _this.week.map(function (n) {
          if (n.relation) {
            var seconds = parseInt(n.relation.baijia_video.length);
            n.videoTime = _this.formatSeconds(seconds);
          }
        });
      });
    },
    // 秒换算分钟
    formatSeconds(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + parseInt(secondTime) + "秒";

      if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
      }
      if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
      }
      return result;
    },
    clickDay(data) {
      let _this = this;
      var time = data.split("/");
      this.date = time[0] + "-" + this.add0(time[1]) + "-" + this.add0(time[2]);
      this.calendarData.map(function (n) {
        if (_this.date == n.date) {
          //console.log(n);
          _this.courseList = n.list;
          _this.courseList.map(function (s) {
            s.time = _this.format(s.start_at * 1000).substring(11, 16);
          });
          //console.log(_this.courseList, "-------LIST");
        }
      });
    },
    changeDate(data) {
      //console.log(data); //左右点击切换月份
      var time = data.split("/");
      this.date = time[0] + "-" + this.add0(time[1]) + "-" + this.add0(time[2]);
      this.loadCalendar();
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    // 时间戳转化时间
    format(shijianchuo) {
      //console.log(shijianchuo, "时间戳");
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      //console.log(time, "转化");
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    },
    goDetail(item) {
      if (item.type == 1) {
        this.$router.push({
          path:
            "/course/" +
            item.relation.course_id +
            "/subchapter/" +
            item.relation.course_subchapter_id,
        });
      } else {
        this.$router.push({
          path: "/radio/play?radio_id=" + item.relation.radio_id,
        });
      }
    },
    // 获取日历
    loadCalendar() {
      let _this = this;
      _this.arr = [];
      calendar({ date: _this.date }).then((res) => {
        var list = res.result;
        _this.calendarData = res.result;
        list.map(function (n) {
          if (n.list.length > 0) {
            _this.arr.push(n.date);
          }
          // 获取当天课程
          if (_this.date == n.date) {
            if (n.list.length> 0) {
              _this.$set(_this.courseList, _this.courseList.length, n.list);
              // _this.courseList = n.list;
              _this.courseList.map(function (s) {
                s.time = _this.format(s.start_at * 1000).substring(12, 16);
              });
            }
          }
        });
      });
    },
    // 获取课程提醒
    loadClassesRemind() {
      let _this = this;
      classesRemind().then((res) => {
        _this.classesRemindList = res.result;
      });
    },
    // 获取课程推荐
    loadHomeRemind() {
      let _this = this;
      homeRemind().then((res) => {
        _this.homeRemindList = res.result;
      });
    },
    goClassDetail(type, parent_id) {
      if (type == 1) {
        this.$router.push({ path: "/course/info/" + parent_id });
      } else if (type == 3) {
        this.$router.push({ path: "/radio/info/" + parent_id });
      } else {
        this.$router.push({ path: "/camp/info/" + parent_id });
      }
    },
  },
  created() {
    this.loadList();
    var time = new Date().getTime();
    this.date = this.format(time).substring(0, 10);
    this.loadCalendar();
    this.loadClassesRemind();
    this.loadHomeRemind();
  },
};
</script>

<style scoped>
.study_list1 ul li {
  width: 222px;
  margin-right: 16px;
  overflow: hidden;
}
.zanwu {
  margin: 40px auto;
  text-align: center;
}
.zanwu img {
  width: 200px;
  margin: 0 auto;
}
.study_list ul li {
  width: 196px;
  float: left;
  margin: 0 14px 18px 0;
  overflow: hidden;
}
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: #fff;
  background: #1890ff;
  border-radius: 50%;
}
#calendar >>> .wh_content_all {
  background-color: #ffffff !important;
  width: 100%;
  overflow: hidden;
  padding-bottom: 8px;
}
#calendar >>> .wh_content_item {
  color: #333333;
}
#calendar >>> .wh_top_changge li {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
}
#calendar >>> .wh_jiantou1 {
  border-top: 2px solid #3561c7;
  border-left: 2px solid #3561c7;
}
#calendar >>> .wh_jiantou2 {
  border-top: 2px solid #3561c7;
  border-right: 2px solid #3561c7;
}
#calendar >>> .wh_item_date:hover {
  background: transparent;
  color: #c74b43;
}
#calendar >>> .wh_content_item .wh_chose_day {
  background: #3561c7 !important;
  color: #ffffff;
  font-weight: bold;
}
#calendar >>> .wh_content_item .wh_isToday {
  border: 2px solid #3561c7;
  background: transparent;
  border-radius: 50%;
}
#calendar >>> .wh_content_item > .wh_isMark:before {
  width: 5px;
  height: 5px;
  background: #3561c7;
  content: "";
  border-radius: 50%;
  position: absolute;
  bottom: 3px;
}
#calendar >>> .wh_content_item > .wh_isMark {
  background: transparent;
}
.p_remind_nr ul li img {
  width: 190px;
}
.box2 {
  max-height: 560px;
  overflow-y: auto;
  margin-bottom: 20px;
}
#calendar >>> .wh_item_date,
.wh_top_tag {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.per2_top {
  cursor: pointer;
}
.study_list ul {
  overflow: hidden;
}
.study_list ul li {
  width: 190px;
}
.remind_r1 a {
  color: #ffffff;
}
</style>
