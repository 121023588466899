<template>
  <div id="box">
    <a-empty description="暂无数据" v-if="list.length === 0" />
    <a-collapse
      v-else
      v-for="item in list"
      :key="item.course_chapter_id"
      expand-icon-position="right"
    >
      <a-collapse-panel :key="item.course_chapter_id + ''" :header="item.name">
        <dl
          v-for="subitem in item.course_subchapter"
          class="kc-dg clearfix mt20"
        >
          <!-- <dt class="color-999">{{subitem.number}}</dt> -->
          <dd>
            <h3>{{ subitem.name }}</h3>
            <p class="f14 color-999" v-if="subitem.type != 5">
              {{ subitem.teacher ? subitem.teacher.name : "" }} 丨
              <span v-if="subitem.type === 1 || subitem.type === 4">
                {{ $moment(subitem.start_at).format("YYYY.MM.DD") }}
                {{ $moment(subitem.start_at).format("HH:mm") }} -
                {{ $moment(subitem.end_at).format("HH:mm") }}
              </span>
            </p>
            <a-progress
              v-if="subitem.type !== 1 && subitem.type !== 5"
              :percent="subitem.baijia_video.user_progress"
              :format="(percent) => '已观看' + percent + '%'"
              strokeColor="#eb98c2"
              :strokeWidth="3"
            />
            <a
              v-if="
                subitem.type === 1 &&
                subitem.class_status < 2 &&
                subitem.auth_level == 1
              "
              @click="toZhibo(item, subitem)"
            >
              {{ subitem | ZhiBo }}
            </a>
            <a
              @click="openvideo(item.course_id, subitem.course_subchapter_id)"
              v-if="
                subitem.type === 1 &&
                subitem.class_status === 2 &&
                subitem.auth_level == 1
              "
            >
              播放视频
            </a>
               <a
              @click="openvideos(item.course_id, subitem.course_subchapter_id)"
              v-if="
                subitem.type === 1 &&
                subitem.class_status === 2 &&
                subitem.auth_level == -1
              "
            >
              播放视频
            </a>
            <a
              v-if="subitem.type === 2 && subitem.auth_level == 1"
              @click="openvideo(item.course_id, subitem.course_subchapter_id)"
            >
              播放视频
            </a>
               <a
              v-if="subitem.type === 2 && subitem.auth_level == -1"
              @click="openvideos(item.course_id, subitem.course_subchapter_id)"
            >
              试听
            </a>
            <a
              v-if="
                subitem.type === 4 &&
                subitem.class_status < 2 &&
                subitem.auth_level == 1
              "
              @click="toZhibo(item, subitem)"
            >
              {{ subitem | ZhiBo }}
            </a>
            <a
              @click="openvideo(item.course_id, subitem.course_subchapter_id)"
              v-if="
                subitem.type === 4 &&
                subitem.class_status === 2 &&
                subitem.auth_level == 1
              "
            >
              播放视频
            </a>
            <a
              @click="openvideo2(item.course_id, subitem.course_subchapter_id)"
              v-if="
                subitem.type === 4 &&
                subitem.class_status === 2 &&
                subitem.auth_level == -1
              "
            >
              试听
            </a>
            <a
              @click="openSubject(subitem)"
              v-if="subitem.type === 5 && subitem.auth_level == 1"
            >
              去做题
            </a>

            <router-link
              v-if="subitem.type === 3 && subitem.auth_level == 1"
              @click="openvideo(item.course_id)"
            >
              音频
            </router-link>
              <router-link
              v-if="subitem.type === 3 && subitem.auth_level == -1"
              @click="openvideos(item.course_id)"
            >
              试听
            </router-link>
            <!-- <router-link v-if="subitem.type===2"
                                     target="_blank"
                                     :to="{path:'/course/'+item.course_id+'/subchapter/'+subitem.course_subchapter_id}">
                            录播
                        </router-link>
                        <router-link v-if="subitem.type===3"
                                     target="_blank"
                                     :to="{path:'/course/'+item.course_id+'/subchapter/'+subitem.course_subchapter_id}">
                            音频
                        </router-link> -->
          </dd>
        </dl>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { Collapse } from "ant-design-vue";

export default {
  name: "CourseChapter",
  components: {
    "a-collapse": Collapse,
    "a-collapse-panel": Collapse.Panel,
  },
  props: {
    list: {
      default: () => {
        return [];
      },
    },
    type: Number,
    vip_free: Number,
  },
  filters: {
    ZhiBo(item) {
      var start_at = item.start_at;
      var end_at = item.end_at;
      if (new Date(start_at) > new Date()) {
        return "未开始";
      } else {
        if (new Date(end_at) > new Date()) {
          return "直播中";
        } else {
          return "已结束";
        }
      }
    },
  },
  data() {
    return {
      activeKey: 0,
    };
  },
  mounted() {
    if (this.list.length !== 0) {
      this.activeKey = this.list[0].course_chapter_id + "";
    }
  },
  watch: {
    list(value) {
      if (value.length !== 0) {
        this.activeKey = this.list[0].course_chapter_id + "";
      }
    },
  },
  methods: {
    toZhibo(item, subitem) {
      console.log(item);  
      console.log(subitem);
      var start_at = subitem.start_at;
      if (this.vip_free == 1) {
        console.log(1)
        if (new Date(start_at) > new Date()) {
          this.$srmsg
            .info({
              content: "直播未开始 您可以提前15分钟进入教室",
            })
            .then(() => {
              if (
                new Date(start_at) <
                this.$moment(new Date()).add(15, "m").toDate()
              ) {
                let routeUrl = this.$router.resolve({
                  path:
                    "/course/" +
                    item.course_id +
                    "/subchapter/" +
                    subitem.course_subchapter_id,
                });
                window.open(routeUrl.href, "_blank");
              }
            });
        } else {
          let routeUrl = this.$router.resolve({
            path:
              "/course/" +
              item.course_id +
              "/subchapter/" +
              subitem.course_subchapter_id,
          });
          window.open(routeUrl.href, "_blank");
        }
        return;
      }
      if (this.type == 0) {
        this.$srmsg.info({
          title: "提示",
          content: "请先购买课程",
        });
      } else {
           console.log(21);
           console.log(this.$moment(new Date()).add(15, "m").toDate());
           console.log(new Date(start_at))
        if (new Date(start_at) > new Date()) {
          this.$srmsg
            .info({
              content: "直播未开始 您可以提前15分钟进入教室",
            })
            .then(() => {
              if (
                new Date(start_at) <
                this.$moment(new Date()).add(15, "m").toDate()
              ) {
                let routeUrl = this.$router.resolve({
                  path:
                    "/course/" +
                    item.course_id +
                    "/subchapter/" +
                    subitem.course_subchapter_id,
                });
                window.open(routeUrl.href, "_blank");
              }
            });
        } else {
          let routeUrl = this.$router.resolve({
            path:
              "/course/" +
              item.course_id +
              "/subchapter/" +
              subitem.course_subchapter_id,
          });
          window.open(routeUrl.href, "_blank");
        }
      }
    },
    toZhibos(item, subitem){
          if (new Date(start_at) > new Date()) {
          this.$srmsg
            .info({
              content: "直播未开始 您可以提前15分钟进入教室",
            })
            .then(() => {
              if (
                new Date(start_at) <
                this.$moment(new Date()).add(15, "m").toDate()
              ) {
                let routeUrl = this.$router.resolve({
                  path:
                    "/course/" +
                    item.course_id +
                    "/subchapter/" +
                    subitem.course_subchapter_id,
                });
                window.open(routeUrl.href, "_blank");
              }
            });
        } else {
          let routeUrl = this.$router.resolve({
            path:
              "/course/" +
              item.course_id +
              "/subchapter/" +
              subitem.course_subchapter_id,
          });
          window.open(routeUrl.href, "_blank");
        }
    },
    openvideo(id, course_subchapter_id) {
      console.log(id, course_subchapter_id);
      console.log(this.type);
      let _this = this;
      if (this.vip_free == 1) {
        this.$router.push({
          path: "/course/" + id + "/subchapter/" + course_subchapter_id,
        });
        return;
      }
      if (this.type == 0) {
        this.$srmsg.info({
          title: "提示",
          content: "请先购买课程",
        });
      } else {
        this.$router.push({
          path: "/course/" + id + "/subchapter/" + course_subchapter_id,
        });
      }
    },
    openvideos(id, course_subchapter_id) {
      this.$router.push({
        path: "/course/" + id + "/subchapter/" + course_subchapter_id,
      });
    },
    openSubject(item) {
      if (this.type == 0) {
        this.$srmsg.info({
          title: "提示",
          content: "请先购买课程",
        });
      } else {
        this.$router.push({
          path: "/exam/examination",
          query: { mock_id: item.course_subchapter_id, type: 101 },
        });
      }
    },
  },
};
</script>

<style scoped>
#box >>> .ant-collapse-header {
  background: #fff;
  padding-left: 65px;
  position: relative;
  background-image: url("~@/assets/img/KC-tbg.png");
  background-repeat: no-repeat;
  background-position: 20px 23px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0;
  line-height: 40px;
}

#box >>> .ant-collapse-content {
  border-top: 0;
}

#box >>> .ant-collapse {
  border: none;
}

#box >>> .ant-collapse-item {
  border-bottom: none;
}
.kc-dg dd {
  float: none;
  width: 100%;
}
</style>
