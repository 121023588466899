import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import cart from './modules/cart'
import common from './modules/common'

import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        common,
        cart
    },
    state: {},
    mutations: {},
    actions: {},
    getters
})
