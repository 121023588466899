<template>
  <div>
    <ShippingAddress
      v-if="needAddress === 1"
      @change="shippingAddressChange"
    ></ShippingAddress>
    <!-- 购物车 -->
    <Car v-model="goods_id" @change="carChange"></Car>

    <!-- 选择优惠券 -->
    <div
      v-if="credit.credit || coupon_list.length"
      class="warp mt20 color-666 chose-yh"
    >
      <h3>
        <img class="dis-inb" src="@/assets/img/KC-tbg2.png" />
        <span>选择优惠券</span>
      </h3>
      <ul class="mt10">
        <!-- <li v-for="item in coupon_list">
          <p class="fn_l">
            使用价值{{ item.coupon.threshold }}元的{{ item.coupon.name }}优惠券
          </p>
          <div class="radio fn_r">
            <SrRadio
              v-model="form.user_coupon_id"
              :zhi="item.user_coupon_id"
              class="sr-radio"
              @change="getRealPrice"
            ></SrRadio>
          </div>
        </li>
        <li v-if="credit.credit">
          <p class="fn_l">
            使用{{ credit.credit }}积分抵扣{{ credit.credit_deduction }}元
          </p>
          <div class="radio fn_r">
            <SrRadio
              v-model="form.credit"
              :zhi="credit.credit"
              class="sr-radio"
              @change="getRealPrice"
            ></SrRadio>
          </div>
        </li> -->
      </ul>
    </div>

    <!-- 选择支付方式 -->
    <div class="warp mt20 color-666 chose-yh">
      <h3 class="f20">请选择支付信息，并完成支付</h3>
      <ul class="mt10">
        <li>
          <p class="fn_l">
            <img class="dis-inb" src="@/assets/img/pay-wx.png" />
            <span class="f20 ml10">微信支付</span>
          </p>
          <div class="radio fn_r">
            <SrRadio v-model="pay_type" :zhi="2" class="sr-radio"></SrRadio>
            <!--<input type="radio" id="radio4" name="radio[]">-->
            <!--<label for="radio4"></label>-->
          </div>
        </li>
        <li class="mt10">
          <p class="fn_l">
            <img class="dis-inb" src="@/assets/img/pay-zfb.png" />
            <span class="f20 ml10">支付宝</span>
          </p>
          <div class="radio fn_r">
            <SrRadio v-model="pay_type" :zhi="1" class="sr-radio"></SrRadio>
          </div>
        </li>
      </ul>
    </div>
    <div class="warp dis-table mt20">
      <div class="dis-tablecell te_r color-red" style="padding-right: 18px">
        <p>
          <span v-if="$store.state.user.now_user_member"
            >vip{{
              $store.state.user.now_user_member.user_member_config_id
            }}</span
          >已优惠
          <span v-if="price_loading">-</span>
          <span v-else>{{real_deduction}}</span>
          元
        </p>
        <p class="f20">
          <span>￥</span>
          <span v-if="price_loading">计算中</span>
          <!-- <countTo
            :decimals="2"
            v-else
            :startVal="now_price"
            :endVal="real_price"
            :duration="1000"
          ></countTo> -->
          <span v-else>{{real_price}}</span>
        </p>
      </div>
      <div style="width: 18%" class="dis-tablecell te_r color-red f26">
        <SrButton @click="buy" :loading="buy_loading">立即结算 </SrButton>
        <!--<button style="cursor: pointer" class="cart-js" type="button" @click="buy">-->
        <!--<a-spin v-if="buy_loading">-->
        <!--<a-icon slot="indicator" type="loading" style="font-size: 24px;color: #fff" spin/>-->
        <!--</a-spin>-->
        <!--<span v-else>立即结算</span>-->
        <!--</button>-->
        <!--<button v-else style="cursor: pointer" @click="buy" class="cart-js" type="button"></button>-->
      </div>
    </div>
    <WechatScan ref="WechatScan" @close="wxclose"></WechatScan>
  </div>
</template>

<script>
import ShippingAddress from "./modules/ShippingAddress";
import Car from "./modules/Car";
import { able } from "@/api/coupon";
import { getCredit, getRealPrice, ordinary, share } from "@/api/buy";
import { web } from "@/api/pay";
import SrRadio from "@/components/SrRadio";
import countTo from "vue-count-to";
import SrButton from "@/components/SrButton";
import WechatScan from "./modules/WechatScan";

export default {
  name: "index",
  components: {
    Car,
    SrRadio,
    countTo,
    SrButton,
    ShippingAddress,
    WechatScan,
  },
  data() {
    return {
      goods_id: [], // 商品id
      goods: [], //商品信息
      coupon_list: [],
      credit: {},
      form: {},
      now_price: 0,
      real_price: 0,
      now_deduction: 0,
      real_deduction: 0,
      cart_id: [],
      price_loading: false,
      buy_loading: false,
      pay_type: 0,
    };
  },
  computed: {
    needAddress() {
      for (let i = 0; i < this.goods.length; i++) {
        if (this.goods[i].need_address === 1) {
          return 1;
        }
      }
      return 0;
    },
  },
  created() {
    var _self = this;
    var query = _self.$route.query;
    console.log(query, "携带");
    if (!query.goods_id) {
      this.$srmsg.info({
        title: "提示",
        content: "商品不存在",
      });
      return false;
    }
    if (Array.isArray(query.goods_id)) {
      _self.goods_id = query.goods_id;
    } else {
      var obj = {
        goods_id: query.goods_id,
        sku_id: query.sku_id,
        num: query.num,
      };
      _self.goods_id.push(obj);
    }
    var invitation_user_id = this.$route.query.invitation_user_id;
    if (_self.goods_id.length === 1 && invitation_user_id) {
      share({ goods_id: this.goods_id[0], share_user_id: invitation_user_id });
    }
    _self.getCouponList();
    _self.getCredit();
  },
  methods: {
    getCouponList() {
      var _self = this;
      _self.coupon_list = [];
      able({ goods_id: _self.goods_id }).then((res) => {
        console.log(res, "优惠券");
        _self.coupon_list = res.result;
      });
    },
    getCredit() {
      var _self = this;
      _self.credit = 0;
      getCredit({ goods_id: _self.goods_id }).then((res) => {
        _self.credit = res.result;
      });
    },
    carChange(goods) {
      this.goods = goods;
      console.log(goods, "goods");
      this.getRealPrice();
    },
    priceChange(real_price, real_deduction = 0) {
      var _self = this;
      _self.real_price = parseFloat(real_price) * _self.$route.query.num;
      _self.real_deduction = parseFloat(real_deduction);
      setTimeout(() => {
        _self.now_price = parseFloat(real_price) * _self.$route.query.num;
        _self.now_deduction = parseFloat(real_deduction);
      }, 1000);
    },
    getRealPrice() {
      var _self = this;
      _self.price_loading = true;
      _self.real_deduction =
        parseFloat(this.goods[0].order_price) - parseFloat(this.goods[0].jia);
        console.log(_self.real_deduction)
      _self.real_price = this.goods[0].jia
      _self.price_loading = false;
      // getRealPrice({
      //   goods_id: _self.goods_id,
      //   credit: _self.form.credit,
      //   user_coupon_id: _self.form.user_coupon_id,
      // }).then((res) => {
      //   console.log(res,'价格')
      //   _self.priceChange(res.result.real_price, res.result.deduction);
      //   _self.price_loading = false;
      // });
    },
    shippingAddressChange(shipping_address_id) {
      this.form.shipping_address_id = shipping_address_id;
    },
    buy() {
      // 下单
      var _self = this;
      if (!_self.pay_type) {
        _self.$srmsg.info({
          title: "提示",
          content: "请先选择支付方式",
        });
        return false;
      }
      if (_self.buy_loading) {
        return false;
      }
      _self.buy_loading = true;
      ordinary({
        goods_id: _self.goods_id,
        ..._self.form,
      })
        .then((res) => {
          if (res.status === 200) {
            var order_id = res.result;
            web({
              order_id: order_id,
              pay_type: _self.pay_type,
            })
              .then((res) => {
                if (res.status === 20001) {
                  _self.$srmsg.info({
                    title: "提示",
                    content: "支付成功",
                  });
                } else {
                  if (_self.pay_type === 1) {
                    document.querySelector("body").innerHTML = res.result;
                    document.forms[0].submit();
                  } else {
                    _self.$refs.WechatScan.show(res.result, order_id);
                  }
                }
                _self.buy_loading = false;
              })
              .catch(() => {
                _self.$srmsg.info({
                  title: "提示",
                  content: "支付失败",
                });
                _self.buy_loading = false;
              });
          } else {
            _self.$srmsg.info({
              title: "提示",
              content: res.msg,
            });
            _self.buy_loading = false;
          }
        })
        .catch(() => {
          _self.$srmsg.info({
            title: "提示",
            content: "下单失败",
          });
          _self.buy_loading = false;
        });
    },
    wxclose() {
      this.getCredit();
      this.getCouponList();
      this.form.credit = 0;
      this.form.user_coupon_id = null;
      this.getRealPrice();
      this.$message.success("订单已生成，可在个人中心中继续支付。");
    },
  },
};
</script>
<style scoped>
.sr-radio {
  position: absolute;
  right: 10px;
  bottom: 0px;
}
</style>