<template>
    <div style="width:100%;height:100%">
        <div class="player" style="width:100%;height:100%"></div>
    </div>
</template>

<script>
    export default {
        name: "index",
        methods: {
            init(config) {
                BJY.init({
                    env: 'production',
                    sign: config.sign,
                    class: {
                        id: config.room_id
                    },
                    user: {
                        number: config.user.number,
                        avatar: config.user.avatar,
                        name: config.user.name,
                        type: 0
                    }
                }).then(function () {
                    var player = BJY.H5Player.create({
                        element: $('.player'),
                        showControls: true,
                        poster: '',
                        customCoverImg: config.customCoverImg// 设置自定义背景图
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>
