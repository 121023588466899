<template>
  <div>
    <div class="warp clearfix mt50 kcxq-box">
      <div class="kcxq-l fn_l">
        <img :src="course.first_cover.url" />
      </div>
      <div class="kcxq-r fn_r">
        <h3>{{ course.name }}</h3>
        <p class="color-666">
          购买后有效期： {{ course.valid_days }}天 {{ course.period }}课时
        </p>
        <!-- <p><img class="ml0"
                        src="@/assets/img/KC-time.png"><span class="color-pink">距结束：<a-statistic-countdown :value="course.end_at|toTime"
                                                                                                           format="D 天 H 时 m 分 s 秒"/></span>
                </p> -->
        <p style="margin-top: 10px">
          <img class="ml0" src="@/assets/img/kc-ewm.jpg" />
        </p>
        <!--<p class="f14 color-999">微信号：544646412</p>-->
      </div>
    </div>
    <div class="warp clearfix mt30">
      <div class="kcxq-con fn_l">
        <div class="tab-wrapper">
          <ul class="tab-menu">
            <li
              :class="[tag_index === 1 ? 'active' : '']"
              @click="tag_index = 1"
            >
              课程大纲
            </li>
            <li
              :class="[tag_index === 2 ? 'active' : '']"
              @click="tag_index = 2"
            >
              讲义下载
            </li>
            <div class="tab_right">
              <p @click="goMyQuestion(0)">我的错题</p>
              <p @click="goMyQuestion(1)">我的收藏</p>
              <p @click="goMyQuestion(2)">练习历史</p>
            </div>
          </ul>
          <div class="tab-content">
            <CourseChapter
              v-if="tag_index === 1"
              :loading="loading"
              :list="course.course_chapter"
              :course_category_id="course_category_id"
            ></CourseChapter>
            <HandoutList
              v-if="tag_index === 2"
              :list="course.handout"
            ></HandoutList>
          </div>
        </div>
      </div>
      <ReCourse></ReCourse>
    </div>
  </div>
</template>

<script>
import { info } from "@/api/school/course";
import CourseChapter from "./modules/CourseChapter";
import ReCourse from "@/layout/recommend/ReCourse";
import HandoutList from "@/views/course/modules/HandoutList";
export default {
  name: "index",
  components: {
    CourseChapter,
    ReCourse,
    HandoutList,
  },
  data() {
    return {
      loading: false,
      course: {
        first_cover: {},
        goods: {},
      },
      tag_index: 1,
      course_category_id: null,
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  methods: {
    goMyQuestion(index) {
        let _this = this
      this.$router.push({
        path: "/question/myQuestion",
        query:{
            tabIndex:index,
            title:_this.course.goods.name,
            courseType:_this.course.goods.type,
            id:_this.course.course_id,
            types:101
        }
      });
    },
  },
  created() {
    var _self = this;
    var course_id = this.$route.query.course_id;
    this.course_category_id = this.$route.query.course_category_id;
    if (!course_id) {
      this.$srmsg
        .info({
          title: "提示",
          content: "课程不存在",
        })
        .then(() => {
          this.$router.push("/");
        });
    }
    info({ course_id: course_id }).then((res) => {
        console.log(res,'的撒大萨达')
      if (res.status === 200) {
        _self.course = res.result;
        document.title = res.result.name + "-" + document.title;
        _self.loading = false;
      }
    });
  },
};
</script>

<style scoped>
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}

.seckill-countdown >>> .ant-statistic {
  display: inline-block;
}

.seckill-countdown >>> .ant-statistic-content {
  display: inline-block;
  font-size: 14px;
}
.tab_right {
  float: right;
  width: 40%;
}
.tab_right p {
  display: inline-block;
  width: 33%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
</style>
