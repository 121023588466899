import request from '@/utils/request'

/**
 * 获取电台
 * @param parameter
 */
export function getlist(parameter) {
    return request({
        url: '/api/radio/radio/getlist',
        method: 'post',
        data: parameter
    })
}

/**
 * 电台详情
 * @param parameter
 */
export function info(parameter) {
    return request({
        url: '/api/radio/radio/info',
        method: 'post',
        data: parameter
    })
}

/**
 * 评论列表
 * @param parameter
 */
export function commentList(parameter) {
    return request({
        url: '/api/radio/radio/commentList',
        method: 'post',
        data: parameter
    })
}

/**
 * 评论
 * @param parameter
 */
export function comment(parameter) {
    return request({
        url: '/api/radio/radio/comment',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取电台课程大纲列表
 * @param parameter
 */
export function chapterList(parameter) {
    return request({
        url: '/api/radio/chapter/list',
        method: 'post',
        data: parameter
    })
}

export function video(parameter) {
    return request({
        url: '/api/radio/subchapter/video',
        method: 'post',
        data: parameter
    })
}
