<template>
    <div>
        <div class="clearfix mt20">
            <p class="fn_l">
                已有<span class="color-pink">{{ pagination.total }}</span> 次留言
            </p>
            <!--<p class="fn_r xly-px">-->
            <!--<span>推荐</span>-->
            <!--<span class="active">时间</span>-->
            <!--</p>-->
            <SrSwitch
                    @change="orderChange"
                    style="float: right"
                    :list="['推荐', '时间']"
            ></SrSwitch>
        </div>
        <a-spin :spinning="loading">
            <a-icon slot="indicator"
                    type="loading"
                    style="font-size: 24px"
                    spin/>
            <div
                    v-if="loading && list.length === 0"
                    style="width: 100%; height: 200px"
            ></div>
            <dl
                    v-else
                    v-for="item in list"
                    :key="item.camp_diary_id"
                    class="kc-evaluate-list clearfix"
            >
                <dt class="fn_l"><img :src="item.user.head_portrait.url"/></dt>
                <dd class="fn_r">
                    <div class="">
                        <h3>
                            {{ item.user.nickname }}
                            <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 1"
                                 src="/img/hyjb.png">
                            <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 2"
                                 src="/img/hyjb2.png">
                            <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 3"
                                 src="/img/hyjb3.png">
                            <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 4"
                                 src="/img/hyjb4.png">
                            <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 5"
                                 src="/img/hyjb5.png">
                            <img v-if="item.user.now_user_member && item.user.now_user_member.user_member_config_id == 6"
                                 src="/img/hyjb6.png">
                            <span v-if="item.is_digest == 1"
                                  class="xly-jy">加精</span>
                            <!--<span class="xly-hfBut fn_r"><img src="@/assets/img/xly-pl.png"></span>-->
                            <span
                                    class="kc-like"
                                    @click="like(item)"
                                    :class="[item.is_like ? 'kc-like-hover' : '', 'kc-like']"
                            >{{ item.likes }}</span
                            >
                        </h3>
                        <div style="font-size:13px; color:#999999">已打卡{{item.user.clock_theme_count}}天</div>
                        <p class="color-666">
                            {{ item.content }}
                        </p>
                        <div class="clearfix">
                            <div v-if="item.video"
                                 class="fn_l kc-evaluate-listImg">
                                <div class="play-btn"
                                     @click="showVideo(item.video)">
                                    <img src="@/assets/img/video-play.png"/>
                                </div>
                                <img :src="item.video.cover"/>
                            </div>
                            <div
                                    v-for="image_item in item.image"
                                    :key="image_item.image_id"
                                    class="fn_l kc-evaluate-listImg"
                            >
                                <img :src="image_item.url"/>
                            </div>
                        </div>
                    </div>
                    <div class="xly-hfBox mt10"
                         v-if="item.reply.length > 0">
                        <div
                                v-for="reply_item in item.reply"
                                :key="reply_item.reply_id"
                                class=""
                        >
                            <p class="f14 color-666">
                                <span>{{ reply_item.user.teacher.name }}回复：</span>
                                {{ reply_item.content }}
                            </p>
                            <div class="clearfix">
                                <div
                                        v-for="reply_image_item in reply_item.image"
                                        :key="reply_image_item.image_id"
                                        class="fn_l kc-evaluate-listImg"
                                >
                                    <img :src="reply_image_item.url"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </dd>
            </dl>
        </a-spin>
        <!-- 页码 -->
        <SrPaginate
                style="margin: 0 auto; text-align: center; margin-top: 50px"
                :current="pagination.current_page"
                :total="pagination.total"
                :pageSize="pagination.page_size"
                @change="changePage"
        ></SrPaginate>
        <VideoModal ref="VideoModal"></VideoModal>

        <div class="btns"
             @click="openMotal"
             v-if="camp_theme_id === 1">提交作业
        </div>

        <div class="motal"
             v-show="motalShow">
            <div class="motal_box">
                <div class="motal_title">
                    <span>打卡主题</span>
                    <p>主题名称</p>
                </div>
                <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        placeholder="今天又那些进步，快来说说吧~"
                        v-model="content"
                ></textarea>
                <div class="motal_video">
                    <div class="motal_imglist"
                         v-if="audio_id">
                        <img src="@/assets/img/icon_audio.png"
                             alt=""/>
                        <img
                                src="@/assets/img/close.png"
                                class="input_close"
                                @click="deleteId('audio')"
                                alt=""
                        />
                    </div>
                    <div class="motal_img">
                        <div class="motal_imglist2"
                             v-for="(i, index) in camp_enclosure_id">
                            <img class="motal_m"
                                 :src="i.url"
                                 alt=""/>
                            <img
                                    src="@/assets/img/close.png"
                                    class="input_close2"
                                    @click="deleteImg(index)"
                                    alt=""
                            />
                        </div>
                    </div>
                    <div class="motal_imglist"
                         v-if="video_id">
                        <img :src="video_url"
                             alt=""/>
                        <img
                                src="@/assets/img/close.png"
                                class="input_close"
                                @click="deleteId('video')"
                                alt=""
                        />
                    </div>
                    <div class="motal_file">
                        <img src="@/assets/img/icon1.png"
                             alt=""/>
                        <input type="file"
                               ref="file"
                               @change="fileChange"/>
                    </div>
                </div>

                <SrButton
                        style="margin: 0 auto; margin-top: 30px"
                        @click="submit"
                        font-size="14px"
                        height="40px"
                        padding="0 20px"
                        :loading="submit_loading"
                >
                    提交
                </SrButton>
            </div>
            <img
                    class="motal_close"
                    @click="motalClose"
                    src="@/assets/img/modal-close.png"
                    alt=""
            />
        </div>
    </div>
</template>

<script>
    import {commentList, like, diary} from "@/api/camp";
    import SrPaginate from "@/components/SrPaginate";
    import SrSwitch from "@/components/SrSwitch";
    import SrButton from "@/components/SrButton";
    import VideoModal from "@/components/VideoModal";
    import {uploadVideo, uploadImage, uploadAudio} from "@/api/common";

    export default {
        name: "CommentList",
        components: {
            SrPaginate,
            SrSwitch,
            VideoModal,
            SrButton,
        },
        props: {
            camp_id: {},
            camp_theme_id: {}

        },
        data() {
            return {
                loading: false,
                list: [],
                query: {},
                pagination: {
                    page: 1,
                    page_size: 6,
                },
                comment_loading: false,
                upload_loading: false,
                form: {
                    video: {},
                    comment_enclosure_id: [],
                    image: [],
                },
                motalShow: false,
                submit_loading: false,
                camp_enclosure_id: [],
                video_id: "",
                audio_id: "",
                video_url: "",
                camp_img_id: [],
                content: "",
            };
        },
        created() {
            this.getData();
        },
        watch: {
            camp_id(value) {
                if (value) {
                    this.getData();
                }
            },
        },
        methods: {
            getData() {
                var _self = this;
                _self.loading = true;
                commentList({
                    camp_id: this.camp_id,
                    camp_theme_id: this.camp_theme_id,
                    ...this.pagination,
                    ...this.query,
                }).then((res) => {
                    _self.list = res.result.items;
                    _self.loading = false;
                    _self.pagination = res.result.pagination;
                });
            },
            changePage(page, page_size) {
                this.pagination.page = page;
                this.getData();
            },
            orderChange(index) {
                if (index === 0) {
                    this.query.order_by = "default";
                } else {
                    this.query.order_by = "created_at";
                }
                this.getData();
            },
            showVideo(video) {
                this.$refs.VideoModal.show(video.url);
            },
            like(item) {
                item.is_like = !item.is_like;
                if (item.is_like) {
                    item.likes++;
                } else {
                    item.likes--;
                }
                like({camp_diary_id: item.camp_diary_id, status: item.is_like});
            },
            submit() {
                let _this = this;
                _this.submit_loading = true;
                diary({
                    camp_id: _this.camp_id,
                    camp_theme_id: _this.camp_theme_id,
                    content: _this.content,
                    video_id: _this.video_id,
                    camp_enclosure_id: _this.camp_img_id,
                    audio_id: _this.audio_id,
                }).then((res) => {
                    console.log(res, "3213213213-----");
                    if (res.status == 200) {
                        _this.motalShow = false
                        _this.submit_loading = false;
                        _this.$message.success("操作成功！");
                        _this.getData();
                    } else {
                        _this.motalShow = false
                        _this.submit_loading = false;
                        _this.$message.warning(res.msg);
                    }
                });
            },
            openMotal() {
                this.motalShow = true;
            },
            motalClose() {
                this.motalShow = false;
            },
            fileChange() {
                var file = this.$refs.file.files[0];
                console.log(file);
                if (file) {
                    var allow_img = ["image/jpeg", "image/png", "image/bmp"];
                    var allow_video = [
                        "video/mp4",
                        "video/msvideo",
                        "video/ms-wmv",
                        "video/flv",
                    ];
                    var allow_audio = ["audio/mp3"];
                    if (allow_video.indexOf(file.type) !== -1) {
                        this.addVideo(file);
                        return true;
                    }
                    if (allow_img.indexOf(file.type) !== -1) {
                        this.addImage(file);
                        return true;
                    }
                    if (allow_audio.indexOf(file.type) !== -1) {
                        this.addAudio(file);
                        return true;
                    }
                    this.$message.warning("所选文件格式不支持");
                }
            },
            addVideo(file) {
                var _self = this;
                var formData = new FormData();
                formData.append("video", file);
                _self.upload_loading = true;
                uploadVideo(formData).then((res) => {
                    console.log(res, "视频");
                    _self.upload_loading = false;
                    if (res.status === 200) {
                        _self.video_id = res.result.video_id;
                        _self.video_url = res.result.cover;
                    } else {
                        _self.$message.warning(res.msg);
                    }
                });
            },
            addImage(file) {
                var _self = this;
                var formData = new FormData();
                formData.append("image", file);
                _self.upload_loading = true;
                uploadImage(formData).then((res) => {
                    console.log(res, "图片");
                    _self.upload_loading = false;
                    if (res.status === 200) {
                        _self.camp_enclosure_id.push(res.result);
                        _self.camp_img_id.push(res.result.image_id);
                        console.log(_self.camp_enclosure_id);
                    } else {
                        _self.$message.warning(res.msg);
                    }
                });
            },
            addAudio(file) {
                var _self = this;
                var formData = new FormData();
                formData.append("audio", file);
                _self.upload_loading = true;
                uploadAudio(formData).then((res) => {
                    console.log(res, "音频");
                    _self.upload_loading = false;
                    if (res.status === 200) {
                        _self.audio_id = res.result.video_id;
                    } else {
                        _self.$message.warning(res.msg);
                    }
                });
            },
            deleteId(type) {
                if (type == "audio") {
                    this.audio_id = "";
                } else {
                    this.video_id = "";
                    this.video_url = "";
                }
            },
            // 删除图片
            deleteImg(index) {
                this.camp_enclosure_id.splice(index, 1);
                this.camp_img_id.splice(index, 1);
                console.log(this.camp_enclosure_id);
            },
        },
    };
</script>

<style scoped>
    .kc-evaluate-listImg {
        position: relative;
    }

    .play-btn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }

    .play-btn img {
        margin: 20% auto;
        height: 30%;
        transform: rotate(90deg);
    }

    .btns {
        background-color: #3561c7;
        border: none;
        height: 42px;
        line-height: 42px;
        border-radius: 50px;
        width: 100%;
        padding: 0;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        margin-top: 30px;
        cursor: pointer;
    }

    .motal {
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
    }

    .motal_box {
        background: #ffffff;
        width: 700px;
        margin: 0 auto;
        border-radius: 20px;
        padding: 30px;
        margin-top: 100px;
    }

    .motal_title p {
        display: inline-block;
        font-size: 24px;
        margin-left: 10px;
    }

    .motal_box textarea {
        background: #f6f6f6;
        border-radius: 10px;
        padding: 15px;
        width: 100%;
        margin-top: 24px;
        border: none;
    }

    .motal_video {
        margin-top: 30px;
    }

    .motal_file {
        position: relative;
        display: inline-block;
    }

    .motal_file input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1000;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    .motal_close {
        margin: 0 auto;
        margin-top: 30px;
        cursor: pointer;
    }

    .motal_imglist {
        display: inline-block;
        margin-right: 10px;
        position: relative;
    }

    .input_close {
        position: absolute;
        right: -10px;
        top: -10px;
        width: 25px;
        height: 25px;
        display: none;
        cursor: pointer;
    }

    .input_close2 {
        position: absolute;
        right: -10px;
        top: -10px;
        width: 25px;
        height: 25px;
        display: none;
        cursor: pointer;
    }

    .motal_imglist:hover .input_close {
        display: block;
    }

    .motal_m {
        width: 100px;
        height: 75px;
    }

    .motal_img {
        display: inline-block;
        margin-right: 10px;
    }

    .motal_imglist2 {
        position: relative;
        display: inline-block;
        margin-right: 10px;
    }

    .motal_imglist2:hover .input_close2 {
        display: block;
    }
</style>

