<template>
  <div>
    <!-- 错题集内容 -->
    <div class="index3_lxls_nr">
      <ul v-if="historyList.length > 0">
        <li v-for="i in historyList">
          <div class="index3_lxls_list_tit">
            <span
              >{{ i.name }}
              <em
                ><i>{{ i.answer_order.length }}</i
                >次</em
              ></span
            >
          </div>
          <div class="index3_lxls_list_nr" v-if="i.answer_order.length > 0">
            <ul>
              <li v-for="item in i.answer_order">
                <div class="index3_lxls_list_nr_box">
                  <p>答题时间：{{ item.created_at }}</p>
                  <div class="index3_lxls_list_a">
                    <a class="index3_lxls_list_on" @click="openMySubject(item)"
                      >我的答题</a
                    >
                    <a
                      class="index3_lxls_list_down"
                      @click="openReport(item, i)"
                      >答题报告</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div class="index3_mktk warp" v-if="historyList.length == 0">
        <div class="in3_mktk in3_mktk_zw">
          <img src="@/assets/img/index3_mktk_tu1.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>

    <SrPaginate
      style="margin: 0 auto; text-align: center; margin-top: 50px"
      :current="pagination.current_page"
      :total="pagination.total"
      :pageSize="pagination.page_size"
      @change="changePage"
      v-if="historyList.length > 0"
    ></SrPaginate>
  </div>
</template>
<script>
import SrPaginate from "@/components/SrPaginate";
import { history } from "@/api/exam";
export default {
  name: "index",
  components: {
    SrPaginate,
  },
  props: {
    id: String || Number,
    type: String || Number,
  },
  data() {
    return {
      historyList: [],
      pagination: {
        page: 0,
        page_size: 9,
        page: 1,
      },
    };
  },
  methods: {
    loadHistory() {
      let _this = this;
      history({
        id: _this.id,
        type: _this.type,
      }).then((res) => {
        _this.historyList = res.result.items;
        _this.pagination = res.result.pagination;
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadHistory();
    },
    openMySubject(item) {
      this.$router.push({
        path: "/answerDetail",
        query: {
          answer_order_id: item.answer_order_id,
        },
      });
    },
    openReport(item, i) {
      console.log(i);
      this.$router.push({
        path: "/exam/reportObjective",
        query: {
          answer_order_id: item.answer_order_id,
          title: i.name,
        },
      });
    },
  },
  created() {
    this.loadHistory();
  },
};
</script>
<style scoped>
.index3_wdctj_title_r {
  width: auto;
}
</style>