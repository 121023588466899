<template>
    <div class="warp mt30">
        <h3 class="kc-tuijian"><img src="@/assets/img/KC-tbg.png">推荐课程</h3>
        <div class="clearfix mt20">
            <div v-for="(item,index) in list"
                 :key="index"
                 class="kc-list kc-listBg fn_l ml0">
                <router-link :to="{path:'/course/info/'+item.relation_id}">
                    <div class=""><img :src="item.relation.first_cover.url"></div>
                    <div class="kc-listTex">
                        <h3>{{item.relation.name}}</h3>
                        <p class="f12 color-main"
                           :style="{color:item.relation.subtitle_color}">
                            {{item.relation.subtitle}}
                        </p>
                        <p class="clearfix f14 color-666">
                            <span class="fn_l">限售<em class="color-main">
                                {{item.relation.goods.sales_restriction}}人</em> |
                                已报名<em class="color-pink">{{item.relation.goods.sale_amount}}人</em></span>
                            <span class="fn_r">
                                <s v-if="item.relation.goods.order_price!==item.relation.goods.real_price"
                                   class="color-999">￥{{item.relation.goods.real_price}}</s>
                                <em class="f22 color-main">￥{{item.relation.goods.order_price}}</em>
                            </span>
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {recommend} from "@/api/common"

    export default {
        name: "ReCourseBig",
        data() {
            return {
                list: []
            }
        },
        created() {
            var _self = this
            recommend({type: 1, page_size: 6}).then((res) => {
                console.log(res,'推荐课程----')
                _self.list = res.result.items
            })
        }
    }
</script>

<style scoped>
    .kc-list{
        margin: 20px;
    }
</style>
