<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的消息</h6>
      </div>
      <div class="per1_message1">
        <ul v-if="list.length > 0">
          <li v-for="i in list" >
			<router-link :to="{path:'/user/center/myMessageDetail',query:{id:i.message_id}}">
              <div class="message1_l">
                <h6>{{i.name}}</h6>
                <p v-html="i.content"></p>
              </div>
              <div class="message1_r">
                <span>{{i.created_at}}</span>
                <i></i>
              </div>
			</router-link>
          </li>
        </ul>
		 <div class="zanwu" v-if="list.length == 0">
          <img src="@/assets/img/zanwu.png" alt="" />
          暂无数据
        </div>
      </div>
      <!-- 页码 -->
	    <SrPaginate
        style="margin: 0 auto; text-align: center; margin-top: 50px"
        :current="pagination.current_page"
        :total="pagination.total"
        :pageSize="pagination.page_size"
        @change="changePage"
		v-if="list.length > 0"
      ></SrPaginate>
    </div>
  </div>
</template>
<script>
import SrPaginate from "@/components/SrPaginate";
import { messageList } from "@/api/user";
export default {
  name: "MyMessage",
    components: {
    SrPaginate
  },
  data() {
    return {
		list:[],
		pagination: {
        page: 1,
        page_size: 10,
      },
	};
  },
  methods: {
    loadList() {
      let _this = this;
      messageList({..._this.pagination}).then((res) => {
		 _this.list = res.result.items
		 _this.pagination = res.result.pagination;
      });
	},
	changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
.zanwu {
  margin: 0 auto;
  text-align: center;
  margin-top: 130px;
}
.zanwu img {
  width: 200px;
  margin: 0 auto;
}
</style>