<template>
  <div>
    <div class="lxbaogao-t" style="line-height:60px">
      <div class="warp pos-rel te_c">
        <a class="color-666" href="">&lt; 退出</a>
        <p>[ 单选题 ] 阅读理解-关联词-转折</p>
      </div>
      <RightNav></RightNav>
      <!-- 3题目-单选题 -->
      <div class="index3_dx_nr mt50 warp">
        <div class="index3_dx_nr_l index3_mktk_con">
          <Radios></Radios>
          <div class="index3_dx_five">
            <a href="" class="index3_dx_five_f1">上一题</a>
            <a href="" class="index3_dx_five_f2">下一题</a>
          </div>
        </div>
        <div class="index3_dx_nr_r">
          <div class="index3_dx_nr_r_tit">
            <i></i>
            <h6>答题卡</h6>
            <em>已做36/100</em>
          </div>
          <div class="index3_dx_nr_r_nei index3_mktk_con index3_mktk_con1">
            <div class="index3_dx_nei_one">
              <ul>
                <li>
                  <i class="in3_yz"></i>
                  <p>已做</p>
                </li>
                <li>
                  <i class="in3_wz"></i>
                  <p>未做</p>
                </li>
              </ul>
            </div>

            <div class="index3_dx_nei_list">
              <ul>
                <li>
                  <span>1<i></i></span>
                </li>
                <li>
                  <span>2</span>
                </li>
                <li>
                  <span>3</span>
                </li>
                <li>
                  <span>4<i></i></span>
                </li>
                <li>
                  <span>5<i></i></span>
                </li>
                <li>
                  <span>6<i></i></span>
                </li>
                <li>
                  <span>7<i></i></span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">8</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">9</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">10</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">11</span>
                </li>
                <li>
                  <span>12</span>
                </li>
                <li>
                  <span>13<i></i></span>
                </li>
                <li>
                  <span>14<i></i></span>
                </li>
                <li>
                  <span>15<i></i></span>
                </li>
                <li>
                  <span>16<i></i></span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">17</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">18</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">19</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">20</span>
                </li>
                <li>
                  <span>21</span>
                </li>
                <li>
                  <span>22<i></i></span>
                </li>
                <li>
                  <span>23<i></i></span>
                </li>
                <li>
                  <span>24<i></i></span>
                </li>
                <li>
                  <span>25<i></i></span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">26</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">27</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">28</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">29</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">30</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">31</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">32</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">33</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">34</span>
                </li>
                <li>
                  <span class="index3_dx_nei_li">35</span>
                </li>
                <li>
                  <span>36</span>
                </li>
                <li>
                  <span>37<i></i></span>
                </li>
                <li>
                  <span>38<i></i></span>
                </li>
                <li>
                  <span>39<i></i></span>
                </li>
                <li>
                  <span>40<i></i></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import SrFooter from "@/layout/SrFooter";
import RightNav from "./modules/rightNav";
import Radios from "./modules/radio";
export default {
  name: "index",
  components: {
    SrFooter,
    RightNav,
    Radios,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  created() {},
};
</script>
<style>
.index3_dx_nr_r{
    width: 30%;
}
.index3_dx_nei_one ul li{
    
}
</style>