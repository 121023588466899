<template>
  <div id="app">
    <div style="min-height: 100vh; position: relative; padding-bottom: 180px">
      <router-view v-if="isReloadAlive"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isReloadAlive: true,
    };
  },
  name: "App",
  components: {},
  computed: {},
  methods: {
    reload() {
      this.isReloadAlive = false;
      this.$nextTick(function () {
        this.isReloadAlive = true;
      });
    },
  },
};
</script>

<style>
@import "./assets/css/css_personal.css";
@import "./assets/css/global.css";
@import "./assets/css/style.css";
@import "./assets/css/style-lxx.css";
@import "./assets/css/new_file.css";
@import "./assets/css/sanqi.css";
@import "./assets/css/sanqi-l.css";
.sr-dialog .ant-modal-content {
  border-radius: 20px;
}

.sr-shipping-address .ant-modal-content {
  border-radius: 0;
  overflow: hidden;
}
</style>
