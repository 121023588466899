<template>
  <div>
    <div class="per3_right per_right">
      <div class="p_remind_title">
        <img src="@/assets/img/remind_icon.png" />
        <h6>我的订单-***课程-发起退款</h6>
      </div>
      <div class="per8_refund">
        <ul>
          <li>
            <span>上传身份证正面:</span>
            <div class="input_r">
              <img :src="card_is_img" />
              <input type="file" ref="file" @change="card_is_change" />
            </div>
          </li>
          <li>
            <span>上传身份证反面:</span>
            <div class="input_r">
              <img :src="card_the_img" />
              <input type="file" ref="file1" @change="card_the_change" />
            </div>
          </li>
          <li>
            <span>上传准考证:</span>
            <div class="input_r">
              <img :src="ticket_img" />
              <input type="file" ref="file2" @change="ticket_change" />
            </div>
          </li>
          <li>
            <span>上传成绩单:</span>
            <div class="input_r">
              <img :src="transcript_img" />
              <input type="file" ref="file3" @change="transcript_change" />
            </div>
          </li>
          <li>
            <span>成绩查询网址:</span>
            <input type="text" v-model="transcript_url" class="ss_refund" />
          </li>
          <li>
            <span>上传公示图片:</span>
            <div class="input_r">
              <img :src="public_img" />
              <input type="file" ref="file4" @change="public_change" />
            </div>
          </li>
          <li>
            <span>公示网址:</span>
            <input type="text" v-model="public_url" class="ss_refund" />
          </li>
        </ul>
      </div>
      <SrButton
        style="width: 250px; margin-top: 30px; margin-left: 80px"
        @click="setRefund"
        font-size="14px"
        height="40px"
        padding="0 20px"
        :loading="submit_loading"
      >
        确认无误并提交
      </SrButton>
    </div>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import { uploadImage, sendMessage, location } from "@/api/common";
import { refund } from "@/api/user";
export default {
  name: "OrderRefund",
  components: {
    SrButton,
  },
  data() {
    return {
      card_is_image_id: null,
      card_the_image_id: null,
      ticket_image_id: null,
      transcript_image_id: null,
      public_image_id: null,
      transcript_url: null,
      public_url: null,
      card_is_img: "/img/refund1.jpg",
      card_the_img: "/img/refund2.jpg",
      ticket_img: "/img/refund3.jpg",
      transcript_img: "/img/refund4.jpg",
      public_img: "/img/refund5.jpg",
      submit_loading: false,
    };
  },
  methods: {
    card_is_change() {
      var file = this.$refs.file.files[0];
      console.log(this.$refs.file,'正面');
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage(file, "card_is");
          //   return true;
        }
        // this.$message.warning("所选文件格式不支持");
      }
    },
    card_the_change() {
      var file = this.$refs.file1.files[0];
      console.log(file,'反面')
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage(file, "card_the");
          //   return true;
        }
        // this.$message.warning("所选文件格式不支持");
      }
    },
    ticket_change() {
      var file = this.$refs.file2.files[0];
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage(file, "ticket");
          //   return true;
        }
        // this.$message.warning("所选文件格式不支持");
      }
    },
    transcript_change() {
      var file = this.$refs.file3.files[0];
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage(file, "transcript");
          //   return true;
        }
        // this.$message.warning("所选文件格式不支持");
      }
    },
    public_change() {
      var file = this.$refs.file4.files[0];
      if (file) {
        var allow_img = ["image/jpeg", "image/png", "image/bmp"];
        var allow_video = [
          "video/mp4",
          "video/msvideo",
          "video/ms-wmv",
          "video/flv",
        ];
        if (allow_img.indexOf(file.type) !== -1) {
          this.addImage(file, "public");
          //   return true;
        }
        // this.$message.warning("所选文件格式不支持");
      }
    },
    addImage(file, e) {
      var _self = this;
      var formData = new FormData();
      formData.append("image", file);
      _self.upload_loading = true;
      uploadImage(formData).then((res) => {
        console.log(res, "------");
        _self.upload_loading = false;
        if (res.status === 200) {
          var file = res.result.url;
          //   _self.nickImg = res.result.url;
          //   _self.nickImgId = res.result.image_id;
          if (e == "card_is") {
            _self.card_is_image_id = res.result.image_id;
            _self.card_is_img = res.result.url;
          }
          if (e == "card_the") {
            _self.card_the_image_id = res.result.image_id;
            _self.card_the_img = res.result.url;
          }
          if (e == "ticket") {
            _self.ticket_image_id = res.result.image_id;
            _self.ticket_img = res.result.url;
          }
          if (e == "transcript") {
            _self.transcript_image_id = res.result.image_id;
            _self.transcript_img = res.result.url;
          }
          if (e == "public") {
            _self.public_image_id = res.result.image_id;
            _self.public_img = res.result.url;
          }
        } else {
          this.$message.warning(res.msg);
        }
        return file;
      });
    },
    setRefund() {
      let _this = this;
      if (_this.card_is_image_id == null) {
        _this.$message.warning("请上传身份证正面");
        return;
      }
      if (_this.card_the_image_id == null) {
        _this.$message.warning("请上传身份证反面");
        return;
      }
       if (_this.ticket_image_id == null) {
        _this.$message.warning("请上传准考证照片");
        return;
      }
      if (_this.transcript_image_id == null) {
        _this.$message.warning("请上传成绩单照片");
        return;
      }
      if (_this.transcript_image_id == null) {
        _this.$message.warning("请上传成绩单照片");
        return;
      }
      if (_this.public_image_id == null) {
        _this.$message.warning("请上传公示图片");
        return;
      }
      if (_this.transcript_url == null) {
        _this.$message.warning("请填写成绩查询地址");
        return;
      }
      if (_this.public_url == null) {
        _this.$message.warning("请填写公示地址");
        return;
      }
      _this.submit_loading = true
      refund({
        order_id:_this.$route.query.order_id,
        order_item_id:_this.$route.query.order_item_id,
        card_is_image_id:_this.card_is_image_id,
        card_the_image_id:_this.card_the_image_id,
        ticket_image_id:_this.ticket_image_id,
        transcript_image_id:_this.transcript_image_id,
        public_image_id:_this.public_image_id,
        transcript_url:_this.transcript_url,
        public_url:_this.public_url
      }).then((res) => {
        console.log(res, "------");
         if (res.status === 200) {
          _this.$message.success("操作成功");
          _this.submit_loading = false
             _this.$srmsg
        .confirm({
          title: "提交成功",
          content: "请等待系统进行审核",
        })
        .then(() => {
             setTimeout(() => {
               this.$router.push({path: '/user/center'});
              }, 1000);
        })
        } else {
          _this.$message.warning(res.msg);
            _this.submit_loading = false
        }
      });
    },
  },
  created() {
    console.log(this.$route.query);
  },
};
</script>

<style scoped>
table {
  text-align: center;
}
.input_r {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.input_r input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
}
</style>