import request from '@/utils/request'

/**
 * 支付时获取可使用积分抵扣
 * @param parameter
 */
export function getCredit(parameter) {
    return request({
        url: '/api/buy/getCredit',
        method: 'post',
        data: parameter
    })
}

/**
 * 支付时计算优惠价格
 * @param parameter
 */
export function getRealPrice(parameter) {
    return request({
        url: '/api/buy/getRealPrice',
        method: 'post',
        data: parameter
    })
}

/**
 * 通常下单
 * @param parameter
 */
// export function ordinary(parameter) {
//     return request({
//         url: '/api/buy/ordinary',
//         method: 'post',
//         data: parameter
//     })
// }
export function ordinary(parameter) {
    return request({
        url: 'api/buy/ordinarySpecial',
        method: 'post',
        data: parameter
    })
}
/**
 * 秒杀下单
 * @param parameter
 */
export function seckill(parameter) {
    return request({
        url: '/api/buy/seckillSpecial',
        method: 'post',
        data: parameter
    })
}

/**
 * 拼团下单
 * @param parameter
 */
export function pintuan(parameter) {
    return request({
        url: '/api/buy/pintuanSpecial',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取商品详情
 * @param parameter
 */
export function getGoodsInfo(parameter) {
    return request({
        url: '/api/buy/getGoodsInfo',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取订单支付状态
 * @param parameter
 */
export function getOrderStatus(parameter) {
    return request({
        url: '/api/buy/getOrderStatus',
        method: 'post',
        data: parameter
    })
}

/**
 * 分享
 * @param parameter
 */
export function share(parameter) {
    return request({
        url: 'api/user/share/pc_share',
        method: 'post',
        data: parameter
    })
}
