import request from '@/utils/request'

/**
 * 获取购物车项目列表
 * @param parameter
 */
export function list(parameter) {
    return request({
        url: '/api/cart/list',
        method: 'post',
        data: parameter
    })
}

/**
 * 加入商品至购物车
 * @param parameter
 */
export function add(parameter) {
    return request({
        url: '/api/cart/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 从购物车移除
 * @param parameter
 */
export function remove(parameter) {
    return request({
        url: '/api/cart/remove',
        method: 'post',
        data: parameter
    })
}
