<template>
  <div>
    <!-- 侧导航 -->
    <!-- <div class="slide_box">
      <div class="slide_active top_text yh_item">
        <div class="in_bock l_text">
          <div class="in_bock">小</div>
          <div class="in_bock">中</div>
          <div class="in_bock">大</div>
        </div>
        <div class="in_bock r_text_size">
          <div class="r_text_size_content">
            <div class="slide_item_top">中</div>
            <div class="slide_item_bot">字号</div>
          </div>
        </div>
      </div>
      <div class="slide_item tog_bac yh_item">
        <div class="slide_item_top sun_img">
          <img src="@/assets/img/sun-normal.489ba2bf5217907f5759.png" alt="" />
        </div>
        <div class="slide_item_top night_img">
          <img src="@/assets/img/night-normal.90274015d84667c6bf4a.png" alt="" />
        </div>
        <div class="slide_item_bot">字号</div>
      </div>

      <div class="slide_item yh_item">
        <a href="#">
          <div class="slide_item_top">
            <img src="@/assets/img/download-night-normal.00e580df3e9d452909f9.png" />
          </div>
          <div class="slide_item_bot">下载</div>
        </a>
      </div>
      <div class="slide_item yh_item">
        <a href="#">
          <div class="slide_item_top">
            <img src="@/assets/img/submit-light-normal.png" />
          </div>
          <div class="slide_item_bot">交卷</div>
        </a>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return {
    
    };
  },
  mounted() {},
  methods: {

  },
  created() {},
};
</script>
<style>
	.slide_box {
			position: fixed;
			right: 0;
			top: 40%;
			display: grid;
			align-items: center;
			justify-items: end;
			background: transparent;
			color: #636e92;
		}

		.slide_item {
			background-color: #fff;
			text-align: center;
			height: 52px;
			width: 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.slide_item_top {
			font-size: 14px;
		}

		.slide_item_bot {
			font-size: 10px;
		}

		.slide_active {
			position: relative;
		}

		.slide_item_box {
			position: relative;
			left: -90px;
			background-color: #fff;
			top: 0;
			position: absolute;
			z-index: 888;
			top: 0;
			box-shadow: 0 2px 10px 0 rgb(47 51 56 / 8%);
			align-items: center;
			border-radius: 4px;

		}

		.slide_item_box {
			overflow: hidden;
		}
		.slide_item_box li {
			height: 52px;
			line-height: 52px;
			text-align: center;
			padding: 0 6px;
			float: left;
		}

		.slide_item_box {
			display: none;
		}

		.bac {
			background-color: #000000;
		}

		.bac * {
			color: #fff !important;

		}

		.night_img {
			display: none;
		}

		.in_bock {
			display: inline-block;
		}

		.r_text_size {
			width: 30px;
			text-align: center;
		}

		.l_text {
			display: none;
			background: #fff;
			margin-right: 5px;
			height: 52px;
			line-height: 52px;
			padding: 0 5px;
			box-sizing: border-box;
			vertical-align: bottom;
			cursor: pointer;
		}

		.l_text>div {
			padding: 0 5px;
		}

		.slide_active:hover .l_text {
			display: inline-block;
		}

		.top_text {
			height: 52px;
		}

		.r_text_size_content {
			height: 52px;
			display: grid;
			align-items: center;
			justify-items: center;
		}

		.slide_box :last-child.yh_item {
			border-bottom: none;
		}

		.yh_item {
			background-color: #fff;
			padding: 0 10px;
			border-bottom: 1px solid #ccc;

		}

		.bac .l_text {
			background: #000;
		}

		.bac .yh_item {
			background: #000;
		}

		.bac .index3_dx_top {
			background: #1a1b1e;
		}

		.bac .in3_dx_top_wen {
			background: #1a1b1e;
		}

		.bac .index3_mktk_con {
			background: #1a1b1e;
		}

		.bac .index3_dx_nr_r_tit {
			background: #1a1b1e;
		}

		.bac .index3_dx_nei_list .index3_dx_nei_li {
			background: #1a1b1e;
		}

		.bac .yh_item {
			background: #1a1b1e;
			border-bottom: 1px solid #2e2e2e;
		}
</style>