<template>
  <div>
    <div class="warp clearfix mt50 kcxq-box">
      <div class="dtxq-l fn_l">
        <div class="pos-rel">
          <SrBjAudio
            :cover="radio.image.url"
            :able="playable"
            ref="SrBjAudio"
          ></SrBjAudio>
        </div>
      </div>
      <div class="dtxq-r fn_r">
        <a-skeleton v-if="loading" :title="false" :paragraph="{ rows: 9 }" />
        <div v-else>
          <h3 class="mt10" style="font-weight: 900">{{ video.name }}</h3>
          <p class=" color-666" style="font-size:14px">播放量{{ radio.playback_volume }}次</p>
          <p class="color-666" style="font-size:14px">
            开播时间：
            {{ $moment(radio.start_at).format("YYYY.MM.DD") }}
            --
            {{ $moment(radio.end_at).format("YYYY.MM.DD") }}
            {{ radio.period }}
            课时
          </p>
          <p class="f12 color-main" :style="{ color: radio.subtitle_color }">
            {{ radio.subtitle }}
          </p>
          <p class="" style="font-size:14px">
            <span class="color-999">
              限售{{ radio.goods.sales_restriction }}人 | 已报名{{
                radio.goods.sale_amount
              }}人
            </span>
            <img src="@/assets/img/KC-time.png" />
            <span class="color-pink">
              距结束：<a-statistic-countdown
                :value="radio.end_at | toTime"
                format="D 天 H 时 m 分 s 秒"
              />
            </span>
          </p>
          <p class="clearfix mt30">
            <span
              v-if="radio.goods.order_price === radio.goods.real_price"
              class="fn_l f26 color-main"
              >￥{{ radio.goods.real_price }}
            </span>
            <span v-else class="fn_l f26 color-main"
              >￥{{ radio.goods.order_price }}
              <s class="color-999" style="font-size:14px">￥{{ radio.goods.real_price }}</s>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="warp clearfix mt30">
      <div class="kcxq-con fn_l">
        <div class="dt-t">
          <img src="@/assets/img/KC-tbg2.png" />播放列表
          <div class="tab_right">
            <p @click="goMyQuestion(0)">我的错题</p>
            <p @click="goMyQuestion(1)">我的收藏</p>
            <p @click="goMyQuestion(2)">练习历史</p>
          </div>
        </div>

        <RadioChapter :list="chapter_list" :radio_id="radio_id"></RadioChapter>
      </div>
      <ReRadio></ReRadio>
    </div>
  </div>
</template>

<script>
import { info, chapterList, video } from "@/api/radio/radio";
import BjDianbo from "@/components/BjDianbo";
import SrBjAudio from "@/components/SrBjAudio";
import RadioChapter from "./modules/RadioChapter";
import ReRadio from "@/layout/recommend/ReRadio";

export default {
  name: "index",
  components: {
    BjDianbo,
    RadioChapter,
    SrBjAudio,
    ReRadio,
  },
  data() {
    return {
      playable: false,
      loading: false,
      radio: {
        image: {},
      },
      video: {},
      radio_subchapter_id: 0,
      chapter_list: [],
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  created() {
    var radio_id = this.$route.query.radio_id;
    if (!radio_id) {
      this.$srmsg.info({
        title: "提示",
        content: "课程不存在",
      });
      return false;
    }
    this.radio_subchapter_id = this.$route.query.radio_subchapter_id;
    console.log(this.radio_subchapter_id, "radio_subchapter_id");
    if (this.radio_subchapter_id) {
      this.getVideo();
    }
    this.radio_id = radio_id;
    this.getData();
  },
  methods: {
    getData() {
      var _self = this;
      _self.loading = true;
      var radio_id = this.radio_id;
      info({ radio_id: radio_id }).then((res) => {
        console.log(res, "电台详情");
        _self.radio = res.result;
        _self.loading = false;
        if (!_self.radio_subchapter_id) {
          //如果当前页没有子章节id  取第一个子章节
          if (!res.result.first_radio_subchapter) {
            _self.$srmsg.info({
              title: "提示",
              content: "暂无课程",
            });
          }
          _self.radio_subchapter_id =
            res.result.first_radio_subchapter.radio_subchapter_id;
          _self.getVideo();
          _self.getSubchapter();
        }
      });
    },
    getSubchapter() {
      var _self = this;
      chapterList({ radio_id: _self.radio_id }).then((res) => {
        var data = res.result;
        data.map(function (n) {
          n.radio_subchapter.map(function (s) {
            if (_self.radio_subchapter_id == s.radio_subchapter_id) {
              s.isPlay = true;
            } else {
              s.isPlay = false;
            }
          });
        });
        _self.chapter_list = data;
      });
    },
    getVideo() {
      var _self = this;
      video({ radio_subchapter_id: _self.radio_subchapter_id }).then((res) => {
        if (res.status === 200) {
          _self.video = res.result;
          _self.playable = true;
          _self.$refs.SrBjAudio.init(
            res.result.token,
            res.result.video_id,
            res.result.length
          );
        } else {
          _self.playable = false;
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
    goMyQuestion(index) {
      let _this = this;
      this.$router.push({
        path: "/question/myQuestion",
        query: {
          tabIndex: index,
          title: _this.radio.goods.name,
          courseType: _this.radio.goods.type,
          id: _this.radio.radio_id,
          types:103
        },
      });
    },
  },
};
</script>

<style scoped>
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}
.tab_right {
  float: right;
  width: 40%;
}
.tab_right p {
  display: inline-block;
  width: 33%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
</style>
