<template>
    <div>
        <a-modal
                class="sr-shipping-address"
                :closable="false"
                :bodyStyle="{padding:0}"
                v-model="visible"
                :width="600"
                :footer="false">
            <div>
                <div class="set5">
                    <div class="set5_nr">
                        <table border="0"
                               cellspacing="0"
                               cellpadding="0"
                               style="margin-top: 0"
                               class="form form2">

                            <a-spin :spinning="loading">
                                <a-icon slot="indicator"
                                        type="loading"
                                        style="font-size: 24px;"
                                        spin/>
                                <div v-if="address_list.length===0"
                                     style="text-align: center;">
                                    <a-empty description="暂无收货地址"
                                             style="margin: 20px auto"/>
                                    <Srbutton style="width: 90px;margin: 0 auto 20px"
                                              padding="0"
                                              @click="$refs.ItemForm.show()"
                                              font-size="14px"
                                              height="30px">点击添加
                                    </Srbutton>
                                </div>
                                <div v-else>
                                    <tr>
                                        <th style="border-radius: 0">收货人</th>
                                        <th>收货地址</th>
                                        <th>手机号码</th>
                                        <th style="border-radius: 0">编辑</th>
                                        <th style="border-radius: 0">选择</th>
                                    </tr>
                                    <tr v-for="item in address_list"
                                        :key="item.shipping_address_id">
                                        <td>{{item.name}}</td>
                                        <td>{{item.address}}</td>
                                        <td>{{item.mobile}}</td>
                                        <td>
                                            <a @click="edit(item)">编辑</a>
                                        </td>
                                        <td>
                                            <a @click="select(item)">选择</a>
                                        </td>
                                    </tr>
                                </div>
                            </a-spin>
                        </table>
                    </div>
                </div>
            </div>
        </a-modal>
        <ItemForm @ok="getData"
                  ref="ItemForm"></ItemForm>
    </div>
</template>

<script>
    import {addressGetList} from "@/api/user"
    import Srbutton from '@/components/SrButton'
    import ItemForm from './ItemForm'

    export default {
        name: "index",
        components: {
            Srbutton,
            ItemForm
        },
        data() {
            return {
                visible: false,
                loading: true,
                address_list: []
            }
        },
        created() {
            this.getData()
        },
        methods: {
            show() {
                this.visible = true
            },
            getData() {
                var _self = this
                _self.loading = true
                addressGetList().then((res) => {
                    _self.address_list = res.result
                    _self.loading = false
                })
            },
            select(item) {
                this.visible = false
                this.$emit('change', item)
            },
            edit(item) {
                this.$refs.ItemForm.show(item)
            }
        }
    }
</script>

<style scoped>
    th {
        text-align: center;
    }
</style>
