<template>
    <a-modal
            :confirm-loading="loading"
            width="300px"
            title="扫码支付"
            :visible="visible"
            @cancel="cancel"
            :footer="false"
            style="z-index:10000"
    >
        <div style="text-align: center">请使用手机微信扫码完成支付</div>
        <a-spin :spinning="loading">
            <a-icon slot="indicator"
                    type="loading"
                    style="font-size: 24px"
                    spin/>
            <div class="qrcode"
                 ref="qrcode"></div>
        </a-spin>


        <SrButton :loading="check_loading"
                  @click="ok"
                  style="width: 140px;margin: 0 auto"
                  height="30px"
                  font-size="16px"
                  padding="0">我已完成支付
        </SrButton>

        <div style="text-align: center;">
            <!--<router-link :to="{path:'/user/center'}"-->
            <!--@click="cancel">我已完成支付-->
            <!--</router-link>-->
        </div>
    </a-modal>
</template>

<script>
    import QRCode from 'qrcodejs2'
    import {getOrderStatus} from "@/api/buy"
    import SrButton from '@/components/SrButton'

    export default {
        name: "WechatScan",
        components: {
            SrButton
        },
        data() {
            return {
                visible: false,
                loading: false,
                order_id: false,
                check_loading: false
            }
        },
        methods: {
            show(text, order_id) {
                this.visible = true
                this.createCode(text)
                this.order_id = order_id
            },
            createCode(text) {
                var _self = this
                _self.loading = true
                setTimeout(() => {
                    new QRCode(this.$refs.qrcode, {
                        text: text, // 需要转换为二维码的内容
                        width: 200,
                        height: 200,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    _self.loading = false
                }, 1000)
            },
            ok() {
                var _self = this
                _self.check_loading = true
                getOrderStatus({order_id: this.order_id}).then((res) => {
                    _self.check_loading = false
                    if (res.status === 200) {
                        if (res.result === 2) {
                            this.$refs.qrcode.innerHTML = ''
                            this.visible = false
                            this.$router.push({path: '/user/center'})
                        } else {
                            _self.$srmsg.info({
                                title: '提示',
                                content: '订单支付不成功，请重试。'
                            })
                        }
                    } else {
                        _self.$srmsg.info({
                            title: '提示',
                            content: res.msg
                        })
                    }
                })
            },
            cancel() {
                this.$refs.qrcode.innerHTML = ''
                this.visible = false
                this.$emit('close')
            }
        }
    }
</script>

<style scoped>
    .qrcode {
        width: 200px;
        height: 200px;
        margin: 20px auto;
    }
</style>
