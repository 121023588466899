<template>
    <div v-html="info.text">
    </div>
</template>

<script>
    import {userAbout} from "@/api/user"

    export default {
        name: "agreement",
        data() {
            return {
                info: ''
            }
        },
        created() {
            var _self = this
            var id = _self.$route.query.id
            if(id!=2&&id!=10){
                return false
            }
            userAbout({id: id}).then((res) => {
                _self.info = res.result
            })
        }
    }
</script>

<style scoped>

</style>
