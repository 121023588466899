<template>
  <div>
    <SrNav></SrNav>
    <!-- 训练营详情 -->
    <div class="warp mt30">
      <div class="xly-conT clearfix">
        <p class="fn_l f22">
          <img class="dis-inb" src="@/assets/img/KC-tbg.png" /> 模考报告
        </p>
        <div class="index3_share">
          <div class="index3_share_a">
            <a href="">退出</a>
          </div>
          <div class="tab_right">
            <p @click="goMyQuestion(0)">我的错题</p>
            <p @click="goMyQuestion(1)">我的收藏</p>
            <p @click="goMyQuestion(2)">练习历史</p>
          </div>
        </div>
      </div>
      <div class="index3_mktk warp" v-if="list.length == 0">
        <div class="in3_mktk">
          <img src="@/assets/img/index3_mktk_tu.png" />
          <p>您还没参加过模考哦~</p>
          <a @click="goExam">立即参加模考</a>
        </div>
      </div>
      <div v-if="list.length > 0">
        <div class="clearfix mt20">
          <div class="mokao-l">
            <div class="mokao-list">
              <ul>
                <li v-for="(i, index) in list">
                  <h6>{{ i.relation.name }}</h6>
                  <p>开始时间：{{ i.relation.created_at }}</p>
                  <p>做题时间：{{ i.relation.start_at }}</p>
                  <a @click="getDetail(i, index)" class="in3_mktk_ljbm"
                    >点击查看</a
                  >
                </li>
              </ul>
            </div>
            <SrPaginate
              v-if="list.length > 0"
              style="margin: 0 auto; text-align: center; margin-top: 50px"
              :current="pagination.current_page"
              :total="pagination.total"
              :pageSize="pagination.page_size"
              @change="changePage"
            ></SrPaginate>
          </div>
          <div class="mokao-r" v-if="detail.report.type == 1">
            <!-- 预约讲解 -->
            <div class="te_r">
              <img class="dis-inb" src="@/assets/img/mokao-icon1.png" />
              &nbsp;<a class="mokao-yy" @click="getOrder">预约讲解</a>
            </div>
            <!-- 百分环比 -->
            <div class="tabbox clearfix mt20">
              <div class="content">
                <ul>
                  <a-progress
                    type="circle"
                    :percent="detail.rate"
                    :strokeWidth="10"
                    :stroke-color="{ '0%': '#35f88c', '100%': '#1dc5e3' }"
                  />
                  <p>
                    {{
                      detail.rate == 100
                        ? "恭喜您获得满分！"
                        : "勤能补拙，您还需要加油"
                    }}
                  </p>
                </ul>
              </div>
              <div class="tab">
                <a href="javascript:;" class="on">
                  <p>2′44″</p>
                  <p>答题时间</p>
                </a>
                <a href="javascript:;">
                  <p>{{ detail.correctNumber }}</p>
                  <p>正确数</p>
                </a>
                <a href="javascript:;">
                  <p>{{ detail.errorNumber }}</p>
                  <p>错误数</p>
                </a>
              </div>
            </div>
            <!-- 答题卡 -->
            <div class="mokao-dtk-t">
              <span><img src="@/assets/img/mokao-dui.png" /></span> 对 &nbsp;
              <span><img src="@/assets/img/mokao-cuo.png" /></span> 错 &nbsp;
              <span><img src="@/assets/img/mokao-weizuo.png" /></span> 未做
              &nbsp;
            </div>
            <p class="mt10">
              单选题 &nbsp; &nbsp;<small class="color-999">共12题</small>
            </p>
            <div class="mokao-dtk-m mt10">
              <!-- correct_flage 0错 1对 2无法给出答案 -->
              <a
                :class="
                  i.answer_situation == null
                    ? ''
                    : i.answer_situation.correct_flage == 1
                    ? 'mokao-dui'
                    : i.answer_situation.correct_flage == 2
                    ? 'mokao-dui'
                    : 'mokao-cuo'
                "
                v-for="(i, index) in subjectList"
                >{{ index + 1 }}</a
              >
            </div>
            <div class="mokao-jiexi mt50 te_r">
              <a @click="goAnswers">答案解析</a>
              <a @click="openShare">分享得积分</a>
            </div>
          </div>
          <div class="mokao-r" v-else>
            <!-- 预约讲解 -->
            <div class="te_r">
              <img class="dis-inb" src="@/assets/img/mokao-icon1.png" />
              &nbsp;<a class="mokao-yy" @click="getOrder">预约讲解</a>
            </div>
            <div>
              <div class="baogao-boxL">
                <div class="shenlun">
                  <img src="@/assets/img/shenlun-img.jpg" />
                  <p>
                    <a @click="openPopup(2)">我要批改</a
                    ><a @click="openMyCourse">查看批改</a>
                  </p>
                </div>
              </div>
              <div class="baogao_r">
                <div class="shenglun-jg">
                  <p>2′44″</p>
                  <p>答题时间</p>
                </div>
                <div class="te_c mt20 color-666">
                  <p>勤能补拙，您还需要加油！</p>
                </div>
                <div
                  class="mokao-jiexi te_r"
                  style="margin-top: 20px; text-align: center"
                >
                  <a @click="goAnswers">答案解析</a>
                  <a @click="openShare">分享得积分</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分享得积分 -->
        <div class="kapian sanqi-fxTC" v-show="motalShow">
          <div class="as">
            <div class="txtx">
              <div class="txtx_wen">
                <p>分享后方可预约成功！</p>
              </div>
              <div class="txtx_zhuang">
                <a @click="setMake">分享</a>
                <a class="guanbi guanbi_a" @click="close">取消</a>
              </div>
            </div>
          </div>
        </div>
        <!-- 2-1模考题库-报名-未参加模考 -->
        <!-- 推荐课程 -->
        <div v-if="recommendList.length > 0">
          <div class="clearfix mt20">
            <p class="fn_l f22">
              <img class="dis-inb" src="@/assets/img/KC-tbg.png" /> 推荐课程
            </p>
          </div>
          <div class="clearfix mt20">
            <div class="kc-list kc-listBg fn_l" v-for="i in recommendList">
              <a v-if="i.goods.type == 1" @click="toCourseInfo(i)">
                <div class="kc-listTex">
                  <p
                    class="f12 color-main"
                    :style="'color:' + i.goods.relation.subtitle_color"
                  >
                    {{ i.goods.relation.subtitle }}
                  </p>
                  <h3>{{ i.goods.name }}</h3>
                  <p class="f14">
                    {{ i.goods.relation.start_at }}--{{
                      i.goods.relation.end_at
                    }}
                  </p>
                  <!-- <ul class="kc-avatar clearfix">
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋江洋</p>
                </li>
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋</p>
                </li>
                <li>
                  <img src="@/assets/img/KC-avatar.jpg" />
                  <p>江洋</p>
                </li>
              </ul> -->
                  <p
                    class="clearfix f14 color-666"
                    style="position: absolute; bottom: 20px"
                  >
                    <span class="fn_l"
                      >限售<em class="color-main"
                        >{{ i.goods.sale_amount }}人</em
                      >
                    </span>
                    <span class="fn_r"
                      ><em class="f22 color-main"
                        >￥{{ i.goods.order_price }}</em
                      ></span
                    >
                  </p>
                </div>
              </a>
              <a v-if="i.goods.type == 4" @click="toCampInfo(i)">
                <div class=""><img :src="i.goods.image.url" /></div>
                <div class="kc-listTex">
                  <h3>{{ i.goods.name }}</h3>
                  <p
                    class="f12 color-main"
                    :style="'color:' + i.goods.relation.subtitle_color"
                  >
                    {{ i.goods.relation.subtitle }}
                  </p>
                  <p class="clearfix f14 color-666">
                    <span class="fn_l"
                      >限售<em class="color-main"
                        >{{ i.goods.sale_amount }}人</em
                      ></span
                    >
                    <span class="fn_r"
                      ><s class="color-999">￥{{ i.goods.real_price }}</s>
                      <em class="f22 color-main"
                        >￥{{ i.goods.order_price }}</em
                      ></span
                    >
                  </p>
                </div>
              </a>
              <a v-if="i.goods.type == 3" @click="toRadioInfo(i)">
                <p class="dt-listImg">
                  <el-image
                    style="height: 240px; width: 100%"
                    :src="i.goods.image.url"
                    fit="contain"
                  ></el-image>
                  <span v-if="i.goods.relation.audition == 1" class="dt-shiting"
                    ><img src="@/assets/img/DT-shiting.png"
                  /></span>
                </p>
                <p class="tj_title">{{ i.goods.name }}</p>
                <p class="tj_ps f14 color-666">
                  播放量{{ i.goods.relation.playback_volume }}次
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 测评弹窗 -->
    <div class="kapian kapian5 tc_show" v-show="motalShowFx">
      <div class="as">
        <div class="yao_friend in3_yao_friend">
          <div class="yao_share1">
            <div class="yao_share">
              <span>分享</span>
              <div class="yao_share_nr index3_yao_share_nr">
                <ul>
                  <li @click="copyShare">
                    <a>
                      <img src="@/assets/img/yao_share1.png" />
                    </a>
                  </li>
                  <!-- <li>
									<a href="">
										<img src="img/yao_share2.png" />
									</a>
								</li> -->
                  <li @click="copyShare">
                    <a>
                      <img src="@/assets/img/yao_share3.png" />
                    </a>
                  </li>
                  <li @click="copyShare">
                    <a>
                      <img src="@/assets/img/yao_share4.png" />
                    </a>
                  </li>
                  <!-- <li>
									<a href="">
										<img src="img/yao_share5.png" />
									</a>
								</li>
								<li>
									<a href="">
										<img src="img/yao_share6.png" />
									</a>
								</li> -->
                  <li @click="copyShare">
                    <a>
                      <img src="@/assets/img/yao_share7.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="yao_img">
            <img src="@/assets/img/yao.jpg" />
            <div class="yao_wen">
              <div class="yao_wen_l in3_yao_wen_l">
                <h6>我正在参加“{{ title }}”</h6>
              </div>
              <div class="yao_wen_r in3_yao_wen_r">
                <img src="@/assets/img/yao_er.jpg" />
                <p style="font-size: 12px; text-align: center">
                  圣儒教育 · 成公社
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 关闭 -->
        <div class="guanbi">
          <img src="@/assets/img/modal-close.png" @click="close" />
        </div>
      </div>
    </div>
    <Popup ref="popups" :type="type" :answer_order_id="answer_order_id"></Popup>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import { report, exerciseReport, situation, addMake } from "@/api/exam";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import Popup from "./modules/popup";
import SrPaginate from "@/components/SrPaginate";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
    SrPaginate,
    Popup,
  },
  data() {
    return {
      tabIndex: 0,
      motal: false,
      list: [],
      recommendList: [],
      pagination: {
        page: 1,
        page_size: 4,
      },
      listIndex: 0,
      answer_order_id: null,
      detail: {},
      subjectList: [],
      motalShow: false,
      motalShowFx: false,
      title: "",
    };
  },
  mounted() {},
  methods: {
    goExam() {
      this.$router.push({
        path: "/exam",
      });
    },
    loadList() {
      let _this = this;
      report({ ..._this.pagination }).then((res) => {
        console.log(res, "报告");
        _this.list = res.result.items;
        _this.answer_order_id =
          res.result.items[_this.listIndex].answer_order_id;
        _this.title = res.result.items[_this.listIndex].relation.name;
        _this.pagination = res.result.pagination;
        _this.loadExerciseReport();
        _this.loadSituation();
      });
    },
    loadExerciseReport() {
      let _this = this;
      exerciseReport({ answer_order_id: _this.answer_order_id }).then((res) => {
        console.log(res, "报告详情");
        _this.detail = res.result;
        if (_this.detail.report.type == 1) {
          _this.detail.rate = _this.detail.rate.toFixed(2);
        }
        _this.recommendList = res.result.recommend;
      });
    },
    loadSituation() {
      let _this = this;
      situation({ answer_order_id: _this.answer_order_id }).then((res) => {
        console.log(res, "答案");
        _this.subjectList = res.result.paper_subject;
      });
    },
    openMyCourse() {
      this.$router.push({
        path: "/user/center/myCourse",
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
    goAnswers() {
      this.$router.push({
        path: "/answerDetail",
        query: {
          answer_order_id: this.answer_order_id,
        },
      });
    },
    getOrder() {
      this.motalShow = true;
    },
    getDetail(i, index) {
      this.answer_order_id = i.answer_order_id;
      this.listIndex = index;
      this.loadExerciseReport();
      this.loadSituation();
    },
    close() {
      this.motalShow = false;
    },
    openPopup(type) {
      this.type = type;
      this.$refs.popups.show();
    },
    openShare() {
      this.motalShowFx = true;
    },
    close() {
      this.motalShowFx = false;
    },
    copyShare() {
      let _this = this;
      var url = "http://srgongkaow.com/exam";
      _this.$copyText(url).then(() => {
        _this.$message.success("复制链接成功！");
        _this.motalShowFx = false;
      });
    },
    goMyQuestion(index) {
      let _this = this;
      this.$router.push({
        path: "/question/myQuestion",
        query: {
          tabIndex: index,
          title: _this.title,
          courseType: 13,
          id: _this.detail.answerSituation.relation_id,
          types: 13,
        },
      });
    },
    setMake() {
      let _this = this;
      addMake({ answer_order_id: _this.answer_order_id }).then((res) => {
        console.log(res, "分享");
        if (res.status == 200) {
          var url = "http://srgongkaow.com/exam";
          _this.$copyText(url).then(() => {
            _this.$message.success("复制链接成功！");
            _this.motalShow = false;
          });
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    toCampInfo(item) {
      console.log(item);
      this.$router.push({
        path: "/camp/info/" + item.goods.relation.camp_id,
      });
    },
    toCourseInfo(item) {
      console.log(item);

      //前往课程详情页
      this.$router.push({
        path: "/course/info/" + item.goods.relation.course_id,
      });
    },
    toRadioInfo(item) {
      console.log(item);
      this.$router.push({
        path: "/radio/info/" + item.goods.relation.radio_id,
      });
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style scoped>
.kc-list {
  height: 320px;
  margin-right: 20px;
  position: relative;
}
.shenlun {
  width: 100%;
}
.shenlun img {
  width: 100%;
}
.baogao-boxL {
  width: 56%;
}
.baogao_r {
  float: right;
  width: 44%;
}
.yao_share_nr {
  width: auto !important;
}
.tab_right {
  float: right;
  width: 40%;
}
.tab_right p {
  display: inline-block;
  width: 33%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.index3_share_a {
  float: right;
}
</style>
