<template>
  <div>
    <!-- 训练营详情 -->
    <div class="warp mt30">
      <div class="xly-conT clearfix">
        <p class="fn_l f22">
          <img class="dis-inb" src="@/assets/img/KC-tbg.png" />训练营 -
          {{ camp.name }}
        </p>

        <p class="fn_r te_r">
          <img
            class="dis-inb share-btn"
            @click="share"
            src="@/assets/img/xly-fx.png"
          />
          <a v-if="camp.user_unlock_count === 0" @click="buy">立即购买</a>
        </p>
        <!-- <div class="tab_right">
          <p @click="goMyQuestion(0)">我的错题</p>
          <p @click="goMyQuestion(1)">我的收藏</p>
          <p @click="goMyQuestion(2)">练习历史</p>
        </div> -->
      </div>
      <div class="xly-conM mt20">
        <div class="swiper-container xly-conList">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in list"
              :key="item.camp_theme_id"
              class="swiper-slide"
            >
              <div class="kc-list ml0">
                <div class=""><img :src="item.image.url" /></div>
                <div class="kc-listTex">
                  <h3>{{ item.name }}</h3>
                  <p class="clearfix f14 color-666">
                    <span class="fn_l">
                      {{ item.clock_count }}人已打卡 |{{
                        $moment(item.start_at).format("YYYY.MM.DD")
                      }}
                    </span>
                    <!-- 6未解锁 5未开课 4已打卡 2去学习 1试学体验 -->
                    <div v-if="camp.in_order == 0" class="fn_r" style="display:inline-block">
                      <a
                   
                      @click="toStudy(item)"
                      class="fn_r xly-conpay"
                      >去学习</a
                    >
                    </div>
                    <div v-else  class="fn_r" style="display:inline-block">
                       <a
                      v-if="item.zt == 1"
                      @click="toStudy(item)"
                      class="fn_r xly-conpay"
                      >试学体验</a
                    >
                    <a
                      v-if="item.zt == 2"
                      @click="toStudy(item)"
                      class="fn_r xly-conpay"
                      >去学习</a
                    >
                    <a
                      v-if="item.zt == 3"
                      class="fn_r xly-conpay"
                      @click="toStudy(item)"
                      >继续学习</a
                    >
                    <a
                      v-if="item.zt == 4"
                      class="fn_r xly-conpay"
                      @click="toStudy(item)"
                      >已打卡</a
                    >
                    <a v-if="item.zt == 5" class="fn_r xly-conpay">未开课</a>
                    <a v-if="item.zt == 6" class="fn_r xly-conpay">未解锁</a>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next">
          <img src="@/assets/img/index-Rbut.png" />
        </div>
        <div class="swiper-button-prev">
          <img src="@/assets/img/index-Lbut.png" />
        </div>
      </div>
    </div>
    <!-- 完课奖励 -->
    <div class="warp mt30 xly-wkBox">
      <div class="clearfix xly-wkjl">
        <dl class="fn_l">
          <dt>
            <p class="f28">班级群</p>
            <p class="color-999 mt10">加入微信班级群，交流沟通更便捷</p>
          </dt>
          <dd>
            <img :src="camp.class_wechat.url" />
          </dd>
        </dl>
        <dl class="fn_r">
          <dt>
            <p class="f28">完课奖励</p>
            <p class="color-999 mt10">完成课程打卡任务，即可获取奖励</p>
            <p class="f14 color-999 mt10">
              已完成{{ reward.count }}次学习，获得{{
                reward.integral
              }}积分，获得{{ reward.money }}元返现
            </p>
          </dt>
          <dd>
            <!--<img src="@/assets/img/xly-wkjl.jpg">-->
          </dd>
        </dl>
      </div>
      <div class="clearfix mt20" style="display: flex; flex-wrap: wrap">
        <div
          v-for="(item, index) in list"
          :key="item.camp_theme_id"
          :class="[item.user_clock ? 'active' : '']"
          class="xly-wkList"
        >
          <p v-if="item.user_clock" class="f12">
            返现 +{{ item.user_clock.return_money }}
          </p>
          <p v-if="item.user_clock" class="f14">
            积分 +{{ item.user_clock.return_integral }}
          </p>
          <img
            v-if="item.user_clock"
            class="dis-inb"
            src="@/assets/img/xly-wjs.png"
          />
          <img v-else class="dis-inb" src="@/assets/img/xly-wjs.png" />
          <h3>第{{ index + 1 }}课</h3>
        </div>
      </div>
      <div class="mt50 xly-wkjlTex">
        <p class="f22">奖励说明</p>
        <p class="color-666 mt10">
          1. 完成课程打卡任务即可获取奖励；<br />
          2. 完成课程越多，奖励越多；<br />
          3. 打卡退费自动到余额，余额可发起提现；
        </p>
      </div>
    </div>
    <Share ref="Share"></Share>
  </div>
</template>

<script>
import { themeList } from "@/api/camp";
import { detail, reward } from "@/api/camp";
import Share from "./module/Share";

export default {
  name: "index",
  components: {
    Share,
  },
  data() {
    return {
      camp_id: 0,
      goods_id: 0,
      camp: {
        class_wechat: {},
      },
      list: [],
      ci: 0,
      jifen: 0,
      fanxian: 0,
      reward: {},
    };
  },
  created() {
    var _self = this;
    this.camp_id = this.$route.params.camp_id;
    this.goods_id = this.$route.params.goods_id;
    themeList({ camp_id: this.camp_id, page_size: 1000, order_by: "asc" }).then(
      (res) => {
        console.log(res, "课程");
        _self.list = res.result.items;
        //   if (new Date(item.start_at) > new Date()) {
        //     return 5;
        //   }
        //   if (item.user_clock_count > 0) {
        //     return 4;
        //   } 
        //   if (item.user_clock_count === 0 && this.camp.user_unlock_count > 0) {
        //     return 2;
        //   }
        //   if (item.type === 1) {
        //     return 1;
        //   }
        //6未解锁 5未开课 4已打卡 2去学习 1试学体验
        _self.list.map(function (n, index) {
          n.zt = 6;
          if (n.type === 1) {
            n.zt = 1;
          }
          if (new Date(n.start_at) > new Date()) {
            n.zt = 5;
          }
          if (n.user_clock_count > 0) {
            n.zt = 4;
          }

          if (index > 0 && _self.list[index - 1].user_clock_count > 0) {
            n.zt = 2;
          }
          if (index == 0 && index.user_unlock_count != 1) {
            n.zt = 2;
          }
          if (_self.list.length == 1) {
            n.zt = 2;
          }
          // _self.ci += n.user_clock_count;
          // if (n.user_clock) {
          //   _self.jifen += n.return_integral;
          //   _self.fanxian += n.return_money;
          // }
        });
        _self.swiperInit();
      }
    );
    detail({ camp_id: this.camp_id }).then((res) => {
      console.log(res, "训练营详情");
      _self.camp = res.result;
    });
    reward({ camp_id: this.camp_id }).then((res) => {
      _self.reward = res.result[0];
    });
  },
  mounted() {
    console.log("监听");
  },
  filters: {},
  methods: {
    btnStatus(item) {
      if (new Date(item.start_at) > new Date()) {
        return 5;
      }
      if (item.user_clock_count > 0) {
        return 4;
      }
      if (item.user_clock_count === 0 && this.camp.user_unlock_count > 0) {
        return 2;
      }

      if (item.type === 1) {
        return 1;
      }
    },
    swiperInit() {
      let _this = this;
      this.$nextTick(function () {
        setTimeout(() => {
          var swiper = new Swiper(".xly-conList", {
            slidesPerView: 3,
            spaceBetween: 30,
            slidesPerGroup: 3,
            loop: true,
            loopFillGroupWithBlank: true,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        }, 100);
      });
    },
    buy() {
      let routeData = this.$router.resolve({
        path: "/buy/settlement",
        query: { goods_id: this.goods_id },
      });
      window.open(routeData.href, "_blank");
    },
    toStudy(item) {
      this.$router.push({
        path: "/camp/task/" + this.camp_id + "/" + item.camp_theme_id,
      });
    },
    share() {
      this.$refs.Share.show(this.camp);
    },
    goMyQuestion(index) {
      let _this = this;
      this.$router.push({
        path: "/question/myQuestion",
        query: {
          tabIndex: index,
          title: _this.camp.goods.name,
          courseType: 3,
          id: _this.camp.camp_id,
          types: 104,
        },
      });
    },
  },
};
</script>

<style scoped>
.swiper-slide {
  width: 340px;
  margin-right: 30px;
}

.xly-wkList {
  margin-right: 20px;
  min-width: 93px;
  max-width: 93px;
}

.dis-inb {
  margin-top: 10px;
}

h3 {
  color: #999;
}

.active h3 {
  color: #fff;
}

.share-btn {
  cursor: pointer;
  margin-top: 0;
}
.tab_right {
  float: right;
}
.tab_right > p {
  font-size: 14px;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}
</style>
