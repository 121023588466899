import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import '@/utils/auth'
import moment from 'moment'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueCropper from 'vue-cropper'
import md5 from 'js-md5';
Vue.use(VueCropper)
Vue.use(ElementUI)

Vue.use(VueDirectiveImagePreviewer, {
    zIndex: 5000
})

Vue.prototype.$moment = moment;
Vue.prototype.$md5 = md5;
import 'ant-design-vue/dist/antd.css'
import 'ant-design-vue/dist/antd.less'
import Antd from 'ant-design-vue'
import '@/assets/css/css.css'
import '@/assets/css/theme.less'
import theConfirm from '@/components/SrMessage/index'
import VueClipboard from 'vue-clipboard2'
Vue.prototype.$srmsg = theConfirm;
Vue.use(Antd)
Vue.use(VueClipboard)
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
