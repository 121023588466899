<template>
    <div class="warp mt30">
        <h3 class="kc-tuijian f26"><img src="@/assets/img/KC-tbg.png">收货地址</h3>
        <div style="cursor: pointer"
             @click="$refs.ShippingAddressSelect.show()"
             class="cart-list dis-table mt20">
            <div class="dis-tablecell"
                 style="width: 40px">
                <img src="@/assets/img/add.png">
            </div>
            <div class="dis-tablecell" style="width:60%"
            >
                <span v-if="!address.mobile">
                    选择收货地址
                </span>
                <span v-else>
                    {{address.address}}-{{address.name}}
                </span>
            </div>
            <div class="dis-tablecell te_r">
                <img class="dis-inb"
                     src="@/assets/img/list-more.png"/>
            </div>
        </div>
        <ShippingAddressSelect ref="ShippingAddressSelect"
                               @change="addressChange"></ShippingAddressSelect>
    </div>
</template>

<script>
    import ShippingAddressSelect from '@/components/ShippingAddressSelect'

    export default {
        name: "ShippingAddress",
        components: {
            ShippingAddressSelect
        },
        data() {
            return {
                address: {}
            }
        },
        methods: {
            addressChange(item) {
                this.address = item
                this.$emit('change', item.shipping_address_id)
            }
        }

    }
</script>

<style scoped>
</style>
