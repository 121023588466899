<template>
    <div>
        <!-- 视频播放 -->
        <div class="warp clearfix mt50 kcxq-box">
            <h3 class="f26">攻破重难点之生活常识 —— 公基教材第六章</h3>
            <div class="clearfix mt20">
                <div class="kc-video fn_l">
                    <BjZhibo ref="BjZhibo"></BjZhibo>
                </div>
                <div class="kc-videoList fn_r">
                    <div class="tab-wrapper">
                        <ul class="tab-menu">
                            <li @click="tab_active=1"
                                :class="[tab_active===1?'active':'']">聊天
                            </li>
                            <li @click="tab_active=2"
                                :class="[tab_active===2?'active':'']">课程大纲
                            </li>
                        </ul>
                        <div class="tab-content">
                            <ChatRoom v-if="tab_active===1"
                                      ref="ChatRoom"></ChatRoom>
                            <CourseChapter v-if="tab_active===2"
                                           :list="course.course_chapter"
                                           type="small"></CourseChapter>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- 推荐课程 -->
        <ReCourseBig></ReCourseBig>
    </div>
</template>

<script>
    import {zhibo, info} from "@/api/school/course"
    import BjZhibo from '@/components/BjZhibo'
    import ChatRoom from './modules/ChatRoom'
    import CourseChapter from '../detail/modules/CourseChapter'
    import ReCourseBig from '@/layout/recommend/ReCourseBig'

    export default {
        name: "index",
        components: {
            BjZhibo,
            ChatRoom,
            CourseChapter,
            ReCourseBig
        },
        data() {
            return {
                loading: false,
                tab_active: 1,
                zhibo: {},
                course: {
                    course_chapter: []
                }
            }
        },
        created() {
            var _self = this
            _self.loading = true
            var course_subchapter_id = this.$route.query.course_subchapter_id
            var course_id = this.$route.query.course_id
            if (!course_subchapter_id || !course_id) {
                _self.noVideo()
            }
            info({course_id: course_id}).then((res) => {
                if (res.status === 200) {
                    _self.course = res.result
                    zhibo({course_subchapter_id: course_subchapter_id}).then((video_res) => {
                        if (video_res.status === 200) {
                            _self.zhibo = video_res.result
                            _self.loading = false
                            var config = _self.zhibo
                            config.customCoverImg = res.result.first_cover.url
                            _self.$refs.BjZhibo.init(config)
                            _self.$refs.ChatRoom.init(config)

                        } else {
                            _self.noVideo()
                        }
                    })
                } else {
                    _self.noVideo()
                }
            })
        },
        methods: {
            noVideo() {
                this.$srmsg.info({
                    title: '提示',
                    content: '直播不存在'
                }).then(() => {
                    this.$router.go(-1)
                })
            }
        }
    }
</script>

<style scoped>

</style>
