<template>
    <div id="box">
        <span id="bg"
              :class="[active===0?'bg1':'bg2']">
        </span>
        <span class="item"
              :class="[active===index?'active':'']"
              @click="selectItem(index)"
              v-for="(item,index) in list"
              :style="{left:(index*48)+'px'}">
            {{item}}
        </span>
    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            list: {}
        },
        data() {
            return {
                active: 0
            }
        },
        methods: {
            selectItem(index) {
                this.active = index
                this.$emit('change', index)
            }
        }

    }
</script>

<style scoped>
    #box {
        display: inline-block;
        position: relative;
        height: 30px;
        line-height: 30px;
        background: #3561C7;
        border-radius: 15px 15px;
        font-size: 14px;
        width: 96px;
    }

    #bg {
        top: 1px;
        height: 28px;
        width: 50%;
        border-radius: 14px 14px;
        background-color: #fff;
        position: absolute;
        transition: all .2s;
        /*z-index: 5;*/
    }

    .item {
        width: 48px;
        padding: 0 10px;
        box-sizing: border-box;
        position: absolute;
        cursor: pointer;
        color: #fff;
    }

    .active {
        color: #3561C7;
    }

    .bg1 {
        left: 1px;
    }

    .bg2 {
        left: 47px;
    }
</style>
