<template>
  <div>
    <SrNav></SrNav>
    <!-- 课程 -->
    <Pintuan v-if="detail.goods.notend_pintuan" :course="detail"></Pintuan>
    <div v-else>
      <div class="warp clearfix mt50 kcxq-box">
        <div class="kcxq-l fn_l">
          <img :src="detail.image.url" />
        </div>
        <div class="kcxq-r fn_r mall2_data">
          <h3 class="mt10">
            {{ detail.goods.name }}
          </h3>
          <p class="mall2_data_wen">
            {{ detail.description }}
          </p>
          <!-- <p class="f12 color-main">9.9体验课</p> -->
          <div class="mall2_cheng_wen" v-if="detail.sku.length > 0">
            <span>规格：</span>
            <div class="mall2_cheng_a">
              <a
                :class="cheng_on == index ? 'cheng_on' : ''"
                v-for="(i, index) in detail.sku"
                @click="getSku(i, index)"
                >{{ i.name }}</a
              >
            </div>
          </div>
          <div class="mall2_cheng1_wen" v-if="detail.sku">
            <span>数量：</span>
            <div class="mall2_cheng1_wenzi">
              <input type="number" v-model="sku_num" @input="skuNum" min="1" />
              <i>套</i>
            </div>
          </div>
          <p class="" style="display: inline-block; margin-top: 10px">
            <span class="color-999">
              已购买{{ detail.goods.sale_amount }}人</span
            >
          </p>
          <div style="display: inline-block">
            <img
              v-if="detail.goods.notend_seckill"
              src="@/assets/img/KC-time.png"
            />
            <span class="color-pink" v-if="detail.goods.notend_seckill">
              距结束：<a-statistic-countdown
                :value="detail.goods.notend_seckill.end_at"
                format="D 天 H 时 m 分 s 秒"
              />
            </span>
          </div>
          <p class="clearfix mt10">
            <span class="fn_l f26 color-main">￥{{ detail.order_price }}</span>
            <s class="hx">￥{{ detail.goods.real_price }}</s>
          </p>
          <div class="mall2_data2">
            <p
              class="mall2_wen4"
              style="margin-top: 10px"
              v-if="detail.goods.most_credit"
            >
              最多可使用{{ detail.goods.most_credit }}积分
            </p>
          </div>
          <div class="mall2_data_wen_a" v-if="detail.goods.notend_seckill">
            <a
              class="fn_r bg-main color-fff"
              v-if="detail.user_unlock.length == 0"
              @click="seckill"
              >立即秒杀</a
            >
          </div>
          <div v-else class="mall2_data_wen_a">
            <a
              class="fn_r bg-main color-fff"
              @click="buy"
              v-if="detail.user_unlock.length == 0"
              >立即购买</a
            >
            <!-- 状态2 -->
            <!-- <a class="fn_r bg-main color-fff" href="课程-课程详情-进入课程.html">进入课程</a> -->
            <!-- 状态3 -->
            <!-- <a class="fn_r bg-999 border-none color-fff" href="">报名结束</a> -->
            <!-- 状态4 -->
            <!-- <a class="fn_r bg-999 border-none color-fff" href="">人员已满</a> -->
            <SrButton
              @click="addCart"
              style="width: 130px; display: inline-block; float: right"
              height="42px"
              padding="0"
              font-size="14px"
              v-if="detail.user_unlock.length == 0"
              :loading="cart_btn_loading"
              type="hollow"
              >加入购物车
            </SrButton>
          </div>
        </div>
      </div>
    </div>

    <div class="warp clearfix mt30">
      <div class="kcxq-con kcxq-con1">
        <div class="tab-wrapper">
          <ul class="tab-menu mall2_tab1">
            <li :class="typeIndex == 0 ? 'active' : ''" @click="getTap(0)">
              商品介绍
            </li>
            <li :class="typeIndex == 1 ? 'active' : ''" @click="getTap(1)">
              商品评价
            </li>
            <li :class="typeIndex == 2 ? 'active' : ''" @click="getTap(2)">
              图书目录
            </li>
          </ul>
          <div class="tab-content">
            <div class="mt20 kc-js" v-if="typeIndex == 0">
              <div v-html="detail.introduce"></div>
            </div>
            <div v-if="typeIndex == 1">
              <div class="clearfix mt20">
                <p class="fn_l">
                  已有<span class="color-pink">{{ comment.length }}</span>
                  次留言
                </p>
                <p class="fn_r xly-px">
                  <span>推荐</span>
                  <span class="active">时间</span>
                </p>
              </div>
              <dl class="kc-evaluate-list clearfix" v-for="i in comment">
                <dt class="fn_l">
                  <img class="tx" :src="i.user.head_portrait.url" />
                </dt>
                <dd class="fn_r">
                  <div class="">
                    <h3>
                      {{ i.user.nickname }}
                      <img
                        :src="
                          i.user.now_user_member.user_member_config_id == 1
                            ? '/img/w1.png'
                            : i.user.now_user_member.user_member_config_id == 2
                            ? '/img/w3.png'
                            : i.user.now_user_member.user_member_config_id == 3
                            ? '/img/w4.png'
                            : i.user.now_user_member.user_member_config_id == 4
                            ? '/img/w5.png'
                            : i.user.now_user_member.user_member_config_id == 5
                            ? '/img/w6.png'
                            : '/img/w6.png'
                        "
                      />
                      <span class="xly-hfBut fn_r"
                        ><img src="@/assets/img/xly-pl.png"
                      /></span>
                      <span
                        :class="i.is_like == 0 ? 'kc-like' : 'kc-like2'"
                        @click="is_like(i.wares_comment_id, i.is_like)"
                        >{{ i.likes }}</span
                      >
                    </h3>
                    <p class="color-666">
                      {{ i.content }}
                    </p>
                    <div class="clearfix">
                      <div
                        class="fn_l kc-evaluate-listImg"
                        v-for="item in i.image"
                      >
                        <img :src="item.url" />
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
            <div v-if="typeIndex == 2">
              <ul class="store_list">
                <li v-for="i in detail.file">
                  <p>{{ i.file_name }}</p>
                  <span
                    v-if="detail.user_unlock.length > 0 && detail.user_unlock"
                    @click="openBook(i.url)"
                    >查看</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import { detail, commentList, like } from "@/api/store";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import SrButton from "@/components/SrButton";
import { add } from "@/api/cart";
import Pintuan from "./modules/Pintuan";
export default {
  name: "index",
  components: {
    SrNav,
    SrFooter,
    SrButton,
    Pintuan,
  },
  data() {
    return {
      detail: {},
      typeIndex: 0,
      cheng_on: 0,
      vip_price: "",
      sku_num: 1,
      comment: [],
      cart_btn_loading: false,
      sku_id: "",
      sales_restriction: 0,
    };
  },
  methods: {
    loadDetail() {
      let _this = this;
      detail({
        wares_id: _this.$route.query.wares_id,
      }).then((res) => {
        console.log(res, "详情");
        _this.detail = res.result;
        if (res.result.sku.length > 0) {
          _this.sku_id = res.result.sku[0].sku_id;
          _this.sales_restriction = res.result.sku[0].sales_restriction;
        }
        var userInfo = _this.$store.state.user;
        if (_this.detail.sku.length > 0) {
          if (userInfo.now_user_member) {
            _this.detail.sku[0].goods_sku_price.map(function (s) {
              if (
                s.user_member_config_id ==
                userInfo.now_user_member.user_member_config_id
              ) {
                _this.detail.order_price = s.real_price;
                _this.detail.jisuan = s.real_price;
              }
            });
          } else {
            _this.detail.order_price =
              _this.detail.sku[0].goods_sku_price[5].real_price;
            _this.detail.jisuan =
              _this.detail.sku[0].goods_sku_price[5].real_price;
          }
        } else {
          _this.detail.order_price = _this.detail.goods.order_price;
          _this.detail.jisuan = _this.detail.goods.order_price;
        }
      });
    },
    getTap(index) {
      this.typeIndex = index;
    },
    skuNum(e) {
      console.log(e, "----e");
      console.log(this.detail.jisuan);
      if (e.target.value == "") {
        this.sku_num = 1;
        this.detail.order_price = 1 * this.detail.jisuan;
      } else {
        this.detail.order_price =
          parseFloat(e.target.value) * this.detail.jisuan;
      }
    },
    is_like(wares_comment_id, status) {
      let _this = this;
      var dian;
      if (status == 0) {
        dian = 1;
      } else {
        dian = 2;
      }
      like({
        wares_comment_id: wares_comment_id,
        status: dian,
      }).then((res) => {
        console.log(res, "点赞");
        if (res.status == 200) {
          _this.loadCommentList();
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    addCart() {
      let _this = this;
      var goods = _this.detail.goods;
      if (this.detail.sku.length > 0) {
        if (this.sku_id == "") {
          _this.$message.warning("请选择规格");
        } else {
          _this.cart_btn_loading = true;
          add({
            goods_id: _this.detail.goods.goods_id,
            sku_id: _this.sku_id,
            num: _this.sku_num,
          }).then((res) => {
            console.log(res, "加入购物车");
            _this.cart_btn_loading = false;
            if (res.status === 200) {
              _this.$store.commit("ADD_CART", goods);
              _this.$message.success("添加成功");
            } else {
              _this.$srmsg.info({
                title: "提示",
                content: res.msg,
              });
            }
          });
        }
      } else {
        _this.cart_btn_loading = true;
        add({
          goods_id: _this.detail.goods.goods_id,
          sku_id: _this.sku_id,
          num: _this.sku_num,
        }).then((res) => {
          console.log(res, "加入购物车");
          _this.cart_btn_loading = false;
          if (res.status === 200) {
            _this.$store.commit("ADD_CART", goods);
            _this.$message.success("添加成功");
          } else {
            _this.$srmsg.info({
              title: "提示",
              content: res.msg,
            });
          }
        });
      }
    },
    getSku(i, index) {
      console.log(i);
      let _this = this;
      // 计算选中sku后的会员价格
      var data = i.goods_sku_price;
      this.sku_id = i.sku_id;
      if (this.$store.state.user.now_user_member) {
        data.map(function (n) {
          if (
            n.user_member_config_id ==
            _this.$store.state.user.now_user_member.user_member_config
              .user_member_config_id
          ) {
            _this.detail.order_price = n.real_price;
          }
        });
      } else {
        _this.detail.order_price = data[5].real_price;
      }
      console.log(_this.detail.order_price);
      this.cheng_on = index;
    },
    seckill() {
      var seckill = this.detail.goods.notend_seckill;
      if (this.detail.sku.length > 0) {
        if (this.sku_id == "") {
          this.$message.warning("请选择规格");
        } else {
          let routeData = this.$router.resolve({
            path: "/buy/seckill",
            query: {
              goods_id: seckill.goods.goods_id,
              seckill_id: seckill.seckill_id,
              sku_id: this.sku_id,
              num: this.sku_num,
            },
          });
          window.open(routeData.href, "_blank");
        }
      } else {
        let routeData = this.$router.resolve({
          path: "/buy/seckill",
          query: {
            goods_id: seckill.goods.goods_id,
            seckill_id: seckill.seckill_id,
            sku_id: this.sku_id,
            num: this.sku_num,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    loadCommentList() {
      let _this = this;
      commentList({
        wares_id: _this.$route.query.wares_id,
      }).then((res) => {
        console.log(res, "评论");
        _this.comment = res.result.items;
      });
    },
    openBook(url) {
      let routeData = this.$router.resolve({
        path: "/store/detail/pdf",
        query: {
          url: url,
        },
      });
      window.open(routeData.href, "_blank");
    },
    buy() {
      let _this = this;
      if (this.detail.sku.length > 0) {
        if (this.sku_id == "") {
          _this.$message.warning("请选择规格");
        } else {
          let routeData = this.$router.resolve({
            path: "/buy/settlement",
            query: {
              goods_id: this.detail.goods.goods_id,
              sku_id: _this.sku_id,
              num: _this.sku_num,
            },
          });
          window.open(routeData.href, "_blank");
        }
      } else {
        let routeData = this.$router.resolve({
          path: "/buy/settlement",
          query: {
            goods_id: this.detail.goods.goods_id,
            sku_id: _this.sku_id,
            num: _this.sku_num,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");

      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符

      return relUrl;
    },
  },
  created() {
    if (storage.getItem("ACCESS_TOKEN")) {
      this.loadDetail();
      this.loadCommentList();
    } else {
      var url = this.GetUrlRelativePath();
      storage.setItem('LOGIN_SKIP', url)
      this.$router.push("/login?action=login");
    }

    console.log(this.$store.state.user);
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.mt50 {
  margin-top: 30px;
}
.color-pink >>> .ant-statistic {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}

.seckill-countdown >>> .ant-statistic {
  display: inline-block;
}

.seckill-countdown >>> .ant-statistic-content {
  display: inline-block;
  font-size: 14px;
}

.seckill-countdown >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #fff;
}
.cheng_on {
  color: #3561c7 !important;
  border: 1px solid #3561c7 !important;
}
.mall2_cheng1_wenzi input {
  background: #3561c7;
  border: none;
  width: 65px;
  height: 26px;
  color: #ffffff;
  text-align: center;
}
.mall2_data .mall2_cheng_a a {
  margin-left: 0;
  margin-right: 20px;
}
.tx {
  height: 44px !important;
  width: 44px !important;
}
.kc-like2 {
  cursor: pointer;
  float: right;
  height: 21px;
  background: url(../../../assets/img/praise-hover.png) no-repeat left;
  padding-left: 28px;
  font-size: 16px;
  color: #999999;
}
.hx {
  display: inline-block;
  color: #999999;
  margin-left: 10px;
  margin-top: 4px;
}
</style>