<template>
  <div>
    <div class="set set7">
      <div class="set7_p">
        <p>请输入{{ mobile }}收到的短信验证码</p>
      </div>
      <div class="set7_nr">
        <ul>
          <li style="position: relative">
            <div class="ss_yzm1">
              <img src="@/assets/img/suo.png" />
              <input
                placeholder="请输入验证码"
                v-model="passwordCode"
                class="ss_set7"
              />
            </div>
            <SrButton
              :disabled="sms_time !== 0"
              @click="
                sendMessage(
                  $store.state.user.mobile,
                  'CodePasswordCode'
                )
              "
              class="login-hqyzm"
              height="33px"
              padding="0 20px"
              font-size="14px"
              :loading="sms_loading"
            >
              <span v-if="sms_time === 0">获取验证码</span>
              <span v-else>重新发送({{ sms_time }})</span>
            </SrButton>
          </li>
          <li>
            <div class="ss_yzm1 set2">
              <img src="@/assets/img/suo.png" />
              <input
                placeholder="请输入密码"
                v-model="newPassword"
                type="password"
                class="ss_set7"
              />
            </div>
            <!-- <div class="kai">
                          <img src="@/assets/img/kai.png" />
                        </div> -->
          </li>
        </ul>
      </div>
      <div class="set7_a">
        <a href="">验证码有问题？</a>
      </div>
      <div class="set7_a1 set7_a2">
        <SrButton
          style="width: 100%"
          @click="setNewPassword"
          font-size="14px"
          height="33px"
          padding="0 20px"
          :loading="setNewPassword_loading"
        >
          提 交
        </SrButton>
      </div>
    </div>
  </div>
</template>
<script>
var timer = null;
import SrButton from "@/components/SrButton";
import storage from "@/utils/storage";
import { codePassword } from "@/api/user";
import { sendMessage } from "@/api/common";
export default {
  name: "Pintuan",
  components: {
    SrButton,
  },
  props: {
    course: {},
  },
  data() {
    return {
      setNewPassword_loading: false,
      editUserPassword_loading: false,
      sms_time: 0,
      sms_loading: false,
      mobile: "",
      // 修改手机状态页面 1旧 2新
      mobileType: 1,
      passwordCode: "",
      newPassword: "",
      has_password: null,
      old: "",
      news: "",
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  mounted() {
    var _self = this;
    var sms_time = storage.getItem("sms_time");
    var phone = this.$store.state.user.mobile;
    this.mobile = phone.substring(0, 3) + "****" + phone.substring(7, 11);
    this.has_password = this.$store.state.user.has_password;
    console.log(this.has_password, "密码");
    if (sms_time > 0) {
      _self.sms_time = sms_time;
      timer = setInterval(function () {
        _self.sms_time--;
        storage.setItem("sms_time", _self.sms_time);
        if (_self.sms_time <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  },
  methods: {
    // 发送验证码
    sendMessage(phone, scenario) {
      console.log(phone);
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      var form = new Object();
      form.phone = phone;
      form.scenario = scenario;
      _self.sms_loading = true;
      sendMessage(form).then((res) => {
        if (res.status === 200) {
          _self.sms_loading = false;
          _self.sms_time = 60;
          timer = setInterval(function () {
            _self.sms_time--;
            storage.setItem("sms_time", _self.sms_time);
            if (_self.sms_time <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          _self.$message.warning(res.msg);
          _self.sms_loading = false;
        }
      });
    },
    // 未设置密码
    setNewPassword() {
      let _this = this;
      if (_this.passwordCode == "") {
        _this.$message.warning("请输入验证码");
        return;
      }
      if (_this.newPassword == "") {
        _this.$message.warning("请输入密码");
        return;
      }
      _this.setNewPassword_loading = true;
      codePassword({
        phone: _this.$store.state.user.mobile,
        code: _this.passwordCode,
        password: _this.newPassword,
      }).then((res) => {
        if (res.status === 200) {
          _this.$message.success("修改成功");
          _this.sms_loading = false;
          storage.setItem("sms_time", 0);
          _this.sms_time = 0;
          setTimeout(() => {
            _this.$router.go(0);
          }, 1000);
          _this.setNewPassword_loading = false;
        } else {
          _this.$message.warning(res.msg);
          _this.setNewPassword_loading = false;
        }
      });
    },
    // 已设置密码
    editUserPassword() {
      let _this = this;
      _this.editUserPassword_loading = true;
      editUserPassword({ old: _this.old, new: _this.news }).then((res) => {
        if (res.status === 200) {
          _this.$message.success("修改成功");
          setTimeout(() => {
            _this.$router.go(0);
          }, 1000);
          _this.editUserPassword_loading = false;
        } else {
          _this.$message.warning(res.msg);
          _this.editUserPassword_loading = false;
        }
      });
    },
  },
};
</script>