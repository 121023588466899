<template>
  <div>
    <!-- 分类 -->
    <CourseTag @change="courseTypeChange"></CourseTag>
    <div class="warp clearfix mt30">
      <a-spin :spinning="loading">
        <a-icon slot="indicator" type="loading" style="font-size: 30px" spin />
        <div
          v-if="loading && list.length == 0"
          style="width: 100%; height: 200px"
        ></div>
        <div v-else>
          <div>
            <div v-if="type == 't'">
              <div v-for="(item, index) in topList">
                <div class="kc-list kc-listBg fn_l">
                  <span v-if="item.goods.notend_seckill" class="kc-listBq">
                    <img src="@/assets/img/miao_logo1.png" />
                  </span>
                  <span v-if="item.goods.notend_pintuan" class="kc-listBq">
                    <img src="@/assets/img/pin_logo1.png" />
                  </span>
                  <a @click="toCourseDetail(item)">
                    <div class="kc-listTex">
                      <p
                        class="f12 color-main"
                        :style="{ color: item.goods.relation.subtitle_color }"
                      >
                        {{ item.goods.relation.subtitle }}
                      </p>
                      <h3 style="margin-top: 10px">
                        {{ item.goods.relation.name }}
                      </h3>
                      <ul class="kc-avatar clearfix">
                        <li
                          v-for="(teacher_item, teacher_index) in item.goods
                            .relation.teacher"
                          v-if="teacher_index < 3"
                        >
                          <img
                            width="66px"
                            height="66px"
                            :src="teacher_item.avatar.url"
                          />
                          <p>{{ teacher_item.name }}</p>
                        </li>
                      </ul>
                      <p
                        v-if="item.goods.type !=2"
                        class="clearfix f14 color-666 dwss"
                        style="margin-top: 50px"
                      >
                          <!-- 已售空 -->
                        <span
                          v-if="
                            item.goods.sales_restriction ==
                            item.goods.sale_amount
                          "
                          ><em class="color-pink"></em></span
                        >
                        <span class="fn_l" v-else
                          >限售<em class="color-main"
                            >{{ item.goods.sales_restriction }}人</em
                          >
                          | 已报名<em class="color-pink"
                            >{{ item.goods.sale_amount }}人</em
                          ></span
                        >
                        <span class="fn_r">
                          <span
                            v-if="
                              item.goods.order_price !== item.goods.real_price
                            "
                          >
                            <s class="color-999" v-if="item.real_price"
                              >￥{{ item.real_price }}</s
                            >
                            <em class="f22 color-main"
                              >￥{{ item.order_price }}</em
                            >
                          </span>
                          <span v-else>
                            <s class="color-999" v-if="item.real_price"
                              >￥{{ item.real_price }}</s
                            >
                            <em class="f22 color-main"
                              >￥{{ item.order_price }}</em
                            >
                          </span>
                        </span>
                      </p>
                      <p
                        v-if="item.goods.type ==2"
                        class="clearfix f14 color-666 dwss"
                        style="margin-top: 50px"
                      >
                          <!-- 已售空 -->
                        <span
                          v-if="
                            item.goods.sales_restriction ==
                            item.goods.sale_amount
                          "
                          ><em class="color-pink"></em></span
                        >
                        <span class="fn_l" v-else
                          >限售<em class="color-main"
                            >{{ item.goods.sales_restriction }}人</em
                          >
                          | 已报名<em class="color-pink"
                            >{{ item.goods.sale_amount }}人</em
                          ></span
                        >
                        <span class="fn_r">
                          <span>
                            <em class="f22 color-main"
                              >￥{{ item.goods.orderprice?item.goods.orderprice:item.goods.order_price }}</em
                            >
                          </span>
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div v-if="type == 'l'">
              <template v-for="(item, index) in list">
                <div class="kc-list kc-listBg fn_l" v-if="item.type === 1">
                  <span v-if="item.course.vip_free === 1" class="kc-listBq">
                    <img src="@/assets/img/KC-hymf.png" />
                  </span>
                  <span v-else>
                    <!-- <span
                  v-if="item.course.goods.agreement_class === 1"
                  class="kc-listBq"
                >
                  <img src="@/assets/img/KC-xyb.png" />
                </span> -->
                    <span
                      v-if="item.course.goods.notend_seckill"
                      class="kc-listBq"
                    >
                      <img src="@/assets/img/miao_logo1.png" />
                    </span>
                    <span
                      v-if="item.course.goods.notend_pintuan"
                      class="kc-listBq"
                    >
                      <img src="@/assets/img/pin_logo1.png" />
                    </span>
                  </span>
                  <a @click="toCourseInfo(item)">
                    <div class="kc-listTex">
                      <p
                        class="f12 color-main"
                        :style="{ color: item.course.subtitle_color }"
                      >
                        {{ item.course.subtitle }}
                      </p>
                      <h3 style="margin-top: 10px">{{ item.course.name }}</h3>
                      <!-- <p class="f14"
                                       style="color: #000">
                                        {{$moment(item.course.start_at).format("YYYY.MM.DD")}}--{{$moment(item.course.end_at).format("YYYY.MM.DD")}}</p> -->
                      <ul class="kc-avatar clearfix">
                        <li
                          v-for="(teacher_item, teacher_index) in item.course
                            .teacher"
                          v-if="teacher_index < 3"
                        >
                          <img
                            width="66px"
                            height="66px"
                            :src="teacher_item.avatar.url"
                          />
                          <p>{{ teacher_item.name }}</p>
                        </li>
                      </ul>
                      <p
                        class="clearfix f14 color-666 dwss"
                        style="margin-top: 50px"
                      >
                        <span
                          v-if="
                            item.course.goods.sales_restriction ==
                            item.course.goods.sale_amount
                          "
                          ><em class="color-pink">已售空</em></span
                        >
                        <span class="fn_l" v-else
                          >限售<em class="color-main"
                            >{{ item.course.goods.sales_restriction }}人</em
                          >
                          | 已报名<em class="color-pink"
                            >{{ item.course.goods.sale_amount }}人</em
                          ></span
                        >
                        <span class="fn_r">
                          <!--判断有无优惠-->
                          <span
                            v-if="
                              item.course.goods.order_price !==
                              item.course.goods.real_price
                            "
                          >
                            <s class="color-999" v-if="item.real_price"
                              >￥{{ item.real_price }}</s
                            >
                            <em class="f22 color-main"
                              >￥{{ item.order_price }}</em
                            >
                          </span>
                          <span v-else>
                            <s class="color-999" v-if="item.real_price"
                              >￥{{ item.real_price }}</s
                            >
                            <em class="f22 color-main"
                              >￥{{ item.order_price }}</em
                            >
                          </span>
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
                <div class="kc-list kc-listBg fn_l" v-if="item.type === 2">
                  <a @click="toCoursePackageInfo(item)">
                    <div class="">
                      <img
                        style="min-width: 100%"
                        :src="item.course_package.first_cover.url"
                      />
                    </div>
                    <div class="kc-listTex">
                      <h3>{{ item.course_package.name }}</h3>
                      <p
                        class="f12 color-main"
                        :style="{ color: item.course_package.subtitle_color }"
                      >
                        {{ item.course_package.subtitle }}
                      </p>
                      <p class="clearfix f14 color-666 dwss">
                        <!-- <span
                      v-if="
                        item.course_package.goods.sales_restriction ==
                        item.course_package.goods.sale_amount
                      "
                      ><em class="color-pink">已售空</em></span
                    >
                    <span class="fn_l"
                      >限售<em class="color-main">{{
                        item.course_package.goods.sales_restriction
                      }}</em>
                      | 已报名<em class="color-pink">{{
                        item.course_package.goods.sale_amount
                      }}</em></span
                    > -->
                        <span class="fn_r">
                          <!--判断有无优惠-->
                      
                          <span
                            v-if="
                              item.course_package.goods.order_price !==
                              item.course_package.goods.real_price
                            "
                          >
                            <em class="f22 color-main"
                              >￥{{item.course_package.goods.orderprice?item.course_package.goods.orderprice: item.order_price }}</em
                            >
                          </span>
                          <span v-else>
                            <em class="f22 color-main"
                              >￥{{ item.course_package.goods.orderprice?item.course_package.goods.orderprice:item.order_price }}</em
                            >
                          </span>
                          
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
                <!-- 训练营 -->
                <div class="kc-list kc-listBg fn_l" v-if="item.type === 3">
                  <span v-if="item.camp.vip_free === 1" class="kc-listBq">
                    <img src="@/assets/img/KC-hymf.png" />
                  </span>

                  <span v-else>
                    <!-- <span
                  v-if="item.course.goods.agreement_class === 1"
                  class="kc-listBq"
                >
                  <img src="@/assets/img/KC-xyb.png" />
                </span> -->
                    <span
                      v-if="item.camp.goods.notend_seckill"
                      class="kc-listBq"
                    >
                      <img src="@/assets/img/miao_logo1.png" />
                    </span>
                    <span
                      v-if="item.camp.goods.notend_pintuan"
                      class="kc-listBq"
                    >
                      <img src="@/assets/img/pin_logo1.png" />
                    </span>
                  </span>
                  <span class="kc-listBq">
                    <img src="@/assets/img/wx-camp.png" />
                  </span>
                  <a @click="toCampInfo(item)">
                    <div class="kc-listTex">
                      <p
                        class="f12 color-main"
                        :style="{ color: item.camp.subtitle_color }"
                      >
                        {{ item.camp.subtitle }}
                      </p>
                      <h3 style="margin-top: 10px">{{ item.camp.name }}</h3>
                      <!-- <p class="f14"
                                       style="color: #000">
                                        {{$moment(item.course.start_at).format("YYYY.MM.DD")}}--{{$moment(item.course.end_at).format("YYYY.MM.DD")}}</p> -->
                      <ul class="kc-avatar clearfix">
                        <li
                          v-for="(teacher_item, teacher_index) in item.camp
                            .teacher"
                          v-if="teacher_index < 3"
                        >
                          <img
                            width="66px"
                            height="66px"
                            :src="teacher_item.avatar.url"
                          />
                          <p>{{ teacher_item.name }}</p>
                        </li>
                      </ul>
                      <p
                        class="clearfix f14 color-666 dwss"
                        style="margin-top: 50px"
                      >
                        <span
                          v-if="
                            item.camp.goods.sales_restriction ==
                            item.camp.goods.sale_amount
                          "
                          ><em class="color-pink">已售空</em></span
                        >
                        <span class="fn_l" v-else
                          >限售<em class="color-main"
                            >{{ item.camp.goods.sales_restriction }}人</em
                          >
                          | 已报名<em class="color-pink"
                            >{{ item.camp.goods.sale_amount }}人</em
                          ></span
                        >
                        <span class="fn_r">
                          <!--判断有无优惠-->
                          <span
                            v-if="
                              item.camp.goods.order_price !==
                              item.camp.goods.real_price
                            "
                          >
                            <s class="color-999" v-if="item.real_price"
                              >￥{{ item.real_price }}</s
                            >
                            <em class="f22 color-main"
                              >￥{{ item.order_price }}</em
                            >
                          </span>
                          <span v-else>
                            <s class="color-999" v-if="item.real_price"
                              >￥{{ item.real_price }}</s
                            >
                            <em class="f22 color-main"
                              >￥{{ item.order_price }}</em
                            >
                          </span>
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
    <SrPaginate
      style="margin: 0 auto; text-align: center; margin-top: 50px"
      :current="pagination.current_page"
      :total="pagination.total"
      :pageSize="pagination.page_size"
      @change="changePage"
    ></SrPaginate>
  </div>
</template>

<script>
import storage from "@/utils/storage";
import SrNav from "@/layout/SrNav";
import CourseTag from "./modules/CourseTag";
import { list, recommend_special } from "@/api/school/course_type_item";
import SrPaginate from "@/components/SrPaginate";

export default {
  name: "index",
  components: {
    SrNav,
    CourseTag,
    SrPaginate,
  },
  data() {
    return {
      loading: true,
      query: {
        course_type_id: 0,
      },
      list: [],
      topList: [],
      pagination: {
        page: 0,
        page_size: 9,
        page: 1,
      },
      course_category_id: null,
      type: "t",
    };
  },
  mounted() {},
  methods: {
    courseTypeChange(item, type) {
      // 班型变化
      console.log(item, type, "班型变化");
      this.type = type;
      if (type == "t") {
        this.course_category_id = item.course_category_id;
        this.pagination.page = 1;
        this.recommend_special();
      } else {
        if (item) {
          this.course_category_id = item.course_category_id;
          this.query.course_type_id = item.course_type_id;
          this.pagination.page = 1;
          this.getData();
        } else {
          this.list = [];
          console.log(11111);
        }
      }
    },
    getData() {
      var _self = this;
      _self.loading = true;
      list({
        course_type_id: _self.query.course_type_id,
        ..._self.pagination,
      }).then((res) => {
        console.log(res, "------课程列表");
        var data = res.result.items;
        console.log(this.$store.state.user);
        // if (!this.$store.state.user.is_login) {
        //   var url = _self.GetUrlRelativePath();
        //   storage.setItem("LOGIN_SKIP", url);
        //   _self.$router.push({path:'/login?action=login'})
        // }
        data.map(function (n) {
          if (n.type == 2) {
            //   type= 1课程 2课程包 3训练营
            // 如果未登录 则只显示原价
            if (_self.$store.state.user.is_login) {
              if (
                n.course_package.goods.notend_pintuan ||
                n.course_package.goods.notend_seckill
              ) {
                n.order_price = n.course_package.goods.order_price;
                n.real_price = n.course_package.goods.real_price;
              } else {
                n.real_price = n.course_package.goods.real_price;
                n.order_price = n.course_package.goods.order_price;
              }
            } else {
              n.order_price = n.course_package.goods.order_price;
              n.real_price = n.course_package.goods.real_price;
            }
          } else if (n.type == 1) {
            //   课程 1.划线价格 没活动的时候 展示原价   有活动的时候展示会员VIP6的价格  2.展示价格有活动的时候展示活动价  没活动的时候展示 自己用户当前等级的价格
            if (_self.$store.state.user.is_login) {
              if (
                n.course.goods.notend_pintuan ||
                n.course.goods.notend_seckill
              ) {
                n.order_price = n.course.goods.order_price;
                n.real_price = n.course.goods.real_price;
              } else {
                // 这里显示如果vip_price有值显示vip6的价格 没有值还是显示原价格
                // if (n.course.goods.vip_price.length > 0) {
                //   // n.real_price = n.course.goods.vip_price[5].price;
                //   if (_self.$store.state.user.now_user_member) {
                //     n.course.goods.vip_price.map(function (s) {
                //       if (
                //         s.user_member_config_id ==
                //         _self.$store.state.user.now_user_member
                //           .user_member_config_id
                //       ) {
                //         n.order_price = s.price;
                //         if (n.order_pric != n.course.goods.real_price) {
                //           n.real_price = n.course.goods.real_price;
                //         }
                //       }
                //     });
                //   } else {
                //     n.order_price = n.course.goods.order_price;
                //   }
                // } else {
                //   n.order_price = n.course.goods.order_price;
                // }
                n.order_price = n.course.goods.order_price;
              }
            } else {
              n.order_price = n.course.goods.order_price;
            }
          } else {
            // 训练营
            if (_self.$store.state.user.is_login) {
              if (n.camp.goods.notend_pintuan || n.camp.goods.notend_seckill) {
                n.order_price = n.course.goods.order_price;
                n.real_price = n.course.goods.real_price;
              } else {
                // 这里显示如果vip_price有值显示vip6的价格 没有值还是显示原价格
                // if (n.camp.goods.vip_price.length > 0) {
                //   // n.real_price = n.course.goods.vip_price[5].price;
                //   if (_self.$store.state.user.now_user_member) {
                //     n.camp.goods.vip_price.map(function (s) {
                //       if (
                //         s.user_member_config_id ==
                //         _self.$store.state.user.now_user_member
                //           .user_member_config_id
                //       ) {
                //         n.order_price = s.price;
                //         if (n.order_pric != n.camp.goods.real_price) {
                //           n.real_price = n.camp.goods.real_price;
                //         }
                //       }
                //     });
                //   } else {
                //     n.order_price = n.camp.goods.order_price;
                //   }
                // } else {
                //   n.order_price = n.camp.goods.order_price;
                // }
                n.order_price = n.camp.goods.order_price;
              }
            } else {
              n.order_price = n.camp.goods.order_price;
            }
          }
        });
        _self.list = res.result.items;
        _self.loading = false;
        _self.pagination = res.result.pagination;
      });
    },
    recommend_special() {
      var _self = this;
      _self.loading = true;
      recommend_special({
        course_category_id: _self.course_category_id,
        ..._self.pagination,
      }).then((res) => {
        console.log(res, "推荐");
        var data = res.result.items;
        data.map(function (n) {
          if (n.goods.type == 2) {
            //   type= 1课程 2课程包 3训练营
            // 如果未登录 则只显示原价
            if (_self.$store.state.user.is_login) {
              if (n.goods.notend_pintuan || n.goods.notend_seckill) {
                n.order_price = n.goods.order_price;
                n.real_price = n.goods.real_price;
              } else {
                n.real_price = n.goods.real_price;
                n.order_price = n.goods.order_price;
              }
            } else {
              n.order_price = n.goods.order_price;
              n.real_price = n.goods.real_price;
            }
          } else if (n.goods.type == 1) {
            //   课程 1.划线价格 没活动的时候 展示原价   有活动的时候展示会员VIP6的价格  2.展示价格有活动的时候展示活动价  没活动的时候展示 自己用户当前等级的价格
            if (_self.$store.state.user.is_login) {
              if (n.goods.notend_pintuan || n.goods.notend_seckill) {
                n.order_price = n.goods.order_price;
                n.real_price = n.goods.real_price;
              } else {
                // 这里显示如果vip_price有值显示vip6的价格 没有值还是显示原价格
                if (n.goods.vip_price.length > 0) {
                  // n.real_price = n.course.goods.vip_price[5].price;
                  if (_self.$store.state.user.now_user_member) {
                    n.goods.vip_price.map(function (s) {
                      if (
                        s.user_member_config_id ==
                        _self.$store.state.user.now_user_member
                          .user_member_config_id
                      ) {
                        n.order_price = s.price;
                        if (n.order_pric != n.goods.real_price) {
                          n.real_price = n.goods.real_price;
                        }
                      }
                    });
                  } else {
                    n.order_price = n.goods.order_price;
                  }
                } else {
                  n.order_price = n.goods.order_price;
                }
              }
            } else {
              n.order_price = n.goods.order_price;
            }
          } else {
            // 训练营
            if (_self.$store.state.user.is_login) {
              if (n.goods.notend_pintuan || n.goods.notend_seckill) {
                n.order_price = n.course.goods.order_price;
                n.real_price = n.course.goods.real_price;
              } else {
                // 这里显示如果vip_price有值显示vip6的价格 没有值还是显示原价格
                if (n.goods.vip_price.length > 0) {
                  // n.real_price = n.course.goods.vip_price[5].price;
                  if (_self.$store.state.user.now_user_member) {
                    n.goods.vip_price.map(function (s) {
                      if (
                        s.user_member_config_id ==
                        _self.$store.state.user.now_user_member
                          .user_member_config_id
                      ) {
                        n.order_price = s.price;
                        if (n.order_pric != n.goods.real_price) {
                          n.real_price = n.goods.real_price;
                        }
                      }
                    });
                  } else {
                    n.order_price = n.goods.order_price;
                  }
                } else {
                  n.order_price = n.goods.order_price;
                }
              }
            } else {
              n.order_price = n.goods.order_price;
            }
          }
        });
        _self.topList = data;
        _self.loading = false;
        _self.pagination = res.result.pagination;
      });
    },
    changePage(page, page_size) {
      console.log(page, "变化");
      this.pagination.page = page;
      if(this.type == 't'){
        this.recommend_special();
      }else{
        this.getData();
      }
    },
    toCourseInfo(item) {
      //前往课程详情页
      this.$router.push({ path: "/course/info/" + item.course_id });
    },
    toCoursePackageInfo(item) {
      //前往课程包详情页
      console.log(item, "课程包");
      if (item.course_package.style_temp == 0) {
        this.$router.push({
          path: "/course-package/info/" + item.course_package_id,
        });
      }
      if (item.course_package.style_temp == 1) {
        this.$router.push({
          path: "/course/courseGroupList/" + item.course_package_id,
        });
      }
    },
    toCampInfo(item) {
      this.$router.push({
        path: "/camp/info/" + item.camp_id,
      });
    },
    toCourseDetail(item) {
      console.log(item);
      if (item.goods.type == 1) {
        this.$router.push({
          path: "/course/info/" + item.goods.relation.course_id,
        });
      }
      if (item.goods.type == 2 && item.goods.relation.style_temp == 0) {
        this.$router.push({
          path: "/course-package/info/" + item.goods.relation.course_package_id,
        });
      }
      if (item.goods.type == 2 && item.goods.relation.style_temp == 1) {
        this.$router.push({
          path:
            "/course/courseGroupList/" + item.goods.relation.course_package_id,
        });
      }
      if (item.goods.type == 3) {
        this.$router.push({
          path: "/camp/info/" + item.goods.relation.camp_id,
        });
      }
    },
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");
      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
      return relUrl;
    },
  },
};
</script>

<style scoped>
.sr-list-margin {
  margin: 0 20px;
}

.kc-listTex h3 {
  font-weight: 900;
  font-size: 18px;
}

.kc-listBg {
  background: url(/sr/img/KC-listBg.jpg) #ffffff no-repeat top right;
}

.kc-avatar {
  margin-top: 20px;
}
.kc-list {
  margin-right: 22px;
  position: relative;
  height: 330px;
}
.dwss{
   position: absolute;
  bottom: 10px;
  width: 95%;
}
</style>
