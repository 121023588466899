<template>
  <div class="warp mt30">
    <div class="xly-conT clearfix">
      <a-icon
        type="left"
        @click="goPrev"
        style="
          float: left;
          font-size: 26px;
          margin-right: 10px;
          cursor: pointer;
          padding-top: 10px;
        "
      />
      <p class="fn_l f22">
        <img class="dis-inb" src="@/assets/img/KC-tbg.png" /> {{ task.name }}
      </p>
      <p class="fn_r te_r">
        <img class="dis-inb" @click="share" src="@/assets/img/xun_share.png" />
      </p>
    </div>

    <!-- 视频 -->
    <div class="xun1_video" style="height: 500px">
      <BjDianbo ref="BjDianbo"></BjDianbo>
    </div>
    <Share ref="Share"></Share>
    <ReCourseBig></ReCourseBig>
  </div>
</template>

<script>
import { themeTaskDetail, video, doTask } from "@/api/camp";
import BjDianbo from "@/components/BjDianbo";
import ReCourseBig from "@/layout/recommend/ReCourseBig";
import Share from "../theme/module/Share";

export default {
  name: "index",
  components: {
    ReCourseBig,
    BjDianbo,
    Share,
  },
  data() {
    return {
      camp_id: 0,
      camp_theme_id: 0,
      camp_theme_task_id: 0,
      camp: {},
      time: 0,
      task: {
        video: {},
      },
    };
  },
  mounted() {
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.goBack, false);
    // }
  },
  destroyed() {
          // history.go(-1);
      //  if (this.user_task_count == 0) {
      //     this.goBack()
      // }else{
      // }
  },
  created() {
    var _self = this;
    this.camp_id = this.$route.params.camp_id;
    this.camp_theme_id = this.$route.params.camp_theme_id;
    this.camp_theme_task_id = this.$route.params.camp_theme_task_id;
    this.user_task_count = this.$route.params.user_task_count;
    console.log(this.user_task_count, "用户状态");
    video({ camp_theme_task_id: this.camp_theme_task_id }).then((res) => {
      console.log(111);
      _self.$refs.BjDianbo.init(res.result.token, res.result.video_id);
    });
    themeTaskDetail({
      camp_theme_id: this.camp_theme_id,
      camp_theme_task_id: this.camp_theme_task_id,
      camp_id: this.camp_id,
    }).then((res) => {
      _self.task = res.result;
      console.log(_self.task);
      //启动计时器
      var timer = setInterval(() => {
        if (_self.time < _self.task.must_time) {
          _self.time++;
        } else {
          doTask({
            camp_id: _self.camp_id,
            camp_theme_id: _self.camp_theme_id,
            camp_theme_task_id: _self.camp_theme_task_id,
          });
          clearInterval(timer);
        }
      }, 1000);
    });
  },
  methods: {
    share() {
      this.$refs.Share.show();
    },
    // goBack() {
    //   this.$srmsg
    //     .confirm({
    //       title: "提示",
    //       content: "学习还未完成 确定要退出吗",
    //     })
    //     .then(() => {
    //       console.log("正确");
    //       clearInterval(timer);
    //       window.history.back(-1);
    //     })
    //     .catch(() => {
    //       console.log("错误");
    //       history.pushState(null, null, document.URL);
    //       window.addEventListener("popstate", this.goBack, false);
    //     });
    // },
    goPrev() {
         history.go(-1);
    },
  },
};
</script>

<style scoped>
</style>
