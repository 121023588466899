<template>
  <div>
    <div class="set set7" v-if="mobileType == 1">
      <div class="set7_p">
        <p>请输入{{ mobile }}收到的短信验证码</p>
      </div>
      <div class="set7_nr">
        <ul>
          <li style="position: relative">
            <div class="ss_yzm1">
              <img src="@/assets/img/suo.png" />
              <input
                placeholder="请输入验证码"
                v-model="oldCode"
                class="ss_set7"
              />
            </div>
            <SrButton
              :disabled="sms_time !== 0"
              @click="
                sendMessage(
                  $store.state.user.mobile,
                  'ModifyMessageValidationCode'
                )
              "
              class="login-hqyzm"
              height="33px"
              padding="0 20px"
              font-size="14px"
              :loading="sms_loading"
            >
              <span v-if="sms_time === 0">获取验证码</span>
              <span v-else>重新发送({{ sms_time }})</span>
            </SrButton>
            <!-- <div class="ss_yzm ss_yzm3">
                        
                        </div> -->
          </li>
        </ul>
      </div>
      <div class="set7_a">
        <a @click="openShow">验证码有问题？</a>
      </div>
      <div class="set7_a1 set7_a2">
        <SrButton
          style="width: 100%"
          font-size="14px"
          height="33px"
          padding="0 20px"
          @click="checkModifyMessage"
          :loading="checkModify_loading"
        >
          下一步
        </SrButton>
      </div>
    </div>
    <!-- 新手机号页面 -->
    <div class="set set7" v-else>
      <div class="set7_p">
        <p>请输入新手机号</p>
      </div>
      <div class="set7_nr">
        <ul>
          <li>
            <div class="ss_yzm1">
              <img src="@/assets/img/suo.png" />
              <input
                placeholder="请输入手机号"
                v-model="newMobile"
                class="ss_set7"
              />
            </div>
          </li>
          <li style="position: relative">
            <div class="ss_yzm1">
              <img src="@/assets/img/suo.png" />
              <input
                placeholder="请输入验证码"
                v-model="newCode"
                class="ss_set7"
              />
            </div>
            <SrButton
              :disabled="sms_time !== 0"
              @click="
                sendMessage(
                  newMobile,
                  'ChangePhoneMessageInformationChangeCode'
                )
              "
              class="login-hqyzm"
              height="33px"
              padding="0 20px"
              font-size="14px"
              :loading="sms_loading"
            >
              <span v-if="sms_time === 0">获取验证码</span>
              <span v-else>重新发送({{ sms_time }})</span>
            </SrButton>
          </li>
        </ul>
      </div>
      <div class="set7_a">
        <a @click="openShow">验证码有问题？</a>
      </div>
      <div class="set7_a1 set7_a2">
        <SrButton
          style="width: 100%"
          @click="changePhone"
          font-size="14px"
          height="33px"
          padding="0 20px"
          :loading="changePhone_loading"
        >
          提 交
        </SrButton>
      </div>
    </div>
     <div class="motal" v-show="showQuestion">
      <div class="motal_box">
        <h3>验证码有问题？</h3>
        <p>您可以直接拨打我们的客户电话进行反馈，我们将全心全意提供服务！</p>
        <p>联系电话：400-1380095；</p>
        <p>工作时间：早上9点-晚上6点；</p>
      </div>
      <img @click="closeShow" src="@/assets/img/modal-close.png" alt="" />
    </div>
  </div>
</template>
<script>
var timer = null;
import SrButton from "@/components/SrButton";
import storage from "@/utils/storage";
import { checkModifyMessage, changePhone } from "@/api/user";
import { sendMessage } from "@/api/common";
export default {
  name: "Pintuan",
  components: {
    SrButton,
  },
  props: {
    course: {},
  },
  data() {
    return {
      changePhone_loading: false,
      setNewPassword_loading: false,
      editUserPassword_loading: false,
      sms_time: 0,
      sms_loading: false,
      newCode:'',
      newMobile:'',
      mobileType:1,
      checkModify_loading:false,
      oldCode:'',
      mobile:'',
      showQuestion:false
    };
  },
  filters: {
    toTime(value) {
      return new Date(value).getTime();
    },
  },
  mounted() {
    var _self = this;
    var sms_time = storage.getItem("sms_time");
    var phone = this.$store.state.user.mobile;
    this.mobile = phone.substring(0, 3) + "****" + phone.substring(7, 11);
    if (sms_time > 0) {
      _self.sms_time = sms_time;
      timer = setInterval(function () {
        _self.sms_time--;
        storage.setItem("sms_time", _self.sms_time);
        if (_self.sms_time <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  },
  methods: {
    // 发送验证码
    sendMessage(phone, scenario) {
      console.log(phone);
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      var form = new Object();
      form.phone = phone;
      form.scenario = scenario;
      _self.sms_loading = true;
      sendMessage(form).then((res) => {
        if (res.status === 200) {
          _self.sms_loading = false;
          _self.sms_time = 60;
          timer = setInterval(function () {
            _self.sms_time--;
            storage.setItem("sms_time", _self.sms_time);
            if (_self.sms_time <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          _self.$message.warning(res.msg);
          _self.sms_loading = false;
        }
      });
    },
    // 修改手机号旧手机号验证码
    checkModifyMessage() {
      let _this = this;
      if (_this.oldCode == "") {
        _this.$message.warning("请输入验证码");
        return;
      }
      _this.checkModify_loading = true;
      checkModifyMessage({
        phone: _this.$store.state.user.mobile,
        code: _this.oldCode,
      }).then((res) => {
        console.log(res, "------");
        if (res.status === 200) {
          _this.checkModify_loading = false;
          _this.mobileType = 2;
          _this.sms_time = 0;
          _this.sms_loading = false;
          storage.setItem("sms_time", 0);
          clearInterval(timer);
        } else {
          _this.$message.warning(res.msg);
          _this.checkModify_loading = false;
        }
      });
    },
    // 执行修改手机号
    changePhone() {
      let _this = this;
      if (_this.newMobile == "") {
        _this.$message.warning("请输入新手机号");
        return;
      }
      if (_this.newCode == "") {
        _this.$message.warning("请输入新手机号");
        return;
      }
      _this.changePhone_loading = true;
      changePhone({ phone: _this.newMobile, code: _this.newCode }).then(
        (res) => {
          if (res.status === 200) {
            _this.$message.success("修改成功");
            _this.sms_loading = false;
            storage.setItem("sms_time", 0);
            _this.sms_time = 0;
            setTimeout(() => {
              _this.$router.go(0);
            }, 1000);
            _this.changePhone_loading = false;
          } else {
            _this.$message.warning(res.msg);
            _this.changePhone_loading = false;
          }
        }
      );
    },
     openShow() {
      this.showQuestion = true;
    },
    closeShow() {
      this.showQuestion = false;
    },
  },
};
</script>
<style scoped>
.motal {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.motal_box {
  background: url(../../../../../assets/img/txtx.png) no-repeat;
  margin: 0 auto;
  width: 455px;
  height: 322px;
  padding: 60px 50px;
  margin-top: 200px;
  z-index: 1000;
}
.motal_box h3 {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}
.motal_box p {
  margin-bottom: 10px;
}
.motal img {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
}
</style>